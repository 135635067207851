import types from "../types";
const initialState = {
  token: null,
  data: null,
  isAuthenticated: false,
};

const initialStateLoading = false;
const initialStatePermissions = {};
const initialStateCategoria = "";
const initialStateEspecialistas = [];
const initialStateSubespecialidad = [];
const initialStatePractica=[];

export const userDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.login: {
      return {
        token: action.payload.token,
        data: action.payload.user,
        isAuthenticated: true,
      };
    }
    case types.logout: {
      return initialState;
    }
    default:
      return state;
  }
};

export const loadingReducer = (state = initialStateLoading, action) => {
  switch (action.type) {
    case types.isLoading: {
      return action.payload;
    }
    default:
      return state;
  }
};

export const permissionsReducer = (state = initialStatePermissions, action) => {
  switch (action.type) {
    case types.permissions: {
      return action.payload;
    }
    default:
      return state;
  }
};

export const categoriaReducer = (state = initialStateCategoria, action) => {
  switch (action.type) {
    case types.categoria: {
      return action.payload;
    }
    default:
      return state;
  }
};

export const especialistasReducer = (
  state = initialStateEspecialistas,
  action
) => {
  switch (action.type) {
    case types.especialistas: {
      return action.payload;
    }
    default:
      return state;
  }
};

export const subespecialidadReducer = (
  state = initialStateSubespecialidad,
  action
) => {
  switch (action.type) {
    case types.subespecialidad: {
      return action.payload;
    }
    default:
      return state;
  }
};

export const practicaReducer = (
  state = initialStatePractica,
  action
) => {
  switch (action.type) {
    case types.practicas: {
      return action.payload;
    }
    default:
      return state;
  }
};
