/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from "react";
import { Form, Row, Col, Button, Card } from "react-bootstrap";
import JoditEditor, { Jodit } from "jodit-react";
import { useFormik } from "formik";
import { Fetch, catchError } from "../../../utils/fetch";
import { useDispatch, useSelector } from "react-redux";
import ImageUploader from "../../../utils/image-uploader";
import { AlertSuccess, AlertInfo } from "../../../components/alert";
import { loadingAction } from "../../../redux/actions";
import { useHistory, useParams } from "react-router-dom";
import MediaUploader from "../../../utils/media-uploader";
import { EstadoContenido } from "../../../utils/constants";
import { eliminarImagen, removeSpecial } from "../../../utils/tools";
import CategoriasComponent from "../../../components/categoria";
import LayoutJodit from "../../../components/customizer/layout-jodit";

const VerNovedadX = () => {
  const params = useParams();
  const id = params.id;
  const dispatch = useDispatch();
  const history = useHistory();
  const isLoading = useSelector((state) => state.loading);
  const permissions = useSelector((state) => state.permissions);
  const canPublish = permissions.novedades.aprobar.enabled;
  const [categoriasAsociadas, setCategoriasAsociadas] = React.useState([]);
  const [subcategoriasSeleccionadas, setSubCategoriasSeleccionadas] =
    React.useState([]);

  const [layoutJodit, setLayoutJodit] = React.useState("PC");
  const editor = React.useRef(null);

  // function preparePaste(jodit) {
  //     jodit.e.on(
  //         'paste',
  //         e => {

  //             jodit.e.stopPropagation('paste');
  //             let plainText = Jodit.modules.Helpers.getDataTransfer(e)
  //                 .getData(Jodit.constants.TEXT_PLAIN);
  //             // plainText = plainText.replace(/(^|;)\s*font-[^;]+/g, "");
  //             // let regex = /<(?!(\/\s*)?(div|b|i|em|strong|u|p)[>,\s])([^>])*>/g;
  //             // plainText = plainText.replace(regex, '');
  //             plainText = plainText.normalize('NFKC').replaceAll("\n","<br/>");
  //             plainText=removeSpecial(plainText)

  //             jodit.s.insertHTML(
  //                 plainText
  //             );
  //             return false;

  //         },
  //         { top: true }
  //     );
  // }
  // Jodit.plugins.add('preparePaste', preparePaste);

  const config = useMemo(
    () => ({
      readonly: false, // all options from https://xdsoft.net/jodit/doc/
      height: 500,
      language: "es",
      disablePlugins: ["table"],
      askBeforePasteHTML: false,
      askBeforePasteFromWord: false,
      defaultActionOnPaste: "insert_clear_html",
    }),
    []
  );

  React.useEffect(() => {
    if (id) {
      getNovedadesById();
    }
  }, []);

  const getNovedadesById = async (values) => {
    // ?_publicationState=preview&published_at_null=true
    dispatch(loadingAction(true));
    try {
      const { data } = await Fetch(
        `novedades/${id}?_publicationState=preview&published_at_null=true`,
        "get"
      );
      setSubCategoriasSeleccionadas(data.subcategorias);
      setValues(data);
      dispatch(loadingAction(false));
    } catch (error) {
      catchError(error);
      dispatch(loadingAction(false));
    }
  };

  const submit = async (values) => {
    if (!subcategoriasSeleccionadas.length) {
      AlertInfo("Debe de seleccionar por lo menos una subcategoría");
      return;
    }

    values.subcategorias = subcategoriasSeleccionadas;

    values.published_at = new Date();
    values.estado = EstadoContenido.Publicado;

    values.descripcion = removeSpecial(values.descripcion);

    dispatch(loadingAction(true));
    try {
      const { data } = await Fetch(
        "novedades/aprobar/" + (values.idPadre === null ? id : values.idPadre),
        "put",
        values
      );

      AlertSuccess("Novedad actualizada con éxito");

      dispatch(loadingAction(false));

      history.push("/novedades/listax");
    } catch (error) {
      catchError(error);
      dispatch(loadingAction(false));
    }
  };

  const onCancelar = async () => {
    dispatch(loadingAction(true));

    const params = {
      published_at: null,
      estado: EstadoContenido.EnRevision,
    };

    try {
      const { data } = await Fetch("novedades/" + id, "put", params);

      dispatch(loadingAction(false));
      AlertSuccess("Se ha cancelado la publicación de la novedad con éxito");

      history.push("/novedades/lista");
    } catch (error) {
      dispatch(loadingAction(false));
      catchError(error);
    }
  };

  const { values, handleSubmit, handleChange, setFieldValue, setValues } =
    useFormik({
      initialValues: {
        titulo: "",
        descripcionCorta: "",
        descripcion: "",
        imagen: {},
        destacado: null,
        slug: "",
        orden: 0,
        habilitado: true,
        locale: "es",
      },
      enableReinitialize: true,
      onSubmit: (values) => submit(values),
    });

  const handleBlurTitulo = async (event) => {
    const value = event.target.value;
    const name = event.target.name;
    const dataToApi = {
      contentTypeUID: "application::novedades.novedades",
      field: "slug",
      data: {
        titulo: value,
        slug: "",
      },
    };
    setFieldValue(name, value, false);
    try {
      const { data } = await Fetch(
        "content-manager/uid/generate",
        "post",
        dataToApi
      );
      setFieldValue("slug", data.data, false);
    } catch (error) {
      console.error("mostrando error", error);
    }
  };

  const handleChangeJoiEditor = (data, field) => {
    setFieldValue(field, data, false);
  };

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit}>
        <Row>
          <Col>
            <Card>
              <Card.Header>
                <Row>
                  <Col>Información de la novedad</Col>
                </Row>
              </Card.Header>
              <Card.Body>
                <Row>
                  <Col>
                    <Form.Group>
                      <label>Titulo de la novedad</label>
                      <input
                        type="text"
                        name="titulo"
                        onChange={handleChange}
                        onBlur={(e) => handleBlurTitulo(e)}
                        value={values.titulo}
                        className="form-control "
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group>
                      <label>Url</label>
                      <input
                        type="text"
                        name="slug"
                        className="form-control"
                        value={values.slug}
                        readOnly
                      />
                    </Form.Group>
                  </Col>
                </Row>
                {Object.keys(values?.imagen || {}).length > 0 && (
                  <>
                  <Row>
                    <Col>
                      <Form.Group>
                        <img
                          src={values.imagen.url}
                          alt="vista-previa"
                          className="img-fluid w-50"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                      <Col>
                        <Button
                          type="button"
                          style={{ marginBottom: "10px" }}
                          onClick={() => {
                            eliminarImagen(values.imagen.id).then(
                              (response) => {
                                setFieldValue("imagen", "", false);
                              }
                            ).catch(error=>{}); ;
                          }}
                          className="btn btn-danger btn-icon mt-2"
                        >
                          <i className="fa fa-trash-o " />
                          Eliminar
                        </Button>
                      </Col>
                    </Row>
                  </>
                )}

                <Row>
                  <Col>
                    <Form.Group>
                      <label>Imagen</label>
                      <MediaUploader
                        setFieldValue={setFieldValue}
                        field={"imagen"}
                        tipoMedia="imagen"
                        ancho={600}
                        alto={270}
                      />
                      <span className="text-muted">
                        Se recomienda una imagen de 600px de ancho x 270px de
                        alto. En formato JPG o PNG.
                      </span>
                    </Form.Group>
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Col>
                    <h3>Información del contenido</h3>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group>
                      <label>Destacado</label>
                      <select
                        className="form-control"
                        name="destacado"
                        value={values.destacado}
                        onChange={handleChange}
                      >
                        <option>Seleccionar</option>
                        <option value={true}>Si</option>
                        <option value={false}>No</option>
                      </select>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group>
                      <label>Descripcion Corta</label>
                      <textarea
                        name="descripcionCorta"
                        onChange={handleChange}
                        className="form-control"
                        value={values.descripcionCorta}
                        maxLength={90}
                      ></textarea>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col className={`${layoutJodit == "MOBILE" && "col-6"}`}>
                    <Form.Group>
                      <label>Descripcion</label>
                      <LayoutJodit
                setLayoutJodit={setLayoutJodit}
                layoutJodit={layoutJodit}
              />
                      <JoditEditor
                        ref={editor}
                        config={config}
                        value={values.descripcion}
                        tabIndex={1} // tabIndex of textarea
                        onBlur={(newContent) =>
                          handleChangeJoiEditor(newContent, "descripcion")
                        }
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <CategoriasComponent
                  setCategoriasAsociadas={setCategoriasAsociadas}
                  categoriasAsociadas={categoriasAsociadas}
                  subcategoriasSeleccionadas={subcategoriasSeleccionadas}
                  setSubCategoriasSeleccionadas={setSubCategoriasSeleccionadas}
                  seccion="novedades"
                />

                <Row className="mt-5">
                  <Col className="col col-lg-2">
                    <Button
                      type="button"
                      onClick={() => handleSubmit()}
                      disabled={isLoading || !canPublish}
                      className="btn btn-success btn-icon"
                    >
                      <i className="fa fa-floppy-o" />
                      Publicar
                    </Button>
                  </Col>
                  <Col className="col col-lg-3">
                    <Button
                      type="button"
                      onClick={() => onCancelar()}
                      disabled={isLoading}
                      className="btn btn-primary btn-icon"
                    >
                      <i className="fa fa-undo " />
                      Por revisar
                    </Button>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </form>
    </React.Fragment>
  );
};
export default VerNovedadX;
