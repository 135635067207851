import React from "react";
import {
  Row,
  Col,
  Card,
  Table,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { Fetch, catchError } from "../../../utils/fetch";
import { AlertConfirm } from "../../../components/alert";
import { connect } from "react-redux";
import { loadingAction } from "../../../redux/actions";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import DataGridForm from "../../../components/common/DataGridForm";
import { copyToClickBoard } from "../../../utils/slug";
import { formatDate } from "../../../utils/formatDate";
import CustomStore from "devextreme/data/custom_store";

function ListaPrensa({ isLoadingAction, isLoading }) {
  const [contenido, setContenido] = React.useState(null);
  const [state, setState] = React.useState({
    filter: "",
    random: Math.random(),
  });

  React.useEffect(() => {
    //getContenido()
    setContenido(
      new CustomStore({
        key: "id",
        load(loadOptions) {
          const resultado = Fetch(`prensas/aprobadas/filtrar`, "post", {
            pageIndex: loadOptions.skip / loadOptions.take + 1,
            pageSize: loadOptions.take,
            filter: state.filter,
            random: state.random,
            filterColumns: loadOptions.filter,
            sort: loadOptions.sort,
          }).then((response) => {
            return {
              data: response?.data?.prensas ?? [],
              totalCount: parseInt(response?.data?.TotalCount) ?? 0,
            };
          });

          return resultado;
        },
      })
    );
  }, [state.random]);
  // const getContenido = async () => {
  //     isLoadingAction(true)
  //     try {
  //         const { data } = await Fetch('prensas?_eliminado=false&_limit=-1', 'get')
  //         setContenido(data)
  //         isLoadingAction(false)
  //     } catch (error) {
  //         console.error('mostrando error', error)
  //         isLoadingAction(false)
  //         catchError(error);
  //     }
  // }

  const deleteContenido = async (id) => {
    isLoadingAction(true);
    try {
      const params = {
        eliminado: true,
      };
      await Fetch("prensas/" + id, "put", params);
      // getContenido()
      setState({ ...state, random: Math.random() });
      isLoadingAction(false);
    } catch (error) {
      console.error("mostrando error", error);
      isLoadingAction(false);
      catchError(error);
    }
  };

  function RenderAlert(title, id) {
    AlertConfirm(title, "question", (response) => {
      if (response.isConfirmed) {
        deleteContenido(id);
      }
    });
  }

  const ordenContenido = async (e) => {
    try {
      isLoadingAction(true);

      let request = [];
      e.forEach(async (item, indice) => {
        const params = {
          orden: indice,
        };
        request.push(Fetch("prensas/" + item.id, "put", params));
      });

      Promise.all(request).then((response) => {
        isLoadingAction(false);
      });
    } catch (error) {
      isLoadingAction(false);
      catchError(error);
    }
  };

  const handlerOnOff = async (prmId, value) => {
    isLoadingAction(true);
    try {
      const params = {
        onOff: value,
      };
      await Fetch("prensas/seleccionar/" + prmId + "/" + value, "put");
      // getContenido()
      setState({ ...state, random: Math.random() });
      isLoadingAction(false);
    } catch (error) {
      isLoadingAction(false);
      catchError(error);
    }
  };

  // const onReorder = async (e) => {
  //     const visibleRows = e.component.getVisibleRows();
  //     const newData = [...contenido];
  //     const toIndex = newData.indexOf(visibleRows[e.toIndex].data);
  //     const fromIndex = newData.indexOf(e.itemData);

  //     newData.splice(fromIndex, 1);
  //     newData.splice(toIndex, 0, e.itemData);

  //     setContenido(newData);
  //     await ordenContenido(newData)
  // }

  function FActualizacion(data) {
    return formatDate(data?.updated_at);
  }

  function OnOff(data) {
    return (
      <div className="custom-control custom-switch">
        <input
          type="checkbox"
          checked={data.onOff}
          className="custom-control-input"
          onChange={(v) => handlerOnOff(data.id, v.target.checked)}
          id={"csPublicado" + data.id}
        />
        <label
          role="button"
          className="custom-control-label"
          for={"csPublicado" + data.id}
        ></label>
      </div>
    );
  }

  function Imagen(data) {
    return data?.imagen?.formats?.thumbnail?.url ? (
      <>
        <img
          src={data.imagen.formats.thumbnail.url}
          style={{
            width: "64px",
            height: "64px",
            borderRadius: "32px",
            objectFit: "cover",
          }}
          className=""
          alt="images"
        />
      </>
    ) : null;
  }

  function Destacado(data) {
    return data.programacion ? (
      <h4>
        {" "}
        <span className="badge bg-success">Si</span>{" "}
      </h4>
    ) : (
      <span className="badge bg-secondary">No</span>
    );
  }

  function Accion(data) {
    return (
      <>
        <Link
          to={"/prensa/ver/" + data.id}
          className="btn btn-sm btn btn-success"
        >
          <i className="fa fa-pencil" />
        </Link>{" "}
        <button
          type="button"
          className="btn btn-sm btn btn-danger"
          onClick={() => {
            RenderAlert("Está apunto de eliminar este contenido", data.id);
          }}
        >
          <i className="fa fa-trash" />
        </button>{" "}
      </>
    );
  }

  return (
    <React.Fragment>
      <Row>
        <Col md="12">
          <Card>
            <Card.Header>
              <div className="float-right mt-10">
                <Link
                  to="/prensa/nuevo"
                  className="btn btn-primary btn-rounded box-shadow btn-icon"
                >
                  <i className="fa fa-plus" /> Nuevo contenido prensa
                </Link>
              </div>
              Listado de contenido prensa publicada
            </Card.Header>
            <Card.Body>
              <DataGridForm
                dataSource={contenido}
                remoteOperations={true}
                // keyExpr='id'
                // allowReordering={false}
                // onReorder={onReorder}
                // columnHidingEnabled={true}
                columns={[
                  { caption: "Id", dataField: "id" },
                  { caption: "Título", dataField: "titulo" },
                  { caption: "Medio", dataField: "medio" },
                  { caption: "Fecha", dataField: "fecha" },
                  { caption: "Url", dataField: "url" },
                  { caption: "Creado por", dataField: "creado_por.username" },
                  {
                    caption: "Actualizado por",
                    dataField: "actualizado_por.username",
                  },
                  {
                    caption: "Ult. Actualización",
                    render: "FActualizacion",
                    cellRender: { FActualizacion },
                  },
                  {
                    caption: "Acción",
                    alignment: "center",
                    render: "Accion",
                    cellRender: { Accion },
                    hidingPriority: 0,
                  },
                ]}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    isLoadingAction: (data) => {
      dispatch(loadingAction(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ListaPrensa);
