/* eslint-disable react-hooks/exhaustive-deps */
import { Form, Row, Col, Button, Card } from "react-bootstrap";
import { useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import React from "react";

import { AlertInfo, AlertSuccess } from "../../../../components/alert";
import { EstadoContenido } from "../../../../utils/constants";
import { Fetch, catchError } from "../../../../utils/fetch";
import { loadingAction } from "../../../../redux/actions";
import { validateModel } from "./validationSchema";

const VerHomeWhatsapp = () => {
  const userType = useSelector((state) => state.user.data.role.type);
  const isLoading = useSelector((state) => state.loading);
  const dispatch = useDispatch();
  const history = useHistory();

  const permissions = useSelector((state) => state.permissions);  
  const canPublish = permissions['seccion-home-whatsapp'].publicar?.enabled;
  

  const { pathname } = useLocation();
  const [isRevesion] = React.useState(pathname.includes("revision"));
  const [isEmpy, setIsEmpy] = React.useState(true);

  React.useEffect(() => {
    getHomeWhatsapp();
  }, []);

  const getHomeWhatsapp = async () => {
    dispatch(loadingAction(true));

    try {
      const estado = isRevesion ? "ER" : "EA";
      const { data } = await Fetch(
        `seccion-home-whatsapps?_publicationState=preview&estado=${estado}`,
        "get"
      );

      if (!data?.length) {
        dispatch(loadingAction(false));

        if (isRevesion) {
          AlertInfo("No hay información que se necesite revisar");
        } else {
          AlertInfo("No hay información que se necesite aprobar");
        }

        return;
      }

      const [values] = data;
      setIsEmpy(false);

      setValues(values);
      dispatch(loadingAction(false));
    } catch (error) {
      catchError(error);
      dispatch(loadingAction(false));
    }
  };

  const onEnviarAprobar = async (values) => {
    dispatch(loadingAction(true));

    try {
      values.estado = EstadoContenido.EnAprobacion;
      values.published_at = null;

      await Fetch(`seccion-home-whatsapps/${values.id}`, "put", values);

      AlertSuccess(
        "Home encabezado actualizado con éxitos, pendiente de aprobación"
      );
      dispatch(loadingAction(false));

      if (userType != "creador") {
        history.push("/configuracion/home-encabezado/aprobar/");
      } else {
        history.push("/configuracion/home-encabezado/ver/");
      }
    } catch (error) {
      catchError(error);
      dispatch(loadingAction(false));
    }
  };

  const onPublicar = async (values) => {
    dispatch(loadingAction(true));

    try {
      await Fetch(`seccion-home-whatsapps/delete-by-estado/PU`, "delete");
    } catch (error) {
      console.error(error);
    }

    try {
      values.estado = EstadoContenido.Publicado;
      values.published_at = new Date();

      await Fetch(`seccion-home-whatsapps/${values.id}`, "put", values);
      AlertSuccess("Home Encabezado publicado con éxitos");

      dispatch(loadingAction(false));
      history.push("/configuracion/home-encabezado/ver/");
    } catch (error) {
      catchError(error);
      dispatch(loadingAction(false));
    }
  };

  const onPorRevisar = async (values) => {
    dispatch(loadingAction(true));

    try {
      values.estado = EstadoContenido.EnRevision;
      await Fetch(`seccion-home-whatsapps/${values.id}`, "put", values);

      AlertSuccess(
        "Home encabezado actualizado con éxitos, pendiente de revisión"
      );
      dispatch(loadingAction(false));

      history.push("/configuracion/home-encabezado/revision/");
    } catch (error) {
      catchError(error);
      dispatch(loadingAction(false));
    }
  };

  const submit = async (values) => {
    dispatch(loadingAction(true));

    try {
      values.estado = EstadoContenido.EnRevision;
      await Fetch(`seccion-home-whatsapps/${values.id}`, "put", values);

      AlertSuccess("Home Encabezado actualizado con éxitos");
      dispatch(loadingAction(false));
    } catch (error) {
      catchError(error);
      dispatch(loadingAction(false));
    }
  };

  const { values, handleSubmit, handleChange, handleBlur, setValues, errors } =
    useFormik({
      initialValues: {
        contenido: "",
        callCenter: "",
      },
      enableReinitialize: true,
      validationSchema: validateModel,
      onSubmit: (values) => submit(values),
    });

  return (
    <form onSubmit={handleSubmit}>
      <Row>
        <Col>
          <Card>
            <Card.Header>
              <Row>
                <Col>
                  Información de home encabezado -{" "}
                  {isRevesion ? "En Revisión" : "Por Aprobar"}
                </Col>
              </Row>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col md={12}>
                  <Form.Group>
                    <label>Atención telefónica las 24hs</label>
                    <input
                      type="text"
                      name="callCenter"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="form-control"
                      value={values.callCenter}
                    />
                    <div className="text-danger">{errors?.callCenter}</div>
                  </Form.Group>
                </Col>
                <Col md={12}>
                  <Form.Group>
                    <label>Central de Turnos</label>
                    <input
                      type="text"
                      name="contenido"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="form-control"
                      value={values.contenido}
                    />
                    <div className="text-danger">{errors?.contenido}</div>
                  </Form.Group>
                </Col>
                <Col md={12}>
                  <Form.Group>
                    <label>WhatsApp</label>
                    <input
                      type="text"
                      name="whatsapp"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="form-control"
                      value={values.whatsapp}
                    />
                    <div className="text-danger">{errors?.whatsapp}</div>
                  </Form.Group>
                </Col>
              </Row>

              <Row className="mt-5">
                {isRevesion ? (
                  <>
                    <Col className="col col-lg-3">
                      <Button
                        type="button"
                        onClick={() => handleSubmit()}
                        disabled={isLoading || isEmpy}
                        className="btn btn-success btn-icon mt-2"
                      >
                        <i className="fa fa-floppy-o " />
                        Guardar
                      </Button>
                    </Col>
                    <Col className="col col-lg-3">
                      <Button
                        type="button"
                        onClick={() => onEnviarAprobar(values)}
                        disabled={isLoading || isEmpy}
                        className="btn btn-info btn-icon mt-2"
                      >
                        <i className="fa fa-share " />
                        Enviar aprobar
                      </Button>
                    </Col>
                  </>
                ) : (
                  <>
                    <Col className="col col-lg-3">
                      <Button
                        type="button"
                        onClick={() => onPublicar(values)}
                        disabled={isLoading || isEmpy || !canPublish}
                        className="btn btn-success btn-icon mt-2"
                      >
                        <i className="fa fa-floppy-o " />
                        Publicar
                      </Button>
                    </Col>
                    <Col className="col col-lg-3">
                      <Button
                        type="button"
                        onClick={() => onPorRevisar(values)}
                        disabled={isLoading || isEmpy}
                        className="btn btn-primary btn-icon no-hover3 btn btn-primary btn btn-primary mt-2"
                      >
                        <i className="fa fa-undo " />
                        Por revisar
                      </Button>
                    </Col>
                  </>
                )}
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </form>
  );
};
export default VerHomeWhatsapp;
