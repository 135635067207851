import {
  userDataReducer,
  loadingReducer,
  permissionsReducer,
  categoriaReducer,
  especialistasReducer,
  subespecialidadReducer,
  practicaReducer,
} from "./reducers";
import { configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import thunk from "redux-thunk";

const reducers = combineReducers({
  user: userDataReducer,
  loading: loadingReducer,
  permissions: permissionsReducer,
  categoria: categoriaReducer,
  especialistas: especialistasReducer,
  subespecialidad: subespecialidadReducer,
  practica:practicaReducer
});

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: [thunk],
});

export default store;
