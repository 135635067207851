/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Form, Row, Col, Button, Card } from "react-bootstrap";
import { useFormik } from "formik";
import { Fetch, catchError } from "../../../utils/fetch";
import { useDispatch, useSelector } from "react-redux";
import { AlertSuccess, AlertInfo } from "../../../components/alert";
import { loadingAction } from "../../../redux/actions";
import { useHistory, useParams } from "react-router-dom";
import MediaUploader from "../../../utils/media-uploader";
import { validateModel } from "./validationSchema";
import { EstadoContenido } from "../../../utils/constants";

const VerAccesosX = () => {
  const params = useParams();
  const id = params.id;
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.loading);
  const permissions = useSelector((state) => state.permissions);
  console.log("PERMISSIONS",permissions)
  const canPublish = permissions['nuestros-accesos'].aprobar.enabled;
  const history = useHistory();
  React.useEffect(() => {
    if (id) {
      getAccesosById();
    }
  }, []);

  const getAccesosById = async (values) => {
    dispatch(loadingAction(true));
    try {
      const { data } = await Fetch(
        `nuestros-accesos/${id}?_publicationState=preview&published_at_null=true`,
        "get"
      );
      setValues(data);
      dispatch(loadingAction(false));
    } catch (error) {
      catchError(error);
      dispatch(loadingAction(false));
    }
  };

  const submit = async (values) => {
    try {
      values.published_at = new Date();
      values.estado = EstadoContenido.Publicado;

      dispatch(loadingAction(true));

      await Fetch(
        "nuestros-accesos/aprobar/" +
          (values.idPadre === null ? id : values.idPadre),
        "put",
        values
      );

      AlertSuccess("Acceso actualizado con éxito");

      dispatch(loadingAction(false));
      history.push("/nuestros-accesos/lista");
    } catch (error) {
      catchError(error);
      dispatch(loadingAction(false));
    }
  };

  const onCancelar = async () => {
    try {
      const params = {
        published_at: null,
        estado: EstadoContenido.EnRevision,
      };

      dispatch(loadingAction(true));

      const { data } = await Fetch("nuestros-accesos/" + id, "put", params);

      dispatch(loadingAction(false));
      AlertSuccess("Se ha cancelado la eliminación del acceso con éxito");

      history.push("/nuestros-accesos/lista-borradores");
    } catch (error) {
      dispatch(loadingAction(false));
      catchError(error);
    }
  };

  const {
    values,
    handleSubmit,
    handleChange,
    setFieldValue,
    setValues,
    errors,
  } = useFormik({
    initialValues: {
      imagenAcceso: "",
      imagenFondo: "",
      textoFondo: "",
      tieneEnlace: false,
      enlace: "",
      enlaceInterno: false,
      eliminado: false,
    },
    enableReinitialize: true,
    validationSchema: validateModel,
    onSubmit: (values) => submit(values),
  });

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit}>
        <Row>
          <Col>
            <Card>
              <Card.Header>
                <Row>
                  <Col>Información del acceso</Col>
                </Row>
              </Card.Header>
              <Card.Body>
                {/* Imagen de acceso */}
                {Object.keys(values.imagenAcceso).length > 0 && (
                  <Row>
                    <Col>
                      <Form.Group>
                        <img
                          src={values.imagenAcceso.url}
                          alt="vista-previa"
                          className="img-fluid w-50"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                )}

                <Row>
                  <Col>
                    <Form.Group>
                      <label>Imagen de acceso</label>
                      <MediaUploader
                        setFieldValue={setFieldValue}
                        field={"imagenAcceso"}
                        tipoMedia="imagen"
                      />
                      <div className="text-danger">{errors?.imagenAcceso}</div>
                      <span className="text-muted">
                        Se recomienda una imagen de 384px de ancho por 310px de
                        alto. En formato JPG o PNG.
                      </span>
                    </Form.Group>
                  </Col>
                </Row>

                {/* Imagen de fondo */}
                {Object.keys(values.imagenFondo).length > 0 && (
                  <Row>
                    <Col>
                      <Form.Group>
                        <img
                          src={values.imagenFondo.url}
                          alt="vista-previa"
                          className="img-fluid w-50"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                )}

                <Row>
                  <Col>
                    <Form.Group>
                      <label>Imagen de fondo</label>
                      <MediaUploader
                        setFieldValue={setFieldValue}
                        field={"imagenFondo"}
                        tipoMedia="imagen"
                      />
                      <div className="text-danger">{errors?.imagenFondo}</div>
                      <span className="text-muted">
                        Se recomienda una imagen de 1208px de ancho por 625px de
                        alto. En formato JPG o PNG.
                      </span>
                    </Form.Group>
                  </Col>
                </Row>

                {/* Texto de fondo */}
                {Object.keys(values.textoFondo).length > 0 && (
                  <Row>
                    <Col>
                      <Form.Group>
                        <img
                          src={values.textoFondo.url}
                          alt="vista-previa"
                          className="img-fluid w-50"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                )}

                <Row>
                  <Col>
                    <Form.Group>
                      <label>Texto de fondo</label>
                      <MediaUploader
                        setFieldValue={setFieldValue}
                        field={"textoFondo"}
                        tipoMedia="imagen"
                      />
                      <div className="text-danger">{errors?.textoFondo}</div>
                      <span className="text-muted">
                        Se recomienda una imagen de 384px de ancho por 310px de
                        alto. En formato JPG o PNG.
                      </span>
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <Form.Group>
                      <label>Tiene enlace</label>
                      <select
                        className="form-control"
                        name="tieneEnlace"
                        value={values.tieneEnlace}
                        onChange={handleChange}
                      >
                        <option value={true}>Si</option>
                        <option value={false}>No</option>
                      </select>
                    </Form.Group>
                  </Col>
                </Row>

                {values.tieneEnlace === "true" || values.tieneEnlace ? (
                  <>
                    <Row>
                      <Col>
                        <Form.Group>
                          <label>Enlace</label>
                          <input
                            type="text"
                            name="enlace"
                            onChange={handleChange}
                            className="form-control"
                            value={values.enlace}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group>
                          <label>¿El enlace es interno?</label>
                          <select
                            className="form-control"
                            name="enlaceInterno"
                            value={values.enlaceInterno}
                            onChange={handleChange}
                          >
                            <option value={true}>Si</option>
                            <option value={false}>No</option>
                          </select>
                        </Form.Group>
                      </Col>
                    </Row>
                  </>
                ) : null}

                <Row className="mt-5">
                  <Col className="col col-lg-2">
                    <Button
                      type="button"
                      onClick={() => handleSubmit()}
                      disabled={isLoading || !canPublish}
                      className="btn btn-success btn-icon"
                    >
                      <i className="fas fa-upload" />
                      Publicar
                    </Button>
                  </Col>
                  <Col className="col col-lg-3">
                    <Button
                      type="button"
                      onClick={() => onCancelar()}
                      disabled={isLoading}
                      className="btn btn-primary btn-icon"
                    >
                      <i className="fa fa-undo " />
                      Por revisar
                    </Button>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </form>
    </React.Fragment>
  );
};
export default VerAccesosX;
