import * as Yup from 'yup';

export const validateModel = Yup.object().shape({

    titulo: Yup.string().required('Campo obligatorio'),    
    descripcion: Yup.string().required('Campo obligatorio'),    
    foto: Yup.string().required('Campo obligatorio'),    
    boton: Yup.object().shape({
        nombre: Yup.string().required('Campo obligatorio'),
        url: Yup.string().required('Campo obligatorio'),        
    }),
    // tituloTmp: Yup.string().required('Campo obligatorio'),    
    // descripcionTmp: Yup.string().required('Campo obligatorio'),    
    // fotoTmp: Yup.string().required('Campo obligatorio'),    
    // botonTmpNombre: Yup.string().required('Campo obligatorio'), 
    // botonTmpUrl: Yup.string().required('Campo obligatorio'),    

});