import React, { useState, useEffect } from "react";
import { Form, Row, Col, Modal, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";

import AvatarGenerico from "../../../assets/img/avatar_generico.jpg";
import { especialistasAction, loadingAction } from "../../../redux/actions";
import MediaUploader from "../../../utils/media-uploader";
import { AlertSuccess } from "../../../components/alert";
import {
  findNumeroMayor,
  generarNumeroAleatorioUnico,
} from "../../../utils/tools";

const validateModel = Yup.object().shape({
  nombres: Yup.string().required("Campo obligatorio"),
  puesto: Yup.string().required("Campo obligatorio"),
  especializacion: Yup.string().required("Campo obligatorio"),
});

const initialValues = {
  id: "",
  nombres: "",
  puesto: "",
  especializacion: "",
  avatar: null,
  destacado: false,
};

const ModalEspecialista = ({
  showModal,
  setShowModal,
  especialistaUpd,
  setEspecialistaUpd,
}) => {
  const especialistas = useSelector((state) => state.especialistas);
  const dispatch = useDispatch();
  const { id } = useParams();
  const isLoading = useSelector((state) => state.loading);
  const [especialista, setEspecialista] = useState(initialValues);

  useEffect(() => {
    if (especialistaUpd) {
      setEspecialista({ ...especialistaUpd });
    } else {
      setEspecialista(initialValues);
    }
  }, [especialistaUpd]);

  const formik = useFormik({
    initialValues: { ...especialista },
    enableReinitialize: true,
    validationSchema: validateModel,
    onSubmit: (values) => submit(values),
  });

  const submit = async () => {
    dispatch(loadingAction(true));
    const flag = especialistaUpd ? "upd" : "add";
    if (!formik.values.id) {
      formik.values.id = generarNumeroAleatorioUnico();
    }
    try {
      const values = {
        ...formik.values,
        especialidadId: id,
      };

      if (flag == "add") {
        values.id = findNumeroMayor(especialistas, "id");
        values.id++;

        const espaciealista = { ...values, isNew: true };
        dispatch(especialistasAction([...especialistas, espaciealista]));
      } else {
        let especialista = especialistas.find((x) => x.id == values.id);
        especialista = values;

        const updatedItems = especialistas.filter(
          (item) => item.id !== values.id
        );
        updatedItems.push(especialista);

        updatedItems.sort((a, b) => a.id - b.id);
        dispatch(especialistasAction([...updatedItems]));
      }

      AlertSuccess("Especialista creado con éxito");
      formik.handleReset();

      setShowModal(false);
      dispatch(loadingAction(false));
    } catch (error) {
      dispatch(loadingAction(false));
    }
  };

  return (
    <Modal size={"md"} show={showModal} centered>
      <Modal.Body>
        <form>
          <div className="mt-2">
            <Row>
              <Col>
                <label>Nuevo especialista</label>
              </Col>
            </Row>
            <Col
              className="border"
              style={{
                marginTop: "15px",
                paddingTop: "15px",
                paddingBottom: "15px",
              }}
            >
              <Row>
                <Col className="col-6">
                  <input
                    name="id"
                    onChange={formik.handleChange}
                    className="form-control mb-3"
                    value={formik.values.id}
                    hidden
                  />
                  <Row>
                    <Col>
                      <label>Nombre completo</label>
                      <input
                        name="nombres"
                        onChange={formik.handleChange}
                        className="form-control mb-3"
                        value={formik.values.nombres}
                      />
                      <div className="text-danger">
                        {formik.errors?.nombres}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <label>Puesto</label>
                      <input
                        name="puesto"
                        onChange={formik.handleChange}
                        className="form-control mb-3"
                        value={formik.values.puesto}
                      />
                      <div className="text-danger">{formik.errors?.puesto}</div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <label>Especialización</label>
                      <input
                        name="especializacion"
                        onChange={formik.handleChange}
                        className="form-control mb-3"
                        value={formik.values.especializacion}
                      />
                      <div className="text-danger">
                        {formik.errors?.especializacion}
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col className="col-6">
                  <Row>
                    <Col>
                      <Row>
                        <Col>
                          <Form.Group>
                            <label>Imagen</label>
                            <MediaUploader
                              setFieldValue={formik.setFieldValue}
                              field={"avatar"}
                              tipoMedia="imagen"
                              ancho={600}
                              alto={300}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={8}>
                          <Form.Group>
                            <img
                              src={formik.values.avatar?.url ?? AvatarGenerico}
                              alt="Avatar"
                              className="img-fluid"
                              style={{ height: "11em" }}
                            />
                          </Form.Group>                                                    
                        </Col>
                        <Col md={4}>
                          <Button
                            style={{ marginTop: 120 }}
                            type="button"
                            onClick={() => {
                              formik.setFieldValue("avatar", null);
                            }}
                            className="btn btn-danger btn-icon"
                            disabled={!formik.values.avatar?.url}
                          >
                            <i className="fa fa-trash" />
                            Eliminar
                          </Button>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}><span className="text-muted">Se recomienda una imagen de 600px de ancho x 600px de alto. En formato JPG o PNG.</span></Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </div>

          <Row>
            <Col md={12}>
              <div className="mt-4">
                <div className="col-md-12" style={{ paddingBottom: "10px" }}>
                  <div className="d-flex justify-content-end">
                    <div
                      className="btn btn-danger"
                      onClick={() => {
                        if (!isLoading) {
                          // Elimina los valores guardados en formikNuevoDoctor
                          formik.setValues({
                            nombres: "",
                            puesto: "",
                            especializacion: "",
                          });
                          setEspecialistaUpd();
                          setShowModal(false);
                        }
                      }}
                    >
                      Cancelar
                    </div>
                    <a
                      className="btn btn-success ml-2"
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={!isLoading && formik.handleSubmit}
                    >
                      Guardar
                    </a>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default ModalEspecialista;
