/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Row, Col, Card } from "react-bootstrap";
import { Fetch, catchError } from "../../../utils/fetch";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import { loadingAction } from "../../../redux/actions";
//Template1Component
import Template1VerComponent from "../../../components/templates-ver/template1";
//Template2Component
import Template2VerComponent from "../../../components/templates-ver/template2";
//Template3Component
import Template3VerComponent from "../../../components/templates-ver/template3";

function VerPaginaTemplate({ user, token, isLoadingAction }) {
  const params = useParams();

  const { template, id } = params;
  const [dataResult, setDataResult] = React.useState(null);

  React.useEffect(() => {
    if (id) {
      getPaginasById(id);
    }
  }, [id]);

  const getPaginasById = async (id) => {
    isLoadingAction(true);
    try {
      const { data } = await Fetch("paginas/" + id, "get");
      isLoadingAction(false);
      setDataResult(data);
    } catch (error) {
      isLoadingAction(false);
      catchError(error);
      console.error(error.message);
    }
  };

  return (
    <React.Fragment>
      <Row>
        <Col>
          <Card>
            <Card.Header>Información de la página</Card.Header>
            <Card.Body>
              {template == "template1" && (
                <Template1VerComponent dataResult={dataResult} />
              )}
              {template == "template2" && (
                <Template2VerComponent dataResult={dataResult} />
              )}
              {template == "template3" && (
                <Template3VerComponent dataResult={dataResult} />
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.user.data,
    token: state.user.token,
    isLoading: state.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    isLoadingAction: (data) => {
      dispatch(loadingAction(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VerPaginaTemplate);
