import { useSelector, useDispatch } from "react-redux";
import React, { useState, useEffect } from "react";
import { Form, Row, Col } from "react-bootstrap";
import { useFormik } from "formik";

import { AlertConfirmDeleteAll, AlertSuccess } from "../../../components/alert";
import { validateModelPractica } from "../nuevo/validationSchema";
import ModalPractica from "./ModalPractica";
import DataGridForm from "../../../components/common/DataGridForm";
import { practicaAction } from "../../../redux/actions";

const Practica = ({ formik, showModal, setShowModal }) => {
  const dispatch = useDispatch();
  const practica = useSelector((state) => state.practica);

  const [showPractica, setShowPractica] = useState(formik.values.show_practicas);

  useEffect(() => {    
    setShowPractica(formik.values.show_practicas);
  }, [formik]);

  const AccionPractica = (data) => {
    return (
      <>
        <button
          type="button"
          className="btn btn-sm btn btn-success"
          onClick={() => {
            formikPractica.setValues({
              id: data.id,
              titulo: data.titulo,
              descripcion: data.descripcion,
            });
            setShowModal(true);
          }}
        >
          <i className="fa fa-pencil" />
        </button>{" "}
        <button
          type="button"
          className="btn btn-sm btn btn-danger"
          onClick={() => {
            DeleteAlertPractica("", data.id);
          }}
        >
          <i className="fa fa-trash" />
        </button>
      </>
    );
  };

  const hidePractica = () => {        
    formik.values.show_practicas = !formik.values.show_practicas;
    setShowPractica(!showPractica);
  };

  const onReorderPractica = async (e) => {
    const visibleRows = e.component.getVisibleRows();
    const newData = [...practica];
    const toIndex = newData.indexOf(visibleRows[e.toIndex].data);
    const fromIndex = newData.indexOf(e.itemData);

    newData.splice(fromIndex, 1);
    newData.splice(toIndex, 0, e.itemData);

    dispatch(practicaAction([...newData]));
  };

  function DeleteAlertPractica(title, id) {
    AlertConfirmDeleteAll(
      title,
      "warning",
      async (response) => {
        if (response.isConfirmed) {
          const nuevoContenido = practica.filter(
            (item) => item.id !== id
          );
          dispatch(practicaAction([...nuevoContenido]));
        }
      },
      (title = "¡Atención!"),
      "Continuar",
      "Cancelar",
      true,
      '<div class="swal2-html-container" id="swal2-html-container">Estás por eliminar la práctica. <br> <br> ¿Querés continuar?</div>'
    );
  }

  const formikPractica = useFormik({
    initialValues: {
      id: "",
      titulo: "",
      descripcion: "",
    },
    enableReinitialize: true,
    validationSchema: validateModelPractica,
    onSubmit: (values) => submit(values),
  });

  const submit = (values) => {
    let nuevoContenido;
    if (values.id) {
      //Editando especialista
      AlertSuccess("Práctica editada con éxito");
      const nuevosDatos = {
        titulo: values.titulo,
        descripcion: values.descripcion,
      };

      // Crea un nuevo array actualizando solo los datos del objeto con el ID especificado

      
      nuevoContenido = practica.map((item) => {
        if (item.id === values.id) {
          return { ...item, ...nuevosDatos };
        }
        return item;
      });

      // Actualiza el estado con el nuevo array
      dispatch(practicaAction([...nuevoContenido]));
    } else {
      //Nuevo especialista
      AlertSuccess("Práctica creada con éxito");
      let nuevaPractica = {
        id: Math.floor(Math.random() * 1000),
        titulo: values.titulo,
        descripcion: values.descripcion,        
        isNew: true,
      };
      // Clonamos el estado "contenido" actual y agregamos el nuevo especialista al nuevo array
      nuevoContenido = [...practica, nuevaPractica];
      // Actualizamos el estado "contenido" con el nuevo array
      dispatch(practicaAction([...nuevoContenido]));
    }

    setShowModal(false);
    // Elimina los valores guardados en formikNuevoDoctor
    formikPractica.setValues({
      id: "",
      titulo: "",
      descripcion: "",
    });
  };

  return (
    <>
      <Row>
        <Col>
          <Form.Group className="d-flex align-items-center">
            <h2 className="mr-3 mb-0">Prácticas</h2>
            <Form.Check
              type="switch"
              id="custom-switch-practica"
              onChange={hidePractica}
              checked={showPractica}
            />
          </Form.Group>
        </Col>
      </Row>
      <div>
        {showPractica ? (
          <div>
            <Row>
              <Col>
                <Form.Group>
                  <label>Título</label>
                  <input
                    name="titulo"
                    // onChange={formik.handleChange}
                    className="form-control"
                    // value={formik.values.titulo_practica}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group>
                  <label>Descripción</label>
                  <textarea
                    name="descripcion"
                    // onChange={formik.handleChange}
                    // value={formik.values.descripcion_practica}
                    className="form-control"
                    rows={4} // El número de filas que deseas mostrar
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group>
                  <label>Prácticas</label>     
                     
                  {practica.length > 0 ? (
                    <DataGridForm
                      dataSource={practica}
                      keyExpr="id"
                      allowReordering={true}
                      onReorder={onReorderPractica}
                      columnHidingEnabled={true}
                      columns={[
                        {
                          caption: "Título",
                          dataField: "titulo",
                        },
                        {
                          caption: "Acciones",
                          alignment: "center",
                          render: "AccionPractica",
                          cellRender: { AccionPractica },
                          hidingPriority: 0,
                        },
                      ]}
                    />
                  ) : (
                    <h5>
                      Aún no se encuentran prácticas. Para iniciar hacer
                      click en "Añadir item".
                    </h5>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => setShowModal(true)}
                >
                  Añadir Item
                </button>
              </Col>
            </Row>
          </div>
        ) : (
          <></>
        )}
      </div>

      {/* MODAL PRÁCTICA */}
      <ModalPractica
        showModal={showModal}
        setShowModal={setShowModal}
        formik={formikPractica}
      />
    </>
  );
};

export default Practica;
