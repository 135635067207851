import React from "react";
import { Form, Row, Col, Button, Card } from "react-bootstrap";
import { FieldArray, Formik, useFormik } from "formik";
import { Fetch, catchError } from "../../../../utils/fetch";
import { useDispatch, useSelector } from "react-redux";
import ImageUploader from "../../../../utils/image-uploader";
import { AlertInfo, AlertSuccess } from "../../../../components/alert";
import { loadingAction } from "../../../../redux/actions";
import MediaUploader from "../../../../utils/media-uploader";
import { validateModel } from "./validationSchema";
import { useHistory } from "react-router-dom";
import { EstadoContenido } from "../../../../utils/constants";

const NuevoHomeSlide = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isLoading = useSelector((state) => state.loading);
  const [formFields, setFormFields] = React.useState([]);
  const permissions = useSelector((state) => state.permissions);
  const canPublish = permissions["home-slides"].aprobar.enabled;

  const [contenido, setContenido] = React.useState(null);

  React.useEffect(() => {
    getContenido();
  }, []);

  const getContenido = async () => {
    //isLoadingAction(true)
    try {
      const { data } = await Fetch("home-slides", "get");
      setContenido(data);
      //isLoadingAction(false)
    } catch (error) {
      //isLoadingAction(false)
      catchError(error);
    }
  };

  const submit = async (values) => {
    values.published_at = null;
    values.estado = EstadoContenido.EnRevision;

    let nroVideosCargados = contenido?.filter(
      (x) => x.tipo === "VIDEO"
    )?.length;

    if (nroVideosCargados === 1 && values?.tipo === "VIDEO") {
      AlertInfo("Solo se permite cargar un solo video");
      return;
    }

    try {
      dispatch(loadingAction(true));
      await Fetch("home-slides", "post", values);
      dispatch(loadingAction(false));

      AlertInfo(
        "Slide creado con éxito.<br/>Recuerde que falta su confirmación para aprobar.<br/> Nota: Por favor, notificar al responsable de publicar"
      );
      history.push("/configuracion/home-slide/lista-borradores");
    } catch (error) {
      catchError(error);
      dispatch(loadingAction(false));
    }
  };

  const {
    values,
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue,
    errors,
  } = useFormik({
    initialValues: {
      tipo: "IMAGEN", //IMAGEN O VIDEO
      media: "",
      caption: {
        h1: "",
        h3: "",
        botones: [
          {
            nombre: "",
            url: "#",
            estilo: "estilo1",
          },
        ],
      },
      enlace: "",
      enlaceInterno: false,
    },
    enableReinitialize: true,
    validationSchema: validateModel,
    onSubmit: (values) => submit(values),
  });

  const addArray = () => {
    let object = { nombre: "", url: "", estilo: "" };
    setFormFields([...formFields, object]);
  };

  const removeArray = (index) => {
    let data = [...formFields];
    data.splice(index, 1);
    setFormFields(data);
  };

  const handleFormChange = (event, index) => {
    let data = [...formFields];
    data[index][event.target.name] = event.target.value;
    setFormFields(data);
  };

  const initialValues = {
    tipo: "IMAGEN", //IMAGEN O VIDEO
    media: "",
    caption: {
      h1: "",
      h3: "",
      botones: [
        {
          nombre: "",
          url: "#",
          estilo: "estilo1",
        },
      ],
    },
    enlace: "",
    enlaceInterno: false,
  };

  return (
    <React.Fragment>
      <Formik
        initialValues={initialValues}
        validationSchema={validateModel}
        onSubmit={(values, { setSubmitting }) => {
          submit(values);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
          /* and other goodies */
        }) => (
          <form onSubmit={handleSubmit}>
            <Row>
              <Col>
                <Card>
                  <Card.Header>
                    <Row>
                      <Col>Nuevo slide</Col>
                    </Row>
                  </Card.Header>
                  <Card.Body>
                    <Row>
                      <Col>
                        <Form.Group>
                          <label>Tipo</label>
                          <select
                            name="tipo"
                            onChange={handleChange}
                            className="form-control"
                            value={values.tipo}
                          >
                            <option value="IMAGEN">IMAGEN</option>
                            <option value="VIDEO">VIDEO</option>
                          </select>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group>
                          <label>Seleccionar archivo</label>
                          {values.tipo === "IMAGEN" ? (
                            <>
                              <MediaUploader
                                setFieldValue={setFieldValue}
                                field={"media"}
                                tipoMedia="imagen"
                                ancho={1200}
                                alto={600}
                              />
                              <span className="text-muted">
                                Se recomienda una imagen de 1200px de ancho x
                                600px de alto. En formato JPG o PNG.
                              </span>
                              <div className="text-danger">{errors?.media}</div>
                            </>
                          ) : (
                            <>
                              <MediaUploader
                                setFieldValue={setFieldValue}
                                field={"media"}
                                tipoMedia="video"
                                ancho={1920}
                                alto={1080}
                              />
                              <span className="text-muted">
                                Se recomienda un video de 1920px de ancho x
                                1080px de alto. En formato MP4 ó WEBM.
                              </span>
                              <div className="text-danger">{errors?.media}</div>
                            </>
                          )}
                        </Form.Group>
                      </Col>
                    </Row>
                    {values.tipo == "VIDEO" && (
                      <>
                        <Row>
                          <Col>
                            <Form.Group>
                              <label>H1 Texto</label>
                              <input
                                type="text"
                                name="caption.h1"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className="form-control"
                                value={values.caption.h1}
                              />
                              <div className="text-danger">
                                {errors?.caption?.h1}
                              </div>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Form.Group>
                              <label>H3 Texto</label>
                              <input
                                type="text"
                                name="caption.h3"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className="form-control"
                                value={values.caption.h3}
                              />
                              <div className="text-danger">
                                {errors?.caption?.h3}
                              </div>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <h2>Botones</h2>
                            <FieldArray name="caption.botones">
                              {({
                                move,
                                swap,
                                push,
                                remove,
                                insert,
                                unshift,
                                pop,
                                form,
                              }) => {
                                return (
                                  <>
                                    {values.caption.botones &&
                                      values.caption.botones.length > 0 &&
                                      values.caption.botones.map(
                                        (data, index) => {
                                          return (
                                            <Row className="border m-3">
                                              <Col
                                                lg={12}
                                                className="p-0 d-flex justify-content-end"
                                              >
                                                <button
                                                  type="button"
                                                  className="btn btn-danger"
                                                  onClick={() => remove(index)}
                                                >
                                                  x
                                                </button>
                                              </Col>

                                              <Col>
                                                <div className="form-group">
                                                  <label>Nombre</label>
                                                  <input
                                                    type="text"
                                                    className="form-control"
                                                    name={`caption.botones.[${index}].nombre`}
                                                    onChange={handleChange}
                                                    value={
                                                      values?.caption?.botones[
                                                        index
                                                      ]?.nombre
                                                    }
                                                  />
                                                  {errors &&
                                                    errors.caption &&
                                                    errors.caption.botones &&
                                                    errors.caption.botones[
                                                      index
                                                    ] &&
                                                    errors.caption.botones[
                                                      index
                                                    ].nombre && (
                                                      <div className="text-danger">
                                                        {
                                                          errors.caption
                                                            .botones[index]
                                                            .nombre
                                                        }
                                                      </div>
                                                    )}
                                                </div>
                                              </Col>
                                              <Col>
                                                <div className="form-group">
                                                  <label>Url</label>
                                                  <input
                                                    type="text"
                                                    className="form-control"
                                                    name={`caption.botones.[${index}].url`}
                                                    onChange={handleChange}
                                                    value={
                                                      values?.caption?.botones[
                                                        index
                                                      ]?.url
                                                    }
                                                  />
                                                  {errors &&
                                                    errors.caption &&
                                                    errors.caption.botones &&
                                                    errors.caption.botones[
                                                      index
                                                    ] &&
                                                    errors.caption.botones[
                                                      index
                                                    ].url && (
                                                      <div className="text-danger">
                                                        {
                                                          errors.caption
                                                            .botones[index].url
                                                        }
                                                      </div>
                                                    )}
                                                </div>
                                              </Col>
                                              <Col>
                                                <div className="form-group">
                                                  <label>Estilo</label>
                                                  <select
                                                    className="form-control"
                                                    name="estilo"
                                                    onChange={handleChange}
                                                    value={data.estilo}
                                                  >
                                                    <option>Seleccionar</option>
                                                    <option value={"estilo1"}>
                                                      Estilo 1
                                                    </option>
                                                    <option value={"estilo2"}>
                                                      Estilo 2
                                                    </option>
                                                    <option value={"estilo3"}>
                                                      Estilo 3
                                                    </option>
                                                    <option value={"estilo4"}>
                                                      Estilo 4
                                                    </option>
                                                  </select>
                                                </div>
                                              </Col>
                                            </Row>
                                          );
                                        }
                                      )}
                                    <button
                                      type="button"
                                      className="btn btn-secondary"
                                      onClick={() =>
                                        push({
                                          nombre: "",
                                          url: "#",
                                          estilo: "estilo1",
                                        })
                                      }
                                    >
                                      Añadir Item
                                    </button>
                                  </>
                                );
                              }}
                            </FieldArray>

                            {/* {formFields && formFields.map((data, index) => {
                                                            return (
                                                                <Row className="border m-3">

                                                                    <Col lg={12} className="p-0 d-flex justify-content-end">
                                                                        <button type="button" className="btn btn-danger" onClick={() => removeArray(index)}>x</button>
                                                                    </Col>

                                                                    <Col>
                                                                        <div className="form-group">
                                                                            <label>Nombre</label>
                                                                            <input type="text" className="form-control" name="nombre" onChange={event => handleFormChange(event, index)} value={data.nombre} />
                                                                        </div>
                                                                    </Col>
                                                                    <Col>
                                                                        <div className="form-group">
                                                                            <label>Url</label>
                                                                            <input type="text" className="form-control" name="url" onChange={event => handleFormChange(event, index)} value={data.url} />
                                                                        </div>
                                                                    </Col>
                                                                    <Col>
                                                                        <div className="form-group">
                                                                            <label>Estilo</label>
                                                                            <select className="form-control" name="estilo" onChange={event => handleFormChange(event, index)} value={data.estilo}>
                                                                                <option>Seleccionar</option>
                                                                                <option value={'estilo1'}>Estilo 1</option>
                                                                                <option value={'estilo2'}>Estilo 2</option>
                                                                                <option value={'estilo3'}>Estilo 3</option>
                                                                            </select>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            )
                                                        })}
                                                        <Row>
                                                            <Col>
                                                                <Button variant="secondary" type="button" className=" mx-2" onClick={() => addArray()}>Añadir item</Button>
                                                            </Col>
                                                        </Row> */}
                          </Col>
                        </Row>
                      </>
                    )}
                    <Row>
                      <Col>
                        <Form.Group>
                          <label>Enlace</label>
                          <input
                            type="text"
                            name="enlace"
                            onChange={handleChange}
                            className="form-control"
                            value={values.enlace}
                          />
                          <div className="text-danger">{errors?.enlace}</div>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group>
                          <label>¿El enlace es interno?</label>
                          <select
                            className="form-control"
                            name="enlaceInterno"
                            value={values.enlaceInterno}
                            onChange={handleChange}
                          >
                            <option value={true}>Si</option>
                            <option value={false}>No</option>
                          </select>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Button
                      type="button"
                      onClick={() => handleSubmit()}
                      disabled={isLoading}
                      className="btn btn-success btn-icon mt-2"
                    >
                      <i className="fa fa-floppy-o " />
                      Guardar
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </form>
        )}
      </Formik>
    </React.Fragment>
  );
};
export default NuevoHomeSlide;
