/* eslint-disable react-hooks/exhaustive-deps */

import { Form, Row, Col, Button, Card } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useFormik } from "formik";
import React from "react";

import { AlertSuccess, AlertInfo } from "../../../../components/alert";
import { EstadoContenido } from "../../../../utils/constants";
import { Fetch, catchError } from "../../../../utils/fetch";
import { loadingAction } from "../../../../redux/actions";
import { validateModel } from "./validationSchema";

const VerHomeWhatsappX = () => {
  const isLoading = useSelector((state) => state.loading);
  const dispatch = useDispatch();
  const history = useHistory();

  React.useEffect(() => {
    getHomeWhatsapp();
  }, []);

  const getHomeWhatsapp = async () => {
    dispatch(loadingAction(true));

    try {
      const { data } = await Fetch("seccion-home-whatsapps", "get");

      if (!data?.length) {
        dispatch(loadingAction(false));
        return;
      }

      const [values] = data;
      if (data.estado === EstadoContenido.EnAprobacion) {
        AlertInfo("Ya tiene una solicitud pendiente por aprobar");
      }

      setValues(values);
      dispatch(loadingAction(false));
    } catch (error) {
      catchError(error);
      dispatch(loadingAction(false));
    }
  };

  const submit = async (values) => {
    dispatch(loadingAction(true));
    values.estado = EstadoContenido.EnRevision;
    values.published_at = null;

    try {
      await Fetch(`seccion-home-whatsapps`, "post", values);
      AlertSuccess(
        "Home encabezado actualizado con éxitos, pendiente de revisión"
      );

      dispatch(loadingAction(false));
      history.push("/configuracion/home-encabezado/revision/");
    } catch (error) {
      AlertInfo("Ya existe un registro pendiente por revisión");
      dispatch(loadingAction(false));
    }
  };

  const { values, handleSubmit, handleChange, handleBlur, setValues, errors } =
    useFormik({
      initialValues: {
        contenido: "",
        callCenter: "",
      },
      enableReinitialize: true,
      validationSchema: validateModel,
      onSubmit: (values) => submit(values),
    });

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit}>
        <Row>
          <Col>
            <Card>
              <Card.Header>
                <Row>
                  <Col>Información de home encabezado - Publicado</Col>
                </Row>
              </Card.Header>
              <Card.Body>
                <Row>
                  <Col md={12}>
                    <Form.Group>
                      <label>Atención telefónica las 24hs</label>
                      <input
                        type="text"
                        name="callCenter"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="form-control"
                        value={values.callCenter}
                      />
                      <div className="text-danger">{errors?.callCenter}</div>
                    </Form.Group>
                  </Col>
                  <Col md={12}>
                    <Form.Group>
                      <label>Central de Turnos</label>
                      <input
                        type="text"
                        name="contenido"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="form-control"
                        value={values.contenido}
                      />
                      <div className="text-danger">{errors?.contenido}</div>
                    </Form.Group>
                  </Col>
                  <Col md={12}>
                    <Form.Group>
                      <label>WhatsApp</label>
                      <input
                        type="text"
                        name="whatsapp"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="form-control"
                        value={values.whatsapp}
                      />
                      <div className="text-danger">{errors?.whatsapp}</div>
                    </Form.Group>
                  </Col>
                </Row>
                <Button
                  type="button"
                  onClick={() => handleSubmit()}
                  disabled={isLoading}
                  className="btn btn-success btn-icon mt-2"
                >
                  <i className="fa fa-floppy-o " />
                  Guardar
                </Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </form>
    </React.Fragment>
  );
};
export default VerHomeWhatsappX;
