/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from "react";
import { Form, Row, Col, Button, Card } from "react-bootstrap";
import JoditEditor, { Jodit } from "jodit-react";
import { useFormik } from "formik";
import { Fetch, catchError } from "../../../utils/fetch";
import { useDispatch, useSelector } from "react-redux";
import ImageUploader from "../../../utils/image-uploader";
import { AlertSuccess, AlertInfo } from "../../../components/alert";
import { loadingAction } from "../../../redux/actions";
import { useHistory, useParams } from "react-router-dom";
import { BASE_URL, EstadoContenido } from "../../../utils/constants";
import { validateModel } from "../nuevo/validationSchema";

const VerParaEliminar = () => {
  const params = useParams();
  const id = params.id;
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.loading);
  const permissions = useSelector((state) => state.permissions);
  const canPublish = permissions.popup.aprobar.enabled;
  const [alto, setAlto] = React.useState(300);
  const [textoRecomienda, setTextoRecomienda] = React.useState(
    "Se recomienda una imagen de 1400px de ancho x 960px de alto. En formato JPG o PNG."
  );
  const user = useSelector((state) => state.user.data);

  const history = useHistory();

  React.useEffect(() => {
    if (id) {
      getPopupById();
    }
  }, []);

  const getPopupById = async (values) => {
    // ?_publicationState=preview&published_at_null=true
    dispatch(loadingAction(true));
    try {
      const { data } = await Fetch(`popups/${id}?_eliminado=true`, "get");
      setValues(data);
      dispatch(loadingAction(false));
    } catch (error) {
      catchError(error);
      dispatch(loadingAction(false));
    }
  };

  const submit = async (values) => {
    values.published_at = new Date();
    dispatch(loadingAction(true));
    try {
      const { data } = await Fetch("popups/" + id, "delete");

      AlertSuccess("Pop up eliminado con éxito");

      dispatch(loadingAction(false));

      history.push("/popup/lista-por-eliminar");
    } catch (error) {
      catchError(error);
      dispatch(loadingAction(false));
    }
  };

  const onCancelar = async (values) => {
    try {
      dispatch(loadingAction(true));

      const params = {
        eliminado: false,
        published_at:
          values.estado === EstadoContenido.EnRevision
            ? null
            : values.published_at,
      };

      const { data } = await Fetch("popups/" + id, "put", params);

      dispatch(loadingAction(false));
      AlertSuccess("Se ha cancelado la eliminación del pop up con éxito");

      //history.push("/popup/lista-por-eliminar");

      if (values.estado === EstadoContenido.EnRevision) {
        history.push("/popup/lista-borradores");
      } else {
        history.push("/popup/lista");
      }
    } catch (error) {
      dispatch(loadingAction(false));
      catchError(error);
    }
  };

  const {
    values,
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue,
    errors,
    touched,
    setValues,
  } = useFormik({
    initialValues: {
      nombre: "",
      imagen: "",
      programacion: false,
      enlace: "",
      enlace_interno: false,
      fecha_inicio: "",
      fecha_fin: "",
      created_by: user.id,
      updated_by: user,
    },
    enableReinitialize: true,
    validationSchema: validateModel,
    validate: ({ fecha_inicio, fecha_fin, programacion }) => {
      const errors = {};
      if (programacion && (fecha_inicio === "" || fecha_fin === "")) {
        errors.programacion = "Fechas y horas invalidas";
      }

      if (programacion) {
        let f_inicio = new Date(fecha_inicio);
        let f_fin = new Date(fecha_fin);

        if (isNaN(f_inicio) || isNaN(f_fin)) {
          errors.programacion = "Fechas inválidas";
        }

        if (fecha_inicio > fecha_fin) {
          errors.programacion =
            "La fecha final no puede ser anterior a la fecha de inicio";
        }
      }

      return errors;
    },
    onSubmit: (values) => submit(values),
  });

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit}>
        <Row>
          <Col>
            <Card>
              <Card.Header>
                <Row>
                  <Col>Nuevo Pop up</Col>
                </Row>
              </Card.Header>
              <Card.Body>
                <Row>
                  <Col>
                    <Form.Group>
                      <label>Nombre Pop up</label>
                      <input
                        type="text"
                        disabled={true}
                        name="nombre"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.nombre}
                        className="form-control "
                      />
                      {touched?.nombre && (
                        <div className="text-danger">{errors?.nombre}</div>
                      )}
                    </Form.Group>
                  </Col>
                </Row>

                {Object.keys(values.imagen).length > 0 && (
                  <Row>
                    <Col>
                      <Form.Group>
                        <img
                          src={values.imagen.url}
                          alt="vista-previa"
                          className="img-fluid w-50"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                )}

                <Row>
                  <Col>
                    <Form.Group>
                      <label>
                        <input
                          disabled={true}
                          type="checkbox"
                          checked={values.programacion}
                          name="programacion"
                          onChange={handleChange}
                        />
                        <span style={{ marginLeft: "5px" }}>Programación</span>
                      </label>
                    </Form.Group>
                  </Col>
                </Row>
                {values.programacion && (
                  <>
                    <Row>
                      <Col id="columna-fecha" xs={12} md={9} lg={9} xl={7}>
                        <div>
                          <label>Inicio</label>
                          <input
                            type="date"
                            disabled={true}
                            className="fecha-personalizada"
                            name="fecha_inicio"
                            defaultValue={values.fecha_inicio?.split("T")[0]}
                          />
                          <input
                            type="time"
                            disabled={true}
                            className="fecha-personalizada"
                            defaultValue={values.fecha_inicio
                              ?.split("T")[1]
                              ?.replace(".000Z", "")}
                          />
                        </div>
                        <div>
                          <label>Fin</label>
                          <input
                            type="date"
                            disabled={true}
                            className="fecha-personalizada"
                            name="fecha_fin"
                            defaultValue={values.fecha_fin?.split("T")[0]}
                          />
                          <input
                            type="time"
                            disabled={true}
                            className="fecha-personalizada"
                            defaultValue={values.fecha_fin
                              ?.split("T")[1]
                              ?.replace(".000Z", "")}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: "10px", marginBottom: "30px" }}>
                      <Col>
                        <span className="text-muted">
                          Seleccione una fecha y hora en el futuro, de inicio y
                          fin de visualización del pop up.
                        </span>
                        {touched.programacion && (
                          <div className="text-danger">
                            {errors?.programacion}
                          </div>
                        )}
                      </Col>
                    </Row>{" "}
                  </>
                )}

                <Row>
                  <Col>
                    <Form.Group>
                      <label>Enlace</label>
                      <input
                        type="text"
                        disabled={true}
                        name="enlace"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.enlace}
                        className="form-control "
                      />
                      {touched.enlace && (
                        <div className="text-danger">{errors?.enlace}</div>
                      )}
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <Form.Group>
                      <label>¿El enlace es interno?</label>
                      <select
                        className="form-control"
                        disabled={true}
                        name="enlace_interno"
                        onChange={handleChange}
                        value={values.enlace_interno}
                      >
                        <option value={true}>Si</option>
                        <option value={false}>No</option>
                      </select>
                      {touched.interno && (
                        <div className="text-danger">{errors?.interno}</div>
                      )}
                    </Form.Group>
                  </Col>
                </Row>

                <Row className="mt-4">
                  <Col className="col col-lg-2">
                    <Button
                      type="button"
                      onClick={() => handleSubmit()}
                      disabled={isLoading}
                      className="btn btn-danger btn-icon"
                    >
                      <i className="fa fa-trash" />
                      Eliminar
                    </Button>
                  </Col>
                  <Col className="col col-lg-2">
                    <Button
                      type="button"
                      onClick={() => onCancelar(values)}
                      disabled={isLoading}
                      className="btn btn-primary btn-icon"
                    >
                      <i className="fa fa-undo " />
                      Por revisar
                    </Button>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </form>
    </React.Fragment>
  );
};

export default VerParaEliminar;
