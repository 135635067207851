/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Row, Col, Card, Table, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Fetch, catchError } from '../../../utils/fetch'
import { connect } from 'react-redux'
import { loadingAction } from '../../../redux/actions';
import DataGridForm from '../../../components/common/DataGridForm';
import { copyToClickBoard } from '../../../utils/slug';
import { formatDate } from '../../../utils/formatDate';

function ListaPrensaX({ isLoadingAction, isLoading }) {
    const [contenido, setContenido] = React.useState(null)

    React.useEffect(() => {
        getContenido()
    }, [])

    const getContenido = async () => {
        isLoadingAction(true)
        try {
            const { data } = await Fetch('prensas?_publicationState=preview&published_at_null=true&estado=EA', 'get')
            setContenido(data)
            isLoadingAction(false)
        } catch (error) {
            console.error('mostrando error', error)
            isLoadingAction(false)
            catchError(error);
        }
    }

    function FActualizacion(data) {
        return formatDate(data?.updated_at)
    }

    function Imagen(data) {
        return (
            data?.imagen?.formats?.thumbnail?.url ? <><img src={data.imagen.formats.thumbnail.url} style={{ width: '64px', height: '64px', borderRadius: '32px', objectFit: 'cover' }} className="" alt="images" /></> : null
        )
    }

    function Destacado(data) {
        return (
            data.programacion ? <h4> <span className="badge bg-success">Si</span> </h4> : <span className="badge bg-secondary">No</span>
        )
    }

    function Accion(data) {
        return (
            <>
                {/* <a href={`${process.env.REACT_APP_BASE_URL_HOME}/novedades-preview/${data.id}`} target="_blank" className="btn btn-sm btn btn-info"><i className="fa fa-eye" /></a>{' '} */}
                <Link to={"/prensa/aprobar/" + data.id} className="btn btn-sm btn btn-success"><i className="fa fa-pencil" /></Link>{' '}
            </>
        )
    }
    
    return (
        <React.Fragment>
            <Row>
                <Col md="12">
                    <Card>
                        <Card.Header>
                            Listado de contenido prensa
                        </Card.Header>
                        <Card.Body>

                            <DataGridForm dataSource={contenido}
                                keyExpr='id'
                                allowReordering={true}
                                columnHidingEnabled={true}
                                columns={[
                                    { caption: 'Id', dataField: 'id' },
                                    { caption: 'Título', dataField: 'titulo' },
                                    { caption: 'Medio',dataField:'medio'},                                    
                                    { caption: 'Fecha',dataField:'fecha'},
                                    { caption: 'Url',dataField:'url'},       
                                    { caption: 'Creado por', dataField: 'creado_por.username' },
                                    { caption: 'Actualizado por', dataField: 'actualizado_por.username' },
                                    { caption: 'Ult. Actualización', render: 'FActualizacion', cellRender: { FActualizacion } },
                                    { caption: 'Acción', alignment: 'center', render: 'Accion', cellRender: { Accion }, hidingPriority: 0 }
                                ]} />
                         
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    )
}

const mapStateToProps = (state) => {

    return {
        isLoading: state.loading,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        isLoadingAction: (data) => { dispatch(loadingAction(data)) }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ListaPrensaX);