/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from "react";
import { Form, Row, Col, Button, Card } from "react-bootstrap";
import JoditEditor from "jodit-react";
import { useFormik } from "formik";
import { Fetch, catchError } from "../../../utils/fetch";
import { useDispatch, useSelector } from "react-redux";
import ImageUploader from "../../../utils/image-uploader";
import { AlertSuccess, AlertInfo } from "../../../components/alert";
import { loadingAction } from "../../../redux/actions";
import { useHistory, useParams } from "react-router-dom";
import { BASE_URL, EstadoContenido } from "../../../utils/constants";
import Acordeon from "../components/acordeon";

const VerParaEliminarCentro = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.loading);
  const [formFields, setFormFields] = React.useState([]);
  const history = useHistory();

  const config = useMemo(
    () => ({
      readonly: false, // all options from https://xdsoft.net/jodit/doc/
      height: 500,
      language: "es",
      disabled: true,
      uploader: {
        url: BASE_URL + "/upload",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("jwt_token"),
        },
        data: null,
        filesVariableName: function (i) {
          return "files";
        },
        withCredentials: false,
        pathVariableName: "path",
        format: "json",
        method: "POST",
        prepareData: function (formData) {
          formData.append("id", 1);
        },
        isSuccess: function (resp) {
          return !resp.error;
        },
        getMessage: function (resp) {
          return resp.msgs.join("\n");
        },
        process: function (resp) {
          return resp;
        },
        defaultHandlerSuccess: function (resp) {
          var imagenesPermitidas = ["image/jpeg", "image/png"];
          for (var i = 0; i < resp.length; i++) {
            if (imagenesPermitidas.indexOf(resp[i].mime) !== -1) {
              var img1 = new Image();
              img1.src = resp[i]["url"];
              img1.alt = "imagen";
              img1.className = "img-fluid";
              this.s.insertImage(img1);
            } else if (resp[i].mime === "application/pdf") {
              const enlace = document.createElement("a");
              enlace.setAttribute("href", resp[i].url);
              const contenido = document.createTextNode(resp[i].name);
              // add the text node to the newly created div
              enlace.appendChild(contenido);
              this.s.insertNode(enlace);
            } else {
              AlertInfo("Tipo de archivo no permitido");
            }
          }
        },
      },
    }),
    []
  );

  React.useEffect(() => {
    if (id) {
      getCentrosMedicosById();
    }
  }, [id]);

  const getCentrosMedicosById = async (values) => {
    dispatch(loadingAction(true));
    try {
      const { data } = await Fetch(
        "centros-medicos/" + id + "?_eliminado=true",
        "get"
      );
      setValues(data);
      setFormFields(data.acordion);
      dispatch(loadingAction(false));
    } catch (error) {
      catchError(error);
      dispatch(loadingAction(false));
    }
  };

  const submit = async (values) => {
    values.acordion = formFields;
    dispatch(loadingAction(true));

    try {
      const { data } = await Fetch("centros-medicos/" + id, "delete");

      AlertSuccess("Centro Médico eliminado con éxito");
      history.push("/centros-medicos/lista-por-eliminar");
      dispatch(loadingAction(false));
    } catch (error) {
      catchError(error);
      dispatch(loadingAction(false));
    }
  };

  const onCancelar = async (values) => {
    try {
      dispatch(loadingAction(true));

      const params = {
        eliminado: false,
        published_at:
          values.estado === EstadoContenido.EnRevision
            ? null
            : values.published_at,
      };

      const { data } = await Fetch("centros-medicos/" + id, "put", params);

      dispatch(loadingAction(false));
      AlertSuccess("Se ha cancelado la eliminación del centro con éxito");

      //history.push("/centros-medicos/lista-por-eliminar");

      if (values.estado === EstadoContenido.EnRevision) {
        history.push("/centros-medicos/lista-borradores");
      } else {
        history.push("/centros-medicos/lista");
      }
    } catch (error) {
      dispatch(loadingAction(false));
      catchError(error);
    }
  };

  const {
    values,
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue,
    setValues,
  } = useFormik({
    initialValues: {
      direccion: "",
      localidad: "",
      provincia: "",
      colectivos: "",
      tren: "",
      info_contacto: "",
      descripcion: "",
      nombre: "",
      slug: "",
      url: "",
      imagen: {},
      miniatura: {},
      acordion: [
        {
          id: "",
          items_acordion: [
            {
              id: "",
              nombre: "",
            },
          ],
          nombre: "",
          mostrar: true,
        },
      ],
      iframe_mapa: "",
      locale: "es",
    },
    enableReinitialize: true,
    onSubmit: (values) => submit(values),
  });

  const handleBlurTitulo = async (event) => {
    const value = event.target.value;
    const name = event.target.name;
    const dataToApi = {
      contentTypeUID: "application::centros-medicos.centros-medicos",
      field: "slug",
      data: {
        nombre: value,
        slug: "",
      },
    };
    setFieldValue(name, value, false);
    try {
      const { data } = await Fetch(
        "content-manager/uid/generate",
        "post",
        dataToApi
      );
      setFieldValue("slug", data.data, false);
    } catch (error) {
      console.error("mostrando error", error);
    }
  };

  const handleChangeJoiEditor = (data, field) => {
    setFieldValue(field, data, false);
  };

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit}>
        <Row>
          <Col>
            <Card>
              <Card.Header>
                <Row>
                  <Col>Información del centro médico</Col>
                </Row>
              </Card.Header>
              <Card.Body>
                <Row>
                  <Col>
                    <Form.Group>
                      <label>Nombre</label>
                      <input
                        type="text"
                        disabled
                        name="nombre"
                        onChange={handleChange}
                        onBlur={(e) => handleBlurTitulo(e)}
                        value={values.nombre}
                        className="form-control "
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group>
                      <label>Url</label>
                      <input
                        type="text"
                        name="slug"
                        className="form-control"
                        value={values.slug}
                        readOnly
                      />
                    </Form.Group>
                  </Col>
                </Row>
                {Object.keys(values.imagen).length > 0 && (
                  <Row>
                    <Col>
                      <Form.Group>
                        <img
                          src={values.imagen.url}
                          alt="vista-previa"
                          className="img-fluid w-50"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                )}

                {values?.miniatura && (
                  <Row>
                    <Col>
                      <Form.Group>
                        <img
                          src={values.miniatura.url}
                          alt="vista-previa"
                          className="img-fluid w-50"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                )}

                <Row>
                  <Col>
                    <Form.Group>
                      <label>Direccion</label>
                      <input
                        type="text"
                        disabled
                        name="direccion"
                        className="form-control"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.direccion}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <Form.Group>
                      <label>Localidad</label>
                      <input
                        type="text"
                        disabled
                        name="localidad"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="form-control"
                        value={values.localidad}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <Form.Group>
                      <label>Provincia</label>
                      <input
                        type="text"
                        disabled
                        name="provincia"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="form-control"
                        value={values.provincia}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <Form.Group>
                      <label>Colectivos</label>
                      <input
                        type="text"
                        disabled
                        name="colectivos"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="form-control"
                        value={values.colectivos}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <Form.Group>
                      <label>Tren</label>
                      <input
                        type="text"
                        disabled
                        name="tren"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="form-control"
                        value={values.tren}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <Form.Group>
                      <label>Información de contacto</label>
                      <JoditEditor
                        config={config}
                        value={values.info_contacto}
                        tabIndex={1} // tabIndex of textarea
                        onBlur={(newContent) =>
                          handleChangeJoiEditor(newContent, "info_contacto")
                        }
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <Form.Group>
                      <label>Descripción</label>
                      <JoditEditor
                        config={config}
                        value={values.descripcion}
                        tabIndex={1} // tabIndex of textarea
                        onBlur={(newContent) =>
                          handleChangeJoiEditor(newContent, "descripcion")
                        }
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <Form.Group>
                      <label>Iframe Mapa</label>
                      <textarea
                        disabled
                        name="iframe_mapa"
                        className="form-control"
                        onChange={handleChange}
                        value={values.iframe_mapa}
                      ></textarea>
                    </Form.Group>
                  </Col>
                </Row>

                <Acordeon
                  values={values}
                  handleChange={handleChange}
                  setFieldValue={setFieldValue}
                  formFields={formFields}
                />

                <Row className="mt-5">
                  <Col className="col col-lg-2">
                    <Button
                      type="button"
                      onClick={() => handleSubmit()}
                      disabled={isLoading}
                      className="btn btn-danger btn-icon"
                    >
                      <i className="fa fa-trash" />
                      Eliminar
                    </Button>
                  </Col>
                  <Col className="col col-lg-2">
                    <Button
                      type="button"
                      onClick={() => onCancelar(values)}
                      disabled={isLoading}
                      className="btn btn-primary btn-icon"
                    >
                      <i className="fa fa-undo " />
                      Cancelar
                    </Button>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </form>
    </React.Fragment>
  );
};
export default VerParaEliminarCentro;
