import React, { useEffect } from "react";
import { Button, Modal } from "react-bootstrap";

const DraggableItem = ({ show, setShow, pagesList, items, setItems }) => {
  useEffect(() => {
    if (!pagesList) return;

    const pages = [...pagesList];

    sortArray(pages);
    setItems([...pages]);
  }, [pagesList]);

  const handleDragStart = (e, index) => {
    e.dataTransfer.setData("text/plain", index);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const sourceIndex = e.dataTransfer.getData("text/plain");
    const targetIndex = e.target.getAttribute("data-index");

    const newItems = [...items];
    const [removedItem] = newItems.splice(sourceIndex, 1);
    newItems.splice(targetIndex, 0, removedItem);

    newItems.forEach((item, i) => {
      item.order = i + 1;
    });

    setItems([...newItems]);
  };

  return (
    <Modal show={show} onHide={setShow} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Ordenar items</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ul className="list-group" style={{ cursor: "pointer" }}>
          {items.map((item, index) => (
            <li
              key={item.id}
              className="list-group-item"
              draggable
              onDragStart={(e) => handleDragStart(e, index)}
              onDragOver={handleDragOver}
              onDrop={handleDrop}
              data-index={index}
            >
              {item.nombre}
            </li>
          ))}
        </ul>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={setShow}>
          Guardar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export const sortArray = (data) => {
  data.sort((a, b) => {
    if (a.order === undefined && b.order === undefined) {
      return 0;
    } else if (a.order === undefined) {
      return 1;
    } else if (b.order === undefined) {
      return -1;
    } else if (a.order === null && b.order === null) {
      return 0;
    } else if (a.order === null) {
      return 1;
    } else if (b.order === null) {
      return -1;
    } else {
      return a.order - b.order;
    }
  });
};

export default DraggableItem;
