import React from "react";
import { Row, Col, Modal } from "react-bootstrap";


const ModalPractica = ({ showModal, setShowModal, formik }) => {
  return (
    <Modal size={"md"} show={showModal} centered>
      <Modal.Body>
        <form onSubmit={formik.handleSubmit}>
          <div className="mt-2">
            <Row>
              <Col>
                <label>Nueva práctica</label>
              </Col>
            </Row>
            <Col
              style={{
                marginTop: "15px",
                paddingTop: "15px",
                paddingBottom: "15px",
                overflow: "auto",
                maxHeight: "500px",
              }}
              className="border"
            >
              <Row>
                <Col>
                  <label>Título</label>
                  <input
                    name="titulo"
                    onChange={formik.handleChange}
                    className="form-control mb-3"
                    value={formik.values.titulo}
                  />
                  <div className="text-danger">{formik.errors?.titulo}</div>
                </Col>
              </Row> 

               <Row>
                <Col>
                  <label>Descripción</label>
                  <textarea
                    name="descripcion"      
                    onChange={formik.handleChange}
                    className="form-control mb-3"                                  
                  >
                    {formik.values.descripcion}
                    </textarea>
                  <div className="text-danger">{formik.errors?.descripcion}</div>
                </Col>
              </Row>            
            </Col>
          </div>

          <Row>
            <Col md={12}>
              <div className="mt-4">
                <div className="col-md-12" style={{ paddingBottom: "10px" }}>
                  <div className="d-flex justify-content-end">
                    <div
                      className="btn btn-danger"
                      onClick={() => {                        
                        // Elimina los valores guardados en formikNuevoDoctor
                        formik.setValues({
                          id: "",
                          titulo: "",
                          descripcion: "",
                        });
                        setShowModal(false);
                      }}
                    >
                      Cancelar                      
                    </div>
                    <a
                      className="btn btn-success ml-2"
                      onClick={() =>formik.handleSubmit()}
                    >
                      Guardar
                    </a>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default ModalPractica;
