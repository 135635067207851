/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Row, Col, Card, Table } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import { Fetch, catchError } from '../../../utils/fetch'
import { connect } from 'react-redux'
import { loadingAction } from '../../../redux/actions';
import { formatDate } from '../../../utils/formatDate';
import DataGridForm from '../../../components/common/DataGridForm';
import { AlertConfirm } from '../../../components/alert';
import { EstadoContenido } from '../../../utils/constants';

function ListaBorradores({ isLoadingAction, isLoading }) {
    const [contenido, setContenido] = React.useState(null)
    const history = useHistory();

    React.useEffect(() => {
        getContenido()
    }, [])

    const deleteContenido = async (values) => {
        isLoadingAction(true)
        try {
            values.eliminado=true;
            values.estado=EstadoContenido.EnRevision;   
            values.published_at=new Date();              
            delete values.idPadre;
            await Fetch('menus/'+values.id, 'put', values)
            getContenido()
            isLoadingAction(false)
            history.push("/menus/lista-por-eliminar");
        } catch (error) {
            console.error('mostrando error', error)
            isLoadingAction(false)
            catchError(error);
        }
    }
	
	 function DeleteAlert(title, data) {
        AlertConfirm(title, 'question', (response) => {            
            if (response.isConfirmed) {
                deleteContenido(data)
            }
        })
    }
   

    const getContenido = async () => {
        isLoadingAction(true)
        try {
            const { data } = await Fetch('menus?_publicationState=preview&published_at_null=true&estado=ER', 'get')
            setContenido(data)
            isLoadingAction(false)
        } catch (error) {
            console.error('mostrando error', error)
            isLoadingAction(false)
            catchError(error);
        }
    }

    function FActualizacion(data) {
        return formatDate(data?.updated_at)
    }
   
    function Accion(data) {
        return (
            <>               
               <Link to={"/menus/ver-borrador/" + data.id} className="btn btn-sm btn btn-success"><i className="fa fa-pencil" /></Link>{' '}
               <button type="button" className="btn btn-sm btn btn-danger" onClick={() => { DeleteAlert("Está apunto de eliminar este contenido", data) }} ><i className="fa fa-trash" /></button>{' '}
            </>
        )
    }

    return (
        <React.Fragment>
            <Row>
                <Col md="12">
                    <Card>
                        <Card.Header>
                            <div className="float-right mt-10">
                                <Link to="/menus/nuevo" className="btn btn-primary btn-rounded box-shadow btn-icon"><i className="fa fa-plus" /> Nuevo menú</Link>
                            </div>
                            Listado de menús en revisión
                        </Card.Header>
                        <Card.Body>
                        <DataGridForm dataSource={contenido}
                                keyExpr='id'     
                                columnHidingEnabled={true}                           
                                columns={[
                                    { caption: 'Id', dataField: 'id' },
                                    { caption: 'Nombre', dataField: 'Menu.Menu.nombre' },
                                    { caption: 'Creado por', dataField: 'creado_por.username' },
                                    { caption: 'Actualizado por', dataField: 'actualizado_por.username' },
                                    { caption: 'Ult. Actualización', render: 'FActualizacion', cellRender: { FActualizacion } },                                    
                                    { caption: 'Acción', alignment: 'center', render: 'Accion', cellRender: { Accion } }
                                ]} />                          
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    )
}

const mapStateToProps = (state) => {

    return {
        isLoading: state.loading,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        isLoadingAction: (data) => { dispatch(loadingAction(data)) }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ListaBorradores);