/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import "./index.scss";
import { Row, Col } from 'react-bootstrap';
import {Fetch, catchError} from '../../utils/fetch'
import {useDispatch} from 'react-redux';
import {loadingAction} from '../../redux/actions'

function Dashboard(){

    const [stats, setStats] = React.useState({
        paginas: 0,
        novedades: 0,
        centrosMedicos: 0,
        slides: 0,
        especialidades:0,
        popups:0,
        prensas:0,
        categorias:0
    });
    const dispatch = useDispatch()
    const isLoadingAction  = (data) => dispatch(loadingAction(data))

    const getStats = async () =>{
        isLoadingAction(true)
        try {
            const { data: paginas } = await Fetch('menus/count/porEstado', 'get')
            const { data: novedades } = await Fetch('novedades/count/porEstado', 'get')
            const { data: centrosMedicos } = await Fetch('centros-medicos/count/porEstado', 'get')
            const { data: slides } = await Fetch('home-slides/count/porEstado', 'get')
            const { data: especialidades } = await Fetch('especialidades/count/porEstado', 'get')
            const { data: popups } = await Fetch('popups/count/porEstado', 'get')
            const { data: prensas } = await Fetch('prensas/count/porEstado', 'get')
            const { data: categorias } = await Fetch('categorias/count/porEstado', 'get')

            Promise.all([paginas, novedades, centrosMedicos, slides,especialidades,popups,prensas,categorias]).then(response => {
                setStats({
                    paginas: response[0],
                    novedades: response[1],
                    centrosMedicos: response[2],
                    slides: response[3],
                    especialidades: response[4],
                    popups:response[5],
                    prensas:response[6],
                    categorias:response[7]
                })
            })
            
            isLoadingAction(false)
        } catch (error) {
            console.error('mostrando error', error)
            isLoadingAction(false)
            catchError(error);
        }
    }

    React.useEffect(()=>{
        getStats()
    },[])
    return(
        <React.Fragment>
                <Row>
                {/* Páginas */}
                <Col xs={12} md={4}>
                    <div className="widget bg-light padding-0">
                        <Row className="row-table">
                            <div className="col-xs-4 text-center padding-15 bg-teal title">
                                {/* <em className="fad fa-dot-circle fa-2x" /> */}
                                PÁGINAS
                            </div>
                            <div className="col-xs-6 padding-15">
                                <div className='card-item'>
                                    <div className="margin-b-0 text-muted">Aprobados</div>
                                    <div className="margin-b-0">{stats.paginas.aprobados}</div>             
                                </div>
                                <div className='card-item'>
                                    <div className="margin-b-0 text-muted">Por aprobar</div>                                                   
                                    <div className="margin-b-0">{stats.paginas.poraprobar}</div>        
                                </div>
                                <div className='card-item'>
                                    <div className="margin-b-0 text-muted">Por revisar</div>                                                   
                                    <div className="margin-b-0">{stats.paginas.porrevisar}</div>                                
                                </div>
                                <div className='card-item'>
                                    <div className="margin-b-0 text-muted">Por eliminar</div>                                                   
                                    <div className="margin-b-0">{stats.paginas.poreliminar}</div>                                
                                </div>
                            </div>

                            {/* <div className="col-xs-2 padding-15 text-right">                                
                                                                
                            </div> */}
                        </Row>
                    </div>
                </Col>
                {/* Fin Páginas */}

                {/* Novedades */}
                <Col xs={12} md={4}>
                    <div className="widget bg-light padding-0">
                        <Row className="row-table">
                            <div className="col-xs-4 text-center padding-15 bg-teal title">
                                {/* <em className="fad fa-dot-circle fa-2x" /> */}
                                NOVEDADES
                            </div>
                            <div className="col-xs-6 padding-15">
                                <div className='card-item'>
                                    <div className="margin-b-0 text-muted">Aprobados</div>
                                    <div className="margin-b-0">{stats.novedades.aprobados}</div>             
                                </div>
                                <div className='card-item'>
                                    <div className="margin-b-0 text-muted">Por aprobar</div>                                                   
                                    <div className="margin-b-0">{stats.novedades.poraprobar}</div>        
                                </div>
                                <div className='card-item'>
                                    <div className="margin-b-0 text-muted">Por revisar</div>                                                   
                                    <div className="margin-b-0">{stats.novedades.porrevisar}</div>                                
                                </div>
                                <div className='card-item'>
                                    <div className="margin-b-0 text-muted">Por eliminar</div>                                                   
                                    <div className="margin-b-0">{stats.novedades.poreliminar}</div>                                
                                </div>
                            </div>

                            {/* <div className="col-xs-2 padding-15 text-right">                                
                                                                
                            </div> */}
                        </Row>
                    </div>
                </Col>
                {/* Fin Novedades */}

                {/* Páginas */}
                <Col xs={12} md={4}>
                    <div className="widget bg-light padding-0">
                        <Row className="row-table">
                            <div className="col-xs-4 text-center padding-15 bg-teal title">
                                {/* <em className="fad fa-dot-circle fa-2x" /> */}
                                CENTROS <br/> MÉDICOS
                            </div>
                            <div className="col-xs-6 padding-15">
                                <div className='card-item'>
                                    <div className="margin-b-0 text-muted">Aprobados</div>
                                    <div className="margin-b-0">{stats.centrosMedicos.aprobados}</div>             
                                </div>
                                <div className='card-item'>
                                    <div className="margin-b-0 text-muted">Por aprobar</div>                                                   
                                    <div className="margin-b-0">{stats.centrosMedicos.poraprobar}</div>        
                                </div>
                                <div className='card-item'>
                                    <div className="margin-b-0 text-muted">Por revisar</div>                                                   
                                    <div className="margin-b-0">{stats.centrosMedicos.porrevisar}</div>                                
                                </div>
                                <div className='card-item'>
                                    <div className="margin-b-0 text-muted">Por eliminar</div>                                                   
                                    <div className="margin-b-0">{stats.centrosMedicos.poreliminar}</div>                                
                                </div>
                            </div>

                            {/* <div className="col-xs-2 padding-15 text-right">                                
                                                                
                            </div> */}
                        </Row>
                    </div>
                </Col>
                {/* Fin Páginas */}

                {/* SLIDEs */}
                <Col xs={12} md={4}>
                    <div className="widget bg-light padding-0">
                        <Row className="row-table">
                            <div className="col-xs-4 text-center padding-15 bg-teal title">
                                {/* <em className="fad fa-dot-circle fa-2x" /> */}
                                SLIDES
                            </div>
                            <div className="col-xs-6 padding-15">
                                <div className='card-item'>
                                    <div className="margin-b-0 text-muted">Aprobados</div>
                                    <div className="margin-b-0">{stats.slides.aprobados}</div>             
                                </div>
                                <div className='card-item'>
                                    <div className="margin-b-0 text-muted">Por aprobar</div>                                                   
                                    <div className="margin-b-0">{stats.slides.poraprobar}</div>        
                                </div>
                                <div className='card-item'>
                                    <div className="margin-b-0 text-muted">Por revisar</div>                                                   
                                    <div className="margin-b-0">{stats.slides.porrevisar}</div>                                
                                </div>
                                <div className='card-item'>
                                    <div className="margin-b-0 text-muted">Por eliminar</div>                                                   
                                    <div className="margin-b-0">{stats.slides.poreliminar}</div>                                
                                </div>
                            </div>

                            {/* <div className="col-xs-2 padding-15 text-right">                                
                                                                
                            </div> */}
                        </Row>
                    </div>
                </Col>
                {/* Fin SLIDES */}

                  {/* ESPECIALIDADES */}
                  <Col xs={12} md={4}>
                    <div className="widget bg-light padding-0">
                        <Row className="row-table">
                            <div className="col-xs-4 text-center padding-15 bg-teal title">
                                {/* <em className="fad fa-dot-circle fa-2x" /> */}
                                ESPECIALIDADES
                            </div>
                            <div className="col-xs-6 padding-15">
                                <div className='card-item'>
                                    <div className="margin-b-0 text-muted">Aprobados</div>
                                    <div className="margin-b-0">{stats.especialidades.aprobados}</div>             
                                </div>
                                <div className='card-item'>
                                    <div className="margin-b-0 text-muted">Por aprobar</div>                                                   
                                    <div className="margin-b-0">{stats.especialidades.poraprobar}</div>        
                                </div>
                                <div className='card-item'>
                                    <div className="margin-b-0 text-muted">Por revisar</div>                                                   
                                    <div className="margin-b-0">{stats.especialidades.porrevisar}</div>                                
                                </div>
                                <div className='card-item'>
                                    <div className="margin-b-0 text-muted">Por eliminar</div>                                                   
                                    <div className="margin-b-0">{stats.especialidades.poreliminar}</div>                                
                                </div>
                            </div>

                            {/* <div className="col-xs-2 padding-15 text-right">                                
                                                                
                            </div> */}
                        </Row>
                    </div>
                </Col>
                {/* Fin ESPECIALIDADES */}

                {/* POPUPS */}
                <Col xs={12} md={4}>
                    <div className="widget bg-light padding-0">
                        <Row className="row-table">
                            <div className="col-xs-4 text-center padding-15 bg-teal title">
                                {/* <em className="fad fa-dot-circle fa-2x" /> */}
                                POP UP
                            </div>
                            <div className="col-xs-6 padding-15">
                                <div className='card-item'>
                                    <div className="margin-b-0 text-muted">Aprobados</div>
                                    <div className="margin-b-0">{stats.popups.aprobados}</div>             
                                </div>
                                <div className='card-item'>
                                    <div className="margin-b-0 text-muted">Por aprobar</div>                                                   
                                    <div className="margin-b-0">{stats.popups.poraprobar}</div>        
                                </div>
                                <div className='card-item'>
                                    <div className="margin-b-0 text-muted">Por revisar</div>                                                   
                                    <div className="margin-b-0">{stats.popups.porrevisar}</div>                                
                                </div>
                                <div className='card-item'>
                                    <div className="margin-b-0 text-muted">Por eliminar</div>                                                   
                                    <div className="margin-b-0">{stats.popups.poreliminar}</div>                                
                                </div>
                            </div>

                            {/* <div className="col-xs-2 padding-15 text-right">                                
                                                                
                            </div> */}
                        </Row>
                    </div>
                </Col>
                {/* Fin POPUPS */}

                 {/* PRENSAS */}
                 <Col xs={12} md={4}>
                    <div className="widget bg-light padding-0">
                        <Row className="row-table">
                            <div className="col-xs-4 text-center padding-15 bg-teal title">
                                {/* <em className="fad fa-dot-circle fa-2x" /> */}
                                PRENSA
                            </div>
                            <div className="col-xs-6 padding-15">
                                <div className='card-item'>
                                    <div className="margin-b-0 text-muted">Aprobados</div>
                                    <div className="margin-b-0">{stats.prensas.aprobados}</div>             
                                </div>
                                <div className='card-item'>
                                    <div className="margin-b-0 text-muted">Por aprobar</div>                                                   
                                    <div className="margin-b-0">{stats.prensas.poraprobar}</div>        
                                </div>
                                <div className='card-item'>
                                    <div className="margin-b-0 text-muted">Por revisar</div>                                                   
                                    <div className="margin-b-0">{stats.prensas.porrevisar}</div>                                
                                </div>
                                <div className='card-item'>
                                    <div className="margin-b-0 text-muted">Por eliminar</div>                                                   
                                    <div className="margin-b-0">{stats.prensas.poreliminar}</div>                                
                                </div>
                            </div>                            

                            {/* <div className="col-xs-2 padding-15 text-right">                                
                                                                
                            </div> */}
                        </Row>
                    </div>
                </Col>
                {/* Fin PRENSAS */}

                 {/* CATEGORÍAS */}
                 <Col xs={12} md={4}>
                    <div className="widget bg-light padding-0">
                        <Row className="row-table">
                            <div className="col-xs-4 text-center padding-15 bg-teal title">
                                {/* <em className="fad fa-dot-circle fa-2x" /> */}
                                CATEGORÍAS
                            </div>
                            <div className="col-xs-6 padding-15">
                                <div className='card-item'>
                                    <div className="margin-b-0 text-muted">Aprobados</div>
                                    <div className="margin-b-0">{stats.categorias.aprobados}</div>             
                                </div>
                                <div className='card-item'>
                                    <div className="margin-b-0 text-muted">&nbsp;</div>                                                   
                                    <div className="margin-b-0">&nbsp;</div>        
                                </div>
                                <div className='card-item'>
                                    <div className="margin-b-0 text-muted">&nbsp;</div>                                                   
                                    <div className="margin-b-0">&nbsp;</div>                                
                                </div>
                                <div className='card-item'>
                                    <div className="margin-b-0 text-muted">&nbsp;</div>                                                   
                                    <div className="margin-b-0">&nbsp;</div>                                
                                </div>
                            </div>                            

                            {/* <div className="col-xs-2 padding-15 text-right">                                
                                                                
                            </div> */}
                        </Row>
                    </div>
                </Col>
                {/* Fin PRENSAS */}

                </Row>
        </React.Fragment>
    )
}

export default Dashboard;
