import React, { useMemo } from "react";
import { Form, Row, Col, Button, Card } from "react-bootstrap";
import JoditEditor, { Jodit } from "jodit-react";
import { Formik, useFormik } from "formik";
import { Fetch, catchError } from "../../../utils/fetch";
import { useDispatch, useSelector } from "react-redux";
import { AlertInfo } from "../../../components/alert";
import { loadingAction } from "../../../redux/actions";
import MediaUploader from "../../../utils/media-uploader";
import { validateModel } from "./validationSchema";
import { useHistory } from "react-router-dom";
import { BASE_URL, EstadoContenido } from "../../../utils/constants";
import { removeSpecial } from "../../../utils/tools";
import Acordeon from "../components/acordeon";

const NuevoCentroMedico = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isLoading = useSelector((state) => state.loading);
  const [formFields, setFormFields] = React.useState([]);

  const config = useMemo(
    () => ({
      readonly: false, // all options from https://xdsoft.net/jodit/doc/
      height: 500,
      language: "es",
      disablePlugins: ["table"],
      askBeforePasteHTML: false,
      askBeforePasteFromWord: false,
      defaultActionOnPaste: "insert_clear_html",
      uploader: {
        url: BASE_URL + "/upload",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("jwt_token"),
        },
        data: null,
        filesVariableName: function (i) {
          return "files";
        },
        withCredentials: false,
        pathVariableName: "path",
        format: "json",
        method: "POST",
        prepareData: function (formData) {
          formData.append("id", 1);
        },
        isSuccess: function (resp) {
          return !resp.error;
        },
        getMessage: function (resp) {
          return resp.msgs.join("\n");
        },
        process: function (resp) {
          return resp;
        },
        defaultHandlerSuccess: function (resp) {
          var imagenesPermitidas = ["image/jpeg", "image/png"];
          for (var i = 0; i < resp.length; i++) {
            if (imagenesPermitidas.indexOf(resp[i].mime) !== -1) {
              var img1 = new Image();
              img1.src = resp[i]["url"];
              img1.alt = "imagen";
              img1.className = "img-fluid";
              this.s.insertImage(img1);
            } else if (resp[i].mime === "application/pdf") {
              const enlace = document.createElement("a");
              enlace.setAttribute("href", resp[i].url);
              const contenido = document.createTextNode(resp[i].name);
              // add the text node to the newly created div
              enlace.appendChild(contenido);
              this.s.insertNode(enlace);
            } else {
              AlertInfo("Tipo de archivo no permitido");
            }
          }
        },
      },
    }),
    []
  );

  const permissions = useSelector((state) => state.permissions);
  const canPublish = permissions["centros-medicos"].aprobar.enabled;

  const submit = async (values) => {
    dispatch(loadingAction(true));

    values.published_at = null;
    values.estado = EstadoContenido.EnRevision;

    values.info_contacto = removeSpecial(values.info_contacto);
    values.descripcion = removeSpecial(values.descripcion);

    try {
      await Fetch("centros-medicos", "post", values);

      dispatch(loadingAction(false));

      AlertInfo(
        "Centro médico creado con éxito.<br/>Recuerde que falta su confirmación para aprobar.<br/> Nota: Por favor, notificar al responsable de publicar"
      );
      history.push("/centros-medicos/lista-borradores");
    } catch (error) {
      catchError(error);
      dispatch(loadingAction(false));
    }
  };

  const {
    values,
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue,
    errors,
  } = useFormik({
    initialValues: {
      direccion: "",
      localidad: "",
      provincia: "",
      colectivos: "",
      tren: "",
      info_contacto: "",
      descripcion: "",
      nombre: "",
      slug: "",
      url: "",
      imagen: "",
      miniatura: "",
      acordion: [
        {
          id: "",
          items_acordion: [
            {
              id: "",
              nombre: "",
            },
          ],
          nombre: "",
          mostrar: true,
        },
      ],
      iframe_mapa: "",
      locale: "es",
    },
    enableReinitialize: true,
    validationSchema: validateModel,
    onSubmit: (values) => submit(values),
  });

  const handleBlurTitulo = async (event, setFieldValue) => {
    const value = event.target.value;
    const name = event.target.name;
    const dataToApi = {
      contentTypeUID: "application::centros-medicos.centros-medicos",
      field: "slug",
      data: {
        nombre: value,
        slug: "",
      },
    };
    setFieldValue(name, value, false);
    try {
      const { data } = await Fetch(
        "content-manager/uid/generate",
        "post",
        dataToApi
      );
      setFieldValue("slug", data.data, false);
    } catch (error) {
      console.error("mostrando error", error);
    }
  };

  const initialValues = {
    direccion: "",
    localidad: "",
    provincia: "",
    colectivos: "",
    tren: "",
    info_contacto: "",
    descripcion: "",
    nombre: "",
    slug: "",
    url: "",
    imagen: "",
    miniatura: "",
    acordion: [
      {
        id: "",
        items_acordion: [
          {
            id: "",
            nombre: "",
          },
        ],
        nombre: "",
        mostrar: true,
      },
    ],
    iframe_mapa: "",
    locale: "es",
  };

  return (
    <React.Fragment>
      <Formik
        initialValues={initialValues}
        validationSchema={validateModel}
        onSubmit={(values, { setSubmitting }) => {
          submit(values);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
          /* and other goodies */
        }) => (
          <form onSubmit={handleSubmit}>
            <Row>
              <Col>
                <Card>
                  <Card.Header>
                    <Row>
                      <Col>Nuevo centro médico</Col>
                    </Row>
                  </Card.Header>
                  <Card.Body>
                    <Row>
                      <Col>
                        <Form.Group>
                          <label>Nombre</label>
                          <input
                            type="text"
                            name="nombre"
                            onChange={handleChange}
                            onBlur={(e) => handleBlurTitulo(e, setFieldValue)}
                            value={values.nombre}
                            className="form-control "
                          />
                          <div className="text-danger">{errors?.nombre}</div>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group>
                          <label>Url</label>
                          <input
                            type="text"
                            name="slug"
                            className="form-control"
                            value={values.slug}
                            readOnly
                          />
                        </Form.Group>
                      </Col>
                    </Row>

                    {Object.keys(values.miniatura).length > 0 && (
                      <Row>
                        <Col>
                          <Form.Group>
                            <img
                              src={values.miniatura.url}
                              alt="vista-previa"
                              className="img-fluid w-50"
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                    )}

                    <Row>
                      <Col>
                        <Form.Group>
                          <label>Imagen miniatura home</label>
                          <br />
                          <span className="text-muted">
                            Se recomienda una imagen de 500px de ancho x 405px
                            de alto. En formato JPG o PNG.
                          </span>
                          <MediaUploader
                            setFieldValue={setFieldValue}
                            field={"miniatura"}
                            tipoMedia="imagen"
                            ancho={500}
                            alto={405}
                          />
                          <div className="text-danger">{errors?.miniatura}</div>
                        </Form.Group>
                      </Col>
                    </Row>

                    {Object.keys(values.imagen).length > 0 && (
                      <Row>
                        <Col>
                          <Form.Group>
                            <img
                              src={values.imagen.url}
                              alt="vista-previa"
                              className="img-fluid w-50"
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                    )}

                    <Row>
                      <Col>
                        <Form.Group>
                          <label>Imagen centro médico</label>
                          <MediaUploader
                            setFieldValue={setFieldValue}
                            field={"imagen"}
                            tipoMedia="imagen"
                            ancho={500}
                            alto={318}
                          />
                          <div className="text-danger">{errors?.imagen}</div>
                          {/* <span className="text-muted">Se recomienda una imagen de 500px de ancho x 318px de alto. En formato JPG o PNG.</span> */}
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <Form.Group>
                          <label>Dirección</label>
                          <input
                            type="text"
                            name="direccion"
                            className="form-control"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.direccion}
                          />
                          <div className="text-danger">{errors?.direccion}</div>
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <Form.Group>
                          <label>Localidad</label>
                          <input
                            type="text"
                            name="localidad"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className="form-control"
                            value={values.localidad}
                          />
                          <div className="text-danger">{errors?.localidad}</div>
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <Form.Group>
                          <label>Provincia</label>
                          <input
                            type="text"
                            name="provincia"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className="form-control"
                            value={values.provincia}
                          />
                          <div className="text-danger">{errors?.provincia}</div>
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <Form.Group>
                          <label>Colectivos</label>
                          <input
                            type="text"
                            name="colectivos"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className="form-control"
                            value={values.colectivos}
                          />
                          <div className="text-danger">
                            {errors?.colectivos}
                          </div>
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <Form.Group>
                          <label>Tren</label>
                          <input
                            type="text"
                            name="tren"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className="form-control"
                            value={values.tren}
                          />
                          <div className="text-danger">{errors?.tren}</div>
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <Form.Group>
                          <label>Información de contacto</label>
                          <JoditEditor
                            config={config}
                            value={values.info_contacto}
                            tabIndex={1} // tabIndex of textarea
                            onBlur={(v) =>
                              setFieldValue(`info_contacto`, v, false)
                            }
                          />
                          <div className="text-danger">
                            {errors?.info_contacto}
                          </div>
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <Form.Group>
                          <label>Descripción</label>
                          <JoditEditor
                            config={config}
                            value={values.descripcion}
                            tabIndex={1} // tabIndex of textarea
                            onBlur={(v) =>
                              setFieldValue(`descripcion`, v, false)
                            }
                          />
                          <div className="text-danger">
                            {errors?.descripcion}
                          </div>
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <Form.Group>
                          <label>Contenido del Mapa</label>
                          <textarea
                            name="iframe_mapa"
                            className="form-control"
                            onChange={handleChange}
                            value={values.iframe_mapa}
                          ></textarea>
                          <div className="text-danger">
                            {errors?.iframe_mapa}
                          </div>
                        </Form.Group>
                      </Col>
                    </Row>

                    <Acordeon
                      values={values}
                      errors={errors}
                      handleChange={handleChange}
                      setFieldValue={setFieldValue}
                    />

                    <Button
                      type="button"
                      onClick={() => handleSubmit()}
                      disabled={isLoading}
                      className="btn btn-success btn-icon mt-5"
                    >
                      <i className="fa fa-floppy-o " />
                      Guardar
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </form>
        )}
      </Formik>
    </React.Fragment>
  );
};
export default NuevoCentroMedico;
