import * as Yup from "yup";

const GENERIG_MSG = "Campo obligatorio";

export const validateModel = Yup.object().shape({
  nombre: Yup.string().trim().required(GENERIG_MSG),
  template1: Yup.object().shape({
    titulo: Yup.string().required(GENERIG_MSG),
    contenido: Yup.string().required(GENERIG_MSG),

    // La imagen es obligatoria cuando hay un (titulo de imagen)
    imagen: Yup.string().test({
      message: GENERIG_MSG,
      test: function (value) {
        const data = this.resolve(Yup.ref("titulo_imagen"));
        if (data) {
          return !!value;
        }
        return true;
      },
    }),

    // El titulo de la imagen es obligatorio cuando hay una (imagen)
    titulo_imagen: Yup.string().test({
      message: GENERIG_MSG,
      test: function (value) {
        const data = this.resolve(Yup.ref("imagen"));
        if (data) {
          return !!value;
        }
        return true;
      },
    }),
  }),
});
