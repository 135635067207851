import React, { useMemo } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import JoditEditor, { Jodit } from "jodit-react";
import { useFormik } from "formik";
import { Fetch, catchError } from "../../../utils/fetch";
import { useDispatch, useSelector } from "react-redux";
import { AlertSuccess } from "../../alert";
import { loadingAction } from "../../../redux/actions";
import { useHistory } from "react-router-dom";
import MediaUploader from "../../../utils/media-uploader";
import { validateModel } from "./validationSchema";
import { BASE_URL, EstadoContenido } from "../../../utils/constants";
import { AlertInfo } from "../../alert";
import { removeSpecial } from "../../../utils/tools";
import LayoutJodit from "../../customizer/layout-jodit";

const Template1Component = ({ dataResult, accion = "" }) => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.loading);
  const [layoutJodit, setLayoutJodit] = React.useState("PC");
  const history = useHistory();

  const permissions = useSelector((state) => state.permissions);
  const canPublish = permissions.paginas.aprobar.enabled;

  React.useEffect(() => {
    if (dataResult) {
      setValues(dataResult);
    }
  }, [dataResult]);

  //const [isLoading, setIsLoading] = React.useState(false);

  // function preparePaste(jodit) {
  //   jodit.e.on(
  //     "paste",
  //     (e) => {
  //       jodit.e.stopPropagation("paste");
  //       let plainText = Jodit.modules.Helpers.getDataTransfer(e).getData(
  //         Jodit.constants.TEXT_PLAIN
  //       );
  //       // plainText = plainText.replace(/(^|;)\s*font-[^;]+/g, "");
  //       // let regex = /<(?!(\/\s*)?(div|b|i|em|strong|u|p)[>,\s])([^>])*>/g;
  //       // plainText = plainText.replace(regex, '');
  //       plainText = plainText.normalize("NFKC").replaceAll("\n", "<br/>");plainText=removeSpecial(plainText);

  //       jodit.s.insertHTML(plainText);
  //       return false;
  //     },
  //     { top: true }
  //   );
  // }
  // Jodit.plugins.add("preparePaste", preparePaste);

  const config = useMemo(
    () => ({
      readonly: false, // all options from https://xdsoft.net/jodit/doc/
      language: "es",
      disablePlugins: ["table"],
      disabled: accion === "eliminar" ? true : false,
      askBeforePasteHTML: false,
      askBeforePasteFromWord: false,
      defaultActionOnPaste: "insert_clear_html",
      enableDragAndDropFileToEditor: true,
      uploader: {
        url: BASE_URL + "/upload",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("jwt_token"),
        },
        data: null,
        filesVariableName: function (i) {
          return "files";
        },
        withCredentials: false,
        pathVariableName: "path",
        format: "json",
        method: "POST",
        prepareData: function (formData) {
          formData.append("id", 1);
        },
        isSuccess: function (resp) {
          return !resp.error;
        },
        getMessage: function (resp) {
          return resp.msgs.join("\n");
        },
        process: function (resp) {
          return resp;
        },
        defaultHandlerSuccess: function (resp) {
          var imagenesPermitidas = ["image/jpeg", "image/png"];
          for (var i = 0; i < resp.length; i++) {
            if (imagenesPermitidas.indexOf(resp[i].mime) !== -1) {
              var img1 = new Image();
              img1.src = resp[i]["url"];
              img1.alt = "imagen";
              img1.className = "img-fluid";
              this.s.insertImage(img1);
            } else if (resp[i].mime === "application/pdf") {
              const enlace = document.createElement("a");
              enlace.setAttribute("href", resp[i].url);
              const contenido = document.createTextNode(resp[i].name);
              // add the text node to the newly created div
              enlace.appendChild(contenido);
              this.s.insertNode(enlace);
            } else {
              AlertInfo("Tipo de archivo no permitido");
            }
          }
        },
      },
    }),
    []
  );

  const submit = async (values) => {
    if (accion === "eliminar") {
      dispatch(loadingAction(true));
      try {
        const { data } = await Fetch("paginas/" + dataResult.id, "delete");
        dispatch(loadingAction(false));
        AlertSuccess("Página eliminada con éxito");
        history.push("/pages/lista-por-eliminar");
      } catch (error) {
        catchError(error);
        dispatch(loadingAction(false));
      }
    } else {
      dispatch(loadingAction(true));

      values.published_at = new Date();
      values.estado = EstadoContenido.Publicado;

      values.template1.contenido = removeSpecial(values.template1.contenido);

      delete values.template1.id;

      try {
        const { data } = await Fetch(
          "paginas/aprobar/" +
            (values.idPadre === null ? dataResult.id : values.idPadre),
          "put",
          values
        );

        dispatch(loadingAction(false));

        AlertSuccess("Página actualizada con éxito");

        history.push("/pages/lista");
      } catch (error) {
        catchError(error);
        dispatch(loadingAction(false));
      }
    }
  };

  const onCancelar = async (values, type = null) => {
    try {
      dispatch(loadingAction(true));

      if (accion === "eliminar") {
        const params = {
          eliminado: false,
          published_at:
            values.estado === EstadoContenido.EnRevision
              ? null
              : values.published_at,
        };

        const { data } = await Fetch("paginas/" + dataResult.id, "put", params);

        dispatch(loadingAction(false));
        AlertSuccess("Se ha cancelado la eliminación de la página con éxito");

        //history.push("/pages/lista-por-eliminar");

        if (values.estado === EstadoContenido.EnRevision) {
          history.push("/pages/lista-borradores");
        } else {
          history.push("/pages/lista");
        }
      } else {
        //por aprobación
        const params = {
          published_at: null,
          estado: EstadoContenido.EnRevision,
        };

        const { data } = await Fetch("paginas/" + dataResult.id, "put", params);

        dispatch(loadingAction(false));
        AlertSuccess("Se ha cancelado la aprobación de la página con éxito");

        history.push("/pages/lista-borradores");
      }
    } catch (error) {
      dispatch(loadingAction(false));
      catchError(error);
    }
  };

  const {
    values,
    handleSubmit,
    handleChange,
    setFieldValue,
    setValues,
    validateForm,
    errors,
  } = useFormik({
    initialValues: {
      nombre: "",
      slug: "",
      template: "template1",
      template1: {
        titulo_imagen: "",
        titulo: "",
        imagen: "",
        contenido: "",
      },
      locale: "es",
    },
    enableReinitialize: true,
    validationSchema: validateModel,
    onSubmit: (values) => submit(values),
  });

  React.useEffect(() => {
    const validateFormImg = async () => {
      if (values.template1.imagen) {
        await validateForm();
      }
    };
    validateFormImg();
  }, [values.template1.imagen]);

  const handleBlurTitulo = async (event) => {
    const value = event.target.value;
    const name = event.target.name;
    const dataToApi = {
      contentTypeUID: "application::paginas.paginas",
      field: "slug",
      data: {
        nombre: value,
        slug: "",
      },
    };
    setFieldValue(name, value, false);
    try {
      const { data } = await Fetch(
        "content-manager/uid/generate",
        "post",
        dataToApi
      );
      if (data.data != "-1") {
        setFieldValue("slug", data.data, false);
      } else {
        setFieldValue("slug", "paginas-1", false);
      }
    } catch (error) {
      console.error("mostrando error", error);
    }
  };

  const handleRemoveImg = async () => {
    setFieldValue("template1.imagen", "", false);
    setTimeout(async () => {
      await validateForm();
    }, 1000);
  };

  const handleChangeJoiEditor = (data) => {
    setFieldValue("template1.contenido", data, false);
  };

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit}>
        <Row>
          <Col>
            <h3>Información de la página</h3>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group>
              <label>Nombre de la página</label>
              <input
                type="text"
                disabled={accion === "eliminar" ? true : false}
                name="nombre"
                onChange={handleChange}
                onBlur={(e) => handleBlurTitulo(e)}
                value={values.nombre}
                className="form-control "
              />
              <div className="text-danger">{errors?.nombre}</div>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group>
              <label>Url</label>
              <input
                type="text"
                disabled={accion === "eliminar" ? true : false}
                name="slug"
                className="form-control"
                value={values.slug}
                readOnly
              />
              <div className="text-danger">{errors?.slug}</div>
            </Form.Group>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col>
            <h3>Título e imagen (opcional)</h3>
          </Col>
        </Row>
        {values.template1?.imagen && (
          <>
            <Row>
              <Col>
                <Form.Group>
                  <img
                    src={values.template1?.imagen?.url}
                    alt="vista-previa"
                    className="img-fluid w-25"
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Button
                  type="button"
                  style={{ marginBottom: "10px" }}
                  onClick={handleRemoveImg}
                  className="btn btn-danger btn-icon mt-2"
                >
                  <i className="fa fa-trash-o " />
                  Eliminar
                </Button>
              </Col>
            </Row>
          </>
        )}
        <Row>
          <Col>
            <Form.Group>
              <label>Título imagen</label>
              <input
                type="text"
                name="template1.titulo_imagen"
                className="form-control"
                onChange={handleChange}
                value={values.template1.titulo_imagen}
              />
              <div className="text-danger">
                {errors?.template1?.titulo_imagen}
              </div>
            </Form.Group>
          </Col>
        </Row>
        {accion !== "eliminar" && (
          <Row>
            <Col>
              <Form.Group>
                <label>Imagen</label>
                <MediaUploader
                  setFieldValue={setFieldValue}
                  field={"template1.imagen"}
                  tipoMedia="imagen"
                  ancho={300}
                  alto={370}
                  value={values.template1?.imagen ? 100 : 0}
                  key={values.template1?.imagen}
                />
                <div className="text-danger">{errors?.template1?.imagen}</div>
                <span className="text-muted">
                  Se recomienda una imagen de 300px de ancho x 370px de alto. En
                  formato JPG o PNG.
                </span>
              </Form.Group>
            </Col>
          </Row>
        )}

        <hr />
        <Row>
          <Col>
            <h3>Información del contenido</h3>
          </Col>
        </Row>

        <Row>
          <Col>
            <Form.Group>
              <label>Titulo</label>
              <input
                type="text"
                disabled={accion === "eliminar" ? true : false}
                name="template1.titulo"
                onChange={handleChange}
                value={values.template1.titulo}
                className="form-control "
              />
              <div className="text-danger">{errors?.template1?.titulo}</div>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col className={`${layoutJodit == "MOBILE" && "col-6"}`}>
            <Form.Group>
              <label>Contenido</label>
              <LayoutJodit
                setLayoutJodit={setLayoutJodit}
                layoutJodit={layoutJodit}
              />
              <JoditEditor
                config={config}
                value={values.template1.contenido}
                tabIndex={1} // tabIndex of textarea
                disabled={accion === "eliminar" ? true : false}
                onBlur={(newContent) => handleChangeJoiEditor(newContent)}
              />
              <div className="text-danger">{errors?.template1?.contenido}</div>
            </Form.Group>
          </Col>
        </Row>

        <Row className="mt-5">
          <Col className="col col-lg-2">
            <Button
              type="button"
              onClick={() => handleSubmit()}
              disabled={isLoading || (accion!=="Eliminar" && !canPublish)}
              className={
                accion === "eliminar"
                  ? "btn btn-danger btn-icon"
                  : "btn btn-success btn-icon"
              }
            >
              <i
                className={
                  accion === "eliminar" ? "fa fa-trash" : "fa fa-floppy-o"
                }
              />
              {accion === "eliminar" ? "Eliminar" : "Publicar"}
            </Button>
          </Col>
          <Col className="col col-lg-3">
            <Button
              type="button"
              onClick={() => onCancelar(values)}
              disabled={isLoading}
              className="btn btn-primary btn-icon"
            >
              <i className="fa fa-undo " />
              {accion === "eliminar" ? "Cancelar" : "Por revisar"}
            </Button>
          </Col>
        </Row>
      </form>
    </React.Fragment>
  );
};
export default Template1Component;
