import React from "react";
import { Row, Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FieldArray, FormikProvider } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

const MyButtons = ({ formik }) => {
  return (
    <>
      <Row>
        <Col>
          <label>Botones</label>
        </Col>
      </Row>
      <Row>
        <Col style={{ padding: 0 }}>
          <FormikProvider value={formik}>
            <FieldArray name="botones">
              {({ move, swap, push, remove, insert, unshift, pop, form }) => {
                return (
                  <>
                    {formik.values?.botones &&
                      formik.values?.botones?.length > 0 &&
                      formik.values?.botones.map((data, index) => {
                        return (
                          <Row key={index} className="border m-3">
                            <Col lg={4} style={{ marginTop: "15px" }}>
                              <div className="form-group">
                                <label>Nombre</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name={`botones.${index}.nombre`}
                                  onChange={formik.handleChange}
                                  value={formik.values.botones[index].nombre}
                                />
                                {formik.errors &&
                                  formik.errors.botones &&
                                  formik.errors.botones[index] &&
                                  formik.errors.botones[index].nombre && (
                                    <div className="text-danger">
                                      {formik.errors.botones[index].nombre}
                                    </div>
                                  )}
                              </div>
                            </Col>
                            <Col lg={4} style={{ marginTop: "15px" }}>
                              <div className="form-group">
                                <label>Url</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name={`botones.${index}.url`}
                                  onChange={formik.handleChange}
                                  value={formik.values.botones[index].url}
                                />
                                {formik.errors &&
                                  formik.errors.botones &&
                                  formik.errors.botones[index] &&
                                  formik.errors.botones[index].url && (
                                    <div className="text-danger">
                                      {formik.errors.botones[index].url}
                                    </div>
                                  )}
                              </div>
                            </Col>
                            <Col lg={4} style={{ marginTop: "15px" }}>
                              <div className="form-group">
                                <label>Estado</label>
                                <select
                                  className="form-control"
                                  name={`botones.${index}.estado`}
                                  onChange={formik.handleChange}
                                  value={formik.values.botones[index].estado}
                                >
                                  <option>Seleccionar</option>
                                  <option value="visible">Visible</option>
                                  <option value="no_visible">No visible</option>
                                </select>
                                {formik.errors &&
                                  formik.errors.botones &&
                                  formik.errors.botones[index] &&
                                  formik.errors.botones[index].estilo && (
                                    <div className="text-danger">
                                      {formik.errors.botones[index].estado}
                                    </div>
                                  )}
                              </div>
                            </Col>
                            <Col lg={4} style={{ marginTop: "15px" }}>
                              <div className="form-group">
                                <label>Pop up</label>&nbsp;
                                <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip id={`tooltip-top`}>Alerta de salida del sitio.</Tooltip>
                                  }
                                >
                                  <FontAwesomeIcon icon={faInfoCircle} color="orange"/>
                                </OverlayTrigger>
                                <select
                                  className="form-control"
                                  name={`botones.${index}.mostrar_popup`}
                                  onChange={formik.handleChange}
                                  value={formik.values.botones[index].mostrar_popup}
                                >
                                  <option>Seleccionar</option>
                                  <option value="visible">Visible</option>
                                  <option value="no_visible">No visible</option>
                                </select>
                                {formik.errors &&
                                  formik.errors.botones &&
                                  formik.errors.botones[index] &&
                                  formik.errors.botones[index].estilo && (
                                    <div className="text-danger">
                                      {formik.errors.botones[index].mostrar_popup}
                                    </div>
                                  )}
                              </div>
                            </Col>
                          </Row>
                        );
                      })}
                  </>
                );
              }}
            </FieldArray>
          </FormikProvider>
        </Col>
      </Row>
    </>
  );
};

export default MyButtons;
