import React from "react";
import {
  Row,
  Col,
  Card,
  Table,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { Fetch, catchError } from "../../../utils/fetch";
import {
  AlertConfirm,
  AlertConfirmDeleteAll,
  AlertInfo,
} from "../../../components/alert";
import { connect, useSelector } from "react-redux";
import { categoriaAction, loadingAction } from "../../../redux/actions";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import DataGridForm from "../../../components/common/DataGridForm";
import { copyToClickBoard } from "../../../utils/slug";
import { formatDate } from "../../../utils/formatDate";
import "./style.css";

function EditarSubcategorias({ isLoadingAction, isLoading }, props) {
  const [contenido, setContenido] = React.useState([]);
  const { id } = useParams();
  const categoria = useSelector((state) => state.categoria);

  React.useEffect(() => {
    getContenido();
  }, []);

  const getContenido = async () => {
    isLoadingAction(true);
    try {
      const { data } = await Fetch(`subcategorias/porCategoria/${id}`, "get");
      setContenido(data);
      isLoadingAction(false);
    } catch (error) {
      console.error("mostrando error", error);
      isLoadingAction(false);
      catchError(error);
    }
  };

  const agregarSubcategoria = () => {
    setContenido([{}, ...contenido]);
  };

  const presionar = async (e, index) => {
    if (e.key === "Enter") {
      isLoadingAction(true);
      let subcategoria = contenido[index];
      subcategoria.nombre = e.target.value;
      subcategoria.categoria = id;

      contenido[index] = subcategoria;
      let response = null;

      if (contenido[index].editando) {
        response = await Fetch(
          `subcategorias/${contenido[index].id}`,
          "put",
          contenido[index]
        );
      } else {
        response = await Fetch(`subcategorias/crear`, "post", contenido[index]);
      }

      if (response.data.status === "ok") {
        contenido[index] = response.data.data;
        setContenido([...contenido]);
      } else if (response.data.status === "error") {
        AlertInfo(
          "El nombre para la subcategoría ya existe. Prueba con otro para poder guardar"
        );
      }
      isLoadingAction(false);
    }
  };

  const editar = (e, index) => {
    contenido[index].editando = true;
    setContenido([...contenido]);
  };

  const onChange = (e, index) => {
    let subCategoria = contenido[index];
    subCategoria.nombre = e.target.value;
    subCategoria.anadiendo = true;
    contenido[index] = subCategoria;
    setContenido([...contenido]);
  };

  const eliminar = (e, index) => {
    AlertConfirmDeleteAll(
      "JUAS",
      "warning",
      async (response) => {
        if (response.isConfirmed) {
          isLoadingAction(true);
          try {
            await Fetch("subcategorias/" + contenido[index].id, "DELETE");
            isLoadingAction(false);
          } catch (error) {
            console.error("mostrando error", error);
            isLoadingAction(false);
            catchError(error);
          }
          await getContenido();
        }
      },
      "¡Atención!",
      "Continuar",
      "Cancelar",
      true,
      '<div class="swal2-html-container" id="swal2-html-container">Estás por eliminar una subcategoria, esto afectará a los contenidos asociados a ella. <br> ¿Querés continuar?</div>'
    );
  };

  return (
    <React.Fragment>
      <Row>
        <Col md="12">
          <Card>
            <Card.Header>
              <div className="float-right mt-10">
                <div
                  className="btn btn-primary btn-rounded box-shadow btn-icon"
                  onClick={agregarSubcategoria}
                >
                  <i className="fa fa-plus" /> Nueva subcategoria
                </div>
              </div>
              Editar {categoria}
            </Card.Header>
            <Card.Body>
              {contenido.map((subcategoria, index) => {
                return (
                  <Card className="custom-card" key={index}>
                    <Card.Body>
                      <div className="contenedor-subcategorias">
                        {"nombre" in subcategoria &&
                        !("editando" in subcategoria) &&
                        !("anadiendo" in subcategoria) ? (
                          <span
                            onDoubleClick={(e) => editar(e, index)}
                            className="nombre-subcategoria"
                          >
                            {subcategoria.nombre}
                          </span>
                        ) : (
                          <input
                            value={subcategoria.nombre}
                            onChange={(e) => onChange(e, index)}
                            onKeyUp={(e) => presionar(e, index)}
                            placeholder="Nombrar nueva subcategoria"
                            className="input-subcategoria"
                          />
                        )}

                        <div
                          className="eliminar-subcategoria"
                          onClick={(e) => eliminar(e, index)}
                        >
                          X
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                );
              })}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.loading,
    categoria: state.categoria,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    isLoadingAction: (data) => {
      dispatch(loadingAction(data));
    },
    categoriaAction: (data) => {
      dispatch(categoriaAction(data));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditarSubcategorias);
