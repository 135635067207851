const types = {
  login: "[Login del usuario]",
  logout: "[Logout del usuario]",
  isLoading: "[Loading]",
  permissions: "[Load Permissions After Login]",
  categoria: "[Categoria]",
  especialistas: "[Especialistas (Doctores) para las especialidades]",
  subespecialidad: "[Subespecialidad para las especialidades]",
  practicas: "[Practicas para especialidades]",
};

export default types;
