import { useSelector, useDispatch } from "react-redux";
import React, { useState, useEffect } from "react";
import { Form, Row, Col } from "react-bootstrap";
import { useFormik } from "formik";

import { AlertConfirmDeleteAll, AlertSuccess } from "../../../components/alert";
import { validateModelNuevaSubespecialidad } from "../nuevo/validationSchema";
import ModalSubespecialidad from "../components/ModalSubespecialidad";
import DataGridForm from "../../../components/common/DataGridForm";
import { subespecialidadAction } from "../../../redux/actions";

const Subespecialidad = ({ formik, showModal, setShowModal }) => {
  const dispatch = useDispatch();
  const subespecialidad = useSelector((state) => state.subespecialidad);

  const [showSubEspecialidad, setShowSubEspecialidad] = useState(false);

  useEffect(() => {
    setShowSubEspecialidad(formik.values.show_subespecialidades);
  }, [formik]);

  const AccionSubespecialidad = (data) => {
    return (
      <>
        <button
          type="button"
          className="btn btn-sm btn btn-success"
          onClick={() => {
            formikSubespecialidad.setValues({
              id: data.id,
              nombre: data.nombre,
              subespecialidad_item: data.subespecialidad_item,
            });
            setShowModal(true);
          }}
        >
          <i className="fa fa-pencil" />
        </button>{" "}
        <button
          type="button"
          className="btn btn-sm btn btn-danger"
          onClick={() => {
            DeleteAlertSubespecialidad("", data.id);
          }}
        >
          <i className="fa fa-trash" />
        </button>
      </>
    );
  };

  const hideSubEspecialidad = () => {
    formik.values.show_subespecialidades = !showSubEspecialidad;
    setShowSubEspecialidad(!showSubEspecialidad);
  };

  const onReorderSubespecialidad = async (e) => {
    const visibleRows = e.component.getVisibleRows();
    const newData = [...subespecialidad];
    const toIndex = newData.indexOf(visibleRows[e.toIndex].data);
    const fromIndex = newData.indexOf(e.itemData);

    newData.splice(fromIndex, 1);
    newData.splice(toIndex, 0, e.itemData);

    dispatch(subespecialidadAction([...newData]));
  };

  function DeleteAlertSubespecialidad(title, id) {
    AlertConfirmDeleteAll(
      title,
      "warning",
      async (response) => {
        if (response.isConfirmed) {
          const nuevoContenido = subespecialidad.filter(
            (item) => item.id !== id
          );
          dispatch(subespecialidadAction([...nuevoContenido]));
        }
      },
      (title = "¡Atención!"),
      "Continuar",
      "Cancelar",
      true,
      '<div class="swal2-html-container" id="swal2-html-container">Estás por eliminar la sub especialidad. <br> <br> ¿Querés continuar?</div>'
    );
  }

  const formikSubespecialidad = useFormik({
    initialValues: {
      id: "",
      nombre: "",
      subespecialidad_item: [],
    },
    enableReinitialize: true,
    validationSchema: validateModelNuevaSubespecialidad,
    onSubmit: (values) => submit(values),
  });

  const submit = (values) => {
    let nuevoContenido;
    if (values.id) {
      //Editando especialista
      AlertSuccess("Sub especialidad editada con éxito");
      const nuevosDatos = {
        nombre: values.nombre,
        subespecialidad_item: values.subespecialidad_item,
      };

      // Crea un nuevo array actualizando solo los datos del objeto con el ID especificado
      nuevoContenido = subespecialidad.map((item) => {
        if (item.id === values.id) {
          return { ...item, ...nuevosDatos };
        }
        return item;
      });

      // Actualiza el estado con el nuevo array
      dispatch(subespecialidadAction([...nuevoContenido]));
    } else {
      //Nuevo especialista
      AlertSuccess("Sub especialidad creada con éxito");
      let nuevaSubEspecialidad = {
        id: Math.floor(Math.random() * 1000),
        nombre: values.nombre,
        subespecialidad_item: values.subespecialidad_item,
        isNew: true,
      };
      // Clonamos el estado "contenido" actual y agregamos el nuevo especialista al nuevo array
      nuevoContenido = [...subespecialidad, nuevaSubEspecialidad];
      // Actualizamos el estado "contenido" con el nuevo array
      dispatch(subespecialidadAction([...nuevoContenido]));
    }

    setShowModal(false);
    // Elimina los valores guardados en formikNuevoDoctor
    formikSubespecialidad.setValues({
      id: "",
      nombre: "",
      subespecialidad_item: [],
    });
  };

  return (
    <>
      <Row>
        <Col>
          <Form.Group className="d-flex align-items-center">
            <h2 className="mr-3 mb-0">Sub-especialidades</h2>
            <Form.Check
              type="switch"
              id="custom-switch"
              onChange={hideSubEspecialidad}
              checked={showSubEspecialidad}
            />
          </Form.Group>
        </Col>
      </Row>
      <div>
        {showSubEspecialidad ? (
          <div>
            <Row>
              <Col>
                <Form.Group>
                  <label>Título</label>
                  <input
                    name="titulo_subespecialidad"
                    onChange={formik.handleChange}
                    className="form-control"
                    value={formik.values.titulo_subespecialidad}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group>
                  <label>Descripción</label>
                  <textarea
                    name="desc_subespecialidad"
                    onChange={formik.handleChange}
                    value={formik.values.desc_subespecialidad}
                    className="form-control"
                    rows={4} // El número de filas que deseas mostrar
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group>
                  <label>Subespecialidades</label>
                  {subespecialidad.length > 0 ? (
                    <DataGridForm
                      dataSource={subespecialidad}
                      keyExpr="id"
                      allowReordering={true}
                      onReorder={onReorderSubespecialidad}
                      columnHidingEnabled={true}
                      columns={[
                        {
                          caption: "Sub-especialidad",
                          dataField: "nombre",
                        },
                        {
                          caption: "Acciones",
                          alignment: "center",
                          render: "AccionSubespecialidad",
                          cellRender: { AccionSubespecialidad },
                          hidingPriority: 0,
                        },
                      ]}
                    />
                  ) : (
                    <h5>
                      Aún no se encuentran subespecialidades. Para iniciar hacer
                      click en "Añadir item".
                    </h5>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => setShowModal(true)}
                >
                  Añadir Item
                </button>
              </Col>
            </Row>
          </div>
        ) : (
          <></>
        )}
      </div>

      {/* MODAL SUB-ESPECIALIDAD */}
      <ModalSubespecialidad
        showModal={showModal}
        setShowModal={setShowModal}
        formik={formikSubespecialidad}
      />
    </>
  );
};

export default Subespecialidad;
