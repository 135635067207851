import { Form, Row, Col, Button, Card } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import React, { useState, useMemo } from "react";
import JoditEditor from "jodit-react";
import { useFormik } from "formik";

import {
  loadingAction,
  especialistasAction,
  subespecialidadAction,
  practicaAction
} from "../../../redux/actions";
import { BASE_URL, EstadoContenido } from "../../../utils/constants";
import { validateModel } from "./../nuevo/validationSchema";
import Subespecialidad from "../components/Subespecialidad";
import { Fetch, catchError } from "../../../utils/fetch";
import { AlertInfo } from "../../../components/alert";
import { removeSpecial } from "../../../utils/tools";
import MyButtons from "../components/MyButtons";
import { mySort } from "../../../utils/tools";
import Equipo from "../components/Equipo";
import LayoutJodit from "../../../components/customizer/layout-jodit";
import JefeServicio from "../components/JefeServicio";
import Practica from "../components/Practica";

const VerEspecialidad = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();

  const subespecialidad = useSelector((state) => state.subespecialidad);
  const especialistas = useSelector((state) => state.especialistas);
  const practica = useSelector((state) => state.practica);
  const isLoading = useSelector((state) => state.loading);
  const [layoutJodit, setLayoutJodit] = React.useState("PC");
  const user = useSelector((state) => state.user.data);
  const [showModal, setShowModal] = useState(false);
  const [showModalPractica, setShowModalPractica] = useState(false);

  const config = useMemo(
    () => ({
      readonly: false,
      height: 500,
      language: "es",
      disablePlugins: ["table"],
      disablePlugins: ["table"],
      askBeforePasteHTML: false,
      askBeforePasteFromWord: false,
      defaultActionOnPaste: "insert_clear_html",
      enableDragAndDropFileToEditor: true,
      uploader: {
        url: BASE_URL + "/upload",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("jwt_token"),
        },
        data: null,
        filesVariableName: function (i) {
          return "files";
        },
        withCredentials: false,
        pathVariableName: "path",
        format: "json",
        method: "POST",
        prepareData: function (formData) {
          formData.append("id", 1);
        },
        isSuccess: function (resp) {
          return !resp.error;
        },
        getMessage: function (resp) {
          return resp.msgs.join("\n");
        },
        process: function (resp) {
          return resp;
        },
        defaultHandlerSuccess: function (resp) {
          var imagenesPermitidas = ["image/jpeg", "image/png"];
          for (var i = 0; i < resp.length; i++) {
            if (imagenesPermitidas.indexOf(resp[i].mime) !== -1) {
              var img1 = new Image();
              img1.src = resp[i]["url"];
              img1.alt = "imagen";
              img1.className = "img-fluid";
              this.s.insertImage(img1);
            } else if (resp[i].mime === "application/pdf") {
              const enlace = document.createElement("a");
              enlace.setAttribute("href", resp[i].url);
              const contenido = document.createTextNode(resp[i].name);
              // add the text node to the newly created div
              enlace.appendChild(contenido);
              this.s.insertNode(enlace);
            } else {
              AlertInfo("Tipo de archivo no permitido");
            }
          }
        },
      },
    }),
    []
  );

  React.useEffect(() => {
    if (id) {
      getData();
    }
  }, [id]);

  const getData = async () => {
    dispatch(loadingAction(true));

    try {
      const request1 = Fetch(`especialidades/${id}`, "get");
      const request2 = Fetch(`doctores/by-especializacion/${id}`, "get");

      Promise.all([request1, request2]).then((response) => {
        const [especialidades, doctores] = response;

        mySort(doctores.data, "order");
        formik.setValues(especialidades.data);

        dispatch(especialistasAction(doctores.data));
        dispatch(subespecialidadAction(especialidades.data.sub_especialidades));        
        dispatch(practicaAction(especialidades.data.practicas));        
        dispatch(loadingAction(false));
      });
    } catch (error) {
      catchError(error);
      dispatch(loadingAction(false));
    }
  };

  const submit = async (values) => {
    
    dispatch(loadingAction(true));

    values.published_at = null;
    values.idPadre = values.id;
    values.estado = EstadoContenido.EnRevision;

    values.doctors = especialistas;
    values.sub_especialidades = subespecialidad;
    values.practicas=practica;
    values.titulo = values.nombre;
    values.doctors.forEach(function (doctor, index) {
      doctor.order = index + 1;
    });

    formik.values.descripcion = removeSpecial(values.descripcion);

    try {
      const { data } = await Fetch("especialidades", "post", values);
      AlertInfo(
        "Especialidad actualizada con éxito.<br/>Recuerde que falta su confirmacion para aprobar.<br/> Nota: Por favor, notificar al responsable de publicar"
      );

      dispatch(loadingAction(false));
      history.push("/especialidades/lista");
    } catch (error) {
      catchError(error);
      dispatch(loadingAction(false));
    }
  };

  const formik = useFormik({
    initialValues: {
      nombre: "",
      titulo: "",
      descripcion: "",
      imagen: "",
      slug: "",
      orden: 0,
      habilitado: true,
      locale: "es",
      eliminado: false,
      localizations: [],
      published_at: "2022-05-17T04:14:37.397Z",
      created_by: user.id,
      updated_by: user,
      show_jefe:false,
      descripcion_jefe:"",
      botones: [
        {
          nombre: "",
          url: "",
          estado: "visible",
        },
      ],
    },
    enableReinitialize: true,
    validationSchema: validateModel,
    onSubmit: (values) => submit(values),
  });

  const handleBlurNombre = async (event) => {
    const value = event.target.value;
    const name = event.target.name;
    const dataToApi = {
      contentTypeUID: "application::novedades.novedades",
      field: "slug",
      data: {
        titulo: value,
        slug: "",
      },
    };

    formik.setFieldValue(name, value, false);
    try {
      const { data } = await Fetch(
        "content-manager/uid/generate",
        "post",
        dataToApi
      );
      formik.setFieldValue("slug", data.data, false);
    } catch (error) {
      console.error("mostrando error", error);
    }
  };

  const handleChangeJoiEditor = (data, field) => {
    formik.setFieldValue(field, data, false);
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Row>
        <Col>
          <Card>
            <Card.Header>
              <Row>
                <Col>Nueva especialidad</Col>
              </Row>
              <hr />
            </Card.Header>
            <Card.Body style={{ marginTop: "-36px" }}>
              <Row>
                <Col>
                  <h3>Información general</h3>
                </Col>
              </Row>
              {/* NOMBRE */}
              <Row>
                <Col>
                  <Form.Group>
                    <label>Nombre de la especialidad</label>
                    <input
                      type="text"
                      name="nombre"
                      onChange={formik.handleChange}
                      onBlur={(e) => handleBlurNombre(e)}
                      value={formik.values.nombre}
                      className="form-control "
                    />
                    <div className="text-danger">{formik.errors?.nombre}</div>
                  </Form.Group>
                </Col>
              </Row>

              {/* URL */}
              <Row>
                <Col>
                  <Form.Group>
                    <label>URL</label>
                    <input
                      type="text"
                      name="slug"
                      className="form-control"
                      value={formik.values.slug}
                      readOnly
                    />
                  </Form.Group>
                </Col>
              </Row>

              {/* BUTTONS */}
              <MyButtons formik={formik} />
              <hr />

              {/* QUIENES SOMOS */}
              <Row>
                <Col>
                  <Form.Group>
                    <h2>¿Quiénes somos?</h2>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col className={`${layoutJodit == "MOBILE" && "col-6"}`}>
                  <Form.Group>
                    <label>Descripción</label>
                    <LayoutJodit
                      setLayoutJodit={setLayoutJodit}
                      layoutJodit={layoutJodit}
                    />
                    <JoditEditor
                      config={config}
                      value={formik.values.descripcion}
                      tabIndex={1} // tabIndex of textarea
                      onBlur={(newContent) =>
                        handleChangeJoiEditor(newContent, "descripcion")
                      }
                    />
                    <div className="text-danger">
                      {formik.errors?.descripcion}
                    </div>
                  </Form.Group>
                </Col>
              </Row>

              <hr />

              {/* EQUIPO */}
              <Equipo formik={formik} />

              <br/><br/>
              <JefeServicio formik={formik} />

              <hr />

              {/* SUB-ESPECIALIDAD */}
              <Subespecialidad
                formik={formik}
                setShowModal={setShowModal}
                showModal={showModal}
              />

              <hr />
              
              <Practica
                formik={formik}
                showModal={showModalPractica}
                setShowModal={setShowModalPractica}
              />

              <Row className="mt-4">
                <Col className="col col-lg-3">
                  <Button
                    type="button"
                    onClick={() => {
                      if (Object.keys(formik.errors).length !== 0) {
                        AlertInfo(
                          "Debe completar todos los datos obligatorios para poder continuar."
                        );
                      } else {
                        formik.handleSubmit();
                      }
                    }}
                    disabled={isLoading}
                    className="btn btn-success btn-icon"
                  >
                    <i className="fa fa-floppy-o " />
                    Guardar
                  </Button>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </form>
  );
};
export default VerEspecialidad;
