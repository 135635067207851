/* eslint-disable eqeqeq */
import React from "react";
import { Row, Col, Card } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
//Template1Component
import Template1Component from "../../../components/templates/template1";
//Template2Component
import Template2Component from "../../../components/templates/template2";
//Template3Component
import Template3Component from "../../../components/templates/template3";

function NuevaPaginaTemplate({ user, token }) {
  const params = useParams();
  const { template } = params;

  React.useEffect(() => {}, []);

  return (
    <React.Fragment>
      <Row>
        <Col>
          <Card>
            <Card.Header>Nueva Página</Card.Header>
            <Card.Body>
              {template == "template1" && <Template1Component />}
              {template == "template2" && <Template2Component />}
              {template == "template3" && <Template3Component />}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.user.data,
    token: state.user.token,
  };
};

export default connect(mapStateToProps, null)(NuevaPaginaTemplate);
