/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Form, Row, Col, Button, Card } from "react-bootstrap";
import { useFormik } from "formik";
import { Fetch, catchError } from "../../../utils/fetch";
import { useDispatch, useSelector } from "react-redux";
import { AlertInfo, AlertSuccess } from "../../../components/alert";
import { loadingAction } from "../../../redux/actions";
import MediaUploader from "../../../utils/media-uploader";
import { validateModel } from "./validationSchema";
import { EstadoContenido } from "../../../utils/constants";
import { useLocation, useHistory } from "react-router-dom";
import { getYouTubeVideoId } from "../../../utils/tools";

const VerSocialNetworkX = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.loading);
  const { pathname } = useLocation();
  const [isRevision] = React.useState(pathname.includes("revision"));
  const [isEmpty, setIsEmpty] = React.useState(true);
  const history = useHistory();
  const userType = useSelector((state) => state.user.data.role.type);
  const permissions = useSelector((state) => state.permissions);    
  const canPublish = permissions['social-networks'].aprobar.enabled;

  React.useEffect(() => {
    const script = document.createElement("script");
    script.async = true;
    script.src = "https://www.instagram.com/embed.js";
    script.onload = () => window.instgrm.Embeds.process();
    document.body.appendChild(script);

    getSocialNetwork();
  }, []);

  const getSocialNetwork = async (values) => {
    dispatch(loadingAction(true));

    try {
      const estado = isRevision ? "ER" : "EA";

      const { data } = await Fetch(
        `social-networks?_publicationState=preview&estado=${estado}`,
        "get"
      );

      if (!data?.length) {
        dispatch(loadingAction(false));

        if (isRevision) {
          AlertInfo("No hay información que se necesite revisar");
        } else {
          AlertInfo("No hay información que se necesite aprobar");
        }

        return;
      }

      const [values] = data;
      setIsEmpty(false);

      setValues(values);
      dispatch(loadingAction(false));
    } catch (error) {
      catchError(error);
      dispatch(loadingAction(false));
    }
  };

  const onEnviarAprobar = async (values) => {
    dispatch(loadingAction(true));

    try {
      try {
        await Fetch(`social-networks/delete-by-estado/EA`, "delete");
      } catch (error) {}

      values.estado = EstadoContenido.EnAprobacion;
      values.published_at = null;

      await Fetch(`social-networks/${values.id}`, "put", values);

      AlertSuccess("Red Social actualizada con éxito, pendiente de aprobación");
      dispatch(loadingAction(false));

      if (userType != "creador") {
        history.push("/social-networks/aprobar/");
      } else {
        history.push("/social-networks/ver/");
      }
    } catch (error) {
      catchError(error);
      dispatch(loadingAction(false));
    }
  };

  const onPublicar = async (values) => {
    dispatch(loadingAction(true));

    try {
      try {
        await Fetch(`social-networks/delete-by-estado/PU`, "delete");
      } catch (error) {}

      values.estado = EstadoContenido.Publicado;
      values.published_at = new Date();

      await Fetch(`social-networks/${values.id}`, "put", values);
      AlertSuccess("Red social publicada con éxito");

      dispatch(loadingAction(false));
      history.push("/social-networks/ver/");
    } catch (error) {
      catchError(error);
      dispatch(loadingAction(false));
    }
  };

  const onPorRevisar = async (values) => {
    dispatch(loadingAction(true));

    try {
      try {
        await Fetch(`social-networks/delete-by-estado/ER`, "delete");
      } catch (error) {}

      values.estado = EstadoContenido.EnRevision;
      await Fetch(`social-networks/${values.id}`, "put", values);

      AlertSuccess("Red Social actualizada con éxito, pendiente de revisión");
      dispatch(loadingAction(false));

      history.push("/social-networks/revision/");
    } catch (error) {
      catchError(error);
      dispatch(loadingAction(false));
    }
  };

  const submit = async (values) => {
    dispatch(loadingAction(true));

    try {
      values.estado = EstadoContenido.EnRevision;
      await Fetch(`social-networks/${values.id}`, "put", values);

      AlertSuccess("Red Social actualizada con éxito");
      dispatch(loadingAction(false));
    } catch (error) {
      catchError(error);
      dispatch(loadingAction(false));
    }
  };

  const handleRemoveImg = async (campo) => {
    setFieldValue(campo, "", false);
  };

  const {
    values,
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue,
    setValues,
    errors,
  } = useFormik({
    initialValues: {
      url_imagen_instagram: "",
      url_video_youtube: "",
      url_video_youtube_estatico: "",
    },
    enableReinitialize: true,
    validationSchema: validateModel,
    onSubmit: (values) => submit(values),
  });

  const renderPreview = (url) => {
    if (url.includes("youtube.com") || url.includes("youtu.be")) {
      const videoId = getYouTubeVideoId(url);
      return (
        <iframe
          width="560"
          height="315"
          src={videoId}
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          referrerpolicy="strict-origin-when-cross-origin"
          allowfullscreen
          title="Vídeo para youtube"
        ></iframe>
      );
    } else {
      return <img src={url} alt="vista-previa" className="img-fluid w-50" />;
    }
  };

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit}>
        <Row>
          <Col>
            <Card>
              <Card.Header>
                <Row>
                  <Col>
                    Redes sociales -{" "}
                    {isRevision ? "En Revisión" : "Por Aprobar"}
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body>
                <Row>
                  <Col>
                    <h3>iframe post Instagram</h3>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group>
                      <label>
                        iframe (destildar opción incluir pie de foto)
                      </label>
                      <input
                        type="text"
                        name="url_imagen_instagram"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="form-control"
                        value={values?.url_imagen_instagram}
                      />
                      <div className="text-danger">
                        {errors?.url_imagen_instagram}
                      </div>
                    </Form.Group>
                  </Col>
                </Row>

                {Object.keys(values?.url_imagen_instagram || {})?.length >
                  0 && (
                  <>
                    <Row>
                      <Col>
                        <Form.Group>
                          {/* <img
                            src={values?.url_imagen_instagram}
                            alt="vista-previa"
                            className="img-fluid w-50"
                          /> */}
                          <div
                            dangerouslySetInnerHTML={{
                              __html: `
                              ${values?.url_imagen_instagram}
                                     `,
                            }}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </>
                )}
                <hr />

                <Row>
                  <Col>
                    <h3>iframe canal YouTube (dinámico)</h3>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group>
                      <label>iframe</label>
                      <input
                        type="text"
                        name="url_video_youtube"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="form-control"
                        value={values?.url_video_youtube}
                      />
                      <div className="text-danger">
                        {errors?.url_video_youtube}
                      </div>
                    </Form.Group>
                  </Col>
                </Row>

                {Object.keys(values?.url_video_youtube || {})?.length > 0 && (
                  <>
                    <Row>
                      <Col>
                        <Form.Group>
                          {renderPreview(values.url_video_youtube)}
                        </Form.Group>
                      </Col>
                    </Row>
                  </>
                  // <>
                  //   <Row>
                  //     <Col>
                  //       <Form.Group>
                  //         <iframe
                  //           width="560"
                  //           height="315"
                  //           src={getYouTubeVideoId(values?.url_video_youtube)}
                  //           frameborder="0"
                  //           allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  //           referrerpolicy="strict-origin-when-cross-origin"
                  //           allowfullscreen
                  //           title="Vídeo para youtube"
                  //         ></iframe>
                  //       </Form.Group>
                  //     </Col>
                  //   </Row>
                  // </>
                )}

                <Row>
                  <Col>
                    <h3>iframe canal YouTube (estático)</h3>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group>
                      <label>iframe</label>
                      <input
                        type="text"
                        name="url_video_youtube_estatico"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="form-control"
                        value={values?.url_video_youtube_estatico}
                      />
                      <div className="text-danger">
                        {errors?.url_video_youtube_estatico}
                      </div>
                    </Form.Group>
                  </Col>
                </Row>

                {Object.keys(values?.url_video_youtube_estatico || {})?.length >
                  0 && (
                  <>
                    <Row>
                      <Col>
                        <Form.Group>
                          {renderPreview(values.url_video_youtube_estatico)}
                        </Form.Group>
                      </Col>
                    </Row>
                  </>
                  // <>
                  //   <Row>
                  //     <Col>
                  //       <Form.Group>
                  //         <iframe
                  //           width="560"
                  //           height="315"
                  //           src={getYouTubeVideoId(values?.url_video_youtube)}
                  //           frameborder="0"
                  //           allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  //           referrerpolicy="strict-origin-when-cross-origin"
                  //           allowfullscreen
                  //           title="Vídeo para youtube"
                  //         ></iframe>
                  //       </Form.Group>
                  //     </Col>
                  //   </Row>
                  // </>
                )}
                <Row className="mt-5">
                  {isRevision ? (
                    <>
                      <Col className="col col-lg-3">
                        <Button
                          type="button"
                          onClick={() => handleSubmit()}
                          disabled={isLoading || isEmpty || !canPublish}
                          className="btn btn-success btn-icon mt-2"
                        >
                          <i className="fa fa-floppy-o " />
                          Guardar
                        </Button>
                      </Col>
                      <Col className="col col-lg-3">
                        <Button
                          type="button"
                          onClick={() => onEnviarAprobar(values)}
                          disabled={isLoading || isEmpty}
                          className="btn btn-info btn-icon mt-2"
                        >
                          <i className="fa fa-share " />
                          Enviar aprobar
                        </Button>
                      </Col>
                    </>
                  ) : (
                    <>
                      <Col className="col col-lg-3">
                        <Button
                          type="button"
                          onClick={() => onPublicar(values)}
                          disabled={isLoading || isEmpty}
                          className="btn btn-success btn-icon mt-2"
                        >
                          <i className="fa fa-floppy-o " />
                          Publicar
                        </Button>
                      </Col>
                      <Col className="col col-lg-3">
                        <Button
                          type="button"
                          onClick={() => onPorRevisar(values)}
                          disabled={isLoading || isEmpty}
                          className="btn btn-primary btn-icon no-hover3 btn btn-primary btn btn-primary mt-2"
                        >
                          <i className="fa fa-undo " />
                          Por revisar
                        </Button>
                      </Col>
                    </>
                  )}
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </form>
    </React.Fragment>
  );
};
export default VerSocialNetworkX;
