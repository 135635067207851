import { AlertConfirm } from "../components/alert";
import { Fetch } from "./fetch";

export const removeSpecial = (string) => {
  return string;
};

export const removeSpecialJodit = (string) => {
  var regex =
    /[\uD800-\uDBFF][\uDC00-\uDFFF]|[\u2600-\u26FF\u2700-\u27BF\uD83C][\uDC00-\uDFFF]|\u24C2[\uFE0F]?|[\uD83C][\uDDE6-\uDDFF]{1,2}|[\uD83D][\uDC00-\uDE4F\uDE80-\uDEFF\uDEC0-\uDEF8\uDF00-\uDF9F\uDFA0-\uDFDF\uDFE0-\uDFEF\uDFF0-\uDFFD\uDFFF]/g;
  var cleanedString = string
    .replace(/[^\x00-\x7F\u00F1\u00D1áéíóúÁÉÍÓÚ¡?¿!()]/g, "")
    .replace(/"/g, '\\"');
  return cleanedString;
};

export const isHTML = (text) => {
  const htmlRegex = /<([A-Z][A-Z0-9]*)\b[^>]*>(.*?)<\/\1>/i;
  return htmlRegex.test(text);
};

export const findNumeroMayor = (array, campo) => {
  if (array.length === 0) {
    return undefined;
  }

  return array.reduce((maximo, objeto) => {
    if (objeto[campo] > maximo) {
      return objeto[campo];
    } else {
      return maximo;
    }
  }, -Infinity);
};

export const generarNumeroAleatorioUnico = () => {
  return Math.floor(Math.random() * 10000) + 1;
};

export const mySort = (data, field) => {
  data.sort((a, b) => {
    if (a[field] === undefined && b[field] === undefined) {
      return 0;
    } else if (a[field] === undefined) {
      return 1;
    } else if (b[field] === undefined) {
      return -1;
    } else if (a[field] === null && b[field] === null) {
      return 0;
    } else if (a[field] === null) {
      return 1;
    } else if (b[field] === null) {
      return -1;
    } else {
      return a[field] - b[field];
    }
  });
};

export const eliminarImagen = async (id) => {
  return new Promise((resolve, reject) => {
    AlertConfirm(
      "¿Estás seguro de querer eliminar la imagen?",
      "question",
      (response) => {
        if (response.isConfirmed) {
          Fetch(`upload/files/${id}`, "delete").then((data) => {
            resolve(data);
          });
        } else {
          reject(1);
        }
      }
    );
  });
};

export const getYouTubeVideoId = (url) => {
  const iframeRegex =
    /<iframe[^>]+src="(?:https?:\/\/)?(?:www\.)?youtube\.com\/embed\/([a-zA-Z0-9_-]{11})/;
  const urlRegex =
    /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;

  let matches = url.match(iframeRegex);
  if (!matches) {
    matches = url.match(urlRegex);
  }

  return matches ? `https://www.youtube.com/embed/${matches[1]}` : url;
};
