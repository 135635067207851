import "../especialidades.css";

import { Form, Row, Col, Button, Card } from "react-bootstrap";
import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import JoditEditor from "jodit-react";
import { useFormik } from "formik";

import { BASE_URL, EstadoContenido } from "../../../utils/constants";
import Subespecialidad from "../components/Subespecialidad";
import { Fetch, catchError } from "../../../utils/fetch";
import { AlertError, AlertInfo } from "../../../components/alert";
import { removeSpecial } from "../../../utils/tools";
import { validateModel } from "./validationSchema";
import MyButtons from "../components/MyButtons";
import Equipo from "../components/Equipo";
import LayoutJodit from "../../../components/customizer/layout-jodit";

import {
  loadingAction,
  subespecialidadAction,
  especialistasAction,
  practicaAction
} from "../../../redux/actions";
import JefeServicio from "../components/JefeServicio";
import Practica from "../components/Practica";

const NuevaEspecialidad = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user.data);
  const isLoading = useSelector((state) => state.loading);
  const especialistas = useSelector((state) => state.especialistas);
  const [layoutJodit, setLayoutJodit] = React.useState("PC");
  const subespecialidad = useSelector((state) => state.subespecialidad);
  const practica = useSelector((state) => state.practica);
  const [showModal, setShowModal] = useState(false);
  const [showModalPractica, setShowModalPractica] = useState(false);

  useEffect(() => {
    dispatch(subespecialidadAction([]));
    dispatch(practicaAction([]));
    dispatch(especialistasAction([]));
  }, []);

  const config = useMemo(
    () => ({
      readonly: false,
      height: 500,
      language: "es",
      disablePlugins: ["table"],
      disablePlugins: ["table"],
      askBeforePasteHTML: false,
      askBeforePasteFromWord: false,
      defaultActionOnPaste: "insert_clear_html",
      enableDragAndDropFileToEditor: true,
      uploader: {
        url: BASE_URL + "/upload",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("jwt_token"),
        },
        data: null,
        filesVariableName: function (i) {
          return "files";
        },
        withCredentials: false,
        pathVariableName: "path",
        format: "json",
        method: "POST",
        prepareData: function (formData) {
          formData.append("id", 1);
        },
        isSuccess: function (resp) {
          return !resp.error;
        },
        getMessage: function (resp) {
          return resp.msgs.join("\n");
        },
        process: function (resp) {
          return resp;
        },
        defaultHandlerSuccess: function (resp) {
          var imagenesPermitidas = ["image/jpeg", "image/png"];
          for (var i = 0; i < resp.length; i++) {
            if (imagenesPermitidas.indexOf(resp[i].mime) !== -1) {
              var img1 = new Image();
              img1.src = resp[i]["url"];
              img1.alt = "imagen";
              img1.className = "img-fluid";
              this.s.insertImage(img1);
            } else if (resp[i].mime === "application/pdf") {
              const enlace = document.createElement("a");
              enlace.setAttribute("href", resp[i].url);
              const contenido = document.createTextNode(resp[i].name);
              enlace.appendChild(contenido);
              this.s.insertNode(enlace);
            } else {
              AlertInfo("Tipo de archivo no permitido");
            }
          }
        },
      },
    }),
    []
  );

  const submit = async (values) => {
    values.doctors = especialistas;
    values.sub_especialidades = subespecialidad;
    values.practicas=practica;
    values.titulo = values.nombre;
    values.doctors.forEach(function (doctor, index) {
      doctor.order = index + 1;
    });
    
    dispatch(loadingAction(true));

    formik.values.published_at = null;
    formik.values.estado = EstadoContenido.EnRevision;

    formik.values.descripcion = removeSpecial(formik.values.descripcion);

    try {
      await Fetch("especialidades", "post", values);

      AlertInfo(
        "Especialidad creada con éxito.<br/>Recuerde que falta su confirmacion para aprobar.<br/> Nota: Por favor, notificar al responsable de publicar"
      );
      history.push("/especialidades/lista-borradores");

      dispatch(loadingAction(false));
    } catch (error) {
      const msg = JSON.stringify(error.response.data);
      if (msg.includes("Duplicate entry")) {
        AlertError("Especialidad duplicada");
      } else {
        catchError(error);
      }

      dispatch(loadingAction(false));
    }
  };

  const formik = useFormik({
    initialValues: {
      nombre: "",
      titulo: "",
      descripcion: "",
      titulo_subespecialidad: "",
      desc_subespecialidad: "",
      descripcion_equipo: "",
      show_equipo: true,
      show_subespecialidades: true,
      imagen: "",
      slug: "",
      orden: 0,
      habilitado: true,
      locale: "es",
      eliminado: false,
      localizations: [],
      published_at: "2022-05-17T04:14:37.397Z",
      created_by: user.id,
      updated_by: user,
      show_jefe:false,
      show_practicas:false,
      descripcion_jefe:"",
      botones: [
        {
          nombre: "Turnos",
          url: process.env.REACT_APP_TURNOS_URL,
          estado: "visible",
          mostrar_popup: "visible",
        },
        {
          nombre: "Ver más",
          url: "",
          estado: "visible",
          mostrar_popup: "visible",
        },
      ],
    },
    enableReinitialize: true,
    validationSchema: validateModel,
    onSubmit: (values) => submit(values),
  });

  const handleBlurNombre = async (event) => {
    const value = event.target.value;
    const name = event.target.name;
    const dataToApi = {
      contentTypeUID: "application::novedades.novedades",
      field: "slug",
      data: {
        titulo: value,
        slug: "",
      },
    };

    formik.setFieldValue(name, value, false);
    try {
      const { data } = await Fetch(
        "content-manager/uid/generate",
        "post",
        dataToApi
      );
      formik.setFieldValue("slug", data.data, false);
    } catch (error) {
      console.error("mostrando error", error);
    }
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Row>
        <Col>
          <Card>
            <Card.Header>
              <Row>
                <Col>Nueva especialidad</Col>
              </Row>
              <hr />
            </Card.Header>
            <Card.Body style={{ marginTop: "-36px" }}>
              <Row>
                <Col>
                  <h3>Información general</h3>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group>
                    <label>Nombre de la especialidad</label>
                    <input
                      type="text"
                      name="nombre"
                      onChange={formik.handleChange}
                      onBlur={(e) => handleBlurNombre(e)}
                      value={formik.values.nombre}
                      className="form-control "
                    />
                    <div className="text-danger">{formik.errors?.nombre}</div>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group>
                    <label>URL</label>
                    <input
                      type="text"
                      name="slug"
                      className="form-control"
                      value={formik.values.slug}
                      readOnly
                    />
                  </Form.Group>
                </Col>
              </Row>

              {/* BUTTONS */}
              <MyButtons formik={formik} />
              <hr />

              <Row>
                <Col>
                  <Form.Group>
                    <h2>¿Quiénes somos?</h2>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col className={`${layoutJodit == "MOBILE" && "col-6"}`}>
                  <Form.Group>
                    <label>Descripción</label>
                    <LayoutJodit
                      setLayoutJodit={setLayoutJodit}
                      layoutJodit={layoutJodit}
                    />
                    <JoditEditor
                      config={config}
                      value={formik.values.descripcion}
                      tabIndex={1} // tabIndex of textarea
                      onBlur={(value) =>
                        formik.setFieldValue("descripcion", value)
                      }
                    />
                    <div className="text-danger">
                      {formik.errors?.descripcion}
                    </div>
                  </Form.Group>
                </Col>
              </Row>

              <hr />

              {/* EQUIPO */}
              <Equipo formik={formik} />

              <br/><br/>
              <JefeServicio formik={formik} />

              <hr />

              {/* SUBESPECIALIDAD */}
              <Subespecialidad
                formik={formik}
                showModal={showModal}
                setShowModal={setShowModal}
              />

              <hr />
              
              <Practica
                formik={formik}
                showModal={showModalPractica}
                setShowModal={setShowModalPractica}
              />

              <Button
                type="button"
                onClick={() => {
                  if (Object.keys(formik.errors).length !== 0) {
                    AlertInfo(
                      "Debe completar todos los datos obligatorios para poder continuar."
                    );
                  } else {
                    formik.handleSubmit();
                  }
                }}
                disabled={isLoading}
                className="btn btn-success btn-icon mt-4"
              >
                <i className="fa fa-floppy-o " />
                Guardar
              </Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </form>
  );
};

export default NuevaEspecialidad;
