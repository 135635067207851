import React from "react";
import {
  Row,
  Col,
  Card,
  Table,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { Fetch, catchError } from "../../../utils/fetch";
import { connect } from "react-redux";
import { loadingAction } from "../../../redux/actions";
import { copyToClickBoard } from "../../../utils/slug";
import DataGridForm from "../../../components/common/DataGridForm";
import { formatDate } from "../../../utils/formatDate";
import { AlertConfirm } from "../../../components/alert";
import { EstadoContenido } from "../../../utils/constants";

function ListaBorradoresPaginas({ isLoadingAction }) {
  const [paginas, setPaginas] = React.useState(null);
  const history = useHistory();

  React.useEffect(() => {
    getPaginas();
  }, []);

  const deleteContenido = async (values) => {
    isLoadingAction(true);
    try {
      values.eliminado = true;
      values.estado = EstadoContenido.EnRevision;
      values.published_at = new Date();
      delete values.idPadre;
      await Fetch("paginas/" + values.id, "put", values);
      getPaginas();
      isLoadingAction(false);
      history.push("/pages/lista-por-eliminar");
    } catch (error) {
      isLoadingAction(false);
      catchError(error);
    }
  };

  function DeleteAlert(title, data) {
    AlertConfirm(title, "question", (response) => {
      if (response.isConfirmed) {
        deleteContenido(data);
      }
    });
  }

  const getPaginas = async () => {
    isLoadingAction(true);
    try {
      const { data } = await Fetch(
        "paginas?_publicationState=preview&published_at_null=true&estado=ER",
        "get"
      );
      setPaginas(data);
      isLoadingAction(false);
    } catch (error) {
      isLoadingAction(false);
      catchError(error);
    }
  };

  function FActualizacion(data) {
    return formatDate(data?.updated_at);
  }

  function Plantilla(data) {
    return (
      <>
        {data.template == "template1" && "Plantilla 1"}
        {data.template == "template2" && "Plantilla 2"}
        {data.template == "template3" && "Plantilla 3"}
      </>
    );
  }

  function Slug(data) {
    return (
      <>
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip id={`tooltip-top`}>Hacer click para copiar url</Tooltip>
          }
        >
          <i
            className="fa fa-copy fa-lg text-info"
            role="button"
            onClick={() => copyToClickBoard("pages/" + data.slug)}
          ></i>
        </OverlayTrigger>
      </>
    );
  }

  function Accion(data) {
    return (
      <>
        <Link
          to={"/pages/ver-borrador/" + data.template + "/" + data.id}
          className="btn btn-sm btn btn-success"
        >
          <i className="fa fa-pencil" />
        </Link>{" "}
        <a
          href={`${process.env.REACT_APP_BASE_URL_HOME}/pages-preview/${data.id}`}
          target="_blank"
          className="btn btn-sm btn btn-info"
        >
          <i className="fa fa-eye" />
        </a>{" "}
        <button
          type="button"
          className="btn btn-sm btn btn-danger"
          onClick={() => {
            DeleteAlert("Está apunto de eliminar este contenido", data);
          }}
        >
          <i className="fa fa-trash" />
        </button>{" "}
      </>
    );
  }

  return (
    <React.Fragment>
      <Row>
        <Col md="12">
          <Card>
            <Card.Header>
              <div className="float-right mt-10">
                <Link
                  to="/pages/nuevo"
                  className="btn btn-primary btn-rounded box-shadow btn-icon"
                >
                  <i className="fa fa-plus" /> Nueva Página
                </Link>
              </div>
              Listado de páginas en revisión
            </Card.Header>
            <Card.Body>
              <DataGridForm
                dataSource={paginas}
                keyExpr="id"
                allowReordering={true}
                columnHidingEnabled={true}
                columns={[
                  { caption: "Id", dataField: "id" },
                  { caption: "Nombre", dataField: "nombre" },
                  {
                    caption: "Url",
                    alignment: "center",
                    width: 50,
                    render: "Slug",
                    cellRender: { Slug },
                  },
                  {
                    caption: "Plantilla",
                    render: "Plantilla",
                    cellRender: { Plantilla },
                  },
                  { caption: "Creado por", dataField: "creado_por.username" },
                  {
                    caption: "Actualizado por",
                    dataField: "actualizado_por.username",
                  },
                  {
                    caption: "Ult. Actualización",
                    render: "FActualizacion",
                    cellRender: { FActualizacion },
                  },
                  {
                    caption: "Acción",
                    alignment: "center",
                    render: "Accion",
                    cellRender: { Accion },
                    hidingPriority: 0,
                  },
                ]}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    isLoadingAction: (data) => {
      dispatch(loadingAction(data));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ListaBorradoresPaginas);
