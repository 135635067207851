import React from "react";
import { Row, Col, Card } from "react-bootstrap";
import { FieldArray } from "formik";
import IconDrag from "../../../assets/icons/icon-drag.svg";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

const Acordeon = ({
  values,
  errors,
  handleChange,
  setFieldValue,
  formFields,
}) => {
  const onDragEnd = (result, index) => {
    if (!result.destination) return; // No se hizo ningún cambio, salir.

    const sourceIndex = result.source.index;
    const destinationIndex = result.destination.index;

    // Reordenar los elementos en el array de datos
    const items = [...values.acordion[index].items_acordion];
    const [movedItem] = items.splice(sourceIndex, 1);
    items.splice(destinationIndex, 0, movedItem);

    // Actualizar el valor en el formulario
    setFieldValue(`acordion[${index}].items_acordion`, items);
  };

  const handlePush = (index, item, insertIndex) => {
    setFieldValue(`acordion[${index}].items_acordion`, [
      ...values.acordion[index].items_acordion.slice(0, insertIndex),
      item,
      ...values.acordion[index].items_acordion.slice(insertIndex),
    ]);
  };

  return (
    <>
      <Row>
        <Col>
          <h2>Información Acordión</h2>
        </Col>
      </Row>
      {/* CUANDO NO ES POR ELIMINAR */}
      {formFields == undefined ? (
        <>
          <Row>
            <Col>
              <FieldArray name="acordion">
                {({ push, remove }) => {
                  return (
                    <>
                      {values.acordion &&
                        values.acordion.length > 0 &&
                        values.acordion.map((data, index) => {
                          return (
                            <Row className="border m-3">
                              <Col
                                lg={12}
                                className="p-0 d-flex justify-content-end"
                              >
                                <button
                                  type="button"
                                  className="btn btn-danger"
                                  onClick={() => remove(index)}
                                >
                                  x
                                </button>
                              </Col>

                              <Col lg={6}>
                                <div className="form-group">
                                  <label>Nombre</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name={`acordion.[${index}].nombre`}
                                    placeholder="Ej: Especialidades - Diagnostico por imagenes"
                                    onChange={handleChange}
                                    value={values?.acordion[index]?.nombre}
                                  />
                                  {errors &&
                                    errors.acordion &&
                                    errors.acordion[index] &&
                                    errors.acordion[index].nombre && (
                                      <div className="text-danger">
                                        {errors?.acordion[index]?.nombre}
                                      </div>
                                    )}
                                </div>
                              </Col>
                              <Col lg={6}>
                                <div className="form-group">
                                  <label>Mostrar</label>
                                  <select
                                    className="form-control"
                                    name={`acordion.[${index}].mostrar`}
                                    onChange={handleChange}
                                    value={values?.acordion[index]?.mostrar}
                                  >
                                    <option value={true}>Si</option>
                                    <option value={false}>No</option>
                                  </select>
                                </div>
                              </Col>
                              <Col>
                                <Card>
                                  <Card.Body className="pt-0">
                                    <FieldArray
                                      name={`acordion[${index}].items_acordion`}
                                    >
                                      {({
                                        move,
                                        swap,
                                        push,
                                        remove,
                                        insert,
                                        unshift,
                                        pop,
                                        form,
                                      }) => {
                                        return (
                                          <>
                                            <DragDropContext
                                              onDragEnd={(result) =>
                                                onDragEnd(result, index)
                                              }
                                            >
                                              <Droppable droppableId="droppable">
                                                {(provided) => (
                                                  <div
                                                    ref={provided.innerRef}
                                                    {...provided.droppableProps}
                                                  >
                                                    {values.acordion[index]
                                                      .items_acordion &&
                                                      values.acordion[index]
                                                        .items_acordion.length >
                                                        0 &&
                                                      values.acordion[
                                                        index
                                                      ].items_acordion.map(
                                                        (data, idx) => (
                                                          <Draggable
                                                            key={idx}
                                                            draggableId={`item-${idx}`}
                                                            index={idx}
                                                          >
                                                            {(provided) => (
                                                              <div
                                                                ref={
                                                                  provided.innerRef
                                                                }
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                              >
                                                                <Row>
                                                                  <Col
                                                                    lg={6}
                                                                    className="p-0 d-flex justify-content-start"
                                                                  >
                                                                    <img
                                                                      src={
                                                                        IconDrag
                                                                      }
                                                                      alt="Icono de arrastre"
                                                                      style={{
                                                                        marginTop:
                                                                          "10px",
                                                                        marginLeft:
                                                                          "15px",
                                                                        height:
                                                                          "15px",
                                                                      }}
                                                                    />
                                                                  </Col>
                                                                  <Col
                                                                    lg={6}
                                                                    className="p-0 d-flex justify-content-end"
                                                                  >
                                                                    <button
                                                                      type="button"
                                                                      className="btn btn-danger"
                                                                      onClick={() =>
                                                                        remove(
                                                                          idx
                                                                        )
                                                                      }
                                                                    >
                                                                      x
                                                                    </button>
                                                                    <button
                                                                      type="button"
                                                                      className="btn btn-secondary"
                                                                      onClick={() =>
                                                                        handlePush(
                                                                          index,
                                                                          {
                                                                            id: "",
                                                                            nombre:
                                                                              "",
                                                                          },
                                                                          idx +
                                                                            1
                                                                        )
                                                                      }
                                                                    >
                                                                      +
                                                                    </button>
                                                                  </Col>
                                                                  <Col lg={12}>
                                                                    <div className="form-group">
                                                                      <label>
                                                                        Nombre
                                                                      </label>
                                                                      <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        name={`acordion[${index}].items_acordion[${idx}].nombre`}
                                                                        placeholder="Ej: Cardiologia"
                                                                        onChange={
                                                                          handleChange
                                                                        }
                                                                        value={
                                                                          values
                                                                            ?.acordion[
                                                                            index
                                                                          ]
                                                                            ?.items_acordion[
                                                                            idx
                                                                          ]
                                                                            ?.nombre
                                                                        }
                                                                      />
                                                                      {errors &&
                                                                        errors.acordion &&
                                                                        errors
                                                                          .acordion[
                                                                          index
                                                                        ] &&
                                                                        errors
                                                                          .acordion[
                                                                          index
                                                                        ]
                                                                          .items_acordion &&
                                                                        errors
                                                                          .acordion[
                                                                          index
                                                                        ]
                                                                          .items_acordion[
                                                                          idx
                                                                        ] &&
                                                                        errors
                                                                          .acordion[
                                                                          index
                                                                        ]
                                                                          .items_acordion[
                                                                          idx
                                                                        ]
                                                                          .nombre && (
                                                                          <div className="text-danger">
                                                                            {
                                                                              errors
                                                                                ?.acordion[
                                                                                index
                                                                              ]
                                                                                ?.items_acordion[
                                                                                idx
                                                                              ]
                                                                                ?.nombre
                                                                            }
                                                                          </div>
                                                                        )}
                                                                    </div>
                                                                  </Col>
                                                                </Row>
                                                              </div>
                                                            )}
                                                          </Draggable>
                                                        )
                                                      )}
                                                    {provided.placeholder}
                                                  </div>
                                                )}
                                              </Droppable>
                                            </DragDropContext>
                                          </>
                                        );
                                      }}
                                    </FieldArray>
                                  </Card.Body>
                                </Card>
                              </Col>
                            </Row>
                          );
                        })}
                      <button
                        type="button"
                        className="btn btn-secondary"
                        onClick={() =>
                          push({
                            id: "",
                            items_acordion: [
                              {
                                id: "",
                                nombre: "",
                              },
                            ],
                            nombre: "",
                            mostrar: true,
                          })
                        }
                      >
                        Añadir Item
                      </button>
                    </>
                  );
                }}
              </FieldArray>
            </Col>
          </Row>
        </>
      ) : (
        <>
          {/* CUANDO ENTRA EN POR ELIMINAR */}
          <Row>
            <Col>
              {formFields &&
                formFields.map((data) => {
                  return (
                    <Row className="border m-3">
                      <Col lg={12} className="p-0 d-flex justify-content-end">
                        <button
                          type="button"
                          disabled
                          className="btn btn-danger"
                        >
                          x
                        </button>
                      </Col>

                      <Col lg={6}>
                        <div className="form-group">
                          <label>Nombre</label>
                          <input
                            type="text"
                            disabled
                            className="form-control"
                            name="nombre"
                            placeholder="Ej: Especialidades - Diagnostico por imagenes"
                            value={data.nombre}
                          />
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="form-group">
                          <label>Mostrar</label>
                          <select
                            className="form-control"
                            disabled
                            name="mostrar"
                            value={data.mostrar}
                          >
                            <option>Seleccionar</option>
                            <option value={true}>Si</option>
                            <option value={false}>No</option>
                          </select>
                        </div>
                      </Col>
                      <Col>
                        <Card>
                          <Card.Body className="pt-0">
                            {data &&
                              data.items_acordion.map(
                                (dataAcordion, index2) => {
                                  return (
                                    <>
                                      <Row>
                                        <Col
                                          lg={12}
                                          className="p-0 d-flex justify-content-end"
                                        >
                                          <button
                                            type="button"
                                            disabled
                                            className="btn btn-danger"
                                          >
                                            x
                                          </button>
                                          <button
                                            type="button"
                                            disabled
                                            className="btn btn-secondary"
                                          >
                                            +
                                          </button>
                                        </Col>
                                        <Col lg={12}>
                                          <div className="form-group">
                                            <label>Nombre</label>
                                            <input
                                              type="text"
                                              disabled
                                              className="form-control"
                                              name="nombre"
                                              placeholder="Ej: Cardiologia"
                                              value={dataAcordion.nombre}
                                            />
                                          </div>
                                        </Col>
                                      </Row>
                                    </>
                                  );
                                }
                              )}
                          </Card.Body>
                        </Card>
                      </Col>
                    </Row>
                  );
                })}
            </Col>
          </Row>
        </>
      )}
    </>
  );
};
export default Acordeon;
