import * as Yup from 'yup';

export const validateModel = Yup.object().shape({

    media: Yup.string().required('Campo obligatorio'),
    enlace: Yup.string().required('Campo obligatorio'),   
    caption: Yup.object().when("media", {
        is: 'VIDEO',
        then: Yup.object().shape({
            h1: Yup.string().required('Campo obligatorio'),
            h3: Yup.string().required('Campo obligatorio'),
            botones: Yup.array(
                Yup.object().shape({
                    nombre: Yup.string().required('Campo obligatorio'),
                    url: Yup.string().required('Campo obligatorio'),
                })
            ),
        })
    }),


});