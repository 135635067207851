import React, { useMemo } from "react";
import { Form, Row, Col, Button, Card } from "react-bootstrap";
import JoditEditor, { Jodit } from "jodit-react";
import { FieldArray, Formik, useFormik } from "formik";
import { Fetch, catchError } from "../../../utils/fetch";
import { useDispatch, useSelector } from "react-redux";
import ImageUploader from "../../../utils/image-uploader";
import { AlertSuccess, AlertInfo } from "../../../components/alert";
import { loadingAction } from "../../../redux/actions";
import { useParams } from "react-router-dom";
import MediaUploader from "../../../utils/media-uploader";
import { validateModel } from "./validationSchema";
import { useHistory } from "react-router-dom";
import { BASE_URL, EstadoContenido } from "../../../utils/constants";
import { removeSpecial } from "../../../utils/tools";
import Acordeon from "../components/acordeon";

const VerCentroMedicoX = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const isLoading = useSelector((state) => state.loading);
  const [contenido, setContenido] = React.useState(undefined);
  const [formFields, setFormFields] = React.useState([]);
  const permissions = useSelector((state) => state.permissions);
  const canPublish = permissions['centros-medicos'].aprobar.enabled;

  // function preparePaste(jodit) {
  //     jodit.e.on(
  //         'paste',
  //         e => {

  //             jodit.e.stopPropagation('paste');
  //             let plainText = Jodit.modules.Helpers.getDataTransfer(e)
  //                 .getData(Jodit.constants.TEXT_PLAIN);
  //             // plainText = plainText.replace(/(^|;)\s*font-[^;]+/g, "");
  //             // let regex = /<(?!(\/\s*)?(div|b|i|em|strong|u|p)[>,\s])([^>])*>/g;
  //             // plainText = plainText.replace(regex, '');
  //             plainText = plainText.normalize('NFKC').replaceAll("\n","<br/>");
  //             plainText=removeSpecial(plainText)

  //             jodit.s.insertHTML(
  //                 plainText
  //             );
  //             return false;

  //         },
  //         { top: true }
  //     );
  // }
  // Jodit.plugins.add('preparePaste', preparePaste);

  const config = useMemo(
    () => ({
      readonly: false, // all options from https://xdsoft.net/jodit/doc/
      height: 500,
      language: "es",
      disablePlugins: ["table"],
      askBeforePasteHTML: false,
      askBeforePasteFromWord: false,
      defaultActionOnPaste: "insert_clear_html",
      uploader: {
        url: BASE_URL + "/upload",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("jwt_token"),
        },
        data: null,
        filesVariableName: function (i) {
          return "files";
        },
        withCredentials: false,
        pathVariableName: "path",
        format: "json",
        method: "POST",
        prepareData: function (formData) {
          formData.append("id", 1);
        },
        isSuccess: function (resp) {
          return !resp.error;
        },
        getMessage: function (resp) {
          return resp.msgs.join("\n");
        },
        process: function (resp) {
          return resp;
        },
        defaultHandlerSuccess: function (resp) {
          var imagenesPermitidas = ["image/jpeg", "image/png"];
          for (var i = 0; i < resp.length; i++) {
            if (imagenesPermitidas.indexOf(resp[i].mime) !== -1) {
              var img1 = new Image();
              img1.src = resp[i]["url"];
              img1.alt = "imagen";
              img1.className = "img-fluid";
              this.s.insertImage(img1);
            } else if (resp[i].mime === "application/pdf") {
              const enlace = document.createElement("a");
              enlace.setAttribute("href", resp[i].url);
              const contenido = document.createTextNode(resp[i].name);
              // add the text node to the newly created div
              enlace.appendChild(contenido);
              this.s.insertNode(enlace);
            } else {
              AlertInfo("Tipo de archivo no permitido");
            }
          }
        },
      },
    }),
    []
  );

  React.useEffect(() => {
    if (id) {
      getCentrosMedicosById();
    }
  }, [id]);

  const getCentrosMedicosById = async (values) => {
    dispatch(loadingAction(true));
    try {
      const { data } = await Fetch(
        "centros-medicos/" +
          id +
          "?_publicationState=preview&published_at_null=true",
        "get"
      );
      setContenido(data);
      setValues(data);
      setFormFields(data.acordion);
      dispatch(loadingAction(false));
    } catch (error) {
      catchError(error);
      dispatch(loadingAction(false));
    }
  };

  const submit = async (values) => {
    dispatch(loadingAction(true));
    values.published_at = new Date();
    values.estado = EstadoContenido.Publicado;
    //delete values.imagen.id

    values.acordion &&
      values.acordion.forEach(function (acor) {
        delete acor.id;

        acor.items_acordion.forEach(function (item) {
          delete item.id;
        });
      });

    values.info_contacto = removeSpecial(values.info_contacto);
    values.descripcion = removeSpecial(values.descripcion);

    try {
      const { data } = await Fetch(
        "centros-medicos/aprobar/" +
          (values.idPadre === null ? id : values.idPadre),
        "put",
        values
      );

      AlertSuccess("Centro Médico actualizado con éxito");
      dispatch(loadingAction(false));

      history.push("/centros-medicos/lista");
    } catch (error) {
      catchError(error);
      dispatch(loadingAction(false));
    }
  };

  const onCancelar = async () => {
    dispatch(loadingAction(true));

    try {
      const params = {
        published_at: null,
        estado: EstadoContenido.EnRevision,
      };

      await Fetch("centros-medicos/" + id, "put", params);

      dispatch(loadingAction(false));
      AlertSuccess("Se ha cancelado la publicación del centro con éxito");

      history.push("/centros-medicos/lista");
    } catch (error) {
      dispatch(loadingAction(false));
      catchError(error);
    }
  };

  const {
    values,
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue,
    setValues,
  } = useFormik({
    initialValues: {
      direccion: "",
      localidad: "",
      provincia: "",
      colectivos: "",
      tren: "",
      info_contacto: "",
      descripcion: "",
      nombre: "",
      slug: "",
      url: "",
      imagen: {},
      miniatura: {},
      acordion: [
        {
          id: "",
          items_acordion: [
            {
              id: "",
              nombre: "",
            },
          ],
          nombre: "",
          mostrar: true,
        },
      ],
      iframe_mapa: "",
      locale: "es",
    },
    enableReinitialize: true,
    onSubmit: (values) => submit(values),
  });

  const handleBlurTitulo = async (event) => {
    const value = event.target.value;
    const name = event.target.name;
    const dataToApi = {
      contentTypeUID: "application::centros-medicos.centros-medicos",
      field: "slug",
      data: {
        nombre: value,
        slug: "",
      },
    };
    setFieldValue(name, value, false);
    try {
      const { data } = await Fetch(
        "content-manager/uid/generate",
        "post",
        dataToApi
      );
      setFieldValue("slug", data.data, false);
    } catch (error) {
      console.error("mostrando error", error);
    }
  };

  const handleChangeJoiEditor = (data, field) => {
    setFieldValue(field, data, false);
  };

  const addArray = () => {
    let object = {
      nombre: "",
      mostrar: null,
      items_acordion: [{ nombre: "" }],
    };
    setFormFields([...formFields, object]);
  };

  const addArray2 = (index1) => {
    let data = [...formFields];
    let object = { nombre: "" };
    data[index1]["items_acordion"].push(object);
    setFormFields(data);
  };

  const removeArray = (index) => {
    let data = [...formFields];
    data.splice(index, 1);
    setFormFields(data);
  };

  const removeArray2 = (index, index2) => {
    let data = [...formFields];
    data[index]["items_acordion"].splice(index2, 1);
    setFormFields(data);
  };

  const handleFormChange = (event, index) => {
    let data = [...formFields];
    data[index][event.target.name] = event.target.value;
    setFormFields(data);
  };

  const handleFormChange2 = (event, index, index2) => {
    let data = [...formFields];
    data[index]["items_acordion"][index2][event.target.name] =
      event.target.value;
    setFormFields(data);
  };

  return (
    <React.Fragment>
      {contenido !== undefined && (
        <Formik
          initialValues={contenido}
          validationSchema={validateModel}
          onSubmit={(values, { setSubmitting }) => {
            submit(values);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            isSubmitting,
            /* and other goodies */
          }) => (
            <form onSubmit={handleSubmit}>
              <Row>
                <Col>
                  <Card>
                    <Card.Header>
                      <Row>
                        <Col>Información del centro médico</Col>
                      </Row>
                    </Card.Header>
                    <Card.Body>
                      <Row>
                        <Col>
                          <Form.Group>
                            <label>Nombre</label>
                            <input
                              type="text"
                              name="nombre"
                              onChange={handleChange}
                              onBlur={(e) => handleBlurTitulo(e, setFieldValue)}
                              value={values?.nombre}
                              className="form-control "
                            />
                            <div className="text-danger">{errors?.nombre}</div>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Form.Group>
                            <label>Url</label>
                            <input
                              type="text"
                              name="slug"
                              className="form-control"
                              value={values.slug}
                              readOnly
                            />
                          </Form.Group>
                        </Col>
                      </Row>

                      {values?.miniatura && (
                        <Row>
                          <Col>
                            <Form.Group>
                              <img
                                src={values.miniatura.url}
                                alt="vista-previa"
                                className="img-fluid w-50"
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                      )}

                      <Row>
                        <Col>
                          <Form.Group>
                            <label>Imagen miniatura home</label>
                            <br />
                            <span className="text-muted">
                              Se recomienda una imagen de 500px de ancho x 405px
                              de alto. En formato JPG o PNG.
                            </span>
                            <MediaUploader
                              setFieldValue={setFieldValue}
                              field={"miniatura"}
                              tipoMedia="imagen"
                              ancho={500}
                              alto={405}
                            />
                            <div className="text-danger">
                              {errors?.miniatura}
                            </div>
                          </Form.Group>
                        </Col>
                      </Row>

                      {Object.keys(values.imagen).length > 0 && (
                        <Row>
                          <Col>
                            <Form.Group>
                              <img
                                src={values.imagen.url}
                                alt="vista-previa"
                                className="img-fluid w-50"
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                      )}

                      <Row>
                        <Col>
                          <Form.Group>
                            <label>Imagen centro médico</label>
                            <MediaUploader
                              setFieldValue={setFieldValue}
                              field={"imagen"}
                              tipoMedia="imagen"
                              ancho={500}
                              alto={318}
                            />
                            <div className="text-danger">{errors?.imagen}</div>
                            {/* <span className="text-muted">Se recomienda una imagen de 500px de ancho x 318px de alto. En formato JPG o PNG.</span> */}
                          </Form.Group>
                        </Col>
                      </Row>

                      <Row>
                        <Col>
                          <Form.Group>
                            <label>Dirección</label>
                            <input
                              type="text"
                              name="direccion"
                              className="form-control"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.direccion}
                            />
                            <div className="text-danger">
                              {errors?.direccion}
                            </div>
                          </Form.Group>
                        </Col>
                      </Row>

                      <Row>
                        <Col>
                          <Form.Group>
                            <label>Localidad</label>
                            <input
                              type="text"
                              name="localidad"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className="form-control"
                              value={values.localidad}
                            />
                            <div className="text-danger">
                              {errors?.localidad}
                            </div>
                          </Form.Group>
                        </Col>
                      </Row>

                      <Row>
                        <Col>
                          <Form.Group>
                            <label>Provincia</label>
                            <input
                              type="text"
                              name="provincia"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className="form-control"
                              value={values.provincia}
                            />
                            <div className="text-danger">
                              {errors?.provincia}
                            </div>
                          </Form.Group>
                        </Col>
                      </Row>

                      <Row>
                        <Col>
                          <Form.Group>
                            <label>Colectivos</label>
                            <input
                              type="text"
                              name="colectivos"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className="form-control"
                              value={values.colectivos}
                            />
                            <div className="text-danger">
                              {errors?.colectivos}
                            </div>
                          </Form.Group>
                        </Col>
                      </Row>

                      <Row>
                        <Col>
                          <Form.Group>
                            <label>Tren</label>
                            <input
                              type="text"
                              name="tren"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className="form-control"
                              value={values.tren}
                            />
                            <div className="text-danger">{errors?.tren}</div>
                          </Form.Group>
                        </Col>
                      </Row>

                      <Row>
                        <Col>
                          <Form.Group>
                            <label>Información de contacto</label>
                            <JoditEditor
                              config={config}
                              value={values.info_contacto}
                              tabIndex={1} // tabIndex of textarea
                              onBlur={(v) =>
                                setFieldValue(`info_contacto`, v, false)
                              }
                            />
                            <div className="text-danger">
                              {errors?.info_contacto}
                            </div>
                          </Form.Group>
                        </Col>
                      </Row>

                      <Row>
                        <Col>
                          <Form.Group>
                            <label>Descripción</label>
                            <JoditEditor
                              config={config}
                              value={values.descripcion}
                              tabIndex={1} // tabIndex of textarea
                              onBlur={(v) =>
                                setFieldValue(`descripcion`, v, false)
                              }
                            />
                            <div className="text-danger">
                              {errors?.descripcion}
                            </div>
                          </Form.Group>
                        </Col>
                      </Row>

                      <Row>
                        <Col>
                          <Form.Group>
                            <label>Contenido del Mapa</label>
                            <textarea
                              name="iframe_mapa"
                              className="form-control"
                              onChange={handleChange}
                              value={values.iframe_mapa}
                            ></textarea>
                            <div className="text-danger">
                              {errors?.iframe_mapa}
                            </div>
                          </Form.Group>
                        </Col>
                      </Row>

                      <Acordeon
                        values={values}
                        errors={errors}
                        handleChange={handleChange}
                        setFieldValue={setFieldValue}
                      />

                      <Row className="mt-5">
                        <Col className="col col-lg-2">
                          <Button
                            type="button"
                            onClick={() => handleSubmit()}
                            disabled={isLoading || !canPublish}
                            className="btn btn-success btn-icon"
                          >
                            <i className="fa fa-floppy-o" />
                            Publicar
                          </Button>
                        </Col>
                        <Col className="col col-lg-3">
                          <Button
                            type="button"
                            onClick={() => onCancelar()}
                            disabled={isLoading}
                            className="btn btn-primary btn-icon"
                          >
                            <i className="fa fa-undo " />
                            Por revisar
                          </Button>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </form>
          )}
        </Formik>
      )}
    </React.Fragment>
  );
};
export default VerCentroMedicoX;
