import React, { useMemo } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { Fetch, catchError } from "../../../utils/fetch";
import JoditEditor, { Jodit } from "jodit-react";
import { FieldArray, Formik, useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { AlertInfo, AlertSuccess } from "../../alert";
import { loadingAction } from "../../../redux/actions";
import { useHistory } from "react-router-dom";
import MediaUploader from "../../../utils/media-uploader";
import { validateModel } from "./validationSchema";
import { BASE_URL, EstadoContenido } from "../../../utils/constants";
import { removeSpecial } from "../../../utils/tools";
import LayoutJoditList from "../../customizer/layout-jodit-list";

const Template2Component = ({ dataResult }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const permissions = useSelector((state) => state.permissions);
  const [layoutJoditList, setLayoutJoditList] = React.useState([]);
  const canPublish = permissions.paginas.aprobar.enabled;

  const [formFields, setFormFields] = React.useState([]);

  // function preparePaste(jodit) {
  //   jodit.e.on(
  //     "paste",
  //     (e) => {
  //       jodit.e.stopPropagation("paste");
  //       let plainText = Jodit.modules.Helpers.getDataTransfer(e).getData(
  //         Jodit.constants.TEXT_PLAIN
  //       );
  //       // plainText = plainText.replace(/(^|;)\s*font-[^;]+/g, "");
  //       // let regex = /<(?!(\/\s*)?(div|b|i|em|strong|u|p)[>,\s])([^>])*>/g;
  //       // plainText = plainText.replace(regex, '');
  //       plainText = plainText.normalize("NFKC").replaceAll("\n", "<br/>");plainText=removeSpecial(plainText);
  //       plainText=removeSpecial(plainText)
  //       jodit.s.insertHTML(plainText);
  //       return false;
  //     },
  //     { top: true }
  //   );
  // }
  // Jodit.plugins.add("preparePaste", preparePaste);

  const config = useMemo(
    () => ({
      readonly: false, // all options from https://xdsoft.net/jodit/doc/
      language: "es",
      disablePlugins: ["table"],
      askBeforePasteHTML: false,
      askBeforePasteFromWord: false,
      defaultActionOnPaste: "insert_clear_html",
      enableDragAndDropFileToEditor: true,
      uploader: {
        url: BASE_URL + "/upload",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("jwt_token"),
        },
        data: null,
        filesVariableName: function (i) {
          return "files";
        },
        withCredentials: false,
        pathVariableName: "path",
        format: "json",
        method: "POST",
        prepareData: function (formData) {
          formData.append("id", 1);
        },
        isSuccess: function (resp) {
          return !resp.error;
        },
        getMessage: function (resp) {
          return resp.msgs.join("\n");
        },
        process: function (resp) {
          return resp;
        },
        defaultHandlerSuccess: function (resp) {
          var imagenesPermitidas = ["image/jpeg", "image/png"];
          for (var i = 0; i < resp.length; i++) {
            if (imagenesPermitidas.indexOf(resp[i].mime) !== -1) {
              var img1 = new Image();
              img1.src = resp[i]["url"];
              img1.alt = "imagen";
              img1.className = "img-fluid";
              this.s.insertImage(img1);
            } else if (resp[i].mime === "application/pdf") {
              const enlace = document.createElement("a");
              enlace.setAttribute("href", resp[i].url);
              const contenido = document.createTextNode(resp[i].name);
              // add the text node to the newly created div
              enlace.appendChild(contenido);
              this.s.insertNode(enlace);
            } else {
              AlertInfo("Tipo de archivo no permitido");
            }
          }
        },
      },
    }),
    []
  );

  React.useEffect(() => {
    if (dataResult) {
      setValues(dataResult);
      setFormFields(dataResult.template2.sidemenu);

      const count = dataResult.template2.sidemenu.length;
      const aux = [];

      for (let i = 0; i < count; i++) {
        aux.push("PC");
      }

      setLayoutJoditList(aux);
    }
  }, [dataResult]);

  const submit = async (values, type) => {
    dispatch(loadingAction(true));

    values.published_at = null;
    values.estado = EstadoContenido.EnRevision;

    // values.template2.sidemenu=values.template2.sidemenu.map(item=>{
    //   item.contenido=removeSpecial(item.contenido)
    //   return item;
    // })

    try {
      const { data } = await Fetch("paginas", "post", values);

      dispatch(loadingAction(false));

      AlertInfo(
        "Página creada con éxito.<br/>Recuerde que falta su confirmación para aprobar.<br/> Nota: Por favor, notificar al responsable de publicar"
      );
      history.push("/pages/lista-borradores");
    } catch (error) {
      dispatch(loadingAction(false));
      catchError(error);
    }
  };

  const {
    values,
    handleSubmit,
    handleChange,
    setFieldValue,
    setValues,
    errors,
  } = useFormik({
    initialValues: {
      nombre: "",
      slug: "",
      template: "template2",
      template2: {
        fondo: "",
        sidemenu: [
          {
            nombre: "",
            contenido: "",
          },
        ],
      },
      locale: "es",
    },
    enableReinitialize: true,
    validationSchema: validateModel,
    onSubmit: (values) => submit(values),
  });

  const handleFormChange = (event, index) => {
    let data = [...formFields];
    data[index][event.target.name] = event.target.value;
    setFormFields(data);
  };

  const handleChangeJoiEditor = (event, index) => {
    let data = [...formFields];
    data[index]["contenido"] = event;
    setFormFields(data);
  };

  const addArray = () => {
    let object = { nombre: "", contenido: "" };
    setFormFields([...formFields, object]);
  };

  const removeArray = (index) => {
    let data = [...formFields];
    data.splice(index, 1);
    setFormFields(data);
  };

  const handleBlurTitulo = async (event, setFieldValue) => {
    const value = event.target.value;
    const name = event.target.name;
    const dataToApi = {
      contentTypeUID: "application::paginas.paginas",
      field: "slug",
      data: {
        nombre: value,
        slug: "",
      },
    };
    setFieldValue(name, value, false);
    try {
      const { data } = await Fetch(
        "content-manager/uid/generate",
        "post",
        dataToApi
      );
      setFieldValue("slug", data.data, false);
    } catch (error) {
      console.error("mostrando error", error);
    }
  };

  const initialValues = {
    nombre: "",
    slug: "",
    template: "template2",
    template2: {
      fondo: "",
      sidemenu: [],
    },
    locale: "es",
  };

  const handleLayoutJodit = (index, device) => {
    const data = [...layoutJoditList];
    data[index] = device;
    setLayoutJoditList(data);
  };

  const handleAgregarItim = (push) => {
    setLayoutJoditList((val) => [...val, "PC"]);
    push({ nombre: "", contenido: "" });
  };

  return (
    <React.Fragment>
      <Formik
        initialValues={initialValues}
        validationSchema={validateModel}
        onSubmit={(values, { setSubmitting }) => {
          submit(values);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
          /* and other goodies */
        }) => (
          <form handleSubmit={handleSubmit}>
            <Row>
              <Col>
                <h2>Nueva página</h2>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="form-group">
                  <label>Nombre de la página</label>
                  <input
                    type="text"
                    name="nombre"
                    onChange={handleChange}
                    onBlur={(e) => handleBlurTitulo(e, setFieldValue)}
                    value={values.nombre}
                    className="form-control "
                  />
                  <div className="text-danger">{errors?.nombre}</div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="form-group">
                  <label>Url</label>
                  <input
                    type="text"
                    name="slug"
                    className="form-control"
                    value={values.slug}
                    readOnly
                  />
                </div>
              </Col>
            </Row>
            {values?.template2?.fondo && (
              <>
                <Row>
                  <Col>
                    <div className="form-group">
                      <img
                        src={values?.template2?.fondo?.url}
                        alt="vista-previa"
                        className="img-fluid w-50"
                      />
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <Button
                      type="button"
                      style={{ marginBottom: "10px" }}
                      onClick={() =>
                        setFieldValue("template2.fondo", "", false)
                      }
                      className="btn btn-danger btn-icon mt-2"
                    >
                      <i className="fa fa-trash-o " />
                      Eliminar
                    </Button>
                  </Col>
                </Row>
              </>
            )}

            <Row>
              <Col>
                <div className="form-group">
                  <label>Fondo</label>
                  <MediaUploader
                    setFieldValue={setFieldValue}
                    field={"template2.fondo"}
                    tipoMedia="imagen"
                    ancho={1200}
                    alto={800}
                    value={values.template2.fondo ? 100 : 0}
                    key={values.template2.fondo}
                  />
                  <div className="text-danger">{errors?.template2?.fondo}</div>
                  <span className="text-muted">
                    Se recomienda una imagen de 1200px de ancho x 800px de alto.
                    En formato JPG o PNG.
                  </span>
                </div>
              </Col>
            </Row>
            <hr />
            <Row>
              <Col>
                <h2>Información del contenido</h2>
              </Col>
            </Row>

            <Row>
              <Col>
                <FieldArray name="template2.sidemenu">
                  {({
                    move,
                    swap,
                    push,
                    remove,
                    insert,
                    unshift,
                    pop,
                    form,
                  }) => {
                    return (
                      <>
                        {values.template2.sidemenu &&
                          values.template2.sidemenu.length > 0 &&
                          values.template2.sidemenu.map((data, index) => {
                            return (
                              <Row key={index} className="border m-3">
                                <Col
                                  lg={12}
                                  className="p-0 d-flex justify-content-end"
                                >
                                  <button
                                    type="button"
                                    className="btn btn-danger"
                                    onClick={() => remove(index)}
                                  >
                                    x
                                  </button>
                                </Col>
                                <Col lg={12}>
                                  <div className="form-group">
                                    <label>Nombre</label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name={`template2.sidemenu[${index}].nombre`}
                                      onChange={handleChange}
                                      value={
                                        values?.template2?.sidemenu[index]
                                          ?.nombre
                                      }
                                    />
                                    {errors &&
                                      errors.template2 &&
                                      errors.template2.sidemenu &&
                                      errors.template2.sidemenu[index] &&
                                      errors.template2.sidemenu[index]
                                        .nombre && (
                                        <div className="text-danger">
                                          {
                                            errors?.template2?.sidemenu[index]
                                              ?.nombre
                                          }
                                        </div>
                                      )}
                                  </div>
                                </Col>
                                <Col
                                  className={`${
                                    layoutJoditList[index] == "MOBILE" &&
                                    "col-6"
                                  }`}
                                >
                                  <div className="form-group">
                                    <label>Contenido</label>
                                    <LayoutJoditList
                                      index={index}
                                      setLayoutJodit={handleLayoutJodit}
                                      layoutJodit={layoutJoditList[index]}
                                    />
                                    <JoditEditor
                                      config={config}
                                      value={
                                        values?.template2?.sidemenu[index]
                                          ?.contenido
                                      }
                                      tabIndex={1} // tabIndex of textarea
                                      onChange={handleChange}
                                      onBlur={(v) =>
                                        setFieldValue(
                                          `template2.sidemenu[${index}].contenido`,
                                          v,
                                          false
                                        )
                                      }
                                    />
                                    {errors &&
                                      errors.template2 &&
                                      errors.template2.sidemenu &&
                                      errors.template2.sidemenu[index] &&
                                      errors.template2.sidemenu[index]
                                        .contenido && (
                                        <div className="text-danger">
                                          {
                                            errors?.template2?.sidemenu[index]
                                              ?.contenido
                                          }
                                        </div>
                                      )}
                                  </div>
                                </Col>
                              </Row>
                            );
                          })}
                        <button
                          type="button"
                          className="btn btn-secondary"
                          onClick={() => handleAgregarItim(push)}
                        >
                          Añadir Item
                        </button>
                      </>
                    );
                  }}
                </FieldArray>

                {/* {formFields && formFields.map((data, index) => {
                  return (
                    <Row className="border m-3">

                      <Col lg={12} className="p-0 d-flex justify-content-end">
                        <button type="button" className="btn btn-danger" onClick={() => removeArray(index)}>x</button>
                      </Col>
                      <Col lg={12}>
                        <div className="form-group">
                          <label>Nombre</label>
                          <input type="text" className="form-control" name={`template2.sidemenu.${index}.nombre`} onChange={event => handleFormChange(event, index)} value={data?.template2?.nombre} />
                          {errors &&
                            errors.template2 &&
                            errors.template2.sidemenu &&
                            errors.template2.sidemenu[index] &&
                            errors.template2.sidemenu[index].nombre &&
                            (
                              <div className="text-danger">{errors?.template2?.sidemenu[index]?.nombre}</div>
                            )
                          }
                        </div>
                      </Col>
                      <Col lg={12}>
                        <div className="form-group">
                          <label>Contenido</label>
                          <JoditEditor
                            config={config}
                            value={data.contenido}
                            tabIndex={1}
                            onBlur={newContent => handleChangeJoiEditor(newContent, index)}
                          />
                        </div>
                      </Col>
                    </Row>
                  )
                })} */}
                <Row>
                  <Col>
                    {/* <Button variant="secondary" type="button" className=" mx-2" onClick={() => addArray()}>Añadir item</Button> */}
                    <Button
                      type="button"
                      className="btn btn-success mt-5"
                      onClick={() => handleSubmit()}
                    >
                      Guardar
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </form>
        )}
      </Formik>
    </React.Fragment>
  );
};
export default Template2Component;
