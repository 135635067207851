import React from 'react';
import { Row, Col, Card, } from 'react-bootstrap';
import { connect } from 'react-redux'
import { Link } from 'react-router-dom';
import plantilla1 from '../../../assets/img/templates/plantilla1.jpg'
import plantilla2 from '../../../assets/img/templates/plantilla2.jpg'
import plantilla3 from '../../../assets/img/templates/plantilla3.jpg'

function NuevaPagina({ user, token }) {

    React.useEffect(() => {

    }, [])

    return (
        <React.Fragment>
            <Row>
                <Col>
                    <Card>
                        <Card.Header>
                            Nueva Página
                        </Card.Header>
                        <Card.Body>
                            <Row>
                                <Col>
                                    <h2>Seleccionar la plantilla para la creación de la nueva página</h2>
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col>
                                    <Card>
                                        <Card.Body>
                                            <img src={plantilla1} className="card-img-top" alt="template" />
                                            <p>Plantilla 1</p>                                            
                                            <Link className="btn btn-success stretched-link" to="/pages/nueva-pagina/template1">Crear</Link>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col>
                                    <Card>
                                        <Card.Body>
                                            <img src={plantilla2} className="card-img-top" alt="template" />
                                            <p>Plantilla 2</p>                                            
                                            <Link className="btn btn-success stretched-link" to="/pages/nueva-pagina/template2">Crear</Link>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col>
                                    <Card>
                                        <Card.Body>
                                            <img src={plantilla3} className="card-img-top" alt="template" />
                                            <p>Plantilla 3</p>                                            
                                            <Link className="btn btn-success stretched-link" to="/pages/nueva-pagina/template3">Crear</Link>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    )
}

const mapStateToProps = (state) => {

    return {
        user: state.user.data,
        token: state.user.token
    }
}

export default connect(mapStateToProps, null)(NuevaPagina);