import React from "react";
import { Form, Row, Col, Button, Card } from "react-bootstrap";
import { useFormik } from "formik";
import { Fetch, catchError } from "../../../utils/fetch";
import { useDispatch, useSelector } from "react-redux";
import { AlertInfo, AlertSuccess } from "../../../components/alert";
import { loadingAction } from "../../../redux/actions";
import MediaUploader from "../../../utils/media-uploader";
import { validateModel } from "./validationSchema";
import { EstadoContenido } from "../../../utils/constants";
import { useHistory } from "react-router-dom";
import { getYouTubeVideoId } from "../../../utils/tools";

const VerSocialNetwork = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.loading);
  const history = useHistory();

  React.useEffect(() => {
    const script = document.createElement("script");
    script.async = true;
    script.src = "https://www.instagram.com/embed.js";
    script.onload = () => window.instgrm.Embeds.process();
    document.body.appendChild(script);

    getSocialNetwork();
  }, []);

  const getSocialNetwork = async (values) => {
    dispatch(loadingAction(true));

    try {
      const { data } = await Fetch("social-networks", "get");
      let valores = data[data.length - 1];

      if (valores) setValues(valores);

      dispatch(loadingAction(false));
    } catch (error) {
      catchError(error);
      dispatch(loadingAction(false));
    }
  };

  const submit = async (values) => {
    dispatch(loadingAction(true));
    values.estado = EstadoContenido.EnRevision;
    values.published_at = null;

    try {
      try {
        await Fetch(`social-networks/delete-by-estado/ER`, "delete");
      } catch (error) {}

      await Fetch(`social-networks`, "post", values);
      AlertSuccess(
        "Redes sociales actualizado con éxito, pendiente de revisión."
      );

      dispatch(loadingAction(false));
      history.push("/social-networks/revision/");
    } catch (error) {
      if (error.response.status === 500) {
        AlertInfo("Ya existe un registro pendiente por revisión");
      }
      dispatch(loadingAction(false));
    }
  };

  const handleRemoveImg = async (campo) => {
    setFieldValue(campo, "", false);
  };

  const {
    values,
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue,
    setValues,
    errors,
  } = useFormik({
    initialValues: {
      url_imagen_instagram: "",
      url_video_youtube: "",
      url_video_youtube_estatico: "",
    },
    enableReinitialize: true,
    validationSchema: validateModel,
    onSubmit: (values) => submit(values),
  });

  const renderPreview = (url) => {
    if (url.includes("youtube.com") || url.includes("youtu.be")) {
      const videoId = getYouTubeVideoId(url);
      return (
        <iframe
          width="560"
          height="315"
          src={videoId}
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          referrerpolicy="strict-origin-when-cross-origin"
          allowfullscreen
          title="Vídeo para youtube"
        ></iframe>
      );
    } else {
      return <img src={url} alt="vista-previa" className="img-fluid w-50" />;
    }
  };

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit}>
        <Row>
          <Col>
            <Card>
              <Card.Header>
                <Row>
                  <Col>Redes sociales - Publicado</Col>
                </Row>
              </Card.Header>
              <Card.Body>
                <Row>
                  <Col>
                    <h3>iframe post Instagram</h3>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group>
                      <label>
                        iframe (destildar opción incluir pie de foto)
                      </label>
                      <input
                        type="text"
                        name="url_imagen_instagram"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="form-control"
                        value={values?.url_imagen_instagram}
                      />
                      <div className="text-danger">
                        {errors?.url_imagen_instagram}
                      </div>
                    </Form.Group>
                  </Col>
                </Row>

                {values?.url_imagen_instagram && (
                  <>
                    <Row>
                      <Col>
                        <Form.Group>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: `
                              ${values?.url_imagen_instagram}
                                     `,
                            }}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </>
                )}
                <hr />

                <Row>
                  <Col>
                    <h3>iframe canal YouTube (dinámico)</h3>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group>
                      <label>iframe</label>
                      <input
                        type="text"
                        name="url_video_youtube"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="form-control"
                        value={values?.url_video_youtube}
                      />
                      <div className="text-danger">
                        {errors?.url_video_youtube}
                      </div>
                    </Form.Group>
                  </Col>
                </Row>

                {values?.url_video_youtube && (
                  <>
                    <Row>
                      <Col>
                        <Form.Group>
                          {renderPreview(values.url_video_youtube)}
                        </Form.Group>
                      </Col>
                    </Row>
                  </>
                )}

                <Row>
                  <Col>
                    <h3>iframe canal YouTube (estático)</h3>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group>
                      <label>iframe</label>
                      <input
                        type="text"
                        name="url_video_youtube_estatico"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="form-control"
                        value={values?.url_video_youtube_estatico}
                      />
                      <div className="text-danger">
                        {errors?.url_video_youtube_estatico}
                      </div>
                    </Form.Group>
                  </Col>
                </Row>

                {values?.url_video_youtube_estatico && (
                  <>
                    <Row>
                      <Col>
                        <Form.Group>
                          {renderPreview(values.url_video_youtube_estatico)}
                        </Form.Group>
                      </Col>
                    </Row>
                  </>
                )}

                <Button
                  type="button"
                  onClick={() => handleSubmit()}
                  disabled={isLoading}
                  className="btn btn-success btn-icon mt-2"
                >
                  <i className="fa fa-floppy-o " />
                  Guardar
                </Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </form>
    </React.Fragment>
  );
};

export default VerSocialNetwork;
