import { Form, Row, Col, Button, Card } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import React, { useMemo, useState } from "react";
import JoditEditor from "jodit-react";
import { useFormik } from "formik";

import { BASE_URL, EstadoContenido } from "../../../utils/constants";
import { AlertInfo, AlertSuccess } from "../../../components/alert";
import Subespecialidad from "../components/Subespecialidad";
import { validateModel } from "./../nuevo/validationSchema";
import { Fetch, catchError } from "../../../utils/fetch";
import { removeSpecial } from "../../../utils/tools";
import MyButtons from "../components/MyButtons";
import { mySort } from "../../../utils/tools";
import Equipo from "../components/Equipo";
import LayoutJodit from "../../../components/customizer/layout-jodit";

import {
  loadingAction,
  especialistasAction,
  subespecialidadAction,
  practicaAction
} from "../../../redux/actions";
import JefeServicio from "../components/JefeServicio";
import Practica from "../components/Practica";

const VerEspecialidadX = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.data);
  const isLoading = useSelector((state) => state.loading);
  const [layoutJodit, setLayoutJodit] = React.useState("PC");
  const subespecialidad = useSelector((state) => state.subespecialidad);
  const especialistas = useSelector((state) => state.especialistas);
  const practica = useSelector((state) => state.practica);  
  const [showModalPractica, setShowModalPractica] = useState(false);
  const permissions = useSelector((state) => state.permissions);
  const canPublish = permissions.especialidades.aprobar.enabled;

  const [showModal, setShowModal] = useState(false);
  const history = useHistory();
  const { id } = useParams();

  const config = useMemo(
    () => ({
      readonly: false,
      height: 500,
      language: "es",
      disablePlugins: ["table"],
      disablePlugins: ["table"],
      askBeforePasteHTML: false,
      askBeforePasteFromWord: false,
      defaultActionOnPaste: "insert_clear_html",
      enableDragAndDropFileToEditor: true,
      uploader: {
        url: BASE_URL + "/upload",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("jwt_token"),
        },
        data: null,
        filesVariableName: function (i) {
          return "files";
        },
        withCredentials: false,
        pathVariableName: "path",
        format: "json",
        method: "POST",
        prepareData: function (formData) {
          formData.append("id", 1);
        },
        isSuccess: function (resp) {
          return !resp.error;
        },
        getMessage: function (resp) {
          return resp.msgs.join("\n");
        },
        process: function (resp) {
          return resp;
        },
        defaultHandlerSuccess: function (resp) {
          var imagenesPermitidas = ["image/jpeg", "image/png"];
          for (var i = 0; i < resp.length; i++) {
            if (imagenesPermitidas.indexOf(resp[i].mime) !== -1) {
              var img1 = new Image();
              img1.src = resp[i]["url"];
              img1.alt = "imagen";
              img1.className = "img-fluid";
              this.s.insertImage(img1);
            } else if (resp[i].mime === "application/pdf") {
              const enlace = document.createElement("a");
              enlace.setAttribute("href", resp[i].url);
              const contenido = document.createTextNode(resp[i].name);
              // add the text node to the newly created div
              enlace.appendChild(contenido);
              this.s.insertNode(enlace);
            } else {
              AlertInfo("Tipo de archivo no permitido");
            }
          }
        },
      },
    }),
    []
  );

  React.useEffect(() => {
    if (id) {
      getData();
    }
  }, [id]);

  const getData = async () => {
    dispatch(loadingAction(true));
    try {
      const url = `especialidades/${id}?_publicationState=preview&published_at_null=true`;
      const request1 = await Fetch(url, "get");
      const request2 = Fetch(`doctores/by-especializacion/${id}`, "get");

      Promise.all([request1, request2]).then((response) => {
        const [especialidad, doctores] = response;

        mySort(doctores.data, "order");
        formik.setValues(especialidad.data);

        dispatch(subespecialidadAction(especialidad.data.sub_especialidades));
        dispatch(practicaAction(especialidad.data.practicas));
        dispatch(especialistasAction(doctores.data));
        dispatch(loadingAction(false));
      });
    } catch (error) {
      catchError(error);
      dispatch(loadingAction(false));
    }
  };

  const submit = async (values) => {
    values.published_at = new Date();
    values.estado = EstadoContenido.Publicado;

    values.descripcion = removeSpecial(values.descripcion);
    values.titulo = values.nombre;

    subespecialidad.forEach((item) => {
      if (item.isNew) delete item.id;
    });

    especialistas.forEach((item) => {
      if (item.isNew) delete item.id;
    });

    practica.forEach((item) => {
      if (item.isNew) delete item.id;
    });

    values.doctors = especialistas;
    values.sub_especialidades = subespecialidad;
    values.practicas=practica;
    values.doctors.forEach(function (doctor, index) {
      doctor.order = index + 1;
    });

    dispatch(loadingAction(true));

    try {
      const idTarget = values.idPadre || id;
      const url = `especialidades/aprobar/${idTarget}`;

      const { data } = await Fetch(url, "put", values);

      AlertSuccess("Especialidad actualizada con éxito");
      dispatch(loadingAction(false));

      history.push("/especialidades/listax");
    } catch (error) {
      catchError(error);
      dispatch(loadingAction(false));
    }
  };

  const onCancelar = async () => {
    dispatch(loadingAction(true));

    practica.forEach((item)=>{
      if(item.isNew) delete item.id;
    })
    const params = {
      published_at: null,
      estado: EstadoContenido.EnRevision      
    };

    try {
      const url = `especialidades/update-single/${id}`;
      const { data } = await Fetch(url, "put", params);

      dispatch(loadingAction(false));
      AlertSuccess(
        "Se ha cancelado la publicación de la especialidad con éxito"
      );

      history.push("/especialidades/lista-borradores");
    } catch (error) {
      dispatch(loadingAction(false));
      catchError(error);
    }
  };

  const formik = useFormik({
    initialValues: {
      nombre: "",
      titulo: "",
      descripcion: "",
      imagen: "",
      slug: "",
      orden: 0,
      habilitado: true,
      locale: "es",
      eliminado: false,
      localizations: [],
      published_at: "2022-05-17T04:14:37.397Z",
      created_by: user.id,
      updated_by: user,
      show_jefe:false,
      descripcion_jefe:"",
      botones: [
        {
          nombre: "",
          url: "",
          estado: "visible",
        },
      ],
    },
    enableReinitialize: true,
    validationSchema: validateModel,
    onSubmit: (values) => submit(values),
  });

  const handleBlurNombre = async (event) => {
    const value = event.target.value;
    const name = event.target.name;
    const dataToApi = {
      contentTypeUID: "application::novedades.novedades",
      field: "slug",
      data: {
        titulo: value,
        slug: "",
      },
    };

    formik.setFieldValue(name, value, false);
    try {
      const { data } = await Fetch(
        "content-manager/uid/generate",
        "post",
        dataToApi
      );
      formik.setFieldValue("slug", data.data, false);
    } catch (error) {
      console.error("mostrando error", error);
    }
  };

  const handleChangeJoiEditor = (data, field) => {
    formik.setFieldValue(field, data, false);
  };

  return (
    <React.Fragment>
      <form onSubmit={formik.handleSubmit}>
        <Row>
          <Col>
            <Card>
              <Card.Header>
                <Row>
                  <Col>Nueva especialidad</Col>
                </Row>
                <hr />
              </Card.Header>
              <Card.Body style={{ marginTop: "-36px" }}>
                <Row>
                  <Col>
                    <h3>Información general</h3>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group>
                      <label>Nombre de la especialidad</label>
                      <input
                        type="text"
                        name="nombre"
                        onChange={formik.handleChange}
                        onBlur={(e) => handleBlurNombre(e)}
                        value={formik.values.nombre}
                        className="form-control "
                      />
                      <div className="text-danger">{formik.errors?.nombre}</div>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group>
                      <label>URL</label>
                      <input
                        type="text"
                        name="slug"
                        className="form-control"
                        value={formik.values.slug}
                        readOnly
                      />
                    </Form.Group>
                  </Col>
                </Row>

                {/* BUTTONS */}
                <MyButtons formik={formik} />
                <hr />

                <Row>
                  <Col className={`${layoutJodit == "MOBILE" && "col-6"}`}>
                    <Form.Group>
                      <label>Descripción</label>
                      <LayoutJodit
                        setLayoutJodit={setLayoutJodit}
                        layoutJodit={layoutJodit}
                      />
                      <JoditEditor
                        config={config}
                        value={formik.values.descripcion}
                        tabIndex={1} // tabIndex of textarea
                        onBlur={(newContent) =>
                          handleChangeJoiEditor(newContent, "descripcion")
                        }
                      />
                      <div className="text-danger">
                        {formik.errors?.descripcion}
                      </div>
                    </Form.Group>
                  </Col>
                </Row>

                {/* EQUIPO */}
                <Equipo formik={formik} />

                <br/><br/>
                <JefeServicio formik={formik} />

                <hr />

                {/* SUB-ESPECIALIDAD */}
                <Subespecialidad
                  formik={formik}
                  setShowModal={setShowModal}
                  showModal={showModal}
                />

              <hr />
              
              <Practica
                formik={formik}
                showModal={showModalPractica}
                setShowModal={setShowModalPractica}
              />


                <Row className="mt-4">
                  <Col className="col col-lg-3">
                    <Button
                      type="button"
                      onClick={() => {
                        if (Object.keys(formik.errors).length !== 0) {
                          AlertInfo(
                            "Debe completar todos los datos obligatorios para poder continuar."
                          );
                        } else {
                          formik.handleSubmit();
                        }
                      }}
                      disabled={isLoading || !canPublish}
                      className="btn btn-success btn-icon"
                    >
                      <i className="fa fa-floppy-o " />
                      Publicar
                    </Button>
                  </Col>
                  <Col className="col col-lg-3">
                    <Button
                      type="button"
                      onClick={() => {
                        if (Object.keys(formik.errors).length !== 0) {
                          AlertInfo(
                            "Debe completar todos los datos obligatorios para poder continuar."
                          );
                        } else {
                          onCancelar(formik.values);
                        }
                      }}
                      disabled={isLoading}
                      className="btn btn-info btn-icon"
                    >
                      <i className="fa fa-undo " />
                      Por revisar
                    </Button>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </form>
    </React.Fragment>
  );
};
export default VerEspecialidadX;
