/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { Row, Col, Card, Button, Accordion } from "react-bootstrap";
import { Fetch, catchError } from "../../../utils/fetch";
import { useHistory, useParams } from "react-router-dom";
import { connect } from "react-redux";
import { Formik, FieldArray } from "formik";
import { loadingAction } from "../../../redux/actions";
import { AlertSuccess } from "../../../components/alert";
import { EstadoContenido } from "../../../utils/constants";
import useDragMenu from "../../../hooks/useDragMenu";

function VerParaEliminarMenu({
  token,
  isLoading,
  isLoadingAction,
  permissions,
}) {
  const params = useParams();
  const { id } = params;
  const history = useHistory();
  const [formValues, setFormValues] = useState({});

  const { handleDragOver, handleDragStart, handleDrop, sortArray } =
    useDragMenu(setFormValues);

  React.useEffect(() => {
    if (id) {
      getMenusById(id);
    }
  }, [id]);

  const getMenusById = async (id) => {
    isLoadingAction(true);
    try {
      const { data } = await Fetch("menus/" + id + "?_eliminado=true", "get");

      isLoadingAction(false);

      sortArray(data.Menu);
      setFormValues(data);
    } catch (error) {
      isLoadingAction(false);
      catchError(error);
    }
  };

  const initialValues = {
    locale: "es",
    Menu: {
      locales: "es",
      Menu: {
        nombre: "",
        url: "#",
        esBoton: false,
        onClick: null,
        SubmenuColumna1: [
          {
            nombre: "",
            url: "#",
            esBoton: false,
            onClick: null,
          },
        ],
        SubmenuColumna2: [
          {
            nombre: "",
            url: "#",
            esBoton: false,
            onClick: null,
          },
        ],
      },
    },
  };

  const submit = async (values, type) => {
    isLoadingAction(true);

    try {
      const { data } = await Fetch("menus/" + id, "delete", values);

      isLoadingAction(false);
      AlertSuccess("Menú eliminado con éxito");

      history.push("/menus/lista");
    } catch (error) {
      isLoadingAction(false);
      catchError(error);
    }
  };

  const onCancelar = async (values, type = null) => {
    isLoadingAction(true);

    try {
      const params = {
        eliminado: false,
        published_at:
          values.estado === EstadoContenido.EnRevision
            ? null
            : values.published_at,
      };

      const { data } = await Fetch("menus/" + id, "put", params);

      isLoadingAction(false);
      AlertSuccess("Se ha cancelado la eliminación del menú con éxito");

      if (values.estado === EstadoContenido.EnRevision) {
        history.push("/menus/en-revision");
      } else {
        history.push("/menus/lista");
      }
    } catch (error) {
      isLoadingAction(false);
      catchError(error);
    }
  };

  return (
    <>
      <Formik
        initialValues={formValues || initialValues}
        enableReinitialize
        onSubmit={(values, { setSubmitting }) => {
          // setTimeout(() => {
          //   alert(JSON.stringify(values, null, 2));
          //   setSubmitting(false);
          // }, 400);
          submit(values);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
          /* and other goodies */
        }) => (
          <form onSubmit={handleSubmit}>
            <Row>
              <Col>
                <Card>
                  <Card.Header>Información del menú</Card.Header>
                  <Card.Body>
                    <Row>
                      <Col>
                        <div className="form-group">
                          <label>Nombre</label>
                          <input
                            type="text"
                            name="Menu.Menu.nombre"
                            className="form-control"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values?.Menu?.Menu?.nombre || ""}
                            disabled
                          />
                          {errors.nombre && touched.nombre && errors.nombre}
                        </div>
                      </Col>
                      <Col>
                        <div className="form-group">
                          <label>URL</label>
                          <input
                            type="text"
                            name="Menu.Menu.url"
                            className="form-control"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values?.Menu?.Menu?.url}
                            disabled
                          />
                          {errors.url && touched.url && errors.url}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className="form-group">
                          <label>¿Es botón?</label>
                          <select
                            className="form-control"
                            disabled
                            name="Menu.Menu.esBoton"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values?.Menu?.Menu?.esBoton}
                          >
                            <option value={true}>Si</option>
                            <option value={false}>No</option>
                          </select>
                          {errors.esBoton && touched.esBoton && errors.esBoton}
                        </div>
                      </Col>
                      <Col>
                        <div className="form-group">
                          <label>OnClick</label>
                          <input
                            type="text"
                            name="Menu.Menu.onClick"
                            className="form-control"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values?.Menu?.Menu?.onClick}
                            disabled
                          />
                          {errors.onClick && touched.onClick && errors.onClick}
                        </div>
                      </Col>
                    </Row>
                    {/* Columna1 */}
                    <Row>
                      <Col>
                        <h2>Submenu Columna1</h2>
                        <FieldArray name="Menu.SubmenuColumna1">
                          {({
                            move,
                            swap,
                            push,
                            remove,
                            insert,
                            unshift,
                            pop,
                            form,
                          }) => {
                            return (
                              <>
                                {values?.Menu?.SubmenuColumna1 &&
                                  values?.Menu?.SubmenuColumna1.length > 0 &&
                                  values?.Menu?.SubmenuColumna1.map(
                                    (data, index) => {
                                      return (
                                        <Row key={index}>
                                          <Col>
                                            <Accordion>
                                              <Card
                                                className="border mb-2"
                                                draggable
                                                onDragStart={(e) =>
                                                  handleDragStart(e, index)
                                                }
                                                onDragOver={handleDragOver}
                                                onDrop={(e) =>
                                                  handleDrop(
                                                    e,
                                                    index,
                                                    formValues,
                                                    "SubmenuColumna1"
                                                  )
                                                }
                                              >
                                                <Card.Header>
                                                  <i
                                                    class="fad fa-grip-vertical"
                                                    style={{
                                                      cursor: "all-scroll",
                                                    }}
                                                  />
                                                  <Accordion.Toggle
                                                    as={Button}
                                                    variant="link"
                                                    eventKey="0"
                                                  >
                                                    {
                                                      values.Menu
                                                        .SubmenuColumna1[index]
                                                        ?.nombre
                                                    }
                                                  </Accordion.Toggle>
                                                  <button
                                                    type="button"
                                                    className="btn btn-default"
                                                    style={{ float: "right" }}
                                                    onClick={() =>
                                                      remove(index)
                                                    }
                                                  >
                                                    X
                                                  </button>
                                                </Card.Header>
                                                <Accordion.Collapse eventKey="0">
                                                  <Card.Body>
                                                    {/* <Row>
                                            <Col>
                                              <div className="form-group">
                                                <label>OnClick</label>
                                                <Field name={`SubmenuColumna1.${index}.nombre`} placeholder="" className="form-control" type="text"/>
                                              </div>
                                            </Col>
                                          </Row> */}
                                                    <Row>
                                                      <Col>
                                                        <div className="form-group">
                                                          <label>Nombre</label>
                                                          <input
                                                            type="text"
                                                            name={`Menu.SubmenuColumna1.${index}.nombre`}
                                                            className="form-control"
                                                            onChange={
                                                              handleChange
                                                            }
                                                            onBlur={handleBlur}
                                                            disabled
                                                            value={
                                                              values?.Menu
                                                                .SubmenuColumna1[
                                                                index
                                                              ]?.nombre
                                                            }
                                                          />
                                                          {errors.nombre &&
                                                            touched.nombre &&
                                                            errors.nombre}
                                                        </div>
                                                      </Col>
                                                      <Col>
                                                        <div className="form-group">
                                                          <label>URL</label>
                                                          <input
                                                            type="text"
                                                            name={`Menu.SubmenuColumna1.${index}.url`}
                                                            className="form-control"
                                                            onChange={
                                                              handleChange
                                                            }
                                                            onBlur={handleBlur}
                                                            disabled
                                                            value={
                                                              values.Menu
                                                                .SubmenuColumna1[
                                                                index
                                                              ].url
                                                            }
                                                          />
                                                          {errors.url &&
                                                            touched.url &&
                                                            errors.url}
                                                        </div>
                                                      </Col>
                                                    </Row>
                                                    <Row>
                                                      <Col>
                                                        <div className="form-group">
                                                          <label>
                                                            ¿Es botón?
                                                          </label>
                                                          <select
                                                            className="form-control"
                                                            disabled
                                                            name={`Menu.SubmenuColumna1.${index}.esBoton`}
                                                            onChange={
                                                              handleChange
                                                            }
                                                            onBlur={handleBlur}
                                                            value={
                                                              values.Menu
                                                                .SubmenuColumna1[
                                                                index
                                                              ].esBoton
                                                            }
                                                          >
                                                            <option
                                                              value={true}
                                                            >
                                                              Si
                                                            </option>
                                                            <option
                                                              value={false}
                                                            >
                                                              No
                                                            </option>
                                                          </select>
                                                          {errors.esBoton &&
                                                            touched.esBoton &&
                                                            errors.esBoton}
                                                        </div>
                                                      </Col>
                                                      <Col>
                                                        <div className="form-group">
                                                          <label>OnClick</label>
                                                          <input
                                                            type="text"
                                                            name={`Menu.SubmenuColumna1.${index}.onClick`}
                                                            className="form-control"
                                                            onChange={
                                                              handleChange
                                                            }
                                                            onBlur={handleBlur}
                                                            disabled
                                                            value={
                                                              values.Menu
                                                                .SubmenuColumna1[
                                                                index
                                                              ].onClick
                                                            }
                                                          />
                                                          {errors.onClick &&
                                                            touched.onClick &&
                                                            errors.onClick}
                                                        </div>
                                                      </Col>
                                                    </Row>
                                                  </Card.Body>
                                                </Accordion.Collapse>
                                              </Card>
                                            </Accordion>
                                          </Col>
                                        </Row>
                                      );
                                    }
                                  )}
                                <button
                                  type="button"
                                  disabled
                                  className="btn btn-secondary"
                                  onClick={() =>
                                    push({
                                      nombre: "nombreMenu",
                                      url: "#",
                                      esBoton: null,
                                      onClick: null,
                                    })
                                  }
                                >
                                  Añadir Item
                                </button>
                              </>
                            );
                          }}
                        </FieldArray>
                      </Col>
                      <Col>
                        <h2>Submenu Columna2</h2>
                        <FieldArray name="Menu.SubmenuColumna2">
                          {({
                            move,
                            swap,
                            push,
                            remove,
                            insert,
                            unshift,
                            pop,
                            form,
                          }) => {
                            return (
                              <>
                                {values?.Menu?.SubmenuColumna2 &&
                                  values?.Menu?.SubmenuColumna2.length > 0 &&
                                  values?.Menu?.SubmenuColumna2.map(
                                    (data, index) => {
                                      return (
                                        <Row key={index}>
                                          <Col>
                                            <Accordion>
                                              <Card
                                                className="border mb-2"
                                                draggable
                                                onDragStart={(e) =>
                                                  handleDragStart(e, index)
                                                }
                                                onDragOver={handleDragOver}
                                                onDrop={(e) =>
                                                  handleDrop(
                                                    e,
                                                    index,
                                                    formValues,
                                                    "SubmenuColumna2"
                                                  )
                                                }
                                              >
                                                <Card.Header>
                                                  <i
                                                    class="fad fa-grip-vertical"
                                                    style={{
                                                      cursor: "all-scroll",
                                                    }}
                                                  />
                                                  <Accordion.Toggle
                                                    as={Button}
                                                    variant="link"
                                                    eventKey="0"
                                                  >
                                                    {
                                                      values.Menu
                                                        .SubmenuColumna2[index]
                                                        ?.nombre
                                                    }
                                                  </Accordion.Toggle>
                                                  <button
                                                    type="button"
                                                    className="btn btn-default"
                                                    style={{ float: "right" }}
                                                    onClick={() =>
                                                      remove(index)
                                                    }
                                                  >
                                                    X
                                                  </button>
                                                </Card.Header>
                                                <Accordion.Collapse eventKey="0">
                                                  <Card.Body>
                                                    {/* <Row>
                                            <Col>
                                              <div className="form-group">
                                                <label>OnClick</label>
                                                <Field name={`SubmenuColumna1.${index}.nombre`} placeholder="" className="form-control" type="text"/>
                                              </div>
                                            </Col>
                                          </Row> */}
                                                    <Row>
                                                      <Col>
                                                        <div className="form-group">
                                                          <label>Nombre</label>
                                                          <input
                                                            type="text"
                                                            name={`SubmenuColumna2.${index}.nombre`}
                                                            className="form-control"
                                                            onChange={
                                                              handleChange
                                                            }
                                                            onBlur={handleBlur}
                                                            disabled
                                                            value={
                                                              values.Menu
                                                                ?.SubmenuColumna2[
                                                                index
                                                              ]?.nombre
                                                            }
                                                          />
                                                          {errors.nombre &&
                                                            touched.nombre &&
                                                            errors.nombre}
                                                        </div>
                                                      </Col>
                                                      <Col>
                                                        <div className="form-group">
                                                          <label>URL</label>
                                                          <input
                                                            type="text"
                                                            name={`SubmenuColumna2.${index}.url`}
                                                            className="form-control"
                                                            onChange={
                                                              handleChange
                                                            }
                                                            onBlur={handleBlur}
                                                            disabled
                                                            value={
                                                              values.Menu
                                                                .SubmenuColumna2[
                                                                index
                                                              ].url
                                                            }
                                                          />
                                                          {errors.url &&
                                                            touched.url &&
                                                            errors.url}
                                                        </div>
                                                      </Col>
                                                    </Row>
                                                    <Row>
                                                      <Col>
                                                        <div className="form-group">
                                                          <label>
                                                            ¿Es botón?
                                                          </label>
                                                          <select
                                                            className="form-control"
                                                            disabled
                                                            name={`SubmenuColumna2.${index}.esBoton`}
                                                            onChange={
                                                              handleChange
                                                            }
                                                            onBlur={handleBlur}
                                                            value={
                                                              values.Menu
                                                                .SubmenuColumna2[
                                                                index
                                                              ].esBoton
                                                            }
                                                          >
                                                            <option
                                                              value={true}
                                                            >
                                                              Si
                                                            </option>
                                                            <option
                                                              value={false}
                                                            >
                                                              No
                                                            </option>
                                                          </select>
                                                          {errors.esBoton &&
                                                            touched.esBoton &&
                                                            errors.esBoton}
                                                        </div>
                                                      </Col>
                                                      <Col>
                                                        <div className="form-group">
                                                          <label>OnClick</label>
                                                          <input
                                                            type="text"
                                                            name={`SubmenuColumna2.${index}.onClick`}
                                                            className="form-control"
                                                            onChange={
                                                              handleChange
                                                            }
                                                            onBlur={handleBlur}
                                                            disabled
                                                            value={
                                                              values.Menu
                                                                .SubmenuColumna2[
                                                                index
                                                              ].onClick
                                                            }
                                                          />
                                                          {errors.onClick &&
                                                            touched.onClick &&
                                                            errors.onClick}
                                                        </div>
                                                      </Col>
                                                    </Row>
                                                  </Card.Body>
                                                </Accordion.Collapse>
                                              </Card>
                                            </Accordion>
                                          </Col>
                                        </Row>
                                      );
                                    }
                                  )}
                                <button
                                  type="button"
                                  disabled
                                  className="btn btn-secondary"
                                  onClick={() =>
                                    push({
                                      nombre: "nombreMenu",
                                      url: "#",
                                      esBoton: null,
                                      onClick: null,
                                    })
                                  }
                                >
                                  Añadir Item
                                </button>
                              </>
                            );
                          }}
                        </FieldArray>
                      </Col>
                    </Row>
                    <Row className="mt-5">
                      <Col className="col col-lg-2">
                        <Button
                          type="button"
                          onClick={() => handleSubmit()}
                          disabled={isLoading}
                          className="btn btn-danger btn-icon"
                        >
                          <i className="fas fa-trash" />
                          Eliminar
                        </Button>
                      </Col>
                      <Col className="col col-lg-2">
                        <Button
                          type="button"
                          onClick={() => onCancelar(values)}
                          disabled={isLoading}
                          className="btn btn-primary btn-icon"
                        >
                          <i className="fas fa-undo" />
                          Cancelar
                        </Button>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </form>
        )}
      </Formik>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.user.data,
    token: state.user.token,
    isLoading: state.loading,
    permissions: state.permissions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    isLoadingAction: (data) => {
      dispatch(loadingAction(data));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VerParaEliminarMenu);
