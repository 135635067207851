import React, { useMemo } from "react";
import { Form, Row, Col, Button, Card } from "react-bootstrap";
import JoditEditor, { Jodit } from "jodit-react";
import { useFormik } from "formik";
import { Fetch, catchError } from "../../../utils/fetch";
import { useDispatch, useSelector } from "react-redux";
import { AlertSuccess, AlertInfo } from "../../../components/alert";
import { loadingAction } from "../../../redux/actions";
import { useHistory, useParams } from "react-router-dom";
import MediaUploader from "../../../utils/media-uploader";
import { BASE_URL, EstadoContenido } from "../../../utils/constants";
import { validateModel } from "../nuevo/validationSchema";
import { formatDate } from "../../../utils/formatDate";
import Select from "react-select";
import meses from "./../../../data/meses.json";

const VerPrensa = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const isLoading = useSelector((state) => state.loading);
  const permissions = useSelector((state) => state.permissions);
  const canPublish = permissions.prensa.aprobar.enabled;
  const [textoRecomienda, setTextoRecomienda] = React.useState(
    "Se recomienda una imagen de 1400px de ancho x 960px de alto. En formato JPG o PNG."
  );
  const [alto, setAlto] = React.useState(300);
  const user = useSelector((state) => state.user.data);

  const [medio, setMedio] = React.useState();
  const [medios, setMedios] = React.useState([]);
  const userType = useSelector((state) => state.user.data.role.type);

  const currentYear = new Date().getFullYear();
  const anios = Array.from(Array(11).keys())
    .map((value) => currentYear - 10 + value)
    .map((item) => {
      return { value: `${item}`, label: item };
    })
    .reverse();

  React.useEffect(() => {
    getMedios();
  }, []);

  const getMedios = async () => {
    const prensas = await Fetch(`prensas/medios/all`, "get");

    let mediosExternos = prensas.data.medios.filter(item=>item!=null).map((item) => {      
      return { label: item, value: item };
    });

    // Crear un conjunto temporal para almacenar valores únicos
    const uniqueSet = new Set();

    // Filtrar y agregar elementos únicos al conjunto
    mediosExternos = mediosExternos.filter((item) => {
      const isUnique = !uniqueSet.has(item.value);
      uniqueSet.add(item.value);
      return isUnique;
    });
    setMedios([...medios, ...mediosExternos]);
  };

  React.useEffect(() => {
    if (id) {
      getPrensasById();
    }
  }, [id]);

  const getPrensasById = async (values) => {
    dispatch(loadingAction(true));

    try {
      const { data } = await Fetch(`prensas/${id}`, "get");
      data.mes = meses.find((item) => item.value === data.fecha.split("/")[0]);
      data.anio = anios.find((item) => item.value === data.fecha.split("/")[1]);

      setMedios([...medios, { label: data.medio, value: data.medio }]);
      setValues(data);
      dispatch(loadingAction(false));
    } catch (error) {
      catchError(error);
      dispatch(loadingAction(false));
    }
  };

  const submit = async (values) => {
    dispatch(loadingAction(true));

    values.published_at = null;
    values.idPadre = values.id;
    values.estado = EstadoContenido.EnRevision;

    values.fecha = values.mes.value + "/" + values.anio.value;
    delete values.anio;
    delete values.mes;

    if (typeof values.medio != "string") {
      values.medio = values.medio.value;
    }

    try {
      const { data } = await Fetch("prensas", "post", values);
      let message = "Contenido prensa actualizado con éxito.";
      if (userType == "creador") {
        message =
          "Contenido prensa actualizado con éxito.<br/>Recuerde que falta su confirmacion para aprobar.<br/> Nota: Por favor, notificar al responsable de publicar";
      }
      AlertInfo(message);

      dispatch(loadingAction(false));
      history.push("/prensa/lista");
    } catch (error) {
      catchError(error);
      dispatch(loadingAction(false));
    }
  };

  const customStyles = {
    padding: "0px",
    option: (provided, state) => ({
      ...provided,
      borderRadius: "20px",
    }),
    singleValue: (provided) => ({
      ...provided,
      margin: "0px",
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: "0px",
      paddingLeft: "10px",
    }),
    control: (provided) => ({
      ...provided,
      boxShadow: "none",
      width: 150,
    }),
    input: (provided, state) => ({
      ...provided,
    }),
    menuList: (provided, state) => ({
      ...provided,
    }),
    menu: (provided, state) => ({
      ...provided,
    }),
    indicatorSeparator: (provided, state) => ({
      ...provided,
      display: "none", // Oculta la flecha
    }),
    placeholder: (provided, state) => ({
      ...provided,
    }),
  };

  const customStylesMedio = {
    padding: "0px",
    option: (provided, state) => ({
      ...provided,
    }),
    singleValue: (provided) => ({
      ...provided,
      margin: "0px",
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: "0px",
      paddingLeft: "10px",
    }),
    control: (provided) => ({
      ...provided,
      boxShadow: "none",
      // width:150
    }),
    input: (provided, state) => ({
      ...provided,
    }),
    menuList: (provided, state) => ({
      ...provided,
    }),
    menu: (provided, state) => ({
      ...provided,
    }),
    indicatorSeparator: (provided, state) => ({
      ...provided,
      display: "none", // Oculta la flecha
    }),
    placeholder: (provided, state) => ({
      ...provided,
    }),
  };

  const inputChangeMedio = (value) => {};

  const blurMedio = (e) => {
    let { value } = e.target;
    if (value === "") return;

    let exists = medios.some((item) => item.value === value);

    if (exists) return;

    setMedio({ label: value, value: value });
    setFieldValue("medio", value);
    setMedios([...medios, { label: value, value: value }]);
  };

  const {
    setValues,
    values,
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue,
    errors,
    touched,
    setErrors,
  } = useFormik({
    initialValues: {
      titulo: "",
      anio: "",
      mes: "",
      medio: "",
      url: "",
      fecha: "",
      created_by: user.id,
      updated_by: user,
    },
    enableReinitialize: true,
    validationSchema: validateModel,
    validate: ({ anio, mes }) => {
      const errors = {};
      if (medio === "") errors.medio = "El medio no debe estar vacio";

      if (anio === "") {
        errors.anio = "El año no debe estar vacio";
      }

      if (mes === "") errors.mes = "El mes no debe estar vacio";

      return errors;
    },
    onSubmit: (values) => submit(values),
  });

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit}>
        <Row>
          <Col>
            <Card>
              <Card.Header>
                <Row>
                  <Col>Contenido de prensa</Col>
                </Row>
              </Card.Header>
              <Card.Body>
                <Row>
                  <Col>
                    <h3>Información del contenido</h3>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group>
                      <label>Título del contenido de prensa</label>
                      <input
                        type="text"
                        name="titulo"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.titulo}
                        className="form-control "
                      />
                      {touched?.titulo && (
                        <div className="text-danger">{errors?.titulo}</div>
                      )}
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <Form.Group>
                      <label>Medio</label>
                      <Select
                        options={medios}
                        placeholder=""
                        styles={customStylesMedio}
                        // isClearable // Esta prop permite que el usuario borre la selección
                        // onBlurResetsInput={false}
                        onBlur={blurMedio}
                        onInputChange={inputChangeMedio}
                        onChange={(e) => {
                          setMedio(e);
                          setFieldValue("medio", e.value);
                        }}
                        // onChange={handleChange}
                        // onBlur={handleBlur}
                        value={{ label: values.medio, value: values.medio }}
                        name="medio"
                      />
                      {touched?.medio && (
                        <div className="text-danger">{errors?.medio}</div>
                      )}
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <label>
                        Fecha de publicación del contenido de prensa
                      </label>
                      &nbsp;&nbsp;
                      <Select
                        options={meses}
                        placeholder="Mes"
                        styles={customStyles}
                        name="mes"
                        value={values.mes}
                        onChange={(e) => {
                          setFieldValue("mes", e);
                        }}
                      />
                      &nbsp;
                      <Select
                        options={anios}
                        placeholder="Año"
                        styles={customStyles}
                        name="anio"
                        value={values.anio}
                        onChange={(e) => {
                          setFieldValue("anio", e);
                        }}
                      />
                    </div>
                    {touched?.mes && errors?.mes && (
                      <div className="text-danger">{errors?.mes}</div>
                    )}
                    {touched?.anio && errors?.anio && (
                      <div className="text-danger">{errors?.anio}</div>
                    )}
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <Form.Group>
                      <label>Enlace</label>
                      <input
                        type="text"
                        name="url"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.url}
                        className="form-control"
                      />
                      {touched.url && (
                        <div className="text-danger">{errors?.url}</div>
                      )}
                    </Form.Group>
                  </Col>
                </Row>

                <Button
                  type="button"
                  onClick={() => handleSubmit()}
                  disabled={isLoading}
                  className="btn btn-success btn-icon mt-2"
                >
                  <i className="fa fa-floppy-o " />
                  Guardar
                </Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </form>
    </React.Fragment>
  );
};
export default VerPrensa;
