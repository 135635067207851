import * as Yup from 'yup';

export const validateModel = Yup.object().shape({

    direccion: Yup.string().required('Campo obligatorio'),
    localidad: Yup.string().required('Campo obligatorio'),
    provincia: Yup.string().required('Campo obligatorio'),
    colectivos: Yup.string().required('Campo obligatorio'),
    tren: Yup.string().required('Campo obligatorio'),
    info_contacto: Yup.string().required('Campo obligatorio'),
    iframe_mapa: Yup.string().required('Campo obligatorio'),
    nombre: Yup.string().required('Campo obligatorio'),
    imagen: Yup.string().required('Campo obligatorio'),  
    miniatura: Yup.string().required('Campo obligatorio'),   
    acordion: Yup.array(
        Yup.object().shape({
            nombre: Yup.string().required('Campo obligatorio'),
            items_acordion: Yup.array(
                Yup.object().shape({
                    nombre: Yup.string().required('Campo obligatorio'),                                
                })
            )  
        })
    )


});