import * as Yup from "yup";

export const validateModel = Yup.object().shape({
  nombre: Yup.string().trim().required("Campo obligatorio"),
  descripcion: Yup.string().trim().required("Campo obligatorio"),
  botones: Yup.array(
    Yup.object().shape({
      nombre: Yup.string().trim().required("Campo obligatorio"),
      url: Yup.string()
        .trim()
        .when("estado", {
          is: "visible",
          then: Yup.string().required("Campo obligatorio"),
          otherwise: Yup.string(),
        }),
    })
  ),
});

export const validateModelNuevaSubespecialidad = Yup.object().shape({
  nombre: Yup.string().trim().required("Campo obligatorio"),
  subespecialidad_item: Yup.array(
    Yup.object().shape({
      nombre: Yup.string().trim().required("Campo obligatorio"),
    })
  ),
});

export const validateModelPractica = Yup.object().shape({
  titulo: Yup.string().trim().required("Campo obligatorio"),
  //descripcion: Yup.string().trim().required("Campo obligatorio"),
});
