import { useEffect, useState } from "react";
import { Badge, Col, Form, Row } from "react-bootstrap";
import { loadingAction } from "../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { Fetch, catchError } from "../../utils/fetch";
import "./style.css";

const CategoriasComponent = ({
  categoriasAsociadas,
  subcategoriasSeleccionadas,
  setSubCategoriasSeleccionadas,
  setCategoriasAsociadas,
  seccion,
}) => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.loading);
  // const [subcategoriasMostradas,setSubcategoriasMostradas]=useState([]);

  useEffect(() => {
    getCategorias();
  }, []);

  const getCategorias = async (values) => {
    dispatch(loadingAction(true));

    try {
      const { data } = await Fetch(
        `categorias/subcategorias/all?seccion=${seccion}`,
        "get"
      );
      setCategoriasAsociadas(data);
      dispatch(loadingAction(false));
    } catch (error) {
      catchError(error);
      dispatch(loadingAction(false));
    }
  };

  const onChangeCategoria = (e, categoria) => {
    if (e.target.value === "all") {
      // let subcategoriasMostradasTemporal=subcategoriasMostradas.filter(item=>item.categoria!==categoria.id)
      // setSubcategoriasMostradas([...subcategoriasMostradasTemporal,{id:"all",categoria:categoria.id,nombre:"TODAS"}])

      let subcategoriaSeleccionadaTemporal = subcategoriasSeleccionadas.filter(
        (item) => item.categoria.id != categoria.id
      );
      let subcategoriasAsociadasTemporal = categoriasAsociadas.find(
        (item) => item.id == categoria.id
      ).subcategorias;
      setSubCategoriasSeleccionadas([
        ...subcategoriaSeleccionadaTemporal,
        ...subcategoriasAsociadasTemporal,
      ]);
      return;
    }

    if (
      subcategoriasSeleccionadas.some(
        (subcategoria) => subcategoria.id == e.target.value
      )
    ) {
      return;
    }

    let subCategoriaEncontrada = categoria.subcategorias.find(
      (subcategoria) => subcategoria.id == e.target.value
    );
    if (!subCategoriaEncontrada) {
      return;
    }

    setSubCategoriasSeleccionadas([
      ...subcategoriasSeleccionadas,
      subCategoriaEncontrada,
    ]);

    //setSubcategoriasMostradas([...subcategoriasSeleccionadas,subCategoriaEncontrada])
  };

  const eliminarSubcategoria = (id, idCategoria) => {
    let filtradas = null;
    if (id === "all") {
      filtradas = subcategoriasSeleccionadas.filter(
        (subcategoriasSeleccionadas) =>
          subcategoriasSeleccionadas.categoria.id !== idCategoria
      );
    } else {
      filtradas = subcategoriasSeleccionadas.filter(
        (subcategoriasSeleccionadas) => subcategoriasSeleccionadas.id != id
      );
    }

    setSubCategoriasSeleccionadas(filtradas);
  };

  return (
    <div className="contenedor-categorias">
      <Row>
        <Col>
          <h3>Categorías asociadas</h3>
        </Col>
      </Row>

      <Row>
        <Col>
          <label style={{ marginBottom: "20px", marginTop: "10px" }}>
            Se pueden seleccionar cuantas categorías se desee, y se debe de
            seleccionar al menos una.
          </label>
        </Col>
      </Row>
      {categoriasAsociadas.map((categoria) => {
        return (
          <Row>
            <Col>
              <Form.Group>
                <label>{categoria.nombre}</label>
                <select
                  className="form-control"
                  name={categoria.nombre}
                  onChange={(e) => onChangeCategoria(e, categoria)}
                  value={0}
                >
                  <option value="0" selected="selected">
                    --Seleccionar categoría--
                  </option>
                  {categoria.subcategorias?.filter(
                    (subcategoria) =>
                      !subcategoriasSeleccionadas.some(
                        (subcategoriaSeleccionada) =>
                          subcategoriaSeleccionada.id == subcategoria.id
                      )
                  ).length === 0 ? (
                    <></>
                  ) : (
                    <option value="all">TODAS</option>
                  )}
                  {categoria.subcategorias
                    ?.filter(
                      (subcategoria) =>
                        !subcategoriasSeleccionadas.some(
                          (subcategoriaSeleccionada) =>
                            subcategoriaSeleccionada.id == subcategoria.id
                        )
                    )
                    .map((subcategoria) => {
                      return (
                        <option value={subcategoria.id}>
                          {subcategoria.nombre}
                        </option>
                      );
                    })}
                </select>

                <div className="badges-subcategorias">
                  {categoria.subcategorias?.filter(
                    (subcategoria) =>
                      !subcategoriasSeleccionadas.some(
                        (subcategoriaSeleccionada) =>
                          subcategoriaSeleccionada.id == subcategoria.id
                      )
                  ).length === 0 ? (
                    <div style={{ padding: "4px 4px 4px 0px" }}>
                      <Badge
                        style={{ padding: "10px", fontSize: "13px" }}
                        variant="secondary"
                      >
                        <span>TODAS</span>{" "}
                        <span
                          onClick={(e) =>
                            eliminarSubcategoria("all", categoria.id)
                          }
                          style={{ marginLeft: "10px", cursor: "pointer" }}
                        >
                          X
                        </span>
                      </Badge>
                    </div>
                  ) : (
                    subcategoriasSeleccionadas
                      ?.filter((subcategoria) => {
                        if (typeof subcategoria.categoria === "number") {
                          return subcategoria.categoria == categoria.id;
                        } else {
                          return subcategoria.categoria.id == categoria.id;
                        }
                      })
                      ?.map((subcategorias) => {
                        return (
                          <div style={{ padding: "4px 4px 4px 0px" }}>
                            <Badge
                              style={{ padding: "10px", fontSize: "13px" }}
                              variant="secondary"
                            >
                              <span>{subcategorias.nombre}</span>{" "}
                              <span
                                onClick={(e) =>
                                  eliminarSubcategoria(
                                    subcategorias.id,
                                    categoria.id
                                  )
                                }
                                style={{
                                  marginLeft: "10px",
                                  cursor: "pointer",
                                }}
                              >
                                X
                              </span>
                            </Badge>
                          </div>
                        );
                      })
                  )}
                </div>
              </Form.Group>
            </Col>
          </Row>
        );
      })}
    </div>
  );
};

export default CategoriasComponent;
