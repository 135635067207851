import types from "../types";

export const loginAction = (token, user) => {
  return {
    type: types.login,
    payload: {
      token,
      user,
    },
  };
};

export const logoutAction = (token) => {
  return {
    type: types.logout,
    payload: null,
  };
};

export const loadingAction = (data) => {
  return {
    type: types.isLoading,
    payload: data,
  };
};

export const permissionsAction = (data) => {
  return {
    type: types.permissions,
    payload: data,
  };
};

export const categoriaAction = (data) => {
  return {
    type: types.categoria,
    payload: data,
  };
};

export const especialistasAction = (data) => {
  return {
    type: types.especialistas,
    payload: data,
  };
};

export const subespecialidadAction = (data) => {
  return {
    type: types.subespecialidad,
    payload: data,
  };
};

export const practicaAction = (data) => {    
  return {
    type: types.practicas,
    payload: data,
  };
};
