import React, { useMemo, useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { Fetch, catchError } from "../../../utils/fetch";
import JoditEditor from "jodit-react";
import { FieldArray, Formik, useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { AlertSuccess, AlertInfo } from "../../alert";
import { loadingAction } from "../../../redux/actions";
import { useHistory } from "react-router-dom";
import MediaUploader from "../../../utils/media-uploader";
import { validateModel } from "./validationSchema";
import { BASE_URL, EstadoContenido } from "../../../utils/constants";
import { removeSpecial } from "../../../utils/tools";
import LayoutJoditList from "../../customizer/layout-jodit-list";
import DraggableItem, { sortArray } from "../../customizer/draggable-item";

const Template2Component = ({ dataResult, accion = "" }) => {
  const dispatch = useDispatch();
  const [formFields, setFormFields] = React.useState([]);
  const isLoading = useSelector((state) => state.loading);
  const [layoutJoditList, setLayoutJoditList] = React.useState([]);
  const [showOrder, setShowOrder] = useState(false);
  const [pagesOrder, setPagesOrder] = useState([]);
  const history = useHistory();
  const permissions = useSelector((state) => state.permissions);
  const canPublish = permissions.paginas.aprobar.enabled;

  const config = useMemo(
    () => ({
      readonly: false, // all options from https://xdsoft.net/jodit/doc/
      language: "es",
      disablePlugins: ["table"],
      disabled: accion === "eliminar" ? true : false,
      askBeforePasteHTML: false,
      askBeforePasteFromWord: false,
      defaultActionOnPaste: "insert_clear_html",
      enableDragAndDropFileToEditor: true,
      uploader: {
        url: BASE_URL + "/upload",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("jwt_token"),
        },
        data: null,
        filesVariableName: function (i) {
          return "files";
        },
        withCredentials: false,
        pathVariableName: "path",
        format: "json",
        method: "POST",
        prepareData: function (formData) {
          formData.append("id", 1);
        },
        isSuccess: function (resp) {
          return !resp.error;
        },
        getMessage: function (resp) {
          return resp.msgs.join("\n");
        },
        process: function (resp) {
          return resp;
        },
        defaultHandlerSuccess: function (resp) {
          var imagenesPermitidas = ["image/jpeg", "image/png"];
          for (var i = 0; i < resp.length; i++) {
            if (imagenesPermitidas.indexOf(resp[i].mime) !== -1) {
              var img1 = new Image();
              img1.src = resp[i]["url"];
              img1.alt = "imagen";
              img1.className = "img-fluid";
              this.s.insertImage(img1);
            } else if (resp[i].mime === "application/pdf") {
              const enlace = document.createElement("a");
              enlace.setAttribute("href", resp[i].url);
              const contenido = document.createTextNode(resp[i].name);
              // add the text node to the newly created div
              enlace.appendChild(contenido);
              this.s.insertNode(enlace);
            } else {
              AlertInfo("Tipo de archivo no permitido");
            }
          }
        },
      },
    }),
    []
  );

  React.useEffect(() => {
    if (dataResult) {
      setValues(dataResult);
      setFormFields(dataResult.template2.sidemenu);
      sortArray(dataResult.template2.sidemenu);

      const count = dataResult.template2.sidemenu.length;
      const aux = [];

      for (let i = 0; i < count; i++) {
        aux.push("PC");
      }

      setLayoutJoditList(aux);
    }
  }, [dataResult]);

  const orderTemplate2Sidemenu = (sidemenu) => {
    sidemenu.forEach((item) => {
      const sideOrder = pagesOrder.find((x) => x.id == item.id);
      if (sideOrder) {
        item.order = sideOrder.order;
      }
    });
  };

  const submit = async (values, type) => {
    if (accion === "eliminar") {
      dispatch(loadingAction(true));
      try {
        const { data } = await Fetch("paginas/" + dataResult.id, "delete");
        dispatch(loadingAction(false));
        AlertSuccess("Página eliminada con éxito");
        history.push("/pages/lista-por-eliminar");
      } catch (error) {
        catchError(error);
        dispatch(loadingAction(false));
      }
    } else {
      dispatch(loadingAction(true));
      orderTemplate2Sidemenu(values.template2.sidemenu);

      values.published_at = new Date();
      values.enAprobacion = false;

      delete values.template2.id;

      values.template2.sidemenu = values.template2.sidemenu.map((item) => {
        item.contenido = removeSpecial(item.contenido);
        return item;
      });

      try {
        const { data } = await Fetch(
          "paginas/aprobar/" +
            (values.idPadre === null ? dataResult.id : values.idPadre),
          "put",
          values
        );

        dispatch(loadingAction(false));

        AlertSuccess("Página actualizada con éxito");

        history.push("/pages/lista");
      } catch (error) {
        dispatch(loadingAction(false));
        catchError(error);
      }
    }
  };

  const onCancelar = async (values, type = null) => {
    try {
      dispatch(loadingAction(true));

      if (accion === "eliminar") {
        const params = {
          eliminado: false,
          published_at:
            values.estado === EstadoContenido.EnRevision
              ? null
              : values.published_at,
        };

        const { data } = await Fetch("paginas/" + dataResult.id, "put", params);

        dispatch(loadingAction(false));
        AlertSuccess("Se ha cancelado la eliminación de la página con éxito");

        //history.push("/pages/lista-por-eliminar");

        if (values.estado === EstadoContenido.EnRevision) {
          history.push("/pages/lista-borradores");
        } else {
          history.push("/pages/lista");
        }
      } else {
        //por aprobación
        const params = {
          published_at: null,
          estado: EstadoContenido.EnRevision,
        };

        const { data } = await Fetch("paginas/" + dataResult.id, "put", params);

        dispatch(loadingAction(false));
        AlertSuccess("Se ha cancelado la aprobación de la página con éxito");

        history.push("/pages/lista-borradores");
      }
    } catch (error) {
      dispatch(loadingAction(false));
      catchError(error);
    }
  };

  const { setValues } = useFormik({
    initialValues: {
      nombre: "",
      slug: "",
      template: "template2",
      template2: {
        fondo: "",
        sidemenu: [],
      },
      locale: "es",
    },
    validationSchema: validateModel,
    enableReinitialize: true,
    onSubmit: (values) => submit(values),
  });

  const handleBlurTitulo = async (event, setFieldValue) => {
    const value = event.target.value;
    const name = event.target.name;
    const dataToApi = {
      contentTypeUID: "application::paginas.paginas",
      field: "slug",
      data: {
        nombre: value,
        slug: "",
      },
    };
    setFieldValue(name, value, false);
    try {
      const { data } = await Fetch(
        "content-manager/uid/generate",
        "post",
        dataToApi
      );
      setFieldValue("slug", data.data, false);
    } catch (error) {
      console.error("mostrando error", error);
    }
  };

  const handleLayoutJodit = (index, device) => {
    const data = [...layoutJoditList];
    data[index] = device;
    setLayoutJoditList(data);
  };

  const handleAgregarItim = (push) => {
    setLayoutJoditList((val) => [...val, "PC"]);
    push({ nombre: "", contenido: "" });
  };

  const handleSort = () => {
    setShowOrder(!showOrder);
  };

  return (
    <React.Fragment>
      <DraggableItem
        show={showOrder}
        setShow={handleSort}
        pagesList={dataResult?.template2?.sidemenu}
        items={pagesOrder}
        setItems={setPagesOrder}
      />
      {dataResult && (
        <Formik
          initialValues={dataResult}
          validationSchema={validateModel}
          onSubmit={(values, { setSubmitting }) => {
            submit(values);
          }}
        >
          {({ values, errors, handleChange, handleSubmit, setFieldValue }) => (
            <form handleSubmit={handleSubmit}>
              <Row>
                <Col>
                  <h2>Información de la página</h2>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="form-group">
                    <label>Nombre de la página</label>
                    <input
                      type="text"
                      name="nombre"
                      disabled={accion === "eliminar" ? true : false}
                      onChange={handleChange}
                      onBlur={(e) => handleBlurTitulo(e, setFieldValue)}
                      value={values.nombre}
                      className="form-control "
                    />
                    <div className="text-danger">{errors?.nombre}</div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="form-group">
                    <label>Url</label>
                    <input
                      type="text"
                      name="slug"
                      disabled={accion === "eliminar" ? true : false}
                      className="form-control"
                      value={values.slug}
                      readOnly
                    />
                    {/* <div className="text-danger">{errors?.slug}</div> */}
                  </div>
                </Col>
              </Row>
              {values?.template2?.fondo && (
                <>
                  <Row>
                    <Col>
                      <div className="form-group">
                        <img
                          src={values.template2.fondo.url}
                          alt="vista-previa"
                          className="img-fluid w-50"
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Button
                        type="button"
                        style={{ marginBottom: "10px" }}
                        onClick={() =>
                          setFieldValue("template2.fondo", "", false)
                        }
                        className="btn btn-danger btn-icon mt-2"
                      >
                        <i className="fa fa-trash-o " />
                        Eliminar
                      </Button>
                    </Col>
                  </Row>
                </>
              )}
              {accion !== "eliminar" && (
                <Row>
                  <Col>
                    <div className="form-group">
                      <label>Fondo</label>
                      <MediaUploader
                        setFieldValue={setFieldValue}
                        field={"template2.fondo"}
                        tipoMedia="imagen"
                        ancho={1200}
                        alto={800}
                        value={values.template2.fondo ? 100 : 0}
                        key={values.template2.fondo}
                      />
                      <div className="text-danger">
                        {errors?.template2?.fondo}
                      </div>
                      <span className="text-muted">
                        Se recomienda una imagen de 1200px de ancho x 800px de
                        alto. En formato JPG o PNG.
                      </span>
                    </div>
                  </Col>
                </Row>
              )}
              <hr />
              <Row>
                <Col>
                  <h2>Información del contenido</h2>
                </Col>
              </Row>

              <Row>
                <Col>
                  {values && values.template2 && values.template2.sidemenu && (
                    <FieldArray name="template2.sidemenu">
                      {({ push, remove }) => {
                        return (
                          <>
                            {values.template2.sidemenu &&
                              values.template2.sidemenu.length > 0 &&
                              values.template2.sidemenu.map((data, index) => {
                                return (
                                  <Row key={index} className="border m-3">
                                    <Col
                                      lg={12}
                                      className="p-0 d-flex justify-content-end"
                                    >
                                      <button
                                        type="button"
                                        disabled={
                                          accion === "eliminar" ? true : false
                                        }
                                        className="btn btn-danger"
                                        onClick={() => remove(index)}
                                      >
                                        x
                                      </button>
                                    </Col>
                                    <Col lg={12}>
                                      <div className="form-group">
                                        <label>Nombre</label>
                                        <input
                                          type="text"
                                          disabled={
                                            accion === "eliminar" ? true : false
                                          }
                                          className="form-control"
                                          name={`template2.sidemenu[${index}].nombre`}
                                          onChange={handleChange}
                                          value={
                                            values?.template2?.sidemenu[index]
                                              ?.nombre
                                          }
                                        />
                                        {errors &&
                                          errors.template2 &&
                                          errors.template2.sidemenu &&
                                          errors.template2.sidemenu[index] &&
                                          errors.template2.sidemenu[index]
                                            .nombre && (
                                            <div className="text-danger">
                                              {
                                                errors?.template2?.sidemenu[
                                                  index
                                                ]?.nombre
                                              }
                                            </div>
                                          )}
                                      </div>
                                    </Col>
                                    <Col
                                      className={`${
                                        layoutJoditList[index] == "MOBILE" &&
                                        "col-6"
                                      }`}
                                    >
                                      <div className="form-group">
                                        <label>Contenido</label>
                                        <LayoutJoditList
                                          index={index}
                                          setLayoutJodit={handleLayoutJodit}
                                          layoutJodit={layoutJoditList[index]}
                                        />
                                        <JoditEditor
                                          config={config}
                                          value={
                                            values?.template2?.sidemenu[index]
                                              ?.contenido
                                          }
                                          tabIndex={1} // tabIndex of textarea
                                          onChange={handleChange}
                                          onBlur={(v) =>
                                            setFieldValue(
                                              `template2.sidemenu[${index}].contenido`,
                                              v,
                                              false
                                            )
                                          }
                                        />
                                        {errors &&
                                          errors.template2 &&
                                          errors.template2.sidemenu &&
                                          errors.template2.sidemenu[index] &&
                                          errors.template2.sidemenu[index]
                                            .contenido && (
                                            <div className="text-danger">
                                              {
                                                errors?.template2?.sidemenu[
                                                  index
                                                ]?.contenido
                                              }
                                            </div>
                                          )}
                                      </div>
                                    </Col>
                                  </Row>
                                );
                              })}
                            <button
                              type="button"
                              disabled={accion === "eliminar" ? true : false}
                              className="btn btn-secondary mr-5"
                              onClick={() => handleAgregarItim(push)}
                            >
                              Añadir Item
                            </button>
                            <Button
                              type="button"
                              onClick={handleSort}
                              className="btn btn-info btn-icon"
                            >
                              <i class="fa fa-sort"></i>
                              Ordenar items
                            </Button>
                          </>
                        );
                      }}
                    </FieldArray>
                  )}
                </Col>
              </Row>

              <Row className="mt-5">
                <Col className="col col-lg-2">
                  <Button
                    type="button"
                    onClick={() => handleSubmit()}
                    disabled={isLoading || (accion!=="Eliminar" && !canPublish)}
                    className={
                      accion === "eliminar"
                        ? "btn btn-danger btn-icon"
                        : "btn btn-success btn-icon"
                    }
                  >
                    <i
                      className={
                        accion === "eliminar" ? "fa fa-trash" : "fa fa-floppy-o"
                      }
                    />
                    {accion === "eliminar" ? "Eliminar" : "Publicar"}
                  </Button>
                </Col>
                <Col className="col col-lg-3">
                  <Button
                    type="button"
                    onClick={() => onCancelar(values)}
                    disabled={isLoading}
                    className="btn btn-primary btn-icon"
                  >
                    <i className="fa fa-undo " />
                    {accion === "eliminar" ? "Cancelar" : "Por revisar"}
                  </Button>
                </Col>
              </Row>
            </form>
          )}
        </Formik>
      )}
    </React.Fragment>
  );
};
export default Template2Component;
