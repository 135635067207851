import React from "react";
import { Row, Col, Modal } from "react-bootstrap";
import { FieldArray, FormikProvider } from "formik";

const ModalSubespecialidad = ({ showModal, setShowModal, formik }) => {
  return (
    <Modal size={"md"} show={showModal} centered>
      <Modal.Body>
        <form onSubmit={formik.handleSubmit}>
          <div className="mt-2">
            <Row>
              <Col>
                <label>Nueva sub-especialidad</label>
              </Col>
            </Row>
            <Col
              style={{
                marginTop: "15px",
                paddingTop: "15px",
                paddingBottom: "15px",
                overflow: "auto",
                maxHeight: "500px",
              }}
              className="border"
            >
              <Row>
                <Col>
                  <label>Título</label>
                  <input
                    name="nombre"
                    onChange={formik.handleChange}
                    className="form-control mb-3"
                    value={formik.values.nombre}
                  />
                  <div className="text-danger">{formik.errors?.nombre}</div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormikProvider value={formik}>
                    <FieldArray name="subespecialidad_item">
                      {({ push, remove }) => {
                        return (
                          <>
                            {formik.values?.subespecialidad_item &&
                              formik.values?.subespecialidad_item?.length > 0 &&
                              formik.values?.subespecialidad_item.map(
                                (data, index) => {
                                  return (
                                    <Row key={index}>
                                      <Col>
                                        <Row>
                                          <Col style={{ marginTop: "15px" }}>
                                            <div className="form-group">
                                              <label>Ítem {index + 1}</label>
                                              <div
                                                style={{
                                                  display: "flex",
                                                  alignItems: "center",
                                                }}
                                              >
                                                <input
                                                  type="text"
                                                  className="form-control"
                                                  name={`subespecialidad_item.${index}.nombre`}
                                                  onChange={formik.handleChange}
                                                  value={
                                                    formik.values
                                                      .subespecialidad_item[
                                                      index
                                                    ].nombre
                                                  }
                                                />
                                                <button
                                                  type="button"
                                                  className="btn btn-danger"
                                                  onClick={() => {
                                                    remove(index);
                                                  }}
                                                >
                                                  x
                                                </button>
                                              </div>
                                              <div className="text-danger">
                                                {formik.errors &&
                                                  formik.errors
                                                    .subespecialidad_item &&
                                                  formik.errors
                                                    .subespecialidad_item[
                                                    index
                                                  ] &&
                                                  formik.errors
                                                    .subespecialidad_item[index]
                                                    .nombre && (
                                                    <div className="text-danger">
                                                      {
                                                        formik.errors
                                                          ?.subespecialidad_item[
                                                          index
                                                        ]?.nombre
                                                      }
                                                    </div>
                                                  )}
                                              </div>
                                            </div>
                                          </Col>
                                        </Row>
                                      </Col>
                                    </Row>
                                  );
                                }
                              )}
                            <button
                              type="button"
                              className="btn btn-secondary"
                              onClick={() => {
                                push({ nombre: "" });
                              }}
                            >
                              Añadir Item
                            </button>
                          </>
                        );
                      }}
                    </FieldArray>
                  </FormikProvider>
                </Col>
              </Row>
            </Col>
          </div>

          <Row>
            <Col md={12}>
              <div className="mt-4">
                <div className="col-md-12" style={{ paddingBottom: "10px" }}>
                  <div className="d-flex justify-content-end">
                    <div
                      className="btn btn-danger"
                      onClick={() => {
                        // Elimina los valores guardados en formikNuevoDoctor
                        formik.setValues({
                          idSubespecialidad: "",
                          nombre: "",
                          subespecialidad_item: [],
                        });
                        setShowModal(false);
                      }}
                    >
                      Cancelar
                    </div>
                    <a
                      className="btn btn-success ml-2"
                      onClick={() => formik.handleSubmit()}
                    >
                      Guardar
                    </a>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default ModalSubespecialidad;
