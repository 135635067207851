/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Row, Col, Card, Table, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Fetch, catchError } from "../../../utils/fetch";
import { connect } from "react-redux";
import { loadingAction } from "../../../redux/actions";
import DataGridForm from "../../../components/common/DataGridForm";
import { formatDate } from "../../../utils/formatDate";
import { AlertConfirmDeleteAll } from "../../../components/alert";

function ListaPorEliminarAcceso({ isLoadingAction, isLoading }) {
  const [contenido, setContenido] = React.useState(null);

  React.useEffect(() => {
    getContenido();
  }, []);

  const getContenido = async () => {
    isLoadingAction(true);
    try {
      const { data } = await Fetch("nuestros-accesos?_eliminado=true", "get");
      setContenido(data);
      isLoadingAction(false);
    } catch (error) {
      console.error("mostrando error", error);
      isLoadingAction(false);
      catchError(error);
    }
  };

  function RenderAlert(title) {
    AlertConfirmDeleteAll(
      title,
      "warning",
      async (response) => {
        if (response.isConfirmed) {
          isLoadingAction(true);
          try {
            await Fetch("nuestros-accesos/delete/all", "DELETE");
            isLoadingAction(false);
          } catch (error) {
            console.error("mostrando error", error);
            isLoadingAction(false);
            catchError(error);
          }
          await getContenido();
        }
      },
      (title = "¡Atención!"),
      "Continuar",
      "Cancelar",
      true,
      '<div class="swal2-html-container" id="swal2-html-container">Estás por eliminar todo el contenido de esta carpeta y no será posible recuperarlo. <br> ¿Querés continuar?</div>'
    );
  }

  function FActualizacion(data) {
    return formatDate(data?.updated_at);
  }

  function ImagenAcceso(data) {
    return data?.imagenAcceso?.formats?.thumbnail?.url ? (
      <>
        <img
          src={data.imagenAcceso.formats.thumbnail.url}
          style={{
            width: "64px",
            height: "64px",
            borderRadius: "32px",
            objectFit: "cover",
          }}
          className=""
          alt="images"
        />
      </>
    ) : null;
  }

  function ImagenFondo(data) {
    return data?.imagenFondo?.formats?.thumbnail?.url ? (
      <>
        <img
          src={data.imagenFondo.formats.thumbnail.url}
          style={{
            width: "64px",
            height: "64px",
            borderRadius: "32px",
            objectFit: "cover",
          }}
          className=""
          alt="images"
        />
      </>
    ) : null;
  }

  function TextoFondo(data) {
    return data?.textoFondo?.formats?.thumbnail?.url ? (
      <>
        <img
          src={data.textoFondo.formats.thumbnail.url}
          style={{
            width: "64px",
            height: "64px",
            borderRadius: "32px",
            objectFit: "cover",
          }}
          className=""
          alt="images"
        />
      </>
    ) : null;
  }

  function Accion(data) {
    return (
      <>
        <Link
          to={"/nuestros-accesos/ver-para-eliminar/" + data.id}
          className="btn btn-sm btn btn-success"
        >
          <i className="fa fa-pencil" />
        </Link>{" "}
      </>
    );
  }

  return (
    <React.Fragment>
      <Row>
        <Col md="12">
          <Card>
            <Card.Header>
              <div className="float-right mt-10">
                {/* <Link
                  to="/pages/nuevo"
                  className="btn btn-primary btn-rounded box-shadow btn-icon"
                >
                  <i className="fa fa-plus" /> Nueva Página
                </Link> */}
                <Button
                  type="button"
                  //   onClick={() => handleSubmit()}
                  onClick={() => {
                    RenderAlert(
                      "Estás por eliminar todo el contenido de esta carpeta y no será posible recuperarlo. ¿Querés continuar?"
                    );
                  }}
                  disabled={isLoading}
                  className="btn btn-danger btn-icon btn-rounded"
                >
                  <i className="fa fa-trash" />
                  Vaciar carpeta
                </Button>
              </div>
              Listado de nuestros accesos por eliminar
            </Card.Header>
            <Card.Body>
              <DataGridForm
                dataSource={contenido}
                keyExpr="id"
                allowReordering={true}
                columnHidingEnabled={true}
                columns={[
                  { caption: "Id", dataField: "id" },
                  {
                    caption: "ImagenAcceso",
                    alignment: "center",
                    render: "ImagenAcceso",
                    cellRender: { ImagenAcceso },
                  },
                  {
                    caption: "ImagenFondo",
                    alignment: "center",
                    render: "ImagenFondo",
                    cellRender: { ImagenFondo },
                  },
                  {
                    caption: "TextoFondo",
                    alignment: "center",
                    render: "TextoFondo",
                    cellRender: { TextoFondo },
                  },
                  { caption: "Creado por", dataField: "creado_por.username" },
                  {
                    caption: "Actualizado por",
                    dataField: "actualizado_por.username",
                  },
                  {
                    caption: "Ult. Actualización",
                    render: "FActualizacion",
                    cellRender: { FActualizacion },
                  },
                  {
                    caption: "Acción",
                    alignment: "center",
                    render: "Accion",
                    cellRender: { Accion },
                    hidingPriority: 0,
                  },
                ]}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    isLoadingAction: (data) => {
      dispatch(loadingAction(data));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ListaPorEliminarAcceso);
