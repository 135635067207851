/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Form, Row, Col, Button, Card } from "react-bootstrap";
import { useFormik } from "formik";
import { Fetch, catchError } from "../../../../utils/fetch";
import { useDispatch, useSelector } from "react-redux";
import { AlertInfo, AlertSuccess } from "../../../../components/alert";
import { loadingAction } from "../../../../redux/actions";
import { useHistory, useParams } from "react-router-dom";
import MediaUploader from "../../../../utils/media-uploader";
import { EstadoContenido } from "../../../../utils/constants";

const VerBorradorHomeSlide = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const isLoading = useSelector((state) => state.loading);
  const [formFields, setFormFields] = React.useState([]);
  const permissions = useSelector((state) => state.permissions);
  const canPublish = permissions["home-slides"].aprobar.enabled;

  React.useEffect(() => {
    if (id) {
      getHomeSlideById();
    }
  }, [id]);

  const getHomeSlideById = async (values) => {
    dispatch(loadingAction(true));
    try {
      const { data } = await Fetch(
        "home-slides/" + id + "?_publicationState=preview",
        "get"
      );

      setValues(data);
      setFormFields(data.caption.botones);
      dispatch(loadingAction(false));
    } catch (error) {
      catchError(error);
      dispatch(loadingAction(false));
    }
  };

  const submit = async (values) => {
    dispatch(loadingAction(true));

    values.published_at = null;
    values.estado = EstadoContenido.EnRevision;

    try {
      await Fetch("home-slides/" + id, "put", values);

      //AlertInfo('Slide actualizado con éxito.<br/>Recuerde que falta su confirmación para aprobar.<br/> Nota: Por favor, notificar al responsable de publicar')
      history.push("/configuracion/home-slide/lista-borradores");

      dispatch(loadingAction(false));
    } catch (error) {
      catchError(error);
      dispatch(loadingAction(false));
    }
  };

  const {
    values,
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue,
    setValues,
  } = useFormik({
    initialValues: {
      tipo: "", //IMAGEN O VIDEO
      media: {},
      caption: {
        h1: "",
        h3: "",
        botones: [
          {
            nombre: "",
            url: "",
            estilo: "estilo1",
          },
        ],
      },
      enlace: "",
      enlaceInterno: false,
    },
    enableReinitialize: true,
    onSubmit: (values) => submit(values),
  });

  const addArray = () => {
    let object = { nombre: "", url: "", estilo: "" };
    setFormFields([...formFields, object]);
  };

  const removeArray = (index) => {
    let data = [...formFields];
    data.splice(index, 1);
    setFormFields(data);
  };

  const handleFormChange = (event, index) => {
    let data = [...formFields];
    data[index][event.target.name] = event.target.value;
    setFormFields(data);
  };

  const onEnviarAprobar = async (values) => {
    dispatch(loadingAction(true));

    values.estado = EstadoContenido.EnAprobacion;

    try {
      await Fetch("home-slides/" + id, "put", values);

      dispatch(loadingAction(false));

      AlertSuccess("Contenido enviado con éxito");
      history.push("/configuracion/home-slide/listax");
    } catch (error) {
      dispatch(loadingAction(false));
      catchError(error);
    }
  };

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit}>
        <Row>
          <Col>
            <Card>
              <Card.Header>
                <Row>
                  <Col>Información del slide</Col>
                </Row>
              </Card.Header>
              <Card.Body>
                <Row>
                  <Col>
                    <Form.Group>
                      <label>Tipo</label>
                      <select
                        name="tipo"
                        onChange={handleChange}
                        className="form-control"
                        value={values.tipo}
                      >
                        <option>Seleccionar</option>
                        <option value="VIDEO">VIDEO</option>
                        <option value="IMAGEN">IMAGEN</option>
                      </select>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group>
                      <label>Seleccionar archivo</label>
                      {values.tipo === "IMAGEN" ? (
                        <>
                          <MediaUploader
                            setFieldValue={setFieldValue}
                            field={"media"}
                            tipoMedia="imagen"
                            ancho={1200}
                            alto={600}
                          />
                          <span className="text-muted">
                            Se recomienda una imagen de 1200px de ancho x 600px
                            de alto. En formato JPG o PNG.
                          </span>
                          {/* <div className="text-danger">{errors?.media}</div> */}
                        </>
                      ) : (
                        <>
                          <MediaUploader
                            setFieldValue={setFieldValue}
                            field={"media"}
                            tipoMedia="video"
                            ancho={1920}
                            alto={1080}
                          />
                          <span className="text-muted">
                            Se recomienda un video de 1920px de ancho x 1080px
                            de alto. En formato MP4 ó WEBM.
                          </span>
                          {/* <div className="text-danger">{errors?.media}</div> */}
                        </>
                      )}
                    </Form.Group>
                  </Col>
                </Row>

                {values.tipo == "VIDEO" && (
                  <>
                    <Row>
                      <Col>
                        <Form.Group>
                          <label>H1 Texto</label>
                          <input
                            type="text"
                            name="caption.h1"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className="form-control"
                            value={values.caption.h1}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group>
                          <label>H3 Texto</label>
                          <input
                            type="text"
                            name="caption.h3"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className="form-control"
                            value={values.caption.h3}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <h2>Botones</h2>
                        {formFields &&
                          formFields.map((data, index) => {
                            return (
                              <Row className="border m-3">
                                <Col
                                  lg={12}
                                  className="p-0 d-flex justify-content-end"
                                >
                                  <button
                                    type="button"
                                    className="btn btn-danger"
                                    onClick={() => removeArray(index)}
                                  >
                                    x
                                  </button>
                                </Col>

                                <Col>
                                  <div className="form-group">
                                    <label>Nombre</label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="nombre"
                                      onChange={(event) =>
                                        handleFormChange(event, index)
                                      }
                                      value={data.nombre}
                                    />
                                  </div>
                                </Col>
                                <Col>
                                  <div className="form-group">
                                    <label>Url</label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="url"
                                      onChange={(event) =>
                                        handleFormChange(event, index)
                                      }
                                      value={data.url}
                                    />
                                  </div>
                                </Col>
                                <Col>
                                  <div className="form-group">
                                    <label>Estilo</label>
                                    <select
                                      className="form-control"
                                      name="estilo"
                                      onChange={(event) =>
                                        handleFormChange(event, index)
                                      }
                                      value={data.estilo}
                                    >
                                      <option>Seleccionar</option>
                                      <option value={"estilo1"}>
                                        Estilo 1
                                      </option>
                                      <option value={"estilo2"}>
                                        Estilo 2
                                      </option>
                                      <option value={"estilo3"}>
                                        Estilo 3
                                      </option>
                                      <option value={"estilo4"}>
                                        Estilo 4
                                      </option>
                                    </select>
                                  </div>
                                </Col>
                              </Row>
                            );
                          })}
                        <Row>
                          <Col>
                            <Button
                              variant="secondary"
                              type="button"
                              className=" mx-2"
                              onClick={() => addArray()}
                            >
                              Añadir item
                            </Button>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </>
                )}
                <Row>
                  <Col>
                    <Form.Group>
                      <label>Enlace</label>
                      <input
                        type="text"
                        name="enlace"
                        onChange={handleChange}
                        className="form-control"
                        value={values.enlace}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group>
                      <label>¿El enlace es interno?</label>
                      <select
                        className="form-control"
                        name="enlaceInterno"
                        value={values.enlaceInterno}
                        onChange={handleChange}
                      >
                        <option value={true}>Si</option>
                        <option value={false}>No</option>
                      </select>
                    </Form.Group>
                  </Col>
                </Row>

                <Row className="mt-5">
                  <Col className="col col-lg-2">
                    <Button
                      type="button"
                      onClick={() => handleSubmit()}
                      disabled={isLoading}
                      className="btn btn-success btn-icon"
                    >
                      <i className="fa fa-floppy-o " />
                      Guardar
                    </Button>
                  </Col>
                  <Col className="col col-lg-3">
                    <Button
                      type="button"
                      onClick={() => onEnviarAprobar(values)}
                      disabled={isLoading}
                      className="btn btn-info btn-icon"
                    >
                      <i className="fa fa-share " />
                      Enviar aprobar
                    </Button>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </form>
    </React.Fragment>
  );
};
export default VerBorradorHomeSlide;
