import React, { useMemo } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import JoditEditor, { Jodit } from "jodit-react";
import { useFormik } from "formik";
import { Fetch, catchError } from "../../../utils/fetch";
import { useDispatch, useSelector } from "react-redux";
import { AlertInfo, AlertSuccess } from "../../alert";
import { loadingAction } from "../../../redux/actions";
import MediaUploader from "../../../utils/media-uploader";
import { validateModel } from "./validationSchema";
import { useHistory } from "react-router-dom";
import { BASE_URL, EstadoContenido } from "../../../utils/constants";
import { removeSpecial } from "../../../utils/tools";
import LayoutJodit from "../../customizer/layout-jodit";

const Template3Component = ({ dataResult }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isLoading = useSelector((state) => state.loading);
  const permissions = useSelector((state) => state.permissions);
  const [layoutJodit, setLayoutJodit] = React.useState("PC");
  const canPublish = permissions.paginas.aprobar.enabled;

  React.useEffect(() => {
    if (dataResult) {
      setValues(dataResult);
    }
  }, [dataResult]);

  // function preparePaste(jodit) {
  //   jodit.e.on(
  //     "paste",
  //     (e) => {
  //       jodit.e.stopPropagation("paste");
  //       let plainText = Jodit.modules.Helpers.getDataTransfer(e).getData(
  //         Jodit.constants.TEXT_PLAIN
  //       );
  //       // plainText = plainText.replace(/(^|;)\s*font-[^;]+/g, "");
  //       // let regex = /<(?!(\/\s*)?(div|b|i|em|strong|u|p)[>,\s])([^>])*>/g;
  //       // plainText = plainText.replace(regex, '');
  //       plainText = plainText.normalize("NFKC").replaceAll("\n", "<br/>");plainText=removeSpecial(plainText);

  //       jodit.s.insertHTML(plainText);
  //       return false;
  //     },
  //     { top: true }
  //   );
  // }
  // Jodit.plugins.add("preparePaste", preparePaste);

  const config = useMemo(
    () => ({
      readonly: false, // all options from https://xdsoft.net/jodit/doc/
      language: "es",
      disablePlugins: ["table"],
      askBeforePasteHTML: false,
      askBeforePasteFromWord: false,
      defaultActionOnPaste: "insert_clear_html",
      enableDragAndDropFileToEditor: true,
      uploader: {
        url: BASE_URL + "/upload",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("jwt_token"),
        },
        data: null,
        filesVariableName: function (i) {
          return "files";
        },
        withCredentials: false,
        pathVariableName: "path",
        format: "json",
        method: "POST",
        prepareData: function (formData) {
          formData.append("id", 1);
        },
        isSuccess: function (resp) {
          return !resp.error;
        },
        getMessage: function (resp) {
          return resp.msgs.join("\n");
        },
        process: function (resp) {
          return resp;
        },
        defaultHandlerSuccess: function (resp) {
          var imagenesPermitidas = ["image/jpeg", "image/png"];
          for (var i = 0; i < resp.length; i++) {
            if (imagenesPermitidas.indexOf(resp[i].mime) !== -1) {
              var img1 = new Image();
              img1.src = resp[i]["url"];
              img1.alt = "imagen";
              img1.className = "img-fluid";
              this.s.insertImage(img1);
            } else if (resp[i].mime === "application/pdf") {
              const enlace = document.createElement("a");
              enlace.setAttribute("href", resp[i].url);
              const contenido = document.createTextNode(resp[i].name);
              // add the text node to the newly created div
              enlace.appendChild(contenido);
              this.s.insertNode(enlace);
            } else {
              AlertInfo("Tipo de archivo no permitido");
            }
          }
        },
      },
    }),
    []
  );

  const submit = async (values) => {
    dispatch(loadingAction(true));

    values.published_at = null;
    values.estado = EstadoContenido.EnRevision;

    //values.template3.contenido=removeSpecial(values.template3.contenido)

    try {
      const { data } = await Fetch("paginas", "post", values);

      dispatch(loadingAction(false));

      AlertInfo(
        "Página creada con éxito.<br/>Recuerde que falta su confirmación para aprobar.<br/> Nota: Por favor, notificar al responsable de publicar"
      );
      history.push("/pages/lista-borradores");
    } catch (error) {
      dispatch(loadingAction(false));
      catchError(error);
    }
  };

  const submitPut = async (values) => {
    dispatch(loadingAction(true));
    if (!canPublish) {
      values.published_at = null;
    }
    try {
      const { data } = await Fetch("paginas/" + dataResult.id, "put", values);
      dispatch(loadingAction(false));
      if (canPublish) AlertSuccess("Página actualizada con éxito");
      else
        AlertInfo(
          "Página actualizada con éxito.<br/>Recuerde que falta su confirmacion para aprobar.<br/> Nota: Por favor, notificar al responsable de publicar"
        );
    } catch (error) {
      dispatch(loadingAction(false));
      catchError(error);
    }
  };

  const {
    values,
    handleSubmit,
    handleChange,
    setFieldValue,
    setValues,
    errors,
  } = useFormik({
    initialValues: {
      nombre: "",
      slug: "",
      template: "template3",
      template3: {
        titulo: "",
        imagen: "",
        contenido: "",
      },
      locale: "es",
    },
    enableReinitialize: true,
    validationSchema: validateModel,
    onSubmit: (values) => (dataResult ? submitPut(values) : submit(values)),
  });

  const handleBlurTitulo = async (event) => {
    const value = event.target.value;
    const name = event.target.name;
    const dataToApi = {
      contentTypeUID: "application::paginas.paginas",
      field: "slug",
      data: {
        nombre: value,
        slug: "",
      },
    };
    setFieldValue(name, value, false);
    try {
      const { data } = await Fetch(
        "content-manager/uid/generate",
        "post",
        dataToApi
      );
      setFieldValue("slug", data.data, false);
    } catch (error) {
      console.error("mostrando error", error);
    }
  };

  const handleChangeJoiEditor = (data) => {
    setFieldValue("template3.contenido", data, false);
  };

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit}>
        <Row>
          <Col>
            <h3>Nueva página</h3>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group>
              <label>Nombre de la página</label>
              <input
                type="text"
                name="nombre"
                onChange={handleChange}
                onBlur={(e) => handleBlurTitulo(e)}
                value={values.nombre}
                className="form-control "
              />
              <div className="text-danger">{errors?.nombre}</div>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group>
              <label>Url</label>
              <input
                type="text"
                name="slug"
                className="form-control"
                value={values.slug}
                readOnly
              />
            </Form.Group>
          </Col>
        </Row>
        {Object.keys(values.template3.imagen).length > 0 && (
          <>
            <Row>
              <Col>
                <div className="form-group">
                  <img
                    src={values.template3.imagen.url}
                    alt="vista-previa"
                    className="img-fluid w-50"
                  />
                </div>
              </Col>
            </Row>

            <Row>
              <Col>
                <Button
                  type="button"
                  style={{ marginBottom: "10px" }}
                  onClick={() => setFieldValue("template3.imagen", "", false)}
                  className="btn btn-danger btn-icon mt-2"
                >
                  <i className="fa fa-trash-o " />
                  Eliminar
                </Button>
              </Col>
            </Row>
          </>
        )}
        <Row>
          <Col>
            <Form.Group>
              <label>Imagen</label>
              <MediaUploader
                setFieldValue={setFieldValue}
                field={"template3.imagen"}
                tipoMedia="imagen"
                ancho={360}
                alto={700}
                value={values.template3.imagen ? 100 : 0}
                key={values.template3.imagen}
              />
              <div className="text-danger">{errors?.template3?.imagen}</div>
              <span className="text-muted">
                Se recomienda una imagen de 360px de ancho x 700px de alto. En
                formato JPG o PNG.
              </span>
            </Form.Group>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col>
            <h3>Información del contenido</h3>
          </Col>
        </Row>

        <Row>
          <Col>
            <Form.Group>
              <label>Título</label>
              <input
                type="text"
                name="template3.titulo"
                onChange={handleChange}
                value={values.template3.titulo}
                className="form-control "
              />
              <div className="text-danger">{errors?.template3?.titulo}</div>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col className={`${layoutJodit == "MOBILE" && "col-6"}`}>
            <Form.Group>
              <label>Contenido</label>
              <LayoutJodit
                setLayoutJodit={setLayoutJodit}
                layoutJodit={layoutJodit}
              />
              <JoditEditor
                config={config}
                value={values.template3.contenido}
                tabIndex={1} // tabIndex of textarea
                onBlur={(newContent) => handleChangeJoiEditor(newContent)}
              />
              <div className="text-danger">{errors?.template3?.contenido}</div>
            </Form.Group>
          </Col>
        </Row>
        <Button
          type="button"
          onClick={() => handleSubmit()}
          disabled={isLoading}
          className="btn btn-success btn-icon mt-2"
        >
          <i className="fa fa-floppy-o " />
          Guardar
        </Button>
      </form>
    </React.Fragment>
  );
};
export default Template3Component;
