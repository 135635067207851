/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Form, Row, Col, Button, Card } from "react-bootstrap";
import { useFormik } from "formik";
import { Fetch, catchError } from "../../../../utils/fetch";
import { useDispatch, useSelector } from "react-redux";
import { AlertInfo, AlertSuccess } from "../../../../components/alert";
import { loadingAction } from "../../../../redux/actions";
import MediaUploader from "../../../../utils/media-uploader";
import { validateModel } from "./validationSchema";
import { EstadoContenido } from "../../../../utils/constants";
import { useLocation, useHistory } from "react-router-dom";

const VerHomeEstudiaX = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.loading);
  const { pathname } = useLocation();
  const [isRevision] = React.useState(pathname.includes("revision"));
  const [isEmpty, setIsEmpty] = React.useState(true);
  const history = useHistory();
  const userType = useSelector((state) => state.user.data.role.type);
  const permissions = useSelector((state) => state.permissions);
  const canPublish = permissions['seccion-home-estudia'].aprobar.enabled;

  const config = {
    readonly: false, // all options from https://xdsoft.net/jodit/doc/
    height: 500,
    language: "es",
  };

  React.useEffect(() => {
    getHomeEstudia();
  }, []);

  const getHomeEstudia = async (values) => {
    dispatch(loadingAction(true));

    try {
      const estado = isRevision ? "ER" : "EA";

      const { data } = await Fetch(
        `seccion-home-estudias?_publicationState=preview&estado=${estado}`,
        "get"
      );

      if (!data?.length) {
        dispatch(loadingAction(false));

        if (isRevision) {
          AlertInfo("No hay información que se necesite revisar");
        } else {
          AlertInfo("No hay información que se necesite aprobar");
        }

        return;
      }

      const [values] = data;
      setIsEmpty(false);

      setValues(values);
      dispatch(loadingAction(false));
    } catch (error) {
      catchError(error);
      dispatch(loadingAction(false));
    }
  };

  const onEnviarAprobar = async (values) => {
    dispatch(loadingAction(true));

    try {
      try {
        await Fetch(`seccion-home-estudias/delete-by-estado/EA`, "delete");
      } catch (error) {}

      values.estado = EstadoContenido.EnAprobacion;
      values.published_at = null;

      await Fetch(`seccion-home-estudias/${values.id}`, "put", values);

      AlertSuccess(
        "Home Estudia actualizado con éxito, pendiente de aprobación"
      );
      dispatch(loadingAction(false));

      if (userType != "creador") {
        history.push("/configuracion/home-estudia/aprobar/");
      } else {
        history.push("/configuracion/home-estudia/ver/");
      }
    } catch (error) {
      catchError(error);
      dispatch(loadingAction(false));
    }
  };

  const onPublicar = async (values) => {
    dispatch(loadingAction(true));

    try {
      try {
        await Fetch(`seccion-home-estudias/delete-by-estado/PU`, "delete");
      } catch (error) {}

      values.estado = EstadoContenido.Publicado;
      values.published_at = new Date();

      await Fetch(`seccion-home-estudias/${values.id}`, "put", values);
      AlertSuccess("Home Estudia publicado con éxito");

      dispatch(loadingAction(false));
      history.push("/configuracion/home-estudia/ver/");
    } catch (error) {
      catchError(error);
      dispatch(loadingAction(false));
    }
  };

  const onPorRevisar = async (values) => {
    dispatch(loadingAction(true));

    try {
      try {
        await Fetch(`seccion-home-estudias/delete-by-estado/ER`, "delete");
      } catch (error) {}

      values.estado = EstadoContenido.EnRevision;
      await Fetch(`seccion-home-estudias/${values.id}`, "put", values);

      AlertSuccess("Home estudia actualizado con éxito, pendiente de revisión");
      dispatch(loadingAction(false));

      history.push("/configuracion/home-estudia/revision/");
    } catch (error) {
      catchError(error);
      dispatch(loadingAction(false));
    }
  };

  const submit = async (values) => {
    dispatch(loadingAction(true));

    try {
      values.estado = EstadoContenido.EnRevision;
      await Fetch(`seccion-home-estudias/${values.id}`, "put", values);

      AlertSuccess("Home estudia actualizado con éxito");
      dispatch(loadingAction(false));
    } catch (error) {
      catchError(error);
      dispatch(loadingAction(false));
    }
  };

  const {
    values,
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue,
    setValues,
    errors,
  } = useFormik({
    initialValues: {
      titulo: "",
      descripcion: "",
      foto: "",
      boton: {
        nombre: "",
        url: "",
        urlInterna: false,
      },
    },
    enableReinitialize: true,
    validationSchema: validateModel,
    onSubmit: (values) => submit(values),
  });

  const handleChangeJoiEditor = (data, field) => {
    setFieldValue(field, data, false);
  };

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit}>
        <Row>
          <Col>
            <Card>
              <Card.Header>
                <Row>
                  <Col>
                    Información de home estudia -{" "}
                    {isRevision ? "En Revisión" : "Por Aprobar"}
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body>
                <Row>
                  <Col>
                    <Form.Group>
                      <label>Título</label>
                      <input
                        type="text"
                        name="titulo"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="form-control"
                        value={values.titulo}
                      />
                      <div className="text-danger">{errors?.titulo}</div>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group>
                      <label>Descripción</label>
                      <textarea
                        name="descripcion"
                        onChange={handleChange}
                        className="form-control"
                        value={values.descripcion}
                      ></textarea>
                      <div className="text-danger">{errors?.descripcion}</div>
                    </Form.Group>
                  </Col>
                </Row>
                {Object.keys(values.foto).length > 0 && (
                  <Row>
                    <Col>
                      <Form.Group>
                        <img
                          src={values.foto[0]?.url || values.foto?.url}
                          alt="vista-previa"
                          className="img-fluid w-50"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                )}
                <Row>
                  <Col>
                    <Form.Group>
                      <label>Foto</label>
                      <MediaUploader
                        setFieldValue={setFieldValue}
                        field={"foto"}
                        tipoMedia="imagen"
                        ancho={300}
                        alto={300}
                      />
                      <div className="text-danger">{errors?.foto}</div>
                      <span className="text-muted">
                        Se recomienda una imagen de 300px de ancho x 300px de
                        alto. En formato JPG o PNG.
                      </span>
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <Form.Group>
                      <label>Botón</label>
                      <input
                        type="text"
                        name="boton.nombre"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="form-control"
                        value={values.boton.nombre}
                      />
                      <div className="text-danger">{errors?.boton?.nombre}</div>

                      <label>Url</label>
                      <input
                        type="text"
                        name="boton.url"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="form-control"
                        value={values.boton.url}
                      />
                      <div className="text-danger">{errors?.boton?.url}</div>

                      <div className="form-check form-switch">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="flexSwitchCheckDefault"
                          name="boton.urlInterna"
                          checked={values.boton?.urlInterna}
                          onChange={handleChange}
                        />
                        <label
                          className="form-check-label"
                          for="flexSwitchCheckDefault"
                        >
                          Enlace interno
                        </label>
                      </div>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mt-5">
                  {isRevision ? (
                    <>
                      <Col className="col col-lg-3">
                        <Button
                          type="button"
                          onClick={() => handleSubmit()}
                          disabled={isLoading || isEmpty}
                          className="btn btn-success btn-icon mt-2"
                        >
                          <i className="fa fa-floppy-o " />
                          Guardar
                        </Button>
                      </Col>
                      <Col className="col col-lg-3">
                        <Button
                          type="button"
                          onClick={() => onEnviarAprobar(values)}
                          disabled={isLoading || isEmpty}
                          className="btn btn-info btn-icon mt-2"
                        >
                          <i className="fa fa-share " />
                          Enviar aprobar
                        </Button>
                      </Col>
                    </>
                  ) : (
                    <>
                      <Col className="col col-lg-3">
                        <Button
                          type="button"
                          onClick={() => onPublicar(values)}
                          disabled={isLoading || isEmpty || !canPublish}
                          className="btn btn-success btn-icon mt-2"
                        >
                          <i className="fa fa-floppy-o " />
                          Publicar
                        </Button>
                      </Col>
                      <Col className="col col-lg-3">
                        <Button
                          type="button"
                          onClick={() => onPorRevisar(values)}
                          disabled={isLoading || isEmpty}
                          className="btn btn-primary btn-icon no-hover3 btn btn-primary btn btn-primary mt-2"
                        >
                          <i className="fa fa-undo " />
                          Por revisar
                        </Button>
                      </Col>
                    </>
                  )}
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </form>
    </React.Fragment>
  );
};
export default VerHomeEstudiaX;
