import { FieldArray, Formik } from "formik";
import { Accordion, Button, Card, Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { AlertInfo } from "../../../components/alert";
import { loadingAction } from "../../../redux/actions";
import { EstadoContenido } from "../../../utils/constants";
import { catchError, Fetch } from "../../../utils/fetch";
import { validateModel } from "./validationSchema";
import React from "react";
function NuevoMenu({ user, token, isLoadingAction, permissions, isLoading }) {
  const history = useHistory();

  const canPublish = permissions.menus.aprobar.enabled;

  const initialValues = {
    Menu: {
      locales: "es",
      Menu: {
        nombre: "",
        url: "#",
        esBoton: false,
        onClick: "",
        enlaceInterno: true,
      },
      SubmenuColumna1: [
        {
          nombre: "",
          url: "#",
          esBoton: false,
          onClick: "",
          enlaceInterno: true,
        },
      ],
      SubmenuColumna2: [
        {
          nombre: "",
          url: "#",
          esBoton: false,
          onClick: "",
          enlaceInterno: true,
        },
      ],
    },
  };

  const submit = async (values, type) => {
    isLoadingAction(true);

    values.published_at = null;
    values.estado = EstadoContenido.EnRevision;

    try {
      const { data } = await Fetch("menus", "post", values);
      isLoadingAction(false);

      AlertInfo(
        "Menú creado con éxito.<br/>Recuerde que falta su confirmación para aprobar.<br/> Nota: Por favor, notificar al responsable de publicar"
      );
      history.push("/menus/en-revision");
    } catch (error) {
      isLoadingAction(false);
      catchError(error);
    }
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validateModel}
        onSubmit={(values, { setSubmitting }) => {
          submit(values);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => (
          <form onSubmit={handleSubmit}>
            <Row>
              <Col>
                <Card>
                  <Card.Header>Nuevo Menú</Card.Header>
                  <Card.Body>
                    <Row>
                      <Col>
                        <div className="form-group">
                          <label>Nombre</label>
                          <input
                            type="text"
                            name="Menu.Menu.nombre"
                            className="form-control"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.Menu.Menu.nombre}
                          />
                          {errors.nombre && touched.nombre && errors.nombre}
                          <div className="text-danger">
                            {errors?.Menu?.Menu?.nombre}
                          </div>
                        </div>
                      </Col>
                      <Col>
                        <div className="form-group">
                          <label>URL</label>
                          <input
                            type="text"
                            name="Menu.Menu.url"
                            className="form-control"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.Menu.Menu.url}
                          />
                          {errors.url && touched.url && errors.url}
                          <div className="text-danger">
                            {errors?.Menu?.Menu?.url}
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className="form-group">
                          <label>¿Es botón?</label>
                          <select
                            className="form-control"
                            name="Menu.Menu.esBoton"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.Menu.Menu.esBoton}
                          >
                            <option value={true}>Si</option>
                            <option value={false}>No</option>
                          </select>
                          {errors.esBoton && touched.esBoton && errors.esBoton}
                        </div>
                      </Col>
                      <Col>
                        <div className="form-group">
                          <label>Al dar click</label>
                          <input
                            type="text"
                            name="Menu.Menu.onClick"
                            className="form-control"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.Menu.Menu.onClick}
                          />
                          {errors.onClick && touched.onClick && errors.onClick}
                          <div className="text-danger">
                            {errors?.Menu?.Menu?.onClick}
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="col-12 col-lg-6">
                        <div className="form-group">
                          <label>¿El enlace es interno?</label>
                          <select
                            className="form-control"
                            name={`Menu.Menu.enlaceInterno`}
                            value={values.Menu.Menu.enlaceInterno}
                            onChange={handleChange}
                          >
                            <option value={true}>Si</option>
                            <option value={false}>No</option>
                          </select>
                          <div className="text-danger">
                            {errors?.Menu?.Menu?.enlaceInterno}
                          </div>
                        </div>
                      </Col>
                    </Row>
                    {/* Columna1 */}
                    <Row>
                      <Col>
                        <h2>Submenú columna 1</h2>
                        <FieldArray name="Menu.SubmenuColumna1">
                          {({
                            move,
                            swap,
                            push,
                            remove,
                            insert,
                            unshift,
                            pop,
                            form,
                          }) => {
                            return (
                              <>
                                {values.Menu.SubmenuColumna1 &&
                                  values.Menu.SubmenuColumna1.length > 0 &&
                                  values.Menu.SubmenuColumna1.map(
                                    (data, index) => {
                                      return (
                                        <Row key={index}>
                                          <Col>
                                            <Accordion defaultActiveKey="0">
                                              <Card className="border mb-2">
                                                <Card.Header className="d-flex justify-content-between">
                                                  <Accordion.Toggle
                                                    as={Button}
                                                    variant="link"
                                                    eventKey="0"
                                                  >
                                                    {
                                                      values?.Menu
                                                        .SubmenuColumna1[index]
                                                        ?.nombre
                                                    }
                                                  </Accordion.Toggle>
                                                  <button
                                                    type="button"
                                                    className="btn btn-default"
                                                    onClick={() =>
                                                      remove(index)
                                                    }
                                                  >
                                                    X
                                                  </button>
                                                </Card.Header>
                                                <Accordion.Collapse eventKey="0">
                                                  <Card.Body>
                                                    <Row>
                                                      <Col>
                                                        <div className="form-group">
                                                          <label>Nombre</label>
                                                          <input
                                                            type="text"
                                                            name={`Menu.SubmenuColumna1.[${index}].nombre`}
                                                            className="form-control"
                                                            onChange={
                                                              handleChange
                                                            }
                                                            onBlur={handleBlur}
                                                            value={
                                                              values?.Menu
                                                                .SubmenuColumna1[
                                                                index
                                                              ]?.nombre
                                                            }
                                                          />
                                                          {errors &&
                                                            errors.Menu &&
                                                            errors.Menu
                                                              .SubmenuColumna1 &&
                                                            errors.Menu
                                                              .SubmenuColumna1[
                                                              index
                                                            ] &&
                                                            errors.Menu
                                                              .SubmenuColumna1[
                                                              index
                                                            ].nombre && (
                                                              <div className="text-danger">
                                                                {
                                                                  errors?.Menu
                                                                    ?.SubmenuColumna1[
                                                                    index
                                                                  ]?.nombre
                                                                }
                                                              </div>
                                                            )}
                                                        </div>
                                                      </Col>
                                                      <Col>
                                                        <div className="form-group">
                                                          <label>URL</label>
                                                          <input
                                                            type="text"
                                                            name={`Menu.SubmenuColumna1.[${index}].url`}
                                                            className="form-control"
                                                            onChange={
                                                              handleChange
                                                            }
                                                            onBlur={handleBlur}
                                                            value={
                                                              values.Menu
                                                                .SubmenuColumna1[
                                                                index
                                                              ].url
                                                            }
                                                          />
                                                          {errors &&
                                                            errors.Menu &&
                                                            errors.Menu
                                                              .SubmenuColumna1 &&
                                                            errors.Menu
                                                              .SubmenuColumna1[
                                                              index
                                                            ] &&
                                                            errors.Menu
                                                              .SubmenuColumna1[
                                                              index
                                                            ].url && (
                                                              <div className="text-danger">
                                                                {
                                                                  errors?.Menu
                                                                    ?.SubmenuColumna1[
                                                                    index
                                                                  ]?.url
                                                                }
                                                              </div>
                                                            )}
                                                        </div>
                                                      </Col>
                                                    </Row>
                                                    <Row>
                                                      <Col>
                                                        <div className="form-group">
                                                          <label>
                                                            ¿Es botón?
                                                          </label>
                                                          <select
                                                            className="form-control"
                                                            name={`Menu.SubmenuColumna1.${index}.esBoton`}
                                                            onChange={
                                                              handleChange
                                                            }
                                                            onBlur={handleBlur}
                                                            value={
                                                              values.Menu
                                                                .SubmenuColumna1[
                                                                index
                                                              ].esBoton
                                                            }
                                                          >
                                                            <option
                                                              value={true}
                                                            >
                                                              Si
                                                            </option>
                                                            <option
                                                              value={false}
                                                            >
                                                              No
                                                            </option>
                                                          </select>
                                                        </div>
                                                      </Col>
                                                      <Col>
                                                        <div className="form-group">
                                                          <label>
                                                            Al dar click
                                                          </label>
                                                          <input
                                                            type="text"
                                                            name={`Menu.SubmenuColumna1.${index}.onClick`}
                                                            className="form-control"
                                                            onChange={
                                                              handleChange
                                                            }
                                                            onBlur={handleBlur}
                                                            value={
                                                              values.Menu
                                                                .SubmenuColumna1[
                                                                index
                                                              ].onClick
                                                            }
                                                          />
                                                          {errors &&
                                                            errors.Menu &&
                                                            errors.Menu
                                                              .SubmenuColumna1 &&
                                                            errors.Menu
                                                              .SubmenuColumna1[
                                                              index
                                                            ] &&
                                                            errors.Menu
                                                              .SubmenuColumna1[
                                                              index
                                                            ].onClick && (
                                                              <div className="text-danger">
                                                                {
                                                                  errors?.Menu
                                                                    ?.SubmenuColumna1[
                                                                    index
                                                                  ]?.onClick
                                                                }
                                                              </div>
                                                            )}
                                                        </div>
                                                      </Col>
                                                    </Row>
                                                    <Row>
                                                      <Col className="col-12 col-lg-6">
                                                        <div className="form-group">
                                                          <label>
                                                            ¿El enlace es
                                                            interno?
                                                          </label>
                                                          <select
                                                            className="form-control"
                                                            name={`Menu.SubmenuColumna1.${index}.enlaceInterno`}
                                                            value={
                                                              values.Menu
                                                                .SubmenuColumna1[
                                                                index
                                                              ].enlaceInterno
                                                            }
                                                            onChange={
                                                              handleChange
                                                            }
                                                          >
                                                            <option
                                                              value={true}
                                                            >
                                                              Si
                                                            </option>
                                                            <option
                                                              value={false}
                                                            >
                                                              No
                                                            </option>
                                                          </select>
                                                        </div>
                                                      </Col>
                                                    </Row>
                                                  </Card.Body>
                                                </Accordion.Collapse>
                                              </Card>
                                            </Accordion>
                                          </Col>
                                        </Row>
                                      );
                                    }
                                  )}
                                <button
                                  type="button"
                                  className="btn btn-secondary"
                                  onClick={() =>
                                    push({
                                      id: "",
                                      nombre: "",
                                      url: "#",
                                      esBoton: false,
                                      onClick: "",
                                      enlaceInterno: true,
                                    })
                                  }
                                >
                                  Añadir Item
                                </button>
                              </>
                            );
                          }}
                        </FieldArray>
                      </Col>
                      <Col>
                        <h2>Submenú columna 2</h2>
                        <FieldArray name="Menu.SubmenuColumna2">
                          {({
                            move,
                            swap,
                            push,
                            remove,
                            insert,
                            unshift,
                            pop,
                            form,
                          }) => {
                            return (
                              <>
                                {values.Menu.SubmenuColumna2 &&
                                  values.Menu.SubmenuColumna2.length > 0 &&
                                  values.Menu.SubmenuColumna2.map(
                                    (data, index) => {
                                      return (
                                        <Row key={index}>
                                          <Col>
                                            <Accordion defaultActiveKey="0">
                                              <Card className="border mb-2">
                                                <Card.Header className="d-flex justify-content-between">
                                                  <Accordion.Toggle
                                                    as={Button}
                                                    variant="link"
                                                    eventKey="0"
                                                  >
                                                    {
                                                      values.Menu
                                                        .SubmenuColumna2[index]
                                                        ?.nombre
                                                    }
                                                  </Accordion.Toggle>
                                                  <button
                                                    type="button"
                                                    className="btn btn-default"
                                                    onClick={() =>
                                                      remove(index)
                                                    }
                                                  >
                                                    X
                                                  </button>
                                                </Card.Header>
                                                <Accordion.Collapse eventKey="0">
                                                  <Card.Body>
                                                    <Row>
                                                      <Col>
                                                        <div className="form-group">
                                                          <label>Nombre</label>
                                                          <input
                                                            type="text"
                                                            name={`Menu.SubmenuColumna2.${index}.nombre`}
                                                            className="form-control"
                                                            onChange={
                                                              handleChange
                                                            }
                                                            onBlur={handleBlur}
                                                            value={
                                                              values.Menu
                                                                .SubmenuColumna2[
                                                                index
                                                              ]?.nombre
                                                            }
                                                          />
                                                          {errors &&
                                                            errors.Menu &&
                                                            errors.Menu
                                                              .SubmenuColumna2 &&
                                                            errors.Menu
                                                              .SubmenuColumna2[
                                                              index
                                                            ] &&
                                                            errors.Menu
                                                              .SubmenuColumna2[
                                                              index
                                                            ].nombre && (
                                                              <div className="text-danger">
                                                                {
                                                                  errors?.Menu
                                                                    ?.SubmenuColumna2[
                                                                    index
                                                                  ]?.nombre
                                                                }
                                                              </div>
                                                            )}
                                                        </div>
                                                      </Col>
                                                      <Col>
                                                        <div className="form-group">
                                                          <label>URL</label>
                                                          <input
                                                            type="text"
                                                            name={`Menu.SubmenuColumna2.${index}.url`}
                                                            className="form-control"
                                                            onChange={
                                                              handleChange
                                                            }
                                                            onBlur={handleBlur}
                                                            value={
                                                              values.Menu
                                                                .SubmenuColumna2[
                                                                index
                                                              ].url
                                                            }
                                                          />

                                                          {errors &&
                                                            errors.Menu &&
                                                            errors.Menu
                                                              .SubmenuColumna2 &&
                                                            errors.Menu
                                                              .SubmenuColumna2[
                                                              index
                                                            ] &&
                                                            errors.Menu
                                                              .SubmenuColumna2[
                                                              index
                                                            ].url && (
                                                              <div className="text-danger">
                                                                {
                                                                  errors?.Menu
                                                                    ?.SubmenuColumna2[
                                                                    index
                                                                  ]?.url
                                                                }
                                                              </div>
                                                            )}
                                                        </div>
                                                      </Col>
                                                    </Row>
                                                    <Row>
                                                      <Col>
                                                        <div className="form-group">
                                                          <label>
                                                            ¿Es botón?
                                                          </label>
                                                          <select
                                                            className="form-control"
                                                            name={`Menu.SubmenuColumna2.${index}.esBoton`}
                                                            onChange={
                                                              handleChange
                                                            }
                                                            onBlur={handleBlur}
                                                            value={
                                                              values.Menu
                                                                .SubmenuColumna2[
                                                                index
                                                              ].esBoton
                                                            }
                                                          >
                                                            <option
                                                              value={true}
                                                            >
                                                              Si
                                                            </option>
                                                            <option
                                                              value={false}
                                                            >
                                                              No
                                                            </option>
                                                          </select>
                                                        </div>
                                                      </Col>
                                                      <Col>
                                                        <div className="form-group">
                                                          <label>
                                                            Al dar click
                                                          </label>
                                                          <input
                                                            type="text"
                                                            name={`Menu.SubmenuColumna2.${index}.onClick`}
                                                            className="form-control"
                                                            onChange={
                                                              handleChange
                                                            }
                                                            onBlur={handleBlur}
                                                            value={
                                                              values.Menu
                                                                .SubmenuColumna2[
                                                                index
                                                              ].onClick
                                                            }
                                                          />
                                                          {errors &&
                                                            errors.Menu &&
                                                            errors.Menu
                                                              .SubmenuColumna2 &&
                                                            errors.Menu
                                                              .SubmenuColumna2[
                                                              index
                                                            ] &&
                                                            errors.Menu
                                                              .SubmenuColumna2[
                                                              index
                                                            ].onClick && (
                                                              <div className="text-danger">
                                                                {
                                                                  errors?.Menu
                                                                    ?.SubmenuColumna2[
                                                                    index
                                                                  ]?.onClick
                                                                }
                                                              </div>
                                                            )}
                                                        </div>
                                                      </Col>
                                                    </Row>
                                                    <Row>
                                                      <Col className="col-12 col-lg-6">
                                                        <div className="form-group">
                                                          <label>
                                                            ¿El enlace es
                                                            interno?
                                                          </label>
                                                          <select
                                                            className="form-control"
                                                            name={`Menu.SubmenuColumna2.${index}.enlaceInterno`}
                                                            value={
                                                              values.Menu
                                                                .SubmenuColumna2[
                                                                index
                                                              ].enlaceInterno
                                                            }
                                                            onChange={
                                                              handleChange
                                                            }
                                                          >
                                                            <option
                                                              value={true}
                                                            >
                                                              Si
                                                            </option>
                                                            <option
                                                              value={false}
                                                            >
                                                              No
                                                            </option>
                                                          </select>
                                                        </div>
                                                      </Col>
                                                    </Row>
                                                  </Card.Body>
                                                </Accordion.Collapse>
                                              </Card>
                                            </Accordion>
                                          </Col>
                                        </Row>
                                      );
                                    }
                                  )}
                                <button
                                  type="button"
                                  className="btn btn-secondary"
                                  onClick={() =>
                                    push({
                                      id: "",
                                      nombre: "",
                                      url: "#",
                                      esBoton: false,
                                      onClick: "",
                                      enlaceInterno: true,
                                    })
                                  }
                                >
                                  Añadir Item
                                </button>
                              </>
                            );
                          }}
                        </FieldArray>
                      </Col>
                    </Row>
                    <Button
                      type="button"
                      onClick={() => handleSubmit()}
                      disabled={isLoading}
                      className="btn btn-success btn-icon mt-2"
                    >
                      <i className="fa fa-floppy-o " />
                      Guardar
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </form>
        )}
      </Formik>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.user.data,
    token: state.user.token,
    permissions: state.permissions,
    isLoading: state.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    isLoadingAction: (data) => {
      dispatch(loadingAction(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NuevoMenu);
