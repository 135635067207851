/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Form, Row, Col, Button, Card } from "react-bootstrap";
import JoditEditor from "jodit-react";
import { useFormik } from "formik";
import { Fetch, catchError } from "../../../utils/fetch";
import { useDispatch, useSelector } from "react-redux";
import ImageUploader from "../../../utils/image-uploader";
import { AlertSuccess, AlertInfo } from "../../../components/alert";
import { loadingAction } from "../../../redux/actions";
import { useHistory, useParams } from "react-router-dom";
import MediaUploader from "../../../utils/media-uploader";
import { validateModel } from "./validationSchema";
import { EstadoContenido } from "../../../utils/constants";

const VerAcceso = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const isLoading = useSelector((state) => state.loading);
  const permissions = useSelector((state) => state.permissions);
  const canPublish = permissions.novedades.aprobar.enabled;

  React.useEffect(() => {
    if (id) {
      getAccesoById();
    }
  }, [id]);

  const getAccesoById = async (values) => {
    dispatch(loadingAction(true));

    try {
      const { data } = await Fetch(
        `nuestros-accesos/${id}?_publicationState=preview`,
        "get"
      );
      setValues(data);
      dispatch(loadingAction(false));
    } catch (error) {
      catchError(error);
      dispatch(loadingAction(false));
    }
  };

  const submit = async (values) => {
    dispatch(loadingAction(true));

    values.published_at = null;
    values.idPadre = values.id;
    values.estado = EstadoContenido.EnRevision;

    try {
      await Fetch("nuestros-accesos", "post", values);
      AlertInfo(
        "Acceso actualizado con éxito.<br/>Recuerde que falta su confirmación para aprobar.<br/> Nota: Por favor, notificar al responsable de publicar"
      );

      dispatch(loadingAction(false));
      history.push("/nuestros-accesos/lista");
    } catch (error) {
      catchError(error);
      dispatch(loadingAction(false));
    }
  };

  const {
    values,
    handleSubmit,
    handleChange,
    setFieldValue,
    setValues,
    errors,
  } = useFormik({
    initialValues: {
      imagenAcceso: "",
      imagenFondo: "",
      textoFondo: "",
      tieneEnlace: false,
      enlace: "",
      enlaceInterno: false,
      eliminado: false,
      enAprobacion: true,
    },
    enableReinitialize: true,
    validationSchema: validateModel,
    onSubmit: (values) => submit(values),
  });

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit}>
        <Row>
          <Col>
            <Card>
              <Card.Header>
                <Row>
                  <Col>Información del acceso</Col>
                </Row>
              </Card.Header>
              <Card.Body>
                {/* Imagen de acceso */}
                {Object.keys(values.imagenAcceso).length > 0 && (
                  <Row>
                    <Col>
                      <Form.Group>
                        <img
                          src={values.imagenAcceso.url}
                          alt="vista-previa"
                          className="img-fluid w-50"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                )}

                <Row>
                  <Col>
                    <Form.Group>
                      <label>Imagen de acceso</label>
                      <MediaUploader
                        setFieldValue={setFieldValue}
                        field={"imagenAcceso"}
                        tipoMedia="imagen"
                      />
                      <div className="text-danger">{errors?.imagenAcceso}</div>
                      <span className="text-muted">
                        Se recomienda una imagen de 384px de ancho por 310px de
                        alto. En formato JPG o PNG.
                      </span>
                    </Form.Group>
                  </Col>
                </Row>

                {/* Imagen de fondo */}
                {Object.keys(values.imagenFondo).length > 0 && (
                  <Row>
                    <Col>
                      <Form.Group>
                        <img
                          src={values.imagenFondo.url}
                          alt="vista-previa"
                          className="img-fluid w-50"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                )}

                <Row>
                  <Col>
                    <Form.Group>
                      <label>Imagen de fondo</label>
                      <MediaUploader
                        setFieldValue={setFieldValue}
                        field={"imagenFondo"}
                        tipoMedia="imagen"
                      />
                      <div className="text-danger">{errors?.imagenFondo}</div>
                      <span className="text-muted">
                        Se recomienda una imagen de 1208px de ancho por 625px de
                        alto. En formato JPG o PNG.
                      </span>
                    </Form.Group>
                  </Col>
                </Row>

                {/* Texto de fondo */}
                {Object.keys(values.textoFondo).length > 0 && (
                  <Row>
                    <Col>
                      <Form.Group>
                        <img
                          src={values.textoFondo.url}
                          alt="vista-previa"
                          className="img-fluid w-50"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                )}

                <Row>
                  <Col>
                    <Form.Group>
                      <label>Texto de fondo</label>
                      <MediaUploader
                        setFieldValue={setFieldValue}
                        field={"textoFondo"}
                        tipoMedia="imagen"
                      />
                      <div className="text-danger">{errors?.textoFondo}</div>
                      <span className="text-muted">
                        Se recomienda una imagen de 384px de ancho por 310px de
                        alto. En formato JPG o PNG.
                      </span>
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <Form.Group>
                      <label>Tiene enlace</label>
                      <select
                        className="form-control"
                        name="tieneEnlace"
                        value={values.tieneEnlace}
                        onChange={handleChange}
                      >
                        <option value={true}>Si</option>
                        <option value={false}>No</option>
                      </select>
                    </Form.Group>
                  </Col>
                </Row>

                {values.tieneEnlace === "true" ||
                values.tieneEnlace === true ? (
                  <>
                    <Row>
                      <Col>
                        <Form.Group>
                          <label>Enlace</label>
                          <input
                            type="text"
                            name="enlace"
                            onChange={handleChange}
                            className="form-control"
                            value={values.enlace}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group>
                          <label>¿El enlace es interno?</label>
                          <select
                            className="form-control"
                            name="enlaceInterno"
                            value={values.enlaceInterno}
                            onChange={handleChange}
                          >
                            <option value={true}>Si</option>
                            <option value={false}>No</option>
                          </select>
                        </Form.Group>
                      </Col>
                    </Row>
                  </>
                ) : null}

                <Button
                  type="button"
                  onClick={() => handleSubmit()}
                  disabled={isLoading}
                  className="btn btn-success btn-icon mt-2"
                >
                  <i className="fa fa-floppy-o " />
                  Guardar
                </Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </form>
    </React.Fragment>
  );
};
export default VerAcceso;
