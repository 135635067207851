/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Fetch, catchError } from '../../../../utils/fetch'
import { connect } from 'react-redux'
import { loadingAction } from '../../../../redux/actions';
import DataGridForm from '../../../../components/common/DataGridForm';
import { formatDate } from '../../../../utils/formatDate';
import { AlertConfirm } from '../../../../components/alert';
import { EstadoContenido } from '../../../../utils/constants';

function ListaBorradoresHomeSlides({ isLoadingAction, isLoading }) {
    const [contenido, setContenido] = React.useState(null)

    React.useEffect(() => {
        getContenido()
    }, [])

    const getContenido = async () => {
        isLoadingAction(true)
        try {
            const { data } = await Fetch('home-slides?_publicationState=preview&published_at_null=true&estado=ER', 'get')
            setContenido(data)
            isLoadingAction(false)
        } catch (error) {
            console.error('mostrando error', error)
            isLoadingAction(false)
            catchError(error);
        }
    }

    const deleteContenido = async (values) => {
        isLoadingAction(true)
        try {
            values.eliminado=true;
            values.estado=EstadoContenido.EnRevision;   
            values.published_at=new Date();              
            delete values.idPadre;
            await Fetch('home-slides/'+values.id, 'put', values)
            getContenido()
            isLoadingAction(false)
        } catch (error) {
            console.error('mostrando error', error)
            isLoadingAction(false)
            catchError(error);
        }
    }
	
	 function DeleteAlert(title, data) {
        AlertConfirm(title, 'question', (response) => {            
            if (response.isConfirmed) {
                deleteContenido(data)
            }
        })
    }

    function FActualizacion(data) {
        return formatDate(data?.updated_at)
    }


    function Accion(data) {
        return (
            <>
                <Link to={"/configuracion/home-slide/ver-borrador/" + data.id} className="btn btn-sm btn btn-success"><i className="fa fa-pencil" /></Link>{' '}
                <a href={`${data.media.url}`} target="_blank" className="btn btn-sm btn btn-info"><i className="fa fa-eye" /></a>{' '}
                <button type="button" className="btn btn-sm btn btn-danger" onClick={() => { DeleteAlert("Está apunto de eliminar este contenido", data) }} ><i className="fa fa-trash" /></button>{' '}
            </>
        )
    }


    return (
        <React.Fragment>
            <Row>
                <Col md="12">
                    <Card>
                        <Card.Header>
                            <div className="float-right mt-10">
                                <Link to="/configuracion/home-slide/nuevo" className="btn btn-primary btn-rounded box-shadow btn-icon"><i className="fa fa-plus" /> Nuevo Slide</Link>
                            </div>
                            Listado de slides en revisión
                        </Card.Header>
                        <Card.Body>

                            <DataGridForm dataSource={contenido}
                                keyExpr='id'
                                allowReordering={true}
                                columnHidingEnabled={true}
                                columns={[
                                    { caption: 'Id', dataField: 'id' },
                                    { caption: 'Tipo', dataField: 'tipo' },
                                    { caption: 'Creado por', dataField: 'creado_por.username' },
                                    { caption: 'Actualizado por', dataField: 'actualizado_por.username' },
                                    { caption: 'Ult. Actualización', render: 'FActualizacion', cellRender: { FActualizacion } },
                                    { caption: 'Acción', alignment: 'center', render: 'Accion', cellRender: { Accion } }
                                ]} />

                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    )
}

const mapStateToProps = (state) => {

    return {
        isLoading: state.loading,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        isLoadingAction: (data) => { dispatch(loadingAction(data)) }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ListaBorradoresHomeSlides);