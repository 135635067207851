import { useSelector, useDispatch } from "react-redux";
import React, { useState, useEffect, useMemo } from "react";
import { Form, Row, Col } from "react-bootstrap";

import { AlertInfo } from "../../../components/alert";
import { especialistasAction } from "../../../redux/actions";
import JoditEditor from "jodit-react";
import { BASE_URL} from "../../../utils/constants";
import LayoutJodit from "../../../components/customizer/layout-jodit";

const JefeServicio = ({ formik }) => {
  const dispatch = useDispatch();
  const especialistas = useSelector((state) => state.especialistas);
  
  const [layoutJodit, setLayoutJodit] = React.useState("PC");
  const [showJefe, setShowJefe] = useState(false);

  useEffect(() => {
    //console.log("FORMIK",formik.values);
    setShowJefe(formik.values.show_jefe);
  }, [formik]);

  const config = useMemo(
    () => ({
      readonly: false,
      height: 500,
      language: "es",
      disablePlugins: ["table"],
      disablePlugins: ["table"],
      askBeforePasteHTML: false,
      askBeforePasteFromWord: false,
      defaultActionOnPaste: "insert_clear_html",
      enableDragAndDropFileToEditor: true,
      uploader: {
        url: BASE_URL + "/upload",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("jwt_token"),
        },
        data: null,
        filesVariableName: function (i) {
          return "files";
        },
        withCredentials: false,
        pathVariableName: "path",
        format: "json",
        method: "POST",
        prepareData: function (formData) {
          formData.append("id", 1);
        },
        isSuccess: function (resp) {
          return !resp.error;
        },
        getMessage: function (resp) {
          return resp.msgs.join("\n");
        },
        process: function (resp) {
          return resp;
        },
        defaultHandlerSuccess: function (resp) {
          var imagenesPermitidas = ["image/jpeg", "image/png"];
          for (var i = 0; i < resp.length; i++) {
            if (imagenesPermitidas.indexOf(resp[i].mime) !== -1) {
              var img1 = new Image();
              img1.src = resp[i]["url"];
              img1.alt = "imagen";
              img1.className = "img-fluid";
              this.s.insertImage(img1);
            } else if (resp[i].mime === "application/pdf") {
              const enlace = document.createElement("a");
              enlace.setAttribute("href", resp[i].url);
              const contenido = document.createTextNode(resp[i].name);
              enlace.appendChild(contenido);
              this.s.insertNode(enlace);
            } else {
              AlertInfo("Tipo de archivo no permitido");
            }
          }
        },
      },
    }),
    []
  );

  // const handlerOnOff = (id, isChecked) => {
  //   const updatedItems = especialistas.map((fila) => {
  //     if (fila.id === id) {
  //       return { ...fila, show_jefe: isChecked };
  //     } else {
  //       return { ...fila, show_jefe: false };
  //     }
  //   });

  //   dispatch(especialistasAction([...updatedItems]));
  // };

  return (
    <>
      <Row>
        <Col>
          <Form.Group className={`d-flex align-items-center ${!showJefe}`}>
            <h2 className="mr-3 mb-0">Jefe de servicio</h2>
            <Form.Check
              type="switch"
              id="custom-switch"
              checked={showJefe}
              onChange={(v) => {
                formik.values.show_jefe = !showJefe;                
                setShowJefe(!showJefe);
              }
              }
            />
          </Form.Group>
        </Col>
      </Row>
      {showJefe &&
      <Row>
        <Col className={`${layoutJodit == "MOBILE" && "col-6"}`}>
          <Form.Group>
            <label>Descripción</label>
            <LayoutJodit
              setLayoutJodit={setLayoutJodit}
              layoutJodit={layoutJodit}
            />
            <JoditEditor
              config={config}
              value={formik.values.descripcion_jefe}
              tabIndex={1} // tabIndex of textarea
              onBlur={(value) => formik.setFieldValue("descripcion_jefe", value)}
            />
            <div className="text-danger">{formik.errors?.descripcion_jefe}</div>
          </Form.Group>
        </Col>
      </Row>
      }
    </>
  );
};

export default JefeServicio;
