import React from "react";
import DataGrid, {
  LoadPanel,
  Column,
  FilterRow,
  Paging,
  SearchPanel,
  Export,
  RowDragging,
} from "devextreme-react/data-grid";
//import { onExportarExcel } from '../../utilities/Util'

export const DataGridForm = ({
  dataSource,
  allowColumnReordering = true,
  showBorders = true,
  rowAlternationEnabled = true,
  showRowLines = true,
  hoverStateEnabled = true,
  exportEnabled = false,
  exporting = "archivo",
  focusedRowEnabled = true,
  filterRow = true,
  searchPanel = false,
  defaultPageSize = 10,
  keyExpr,
  columns = [],
  onCellClick = () => null,
  onReorder,
  allowReordering = false,
  columnHidingEnabled = false,
  allowColumnResizing = true,
  remoteOperations = false,
}) => {
  return (
    <DataGrid
      dataSource={dataSource}
      allowColumnReordering={allowColumnReordering}
      showBorders={showBorders}
      rowAlternationEnabled={rowAlternationEnabled}
      showRowLines={showRowLines}
      hoverStateEnabled={hoverStateEnabled}
      //onExporting={(e) => onExportarExcel(e, exporting)}
      focusedRowEnabled={focusedRowEnabled}
      columnHidingEnabled={false}
      allowColumnResizing={allowColumnResizing}
      keyExpr={keyExpr}
      onCellClick={(cell) => onCellClick(cell)}
      remoteOperations={remoteOperations}
    >
      <RowDragging
        allowReordering={allowReordering}
        onReorder={onReorder}
        //showDragIcons={this.state.showDragIcons}
      />
      <LoadPanel enabled />
      <FilterRow visible={filterRow} />
      <SearchPanel visible={searchPanel} highlightCaseSensitive={true} />
      <Paging defaultPageSize={defaultPageSize} />
      <Export enabled={exportEnabled} />

      {columns.map((c, i) => {
        if (c.dataField !== undefined) {
          return (
            <Column
              key={i.toString()}
              dataField={c.dataField}
              caption={c.caption}
              alignment={c.alignment}
              width={c.caption === "Id" ? 50 : c.width}
              allowSorting={c.allowSorting}
              hidingPriority={c.hidingPriority}
              defaultSortOrder={c.defaultSortOrder} // Agregar esta línea
            />
          );
        }

        if (c.dataField === undefined) {
          return (
            <Column
              key={i.toString()}
              dataField={c.dataFields}
              caption={c.caption}
              alignment={c.alignment}
              width={c.caption === "Id" ? 50 : c.width}
              allowSorting={c.allowSorting}
              allowFiltering={c.allowFiltering}
              cellRender={(item) => c.cellRender[c.render](item.data)}
              hidingPriority={c.hidingPriority}
              defaultSortOrder={c.defaultSortOrder} // Agregar esta línea
            />
          );
        }
      })}
    </DataGrid>
  );
};

export default DataGridForm;
