import React from 'react';
import { Row, Col, Card, Table, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Fetch, catchError } from '../../../utils/fetch'
import { connect } from 'react-redux'
import { loadingAction } from '../../../redux/actions';
import DataGridForm from '../../../components/common/DataGridForm';
import { copyToClickBoard } from '../../../utils/slug';
import { formatDate } from '../../../utils/formatDate';
import { AlertConfirm } from '../../../components/alert';
import { EstadoContenido } from '../../../utils/constants';

function ListaBorradoresPrensa({ isLoadingAction, isLoading }) {
    const [contenido, setContenido] = React.useState(null)

    React.useEffect(() => {
        getContenido()

    }, [])

    const getContenido = async () => {
        isLoadingAction(true)
        try {
            const { data } = await Fetch('prensas?_publicationState=preview&published_at_null=true&estado=ER', 'get')
            setContenido(data)
            isLoadingAction(false)
        } catch (error) {            
            isLoadingAction(false)
            catchError(error);
        }
    }

    
const deleteContenido = async (values) => {
    isLoadingAction(true)
    try {
        values.eliminado=true;
        values.estado=EstadoContenido.EnRevision;   
        values.published_at=new Date();              
        delete values.idPadre;
        await Fetch('prensas/'+values.id, 'put', values)
        getContenido()
        isLoadingAction(false)
    } catch (error) {
        console.error('mostrando error', error)
        isLoadingAction(false)
        catchError(error);
    }
}

 function DeleteAlert(title, data) {
    AlertConfirm(title, 'question', (response) => {        
        if (response.isConfirmed) {
            deleteContenido(data)
        }
    })
}


   
 

    
    function Accion(data) {
        return (
            <>
                <Link to={"/prensa/ver-borrador/" + data.id} className="btn btn-sm btn btn-success"><i className="fa fa-pencil" /></Link>{' '}
                <button type="button" className="btn btn-sm btn btn-danger" onClick={() => { DeleteAlert("Está apunto de eliminar este contenido", data) }} ><i className="fa fa-trash" /></button>{' '}
                {/* <a href={`${process.env.REACT_APP_BASE_URL_HOME}/novedades-preview/${data.id}`} target="_blank" className="btn btn-sm btn btn-info"><i className="fa fa-eye" /></a>{' '} */}
            </>
        )
    }

    
    return (
        <React.Fragment>
            <Row>
                <Col md="12">
                    <Card>
                        <Card.Header>
                            <div className="float-right mt-10">
                                <Link to="/prensa/nuevo" className="btn btn-primary btn-rounded box-shadow btn-icon"><i className="fa fa-plus" /> Contenido prensa</Link>
                            </div>
                            Listado de contenido de prensa en revisión
                        </Card.Header>
                        <Card.Body>

                            <DataGridForm dataSource={contenido}
                                keyExpr='id'
                                allowReordering={true}
                                columnHidingEnabled={true}
                                columns={[
                                    { caption: 'Id', dataField: 'id' },
                                    { caption: 'Título', dataField: 'titulo' },
                                    { caption: 'Medio',dataField:'medio'},                                    
                                    { caption: 'Fecha',dataField:'fecha'},
                                    { caption: 'Url',dataField:'url'},                                                                        
                                    { caption: 'Acción', alignment: 'center', render: 'Accion', cellRender: { Accion }, hidingPriority: 0 }
                                ]} />
                          
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    )
}

const mapStateToProps = (state) => {

    return {
        isLoading: state.loading,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        isLoadingAction: (data) => { dispatch(loadingAction(data)) }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ListaBorradoresPrensa);