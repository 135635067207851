/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Form, Row, Col, Button, Card } from "react-bootstrap";
import { Jodit } from "jodit-react";
import { useFormik } from "formik";
import { Fetch, catchError } from "../../../../utils/fetch";
import { useDispatch, useSelector } from "react-redux";
import { AlertInfo, AlertSuccess } from "../../../../components/alert";
import { loadingAction } from "../../../../redux/actions";
import MediaUploader from "../../../../utils/media-uploader";
import { validateModel } from "./validationSchema";
import { EstadoContenido } from "../../../../utils/constants";
import { removeSpecial } from "../../../../utils/tools";
import { useHistory } from "react-router-dom";

const VerHomeEstudia = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.loading);
  const history = useHistory();

  function preparePaste(jodit) {
    jodit.e.on(
      "paste",
      (e) => {
        jodit.e.stopPropagation("paste");
        let plainText = Jodit.modules.Helpers.getDataTransfer(e).getData(
          Jodit.constants.TEXT_PLAIN
        );
        plainText = plainText.normalize("NFKC").replaceAll("\n", "<br/>");
        plainText = removeSpecial(plainText);

        jodit.s.insertHTML(plainText);
        return false;
      },
      { top: true }
    );
  }
  Jodit.plugins.add("preparePaste", preparePaste);

  const config = {
    readonly: false, // all options from https://xdsoft.net/jodit/doc/
    height: 500,
    language: "es",
  };
  const permissions = useSelector((state) => state.permissions);
  const canPublish = permissions["seccion-home-estudia"].aprobar.enabled;

  React.useEffect(() => {
    getHomeEstudia();
  }, []);

  const getHomeEstudia = async (values) => {
    dispatch(loadingAction(true));

    try {
      const { data } = await Fetch("seccion-home-estudias", "get");
      let valores = data[0];

      setValues(valores);
      dispatch(loadingAction(false));
    } catch (error) {
      catchError(error);
      dispatch(loadingAction(false));
    }
  };

  const submit = async (values) => {
    dispatch(loadingAction(true));
    values.estado = EstadoContenido.EnRevision;
    values.published_at = null;

    try {
      try {
        await Fetch(`seccion-home-estudias/delete-by-estado/ER`, "delete");
      } catch (error) {}

      await Fetch(`seccion-home-estudias`, "post", values);
      AlertSuccess(
        "Home estudia actualizado con éxito, pendiente de revisión."
      );

      dispatch(loadingAction(false));
      history.push("/configuracion/home-estudia/revision/");
    } catch (error) {
      if (error.response.status === 500) {
        AlertInfo("Ya existe un registro pendiente por revisión");
      }
      dispatch(loadingAction(false));
    }
  };

  const {
    values,
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue,
    setValues,
    errors,
  } = useFormik({
    initialValues: {
      titulo: "",
      descripcion: "",
      foto: "",
      boton: {
        nombre: "",
        url: "",
        urlInterna: false,
      },
    },
    enableReinitialize: true,
    validationSchema: validateModel,
    onSubmit: (values) => submit(values),
  });

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit}>
        <Row>
          <Col>
            <Card>
              <Card.Header>
                <Row>
                  <Col>Información de home estudia - Publicado</Col>
                </Row>
              </Card.Header>
              <Card.Body>
                <Row>
                  <Col>
                    <Form.Group>
                      <label>Título</label>
                      <input
                        type="text"
                        name="titulo"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="form-control"
                        value={values.titulo}
                      />
                      <div className="text-danger">{errors?.titulo}</div>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group>
                      <label>Descripción</label>
                      <textarea
                        name="descripcion"
                        onChange={handleChange}
                        className="form-control"
                        value={values.descripcion}
                      ></textarea>
                      <div className="text-danger">{errors?.descripcion}</div>
                    </Form.Group>
                  </Col>
                </Row>
                {Object.keys(values.foto).length > 0 && (
                  <Row>
                    <Col>
                      <Form.Group>
                        <img
                          src={values?.foto[0]?.url || values?.foto?.url}
                          alt="vista-previa"
                          className="img-fluid w-50"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                )}
                <Row>
                  <Col>
                    <Form.Group>
                      <label>Foto</label>
                      <MediaUploader
                        setFieldValue={setFieldValue}
                        field={"foto"}
                        tipoMedia="imagen"
                        ancho={300}
                        alto={300}
                      />
                      <div className="text-danger">{errors?.foto}</div>
                      <span className="text-muted">
                        Se recomienda una imagen de 300px de ancho x 300px de
                        alto. En formato JPG o PNG.
                      </span>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group>
                      <label>Botón</label>
                      <input
                        type="text"
                        name="boton.nombre"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="form-control"
                        value={values.boton.nombre}
                      />
                      <div className="text-danger">{errors?.boton?.nombre}</div>

                      <label>Url</label>
                      <input
                        type="text"
                        name="boton.url"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="form-control"
                        value={values.boton.url}
                      />
                      <div className="text-danger">{errors?.boton?.url}</div>

                      <label>¿El enlace es interno?</label>
                      <select
                        className="form-control"
                        name="boton.urlInterna"
                        value={values.boton.urlInterna}
                        onChange={handleChange}
                      >
                        <option value={true}>Si</option>
                        <option value={false}>No</option>
                      </select>
                    </Form.Group>
                  </Col>
                </Row>
                <Button
                  type="button"
                  onClick={() => handleSubmit()}
                  disabled={isLoading}
                  className="btn btn-success btn-icon mt-2"
                >
                  <i className="fa fa-floppy-o " />
                  Guardar
                </Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </form>
    </React.Fragment>
  );
};
export default VerHomeEstudia;
