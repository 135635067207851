import React from "react";
import { Row, Col, Card, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Fetch, catchError } from "../../../utils/fetch";
import { AlertConfirm } from "../../../components/alert";
import { connect } from "react-redux";
import { loadingAction } from "../../../redux/actions";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import DataGridForm from "../../../components/common/DataGridForm";
import { formatDate } from "../../../utils/formatDate";
function ListaNuestrosAccesos({ isLoadingAction, isLoading }) {
  const [contenido, setContenido] = React.useState(null);

  React.useEffect(() => {
    getContenido();
  }, []);

  const getContenido = async () => {
    isLoadingAction(true);
    try {
      const qs = require("qs");
      const query = qs.stringify({
        sort: ["orden:asc"],
        filters: {
          eliminado: {
            $eq: "false",
          },
        },
      });
      const { data } = await Fetch(
        "nuestros-accesos?_eliminado=false&_sort=orden:ASC",
        "get"
      );

      setContenido(data);
      isLoadingAction(false);
    } catch (error) {
      isLoadingAction(false);
      catchError(error);
    }
  };

  const deleteContenido = async (id) => {
    isLoadingAction(true);
    try {
      const params = {
        eliminado: true,
      };
      await Fetch("nuestros-accesos/" + id, "put", params);
      getContenido();
      isLoadingAction(false);
    } catch (error) {
      isLoadingAction(false);
      catchError(error);
    }
  };

  const ordenContenido = async (e) => {
    try {
      isLoadingAction(true);

      let request = [];
      e.forEach(async (item, indice) => {
        const params = {
          orden: indice,
        };
        request.push(Fetch("nuestros-accesos/" + item.id, "put", params));
      });

      Promise.all(request).then((response) => {
        isLoadingAction(false);
      });
    } catch (error) {
      isLoadingAction(false);
      catchError(error);
    }
  };

  function RenderAlert(title, id) {
    AlertConfirm(title, "question", (response) => {
      if (response.isConfirmed) {
        deleteContenido(id);
      }
    });
  }

  const handleDragEnd = async (e) => {
    if (!e.destination) return;
    let tempData = Array.from(contenido);
    let [source_data] = tempData.splice(e.source.index, 1);
    tempData.splice(e.destination.index, 0, source_data);
    tempData.map((item, indice) => (item.orden = indice));
    setContenido(tempData);
    await ordenContenido(tempData);
  };

  const handlerOnOff = async (prmId, value) => {
    isLoadingAction(true);
    try {
      const params = {
        onOff: value,
      };
      await Fetch("nuestros-accesos/" + prmId, "put", params);
      getContenido();
      isLoadingAction(false);
    } catch (error) {
      isLoadingAction(false);
      catchError(error);
    }
  };

  const onReorder = async (e) => {
    const visibleRows = e.component.getVisibleRows();
    const newData = [...contenido];
    const toIndex = newData.indexOf(visibleRows[e.toIndex].data);
    const fromIndex = newData.indexOf(e.itemData);

    newData.splice(fromIndex, 1);
    newData.splice(toIndex, 0, e.itemData);

    setContenido(newData);
    await ordenContenido(newData);
  };

  function FActualizacion(data) {
    return formatDate(data?.updated_at);
  }

  function OnOff(data) {
    return (
      <div className="custom-control custom-switch">
        <input
          type="checkbox"
          checked={data.onOff}
          className="custom-control-input"
          onChange={(v) => handlerOnOff(data.id, v.target.checked)}
          id={"csPublicado" + data.id}
        />
        <label
          role="button"
          className="custom-control-label"
          for={"csPublicado" + data.id}
        ></label>
      </div>
    );
  }

  function ImagenAcceso(data) {
    return data?.imagenAcceso?.formats?.thumbnail?.url ? (
      <>
        <a href={data.imagenAcceso.url} target="_blank">
          <img
            src={data.imagenAcceso.formats.thumbnail.url}
            style={{
              width: "64px",
              height: "64px",
              borderRadius: "32px",
              objectFit: "cover",
            }}
            className=""
            alt="images"
          />
        </a>
      </>
    ) : null;
  }

  function ImagenFondo(data) {
    return data?.imagenFondo?.formats?.thumbnail?.url ? (
      <>
        <a href={data.imagenFondo.url} target="_blank">
          <img
            src={data.imagenFondo.formats.thumbnail.url}
            style={{
              width: "64px",
              height: "64px",
              borderRadius: "32px",
              objectFit: "cover",
            }}
            className=""
            alt="images"
          />
        </a>
      </>
    ) : null;
  }

  function TextoFondo(data) {
    return data?.textoFondo?.formats?.thumbnail?.url ? (
      <>
        <a href={data.textoFondo.url} target="_blank">
          <img
            src={data.textoFondo.formats.thumbnail.url}
            style={{
              width: "64px",
              height: "64px",
              borderRadius: "32px",
              objectFit: "cover",
            }}
            className=""
            alt="images"
          />
        </a>
      </>
    ) : null;
  }

  function Accion(data) {
    return (
      <>
        <Link
          to={"/nuestros-accesos/ver/" + data.id}
          className="btn btn-sm btn btn-success"
        >
          <i className="fa fa-pencil" />
        </Link>{" "}
        <button
          type="button"
          className="btn btn-sm btn btn-danger"
          onClick={() => {
            RenderAlert("Está apunto de eliminar este contenido", data.id);
          }}
        >
          <i className="fa fa-trash" />
        </button>{" "}
      </>
    );
  }

  return (
    <React.Fragment>
      <Row>
        <Col md="12">
          <Card>
            <Card.Header>
              <div className="float-right mt-10">
                <Link
                  to="/nuestros-accesos/nuevo"
                  className="btn btn-primary btn-rounded box-shadow btn-icon"
                >
                  <i className="fa fa-plus" /> Nuevo Acceso
                </Link>
              </div>
              Listado de nuestros accesos publicados
            </Card.Header>
            <Card.Body>
              <DataGridForm
                dataSource={contenido}
                keyExpr="id"
                allowReordering={true}
                onReorder={onReorder}
                columnHidingEnabled={true}
                columns={[
                  { caption: "Id", dataField: "id" },
                  {
                    caption: "ImagenAcceso",
                    alignment: "center",
                    render: "ImagenAcceso",
                    cellRender: { ImagenAcceso },
                  },
                  {
                    caption: "ImagenFondo",
                    alignment: "center",
                    render: "ImagenFondo",
                    cellRender: { ImagenFondo },
                  },
                  {
                    caption: "TextoFondo",
                    alignment: "center",
                    render: "TextoFondo",
                    cellRender: { TextoFondo },
                  },
                  { caption: "Creado por", dataField: "creado_por.username" },
                  {
                    caption: "Actualizado por",
                    dataField: "actualizado_por.username",
                  },
                  {
                    caption: "Ult. Actualización",
                    render: "FActualizacion",
                    cellRender: { FActualizacion },
                  },
                  {
                    caption: "Inactivo/Activo",
                    alignment: "center",
                    render: "OnOff",
                    cellRender: { OnOff },
                  },
                  {
                    caption: "Acción",
                    alignment: "center",
                    render: "Accion",
                    cellRender: { Accion },
                    hidingPriority: 0,
                  },
                ]}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    isLoadingAction: (data) => {
      dispatch(loadingAction(data));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ListaNuestrosAccesos);
