/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React from "react";
import { Form, Row, Col, Button, Card } from "react-bootstrap";
import { useFormik } from "formik";
import { Fetch, catchError } from "../../../../utils/fetch";
import { useDispatch, useSelector } from "react-redux";
import ImageUploader from "../../../../utils/image-uploader";
import { AlertSuccess } from "../../../../components/alert";
import { loadingAction } from "../../../../redux/actions";
import { useHistory, useParams } from "react-router-dom";
import { EstadoContenido } from "../../../../utils/constants";

const VerParaEliminarSlide = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.loading);
  const [formFields, setFormFields] = React.useState([]);

  const history = useHistory();
  React.useEffect(() => {
    if (id) {
      getHomeSlideById();
    }
  }, [id]);

  const getHomeSlideById = async (values) => {
    dispatch(loadingAction(true));
    try {
      const { data } = await Fetch(
        "home-slides/" + id + "?_eliminado=true",
        "get"
      );
      setValues(data);
      setFormFields(data.caption.botones);
      dispatch(loadingAction(false));
    } catch (error) {
      catchError(error);
      dispatch(loadingAction(false));
    }
  };

  const submit = async (values) => {
    if (formFields.length > 0) {
      values.caption.botones = formFields;
    }

    dispatch(loadingAction(true));
    values.published_at = new Date();
    try {
      const { data } = await Fetch("home-slides/" + id, "delete");

      AlertSuccess("Home slide eliminado con éxito");
      dispatch(loadingAction(false));
      history.push("/configuracion/home-slide/lista-por-eliminar");
    } catch (error) {
      catchError(error);
      dispatch(loadingAction(false));
    }
  };

  const onCancelar = async (values) => {
    try {
      dispatch(loadingAction(true));

      const params = {
        eliminado: false,
        published_at:
          values.estado === EstadoContenido.EnRevision
            ? null
            : values.published_at,
      };

      const { data } = await Fetch("home-slides/" + id, "put", params);

      dispatch(loadingAction(false));
      AlertSuccess("Se ha cancelado la eliminación del slide con éxito");

      //history.push("/configuracion/home-slide/lista-por-eliminar");
      if (values.estado === EstadoContenido.EnRevision) {
        history.push("/configuracion/home-slide/lista-borradores");
      } else {
        history.push("/configuracion/home-slide/lista");
      }
    } catch (error) {
      dispatch(loadingAction(false));
      catchError(error);
    }
  };

  const {
    values,
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue,
    setValues,
  } = useFormik({
    initialValues: {
      tipo: "", //IMAGEN O VIDEO
      media: {},
      caption: {
        h1: "",
        h3: "",
        botones: [
          {
            nombre: "",
            url: "",
            estilo: "estilo1",
          },
        ],
      },
      enlace: "",
      enlaceInterno: false,
    },
    enableReinitialize: true,
    onSubmit: (values) => submit(values),
  });

  const addArray = () => {
    let object = { nombre: "", url: "", estilo: "" };
    setFormFields([...formFields, object]);
  };

  const removeArray = (index) => {
    let data = [...formFields];
    data.splice(index, 1);
    setFormFields(data);
  };

  const handleFormChange = (event, index) => {
    let data = [...formFields];
    data[index][event.target.name] = event.target.value;
    setFormFields(data);
  };

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit}>
        <Row>
          <Col>
            <Card>
              <Card.Header>
                <Row>
                  <Col>Información del slide</Col>
                </Row>
              </Card.Header>
              <Card.Body>
                <Row>
                  <Col>
                    <Form.Group>
                      <label>Tipo</label>
                      <select
                        name="tipo"
                        disabled
                        onChange={handleChange}
                        className="form-control"
                        value={values.tipo}
                      >
                        <option>Seleccionar</option>
                        <option value="VIDEO">VIDEO</option>
                        <option value="IMAGEN">IMAGEN</option>
                      </select>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group>
                      <img className="img-fluid" src={values.media.url} />
                    </Form.Group>
                  </Col>
                </Row>
                {values.tipo == "VIDEO" && (
                  <>
                    <Row>
                      <Col>
                        <Form.Group>
                          <label>H1 Texto</label>
                          <input
                            type="text"
                            disabled
                            name="caption.h1"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className="form-control"
                            value={values.caption.h1}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group>
                          <label>H3 Texto</label>
                          <input
                            type="text"
                            disabled
                            name="caption.h3"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className="form-control"
                            value={values.caption.h3}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <h2>Botones</h2>
                        {formFields &&
                          formFields.map((data, index) => {
                            return (
                              <Row className="border m-3">
                                <Col
                                  lg={12}
                                  className="p-0 d-flex justify-content-end"
                                >
                                  <button
                                    type="button"
                                    disabled
                                    className="btn btn-danger"
                                    onClick={() => removeArray(index)}
                                  >
                                    x
                                  </button>
                                </Col>

                                <Col>
                                  <div className="form-group">
                                    <label>Nombre</label>
                                    <input
                                      type="text"
                                      disabled
                                      className="form-control"
                                      name="nombre"
                                      onChange={(event) =>
                                        handleFormChange(event, index)
                                      }
                                      value={data.nombre}
                                    />
                                  </div>
                                </Col>
                                <Col>
                                  <div className="form-group">
                                    <label>Url</label>
                                    <input
                                      type="text"
                                      disabled
                                      className="form-control"
                                      name="url"
                                      onChange={(event) =>
                                        handleFormChange(event, index)
                                      }
                                      value={data.url}
                                    />
                                  </div>
                                </Col>
                                <Col>
                                  <div className="form-group">
                                    <label>Estilo</label>
                                    <select
                                      className="form-control"
                                      disabled
                                      name="estilo"
                                      onChange={(event) =>
                                        handleFormChange(event, index)
                                      }
                                      value={data.estilo}
                                    >
                                      <option>Seleccionar</option>
                                      <option value={"estilo1"}>
                                        Estilo 1
                                      </option>
                                      <option value={"estilo2"}>
                                        Estilo 2
                                      </option>
                                      <option value={"estilo3"}>
                                        Estilo 3
                                      </option>
                                      <option value={"estilo4"}>
                                        Estilo 4
                                      </option>
                                    </select>
                                  </div>
                                </Col>
                              </Row>
                            );
                          })}
                      </Col>
                    </Row>
                  </>
                )}
                <Row>
                  <Col>
                    <Form.Group>
                      <label>Enlace</label>
                      <input
                        type="text"
                        name="enlace"
                        onChange={handleChange}
                        className="form-control"
                        value={values.enlace}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group>
                      <label>¿El enlace es interno?</label>
                      <select
                        disabled
                        className="form-control"
                        name="enlaceInterno"
                        value={values.enlaceInterno}
                        onChange={handleChange}
                      >
                        <option value={true}>Si</option>
                        <option value={false}>No</option>
                      </select>
                    </Form.Group>
                  </Col>
                </Row>

                <Row className="mt-5">
                  <Col className="col col-lg-2">
                    <Button
                      type="button"
                      onClick={() => handleSubmit()}
                      disabled={isLoading}
                      className="btn btn-danger btn-icon"
                    >
                      <i className="fa fa-trash" />
                      Eliminar
                    </Button>
                  </Col>
                  <Col className="col col-lg-2">
                    <Button
                      type="button"
                      onClick={() => onCancelar(values)}
                      disabled={isLoading}
                      className="btn btn-primary btn-icon"
                    >
                      <i className="fa fa-undo " />
                      Cancelar
                    </Button>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </form>
    </React.Fragment>
  );
};
export default VerParaEliminarSlide;
