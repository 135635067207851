import * as Yup from 'yup';

export const validateModel = Yup.object().shape({

    nombre: Yup.string().required('Campo obligatorio'),    
    template2: Yup.object().shape({
        // fondo: Yup.string().required('Campo obligatorio'),   
        sidemenu: Yup.array(
            Yup.object().shape({
                nombre: Yup.string().required('Campo obligatorio'),
                contenido: Yup.string().required('Campo obligatorio'),                     
            })
        )           
    })


});