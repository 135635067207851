import Swal from "sweetalert2";

export const AlertConfirm = (msg, type, callback) => {
  return Swal.fire({
    title: "Está seguro?",
    text: msg,
    icon: type,
    showCancelButton: true,
    confirmButtonText: "Si",
    cancelButtonText: "No",
  }).then((result) => {
    return callback(result);
  });
};

export const AlertConfirmDeleteAll = (
  msg,
  type,
  callback,
  title,
  confirmButtonText,
  cancelButtonText,
  reverseButtons,
  html
) => {
  return Swal.fire({
    html,
    title,
    // text: msg,
    icon: type,
    showCancelButton: true,
    confirmButtonText,
    cancelButtonText,
    reverseButtons,
  }).then((result) => {
    return callback(result);
  });
};

export const AlertSuccess = (msg, callback) => {
  return Swal.fire("Éxito!", msg, "success");
};

export const AlertError = (msg, callback) => {
  return Swal.fire("Error!", msg, "error");
};

export const AlertInfo = (msg, callback) => {
  return Swal.fire("Info!", msg, "info");
};
