import * as Yup from 'yup';

export const validateModel = Yup.object().shape({

    nombre: Yup.string().required('Campo obligatorio'),    
    template3: Yup.object().shape({
        titulo: Yup.string().required('Campo obligatorio'),
        // imagen: Yup.string().required('Campo obligatorio'),
        contenido: Yup.string().required('Campo obligatorio'),
    })


});