import * as Yup from 'yup';

export const validateModel = Yup.object().shape({

    Menu: Yup.object().shape({

        Menu: Yup.object().shape({

            nombre: Yup.string().required('Campo obligatorio'),
            url: Yup.string().required('Campo obligatorio'),
            esBoton: Yup.bool().required('Campo obligatorio'),
            onClick: Yup.string().when("esBoton", {
                is: true,
                then: Yup.string().required("Campo obligatorio")
            })            
        }),
        SubmenuColumna1: Yup.array(
            Yup.object().shape({
                nombre: Yup.string().required('Campo obligatorio'),
                url: Yup.string().required('Campo obligatorio'),
                esBoton: Yup.bool().required('Campo obligatorio'),
                onClick: Yup.string().when("esBoton", {
                    is: true,
                    then: Yup.string().required("Campo obligatorio")
                }),                
            })
        ),
        SubmenuColumna2: Yup.array(
            Yup.object().shape({
                nombre: Yup.string().required('Campo obligatorio'),
                url: Yup.string().required('Campo obligatorio'),
                esBoton: Yup.bool().required('Campo obligatorio'),
                onClick: Yup.string().when("esBoton", {
                    is: true,
                    then: Yup.string().required("Campo obligatorio")
                }),                
            })
        )
    })

});