/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Form, Row, Col, Button, Card } from "react-bootstrap";
import { useFormik } from "formik";
import { Fetch, catchError } from "../../../utils/fetch";
import { useDispatch, useSelector } from "react-redux";
import { AlertSuccess, AlertInfo } from "../../../components/alert";
import { loadingAction } from "../../../redux/actions";
import { useHistory, useParams } from "react-router-dom";
import MediaUploader from "../../../utils/media-uploader";
import { EstadoContenido } from "../../../utils/constants";

const VerParaEliminarAcceso = () => {
  const params = useParams();
  const id = params.id;
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.loading);
  const permissions = useSelector((state) => state.permissions);
  const canPublish = permissions.novedades.aprobar.enabled;
  const history = useHistory();

  React.useEffect(() => {
    if (id) {
      getAccesosById();
    }
  }, []);

  const getAccesosById = async (values) => {
    dispatch(loadingAction(true));
    try {
      const { data } = await Fetch(
        `nuestros-accesos/${id}?_eliminado=true`,
        "get"
      );
      setValues(data);
      dispatch(loadingAction(false));
    } catch (error) {
      catchError(error);
      dispatch(loadingAction(false));
    }
  };

  const submit = async (values) => {
    values.published_at = new Date();
    dispatch(loadingAction(true));
    try {
      const { data } = await Fetch("nuestros-accesos/" + id, "delete");

      if (canPublish) AlertSuccess("Acceso eliminado con éxito");
      else
        AlertInfo(
          "Acceso eliminado con éxito.<br/>Recuerde que falta su confirmacion para aprobar.<br/> Nota: Por favor, notificar al responsable de publicar"
        );

      dispatch(loadingAction(false));
      history.push("/nuestros-accesos/lista-por-eliminar");
    } catch (error) {
      catchError(error);
      dispatch(loadingAction(false));
    }
  };

  const onCancelar = async (values) => {
    try {
      dispatch(loadingAction(true));

      const params = {
        eliminado: false,
        published_at:
          values.estado === EstadoContenido.EnRevision
            ? null
            : values.published_at,
      };

      const { data } = await Fetch("nuestros-accesos/" + id, "put", params);

      dispatch(loadingAction(false));
      AlertSuccess("Se ha cancelado la eliminación del acceso con éxito");

      //history.push("/nuestros-accesos/lista-por-eliminar");

      if (values.estado === EstadoContenido.EnRevision) {
        history.push("/nuestros-accesos/lista-borradores");
      } else {
        history.push("/nuestros-accesos/lista");
      }
    } catch (error) {
      dispatch(loadingAction(false));
      catchError(error);
    }
  };

  const { values, handleSubmit, handleChange, setFieldValue, setValues } =
    useFormik({
      initialValues: {
        imagenAcceso: {},
        imagenFondo: {},
        textoFondo: {},
        tieneEnlace: false,
        enlace: "",
        enlaceInterno: false,
        eliminado: false,
      },
      enableReinitialize: true,
      onSubmit: (values) => submit(values),
    });

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit}>
        <Row>
          <Col>
            <Card>
              <Card.Header>
                <Row>
                  <Col>Información del acceso</Col>
                </Row>
              </Card.Header>
              <Card.Body>
                {/* Imagen de acceso */}
                {Object.keys(values.imagenAcceso).length > 0 && (
                  <Row>
                    <Col>
                      <Form.Group>
                        <label>Imagen de acceso</label>
                        <img
                          src={values.imagenAcceso.url}
                          alt="vista-previa"
                          className="img-fluid w-50"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                )}

                {/* Imagen de fondo */}
                {Object.keys(values.imagenFondo).length > 0 && (
                  <Row>
                    <Col>
                      <Form.Group>
                        <label>Imagen de fondo</label>
                        <img
                          src={values.imagenFondo.url}
                          alt="vista-previa"
                          className="img-fluid w-50"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                )}

                {/* Texto de fondo */}
                {Object.keys(values.textoFondo).length > 0 && (
                  <Row>
                    <Col>
                      <Form.Group>
                        <label>Texto de fondo</label>
                        <img
                          src={values.textoFondo.url}
                          alt="vista-previa"
                          className="img-fluid w-50"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                )}

                <Row>
                  <Col>
                    <Form.Group>
                      <label>Tiene enlace</label>
                      <select
                        className="form-control"
                        disabled
                        name="tieneEnlace"
                        value={values.tieneEnlace}
                        onChange={handleChange}
                      >
                        <option value={true}>Si</option>
                        <option value={false}>No</option>
                      </select>
                    </Form.Group>
                  </Col>
                </Row>

                {values.tieneEnlace === "true" || values.tieneEnlace ? (
                  <>
                    <Row>
                      <Col>
                        <Form.Group>
                          <label>Enlace</label>
                          <input
                            type="text"
                            name="enlace"
                            disabled
                            onChange={handleChange}
                            className="form-control"
                            value={values.enlace}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group>
                          <label>¿El enlace es interno?</label>
                          <select
                            className="form-control"
                            disabled
                            name="enlaceInterno"
                            value={values.enlaceInterno}
                            onChange={handleChange}
                          >
                            <option value={true}>Si</option>
                            <option value={false}>No</option>
                          </select>
                        </Form.Group>
                      </Col>
                    </Row>
                  </>
                ) : null}

                <Row className="mt-5">
                  <Col className="col col-lg-2">
                    <Button
                      type="button"
                      onClick={() => handleSubmit()}
                      disabled={isLoading}
                      className="btn btn-danger btn-icon"
                    >
                      <i className="fa fa-trash" />
                      Eliminar
                    </Button>
                  </Col>
                  <Col className="col col-lg-2">
                    <Button
                      type="button"
                      onClick={() => onCancelar(values)}
                      disabled={isLoading}
                      className="btn btn-primary btn-icon"
                    >
                      <i className="fa fa-undo " />
                      Cancelar
                    </Button>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </form>
    </React.Fragment>
  );
};
export default VerParaEliminarAcceso;
