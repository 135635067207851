import React from "react";
import { Row, Col, Card, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Fetch, catchError } from "../../../utils/fetch";
import { connect } from "react-redux";
import { loadingAction } from "../../../redux/actions";
import DataGridForm from "../../../components/common/DataGridForm";
import { formatDate } from "../../../utils/formatDate";

function ListaAccesoX({ isLoadingAction, isLoading }) {
  const [contenido, setContenido] = React.useState(null);

  React.useEffect(() => {
    getContenido();
  }, []);

  const getContenido = async () => {
    isLoadingAction(true);
    try {
      const { data } = await Fetch(
        "nuestros-accesos?_publicationState=preview&published_at_null=true&estado=EA",
        "get"
      );
      setContenido(data);
      isLoadingAction(false);
    } catch (error) {
      console.error("mostrando error", error);
      isLoadingAction(false);
      catchError(error);
    }
  };

  function FActualizacion(data) {
    return formatDate(data?.updated_at);
  }

  function ImagenAcceso(data) {
    return data?.imagenAcceso?.formats?.thumbnail?.url ? (
      <>
        <a href={data.imagenAcceso.url} target="_blank">
          <img
            src={data.imagenAcceso.formats.thumbnail.url}
            style={{
              width: "64px",
              height: "64px",
              borderRadius: "32px",
              objectFit: "cover",
            }}
            className=""
            alt="images"
          />
        </a>
      </>
    ) : null;
  }

  function ImagenFondo(data) {
    return data?.imagenFondo?.formats?.thumbnail?.url ? (
      <>
        <a href={data.imagenFondo.url} target="_blank">
          <img
            src={data.imagenFondo.formats.thumbnail.url}
            style={{
              width: "64px",
              height: "64px",
              borderRadius: "32px",
              objectFit: "cover",
            }}
            className=""
            alt="images"
          />
        </a>
      </>
    ) : null;
  }

  function TextoFondo(data) {
    return data?.textoFondo?.formats?.thumbnail?.url ? (
      <>
        <a href={data.textoFondo.url} target="_blank">
          <img
            src={data.textoFondo.formats.thumbnail.url}
            style={{
              width: "64px",
              height: "64px",
              borderRadius: "32px",
              objectFit: "cover",
            }}
            className=""
            alt="images"
          />
        </a>
      </>
    ) : null;
  }

  function Accion(data) {
    return (
      <>
        <Link
          to={"/nuestros-accesos/aprobar/" + data.id}
          className="btn btn-sm btn btn-success"
        >
          <i className="fa fa-pencil" />
        </Link>{" "}
      </>
    );
  }

  return (
    <React.Fragment>
      <Row>
        <Col md="12">
          <Card>
            <Card.Header>Listado de nuestros accesos por aprobar</Card.Header>
            <Card.Body>
              <DataGridForm
                dataSource={contenido}
                keyExpr="id"
                allowReordering={true}
                columnHidingEnabled={true}
                columns={[
                  { caption: "Id", dataField: "id" },
                  {
                    caption: "ImagenAcceso",
                    alignment: "center",
                    render: "ImagenAcceso",
                    cellRender: { ImagenAcceso },
                  },
                  {
                    caption: "ImagenFondo",
                    alignment: "center",
                    render: "ImagenFondo",
                    cellRender: { ImagenFondo },
                  },
                  {
                    caption: "TextoFondo",
                    alignment: "center",
                    render: "TextoFondo",
                    cellRender: { TextoFondo },
                  },
                  { caption: "Creado por", dataField: "creado_por.username" },
                  {
                    caption: "Actualizado por",
                    dataField: "actualizado_por.username",
                  },
                  {
                    caption: "Ult. Actualización",
                    render: "FActualizacion",
                    cellRender: { FActualizacion },
                  },
                  {
                    caption: "Acción",
                    alignment: "center",
                    render: "Accion",
                    cellRender: { Accion },
                    hidingPriority: 0,
                  },
                ]}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    isLoadingAction: (data) => {
      dispatch(loadingAction(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ListaAccesoX);
