import { Row, Col, Card, OverlayTrigger, Tooltip } from "react-bootstrap";
import CustomStore from "devextreme/data/custom_store";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import React from "react";

import DataGridForm from "../../../components/common/DataGridForm";
import { Fetch, catchError } from "../../../utils/fetch";
import { AlertConfirm } from "../../../components/alert";
import { loadingAction } from "../../../redux/actions";
import { copyToClickBoard } from "../../../utils/slug";
import { formatDate } from "../../../utils/formatDate";

function ListaNovedades({ isLoadingAction }) {
  const [contenido, setContenido] = React.useState(null);
  const [state, setState] = React.useState({
    filter: "",
    random: Math.random(),
  });

  React.useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    setContenido(
      new CustomStore({
        key: "id",
        load(loadOptions) {
          const resultado = Fetch(`novedades/aprobadas/filtrar`, "post", {
            pageIndex: loadOptions.skip / loadOptions.take + 1,
            pageSize: loadOptions.take,
            filter: state.filter,
            random: state.random,
            filterColumns: loadOptions.filter,
            sort: loadOptions.sort,
          }).then((response) => {
            return {
              data: response?.data?.novedades ?? [],
              totalCount: parseInt(response?.data?.TotalCount) ?? 0,
            };
          });

          return resultado;
        },
      })
    );
  };

  const deleteContenido = async (id) => {
    isLoadingAction(true);
    try {
      const params = {
        eliminado: true,
      };

      await Fetch("novedades/" + id, "put", params);
      setState({ ...state, random: Math.random() });
      getData();

      isLoadingAction(false);
    } catch (error) {
      console.error("mostrando error", error);
      isLoadingAction(false);
      catchError(error);
    }
  };

  function RenderAlert(title, id) {
    AlertConfirm(title, "question", (response) => {
      if (response.isConfirmed) {
        deleteContenido(id);
      }
    });
  }

  const ordenContenido = async (e) => {
    try {
      isLoadingAction(true);

      let request = [];
      e.forEach(async (item, indice) => {
        const params = {
          orden: indice,
        };
        request.push(Fetch("novedades/" + item.id, "put", params));
      });

      Promise.all(request).then((response) => {
        isLoadingAction(false);
      });
    } catch (error) {
      isLoadingAction(false);
      catchError(error);
    }
  };

  const handlerOnOff = async (prmId, value) => {
    isLoadingAction(true);
    try {
      const params = {
        onOff: value,
      };

      await Fetch("novedades/" + prmId, "put", params);
      setState({ ...state, random: Math.random() });
      getData();
      isLoadingAction(false);
    } catch (error) {
      isLoadingAction(false);
      catchError(error);
    }
  };

  const onReorder = async (e) => {
    const visibleRows = e.component.getVisibleRows();
    const newData = [...contenido];
    const toIndex = newData.indexOf(visibleRows[e.toIndex].data);
    const fromIndex = newData.indexOf(e.itemData);

    newData.splice(fromIndex, 1);
    newData.splice(toIndex, 0, e.itemData);

    setContenido(newData);
    await ordenContenido(newData);
  };

  function FActualizacion(data) {
    return formatDate(data?.updated_at);
  }

  function OnOff(data) {
    return (
      <div className="custom-control custom-switch">
        <input
          type="checkbox"
          checked={data.onOff}
          className="custom-control-input"
          onChange={(v) => handlerOnOff(data.id, v.target.checked)}
          id={"csPublicado" + data.id}
        />
        <label
          role="button"
          className="custom-control-label"
          for={"csPublicado" + data.id}
        ></label>
      </div>
    );
  }

  function Imagen(data) {
    return data?.imagen?.formats?.thumbnail?.url ? (
      <>
        <img
          src={data.imagen.formats.thumbnail.url}
          style={{
            width: "64px",
            height: "64px",
            borderRadius: "32px",
            objectFit: "cover",
          }}
          className=""
          alt="images"
        />
      </>
    ) : null;
  }

  function Destacado(data) {
    return data.destacado ? (
      <span className="badge bg-success">Si</span>
    ) : (
      <span className="badge bg-secondary">No</span>
    );
  }

  function Accion(data) {
    return (
      <>
        <Link
          to={"/novedades/ver/" + data.id}
          className="btn btn-sm btn btn-success"
        >
          <i className="fa fa-pencil" />
        </Link>{" "}
        <a
          href={`${process.env.REACT_APP_BASE_URL_HOME}/novedades-preview/${data.slug}`}
          target="_blank"
          className="btn btn-sm btn btn-info"
        >
          <i className="fa fa-eye" />
        </a>{" "}
        <button
          type="button"
          className="btn btn-sm btn btn-danger"
          onClick={() => {
            RenderAlert("Está apunto de eliminar este contenido", data.id);
          }}
        >
          <i className="fa fa-trash" />
        </button>{" "}
      </>
    );
  }

  function Slug(data) {
    return (
      <>
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip id={`tooltip-top`}>Hacer click para copiar url</Tooltip>
          }
        >
          <i
            className="fa fa-copy fa-lg text-info"
            role="button"
            onClick={() => copyToClickBoard("novedades/" + data.slug)}
          ></i>
        </OverlayTrigger>
      </>
    );
  }

  return (
    <React.Fragment>
      <Row>
        <Col md="12">
          <Card>
            <Card.Header>
              <div className="float-right mt-10">
                <Link
                  to="/novedades/nuevo"
                  className="btn btn-primary btn-rounded box-shadow btn-icon"
                >
                  <i className="fa fa-plus" /> Nueva Novedad
                </Link>
              </div>
              Listado de novedades publicadas
            </Card.Header>
            <Card.Body>
              <DataGridForm
                dataSource={contenido}
                remoteOperations={true}
                columns={[
                  {
                    caption: "Id",
                    dataField: "id",
                    defaultSortOrder: "desc",
                  },
                  {
                    caption: "Imagen",
                    alignment: "center",
                    render: "Imagen",
                    cellRender: { Imagen },
                  },
                  { caption: "Título", dataField: "titulo" },
                  {
                    caption: "Destacado",
                    alignment: "center",
                    render: "Destacado",
                    cellRender: { Destacado },
                    allowSorting: true,
                    dataFields: "destacado",
                    allowFiltering: false,
                  },
                  {
                    caption: "Url",
                    alignment: "center",
                    width: 50,
                    render: "Slug",
                    cellRender: { Slug },
                  },
                  { caption: "Creado por", dataField: "creado_por.username" },
                  {
                    caption: "Actualizado por",
                    dataField: "actualizado_por.username",
                  },
                  {
                    caption: "Ult. Actualización",
                    render: "FActualizacion",
                    cellRender: { FActualizacion },
                  },
                  {
                    caption: "Inactivo/Activo",
                    alignment: "center",
                    render: "OnOff",
                    cellRender: { OnOff },
                  },
                  {
                    caption: "Acción",
                    alignment: "center",
                    render: "Accion",
                    cellRender: { Accion },
                    hidingPriority: 0,
                  },
                ]}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    isLoadingAction: (data) => {
      dispatch(loadingAction(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ListaNovedades);
