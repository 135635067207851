import React from "react";
import { Row, Col, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Fetch, catchError } from "../../../utils/fetch";
import { connect } from "react-redux";
import { loadingAction, categoriaAction } from "../../../redux/actions";
import DataGridForm from "../../../components/common/DataGridForm";

function ListaCategorias({ isLoadingAction, isLoading, categoriaAction }) {
  const [contenido, setContenido] = React.useState([]);

  React.useEffect(() => {
    getContenido();
  }, []);

  const getContenido = async () => {
    isLoadingAction(true);
    try {
      const { data } = await Fetch("categorias", "get");
      setContenido(data);
      isLoadingAction(false);
    } catch (error) {
      console.error("mostrando error", error);
      isLoadingAction(false);
      catchError(error);
    }
  };

  function Accion(data) {
    return (
      <>
        <Link
          onClick={(e)=>categoriaAction(data.nombre)}
          to={"/categorias/ver/" + data.id}
          className="btn btn-sm btn btn-success"
        >
          <i className="fa fa-pencil" />
        </Link>{" "}
      </>
    );
  }

  return (
    <React.Fragment>
      <Row>
        <Col md="12">
          <Card>
            <Card.Header>Categorías</Card.Header>
            <Card.Body>
              {/* <table class="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">Nombre de categoría</th>
                    <th scope="col">Secciones</th>
                    <th scope="col">Acción</th>
                  </tr>
                </thead>
                <tbody>
                  {contenido?.map((categoria) => (
                    <tr onClick={(e)=>categoriaAction(categoria.nombre)}>
                      <td>{categoria.nombre}</td>
                      <td>{categoria.secciones}</td>
                      <td style={{display:"flex",justifyContent:"center"}}><Link to={"/categorias/ver/" + categoria.id} className="btn btn-sm btn btn-success"><i className="fa fa-pencil" /></Link>{' '}</td>
                    </tr>
                  ))}
                </tbody>
              </table> */}

              <DataGridForm
                dataSource={contenido}
                keyExpr="id"
                allowReordering={true}
                columnHidingEnabled={false}
                columns={[
                  // { caption: "Id", dataField: "id" },
                  { caption: "Nombre", dataField: "nombre" },
                  { caption: "Secciones",  dataField: "secciones" },
                  {
                    caption: "Acción",
                    alignment: "center",
                    render: "Accion",
                    cellRender: { Accion },
                    hidingPriority: 0,
                  },
                ]}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.loading,
    categoria: state.categoria,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    isLoadingAction: (data) => {
      dispatch(loadingAction(data));
    },
    categoriaAction: (data) => {
      dispatch(categoriaAction(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ListaCategorias);
