import React from "react";
import { Button, Col, Container, Form, Image, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { AlertError, AlertInfo, AlertSuccess } from "../../../components/alert";
import { loginAction, permissionsAction } from "../../../redux/actions";
import { catchError, Fetch } from "../../../utils/fetch";

function LoginPage(props) {
  const history = useHistory();
  const { loginActionProps, permissionsActionProps } = props;
  const [user, setUser] = React.useState(null);
  const [pass, setPass] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [type, setType] = React.useState(true);

  const handleSubmit = async () => {
    localStorage.clear();
    const params = {
      identifier: user,
      password: pass,
    };
    try {
      setLoading(true);
      const { data } = await Fetch("auth/local", "post", params);
      localStorage.setItem("jwt_token", data.jwt);
      loginActionProps(data.jwt, data.user);
      checkPermissions(data.user.role.id);
      setLoading(false);
    } catch (error) {
      catchError(error);
      AlertInfo("Credenciales incorrectas, favor verificar");
      setLoading(false);
      console.error("mostrando error login", error);
    }
  };

  const checkPermissions = async (id) => {
    try {
      setLoading(true);
      const { data } = await Fetch("users-permissions/roles/" + id, "get");
      const permissions = data.role.permissions.application.controllers;
      permissionsActionProps(permissions);
      AlertSuccess("Login exitoso!");
      history.push("/dashboard");
      //localStorage.setItem('jwt_token', data.jwt)
      //loginActionProps(data.jwt, data.user)
      //setLoading(false)
    } catch (error) {
      catchError(error);
      AlertError(
        "Se ha presentado un problema al intentar hacer login, verifique usuario y contraseña"
      );
      setLoading(false);
      console.error("mostrando error login", error);
    }
  };
  return (
    <React.Fragment>
      <div className="misc-wrapper">
        <div className="misc-content">
          <Container>
            <Row className="justify-content-center">
              <Col sm="12" md="5" lg="4" className="col-4">
                <div to="#javascript" className="misc-header text-center">
                  <Link to="/">
                    <Image
                      alt=""
                      src="/assets/img/logo.png"
                      className="toggle-none hidden-xs"
                    />
                  </Link>
                </div>
                <div className="misc-box">
                  <Form>
                    <Form.Group>
                      <label htmlFor="exampleuser1">Usuario</label>
                      <div className="group-icon">
                        <input
                          id="exampleuser1"
                          type="text"
                          onChange={(e) => setUser(e.target.value)}
                          placeholder="Usuario"
                          className="form-control"
                        />
                        <span className="icon-user text-muted icon-input" />
                      </div>
                    </Form.Group>
                    <Form.Group>
                      <label htmlFor="exampleInputPassword1">Contraseña</label>
                      <div className="group-icon">
                        <input
                          id="exampleInputPassword1"
                          type={type ? "password" : "text"}
                          onChange={(e) => setPass(e.target.value)}
                          placeholder="Password"
                          className="form-control"
                        />
                        <span
                          className={
                            (type ? "icon-lock" : "icon-lock-open") +
                            " text-muted icon-input"
                          }
                          style={{ cursor: "pointer" }}
                          onClick={() => setType(!type)}
                        />
                      </div>
                    </Form.Group>
                    <Button
                      type="button"
                      onClick={() => {
                        handleSubmit();
                      }}
                      disabled={loading}
                      className="btn btn-block btn-primary btn-rounded box-shadow"
                    >
                      Iniciar
                    </Button>
                  </Form>
                </div>
                <div className="text-center misc-footer">
                  <p>Copyright © 2024 Hospital Británico</p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </React.Fragment>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    loginActionProps: (data, user) => {
      dispatch(loginAction(data, user));
    },
    permissionsActionProps: (data) => {
      dispatch(permissionsAction(data));
    },
  };
};

export default connect(null, mapDispatchToProps)(LoginPage);
