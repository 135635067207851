import * as Yup from 'yup';

export const validateModel = Yup.object().shape({

    titulo: Yup.string().required('Campo obligatorio'),
    // imagen: Yup.string().required('Campo obligatorio'),
    destacado: Yup.bool().required('Campo obligatorio'),
    descripcionCorta: Yup.string().required('Campo obligatorio'),
    descripcion: Yup.string().required('Campo obligatorio'),

});