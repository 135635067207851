import React, { useState } from "react";
import { Row, Col, Card, Button, Accordion } from "react-bootstrap";
import { Fetch, catchError } from "../../../utils/fetch";
import { useHistory, useParams } from "react-router-dom";
import { connect } from "react-redux";
import { Formik, FieldArray } from "formik";
import { loadingAction } from "../../../redux/actions";
import { AlertSuccess, AlertInfo } from "../../../components/alert";
import { validateModel } from "./validationSchema";
import { EstadoContenido } from "../../../utils/constants";
import useDragMenu from "../../../hooks/useDragMenu";

function VerBorradorMenu({
  user,
  token,
  isLoading,
  isLoadingAction,
  permissions,
}) {
  const params = useParams();

  const { id } = params;
  const history = useHistory();
  const [formValues, setFormValues] = useState({});
  const canPublish = permissions.menus.aprobar.enabled;
  const [errores, setErrores] = React.useState({});

  const { handleDragOver, handleDragStart, handleDrop, sortArray } =
    useDragMenu(setFormValues);

  React.useEffect(() => {
    if (id) {
      getMenusById(id);
    }
  }, [id]);

  const getMenusById = async (id) => {
    isLoadingAction(true);
    try {
      const { data } = await Fetch(
        "menus/" + id + "?_publicationState=preview",
        "get"
      );

      isLoadingAction(false);

      sortArray(data.Menu);
      setFormValues(data);
    } catch (error) {
      isLoadingAction(false);
      catchError(error);
    }
  };

  const initialValues = {
    locale: "es",
    Menu: {
      locales: "es",
      Menu: {
        nombre: "",
        url: "#",
        esBoton: false,
        onClick: "",
        enlaceInterno: true,
      },
      SubmenuColumna1: [
        {
          nombre: "",
          url: "#",
          esBoton: false,
          onClick: "",
          enlaceInterno: true,
        },
      ],
      SubmenuColumna2: [
        {
          nombre: "",
          url: "#",
          esBoton: false,
          onClick: "",
          enlaceInterno: true,
        },
      ],
    },
  };

  const submit = async (values, type) => {
    isLoadingAction(true);

    values.published_at = null;
    values.estado = EstadoContenido.EnRevision;

    try {
      await Fetch("menus/" + id, "put", values);

      isLoadingAction(false);

      AlertInfo(
        "Menú actualizado con éxito.<br/>Recuerde que falta su confirmación para aprobar.<br/> Nota: Por favor, notificar al responsable de publicar"
      );
      history.push("/menus/en-revision");
    } catch (error) {
      isLoadingAction(false);
      catchError(error);
    }
  };

  const onEnviarAprobar = async (values) => {
    isLoadingAction(true);

    values.estado = EstadoContenido.EnAprobacion;

    try {
      await Fetch("menus/" + id, "put", values);

      isLoadingAction(false);

      AlertSuccess("Contenido enviado con éxito");
      history.push("/menus/listax");
    } catch (error) {
      isLoadingAction(false);
      catchError(error);
    }
  };

  return (
    <>
      <Formik
        initialValues={formValues || initialValues}
        enableReinitialize
        validationSchema={validateModel}
        onSubmit={(values, { setSubmitting }) => {
          submit(values);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
          /* and other goodies */
        }) => (
          <form onSubmit={handleSubmit}>
            <Row>
              <Col>
                <Card>
                  <Card.Header>Información del menú</Card.Header>
                  <Card.Body>
                    <Row>
                      <Col>
                        <div className="form-group">
                          <label>Nombre</label>
                          <input
                            type="text"
                            name="Menu.Menu.nombre"
                            className="form-control"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values?.Menu?.Menu?.nombre || ""}
                          />
                          <div className="text-danger">
                            {errors?.Menu?.Menu?.nombre}
                          </div>
                        </div>
                      </Col>
                      <Col>
                        <div className="form-group">
                          <label>URL</label>
                          <input
                            type="text"
                            name="Menu.Menu.url"
                            className="form-control"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values?.Menu?.Menu?.url}
                          />
                          <div className="text-danger">
                            {errors?.Menu?.Menu?.url}
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className="form-group">
                          <label>¿Es botón?</label>
                          <select
                            className="form-control"
                            name="Menu.Menu.esBoton"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values?.Menu?.Menu?.esBoton}
                          >
                            <option value={true}>Si</option>
                            <option value={false}>No</option>
                          </select>
                        </div>
                      </Col>
                      <Col>
                        <div className="form-group">
                          <label>Al dar click</label>
                          <input
                            type="text"
                            name="Menu.Menu.onClick"
                            className="form-control"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values?.Menu?.Menu?.onClick}
                          />
                          <div className="text-danger">
                            {errors?.Menu?.Menu?.onClick}
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className="form-group">
                          <label>¿El enlace es interno?</label>
                          <select
                            className="form-control"
                            name={`Menu.Menu.enlaceInterno`}
                            value={values?.Menu?.Menu?.enlaceInterno}
                            onChange={handleChange}
                          >
                            <option value={true}>Si</option>
                            <option value={false}>No</option>
                          </select>
                        </div>
                      </Col>
                    </Row>
                    {/* Columna1 */}
                    <Row>
                      <Col>
                        <h2>Submenu Columna1</h2>
                        <FieldArray name="Menu.SubmenuColumna1">
                          {({
                            move,
                            swap,
                            push,
                            remove,
                            insert,
                            unshift,
                            pop,
                            form,
                          }) => {
                            return (
                              <>
                                {values?.Menu?.SubmenuColumna1 &&
                                  values?.Menu?.SubmenuColumna1.length > 0 &&
                                  values?.Menu?.SubmenuColumna1.map(
                                    (data, index) => {
                                      return (
                                        <Row key={index}>
                                          <Col>
                                            <Accordion>
                                              <Card
                                                className="border mb-2"
                                                draggable
                                                onDragStart={(e) =>
                                                  handleDragStart(e, index)
                                                }
                                                onDragOver={handleDragOver}
                                                onDrop={(e) =>
                                                  handleDrop(
                                                    e,
                                                    index,
                                                    formValues,
                                                    "SubmenuColumna1"
                                                  )
                                                }
                                              >
                                                <Card.Header>
                                                  <i
                                                    class="fad fa-grip-vertical"
                                                    style={{
                                                      cursor: "all-scroll",
                                                    }}
                                                  />
                                                  <Accordion.Toggle
                                                    as={Button}
                                                    variant="link"
                                                    eventKey="0"
                                                  >
                                                    {
                                                      values.Menu
                                                        .SubmenuColumna1[index]
                                                        ?.nombre
                                                    }
                                                  </Accordion.Toggle>
                                                  <button
                                                    type="button"
                                                    className="btn btn-default"
                                                    style={{ float: "right" }}
                                                    onClick={() =>
                                                      remove(index)
                                                    }
                                                  >
                                                    X
                                                  </button>
                                                </Card.Header>
                                                <Accordion.Collapse eventKey="0">
                                                  <Card.Body>
                                                    <Row>
                                                      <Col>
                                                        <div className="form-group">
                                                          <label>Nombre</label>
                                                          <input
                                                            type="text"
                                                            name={`Menu.SubmenuColumna1.${index}.nombre`}
                                                            className="form-control"
                                                            onChange={
                                                              handleChange
                                                            }
                                                            onBlur={handleBlur}
                                                            value={
                                                              values?.Menu
                                                                .SubmenuColumna1[
                                                                index
                                                              ]?.nombre
                                                            }
                                                          />
                                                          {errors &&
                                                            errors.Menu &&
                                                            errors.Menu
                                                              .SubmenuColumna1 &&
                                                            errors.Menu
                                                              .SubmenuColumna1[
                                                              index
                                                            ] &&
                                                            errors.Menu
                                                              .SubmenuColumna1[
                                                              index
                                                            ].nombre && (
                                                              <div className="text-danger">
                                                                {
                                                                  errors?.Menu
                                                                    ?.SubmenuColumna1[
                                                                    index
                                                                  ]?.nombre
                                                                }
                                                              </div>
                                                            )}
                                                        </div>
                                                      </Col>
                                                      <Col>
                                                        <div className="form-group">
                                                          <label>URL</label>
                                                          <input
                                                            type="text"
                                                            name={`Menu.SubmenuColumna1.${index}.url`}
                                                            className="form-control"
                                                            onChange={
                                                              handleChange
                                                            }
                                                            onBlur={handleBlur}
                                                            value={
                                                              values.Menu
                                                                .SubmenuColumna1[
                                                                index
                                                              ].url
                                                            }
                                                          />
                                                          {errors &&
                                                            errors.Menu &&
                                                            errors.Menu
                                                              .SubmenuColumna1 &&
                                                            errors.Menu
                                                              .SubmenuColumna1[
                                                              index
                                                            ] &&
                                                            errors.Menu
                                                              .SubmenuColumna1[
                                                              index
                                                            ].url && (
                                                              <div className="text-danger">
                                                                {
                                                                  errors?.Menu
                                                                    ?.SubmenuColumna1[
                                                                    index
                                                                  ]?.url
                                                                }
                                                              </div>
                                                            )}
                                                        </div>
                                                      </Col>
                                                    </Row>
                                                    <Row>
                                                      <Col>
                                                        <div className="form-group">
                                                          <label>
                                                            ¿Es botón?
                                                          </label>
                                                          <select
                                                            className="form-control"
                                                            name={`Menu.SubmenuColumna1.${index}.esBoton`}
                                                            onChange={
                                                              handleChange
                                                            }
                                                            onBlur={handleBlur}
                                                            value={
                                                              values.Menu
                                                                .SubmenuColumna1[
                                                                index
                                                              ].esBoton
                                                            }
                                                          >
                                                            <option
                                                              value={true}
                                                            >
                                                              Si
                                                            </option>
                                                            <option
                                                              value={false}
                                                            >
                                                              No
                                                            </option>
                                                          </select>
                                                        </div>
                                                      </Col>
                                                      <Col>
                                                        <div className="form-group">
                                                          <label>OnClick</label>
                                                          <input
                                                            type="text"
                                                            name={`Menu.SubmenuColumna1.${index}.onClick`}
                                                            className="form-control"
                                                            onChange={
                                                              handleChange
                                                            }
                                                            onBlur={handleBlur}
                                                            value={
                                                              values.Menu
                                                                .SubmenuColumna1[
                                                                index
                                                              ].onClick
                                                            }
                                                          />
                                                          {errors &&
                                                            errors.Menu &&
                                                            errors.Menu
                                                              .SubmenuColumna1 &&
                                                            errors.Menu
                                                              .SubmenuColumna1[
                                                              index
                                                            ] &&
                                                            errors.Menu
                                                              .SubmenuColumna1[
                                                              index
                                                            ].onClick && (
                                                              <div className="text-danger">
                                                                {
                                                                  errors?.Menu
                                                                    ?.SubmenuColumna1[
                                                                    index
                                                                  ]?.onClick
                                                                }
                                                              </div>
                                                            )}
                                                        </div>
                                                      </Col>
                                                    </Row>
                                                    <Row>
                                                      <Col>
                                                        <div className="form-group">
                                                          <label>
                                                            ¿El enlace es
                                                            interno?
                                                          </label>
                                                          <select
                                                            className="form-control"
                                                            name={`Menu.SubmenuColumna1.${index}.enlaceInterno`}
                                                            value={
                                                              values.Menu
                                                                .SubmenuColumna1[
                                                                index
                                                              ].enlaceInterno
                                                            }
                                                            onChange={
                                                              handleChange
                                                            }
                                                          >
                                                            <option
                                                              value={true}
                                                            >
                                                              Si
                                                            </option>
                                                            <option
                                                              value={false}
                                                            >
                                                              No
                                                            </option>
                                                          </select>
                                                        </div>
                                                      </Col>
                                                    </Row>
                                                  </Card.Body>
                                                </Accordion.Collapse>
                                              </Card>
                                            </Accordion>
                                          </Col>
                                        </Row>
                                      );
                                    }
                                  )}
                                <button
                                  type="button"
                                  className="btn btn-secondary"
                                  onClick={() =>
                                    push({
                                      nombre: "nombreMenu",
                                      url: "#",
                                      esBoton: false,
                                      onClick: "",
                                      enlaceInterno: true,
                                    })
                                  }
                                >
                                  Añadir Item
                                </button>
                              </>
                            );
                          }}
                        </FieldArray>
                      </Col>
                      <Col>
                        <h2>Submenu Columna2</h2>
                        <FieldArray name="Menu.SubmenuColumna2">
                          {({
                            move,
                            swap,
                            push,
                            remove,
                            insert,
                            unshift,
                            pop,
                            form,
                          }) => {
                            return (
                              <>
                                {values?.Menu?.SubmenuColumna2 &&
                                  values?.Menu?.SubmenuColumna2.length > 0 &&
                                  values?.Menu?.SubmenuColumna2.map(
                                    (data, index) => {
                                      return (
                                        <Row key={index}>
                                          <Col>
                                            <Accordion>
                                              <Card
                                                className="border mb-2"
                                                draggable
                                                onDragStart={(e) =>
                                                  handleDragStart(e, index)
                                                }
                                                onDragOver={handleDragOver}
                                                onDrop={(e) =>
                                                  handleDrop(
                                                    e,
                                                    index,
                                                    formValues,
                                                    "SubmenuColumna2"
                                                  )
                                                }
                                              >
                                                <Card.Header>
                                                  <i
                                                    class="fad fa-grip-vertical"
                                                    style={{
                                                      cursor: "all-scroll",
                                                    }}
                                                  />
                                                  <Accordion.Toggle
                                                    as={Button}
                                                    variant="link"
                                                    eventKey="0"
                                                  >
                                                    {
                                                      values.Menu
                                                        .SubmenuColumna2[index]
                                                        ?.nombre
                                                    }
                                                  </Accordion.Toggle>
                                                  <button
                                                    type="button"
                                                    className="btn btn-default"
                                                    style={{ float: "right" }}
                                                    onClick={() =>
                                                      remove(index)
                                                    }
                                                  >
                                                    X
                                                  </button>
                                                </Card.Header>
                                                <Accordion.Collapse eventKey="0">
                                                  <Card.Body>
                                                    <Row>
                                                      <Col>
                                                        <div className="form-group">
                                                          <label>Nombre</label>
                                                          <input
                                                            type="text"
                                                            name={`Menu.SubmenuColumna2.${index}.nombre`}
                                                            className="form-control"
                                                            onChange={
                                                              handleChange
                                                            }
                                                            onBlur={handleBlur}
                                                            value={
                                                              values.Menu
                                                                ?.SubmenuColumna2[
                                                                index
                                                              ]?.nombre
                                                            }
                                                          />
                                                          {errors &&
                                                            errors.Menu &&
                                                            errors.Menu
                                                              .SubmenuColumna2 &&
                                                            errors.Menu
                                                              .SubmenuColumna2[
                                                              index
                                                            ] &&
                                                            errors.Menu
                                                              .SubmenuColumna2[
                                                              index
                                                            ].nombre && (
                                                              <div className="text-danger">
                                                                {
                                                                  errors?.Menu
                                                                    ?.SubmenuColumna2[
                                                                    index
                                                                  ]?.nombre
                                                                }
                                                              </div>
                                                            )}
                                                        </div>
                                                      </Col>
                                                      <Col>
                                                        <div className="form-group">
                                                          <label>URL</label>
                                                          <input
                                                            type="text"
                                                            name={`Menu.SubmenuColumna2.${index}.url`}
                                                            className="form-control"
                                                            onChange={
                                                              handleChange
                                                            }
                                                            onBlur={handleBlur}
                                                            value={
                                                              values.Menu
                                                                .SubmenuColumna2[
                                                                index
                                                              ].url
                                                            }
                                                          />
                                                          {errors &&
                                                            errors.Menu &&
                                                            errors.Menu
                                                              .SubmenuColumna2 &&
                                                            errors.Menu
                                                              .SubmenuColumna2[
                                                              index
                                                            ] &&
                                                            errors.Menu
                                                              .SubmenuColumna2[
                                                              index
                                                            ].url && (
                                                              <div className="text-danger">
                                                                {
                                                                  errors?.Menu
                                                                    ?.SubmenuColumna2[
                                                                    index
                                                                  ]?.url
                                                                }
                                                              </div>
                                                            )}
                                                        </div>
                                                      </Col>
                                                    </Row>
                                                    <Row>
                                                      <Col>
                                                        <div className="form-group">
                                                          <label>
                                                            ¿Es botón?
                                                          </label>
                                                          <select
                                                            className="form-control"
                                                            name={`Menu.SubmenuColumna2.${index}.esBoton`}
                                                            onChange={
                                                              handleChange
                                                            }
                                                            onBlur={handleBlur}
                                                            value={
                                                              values.Menu
                                                                .SubmenuColumna2[
                                                                index
                                                              ].esBoton
                                                            }
                                                          >
                                                            <option
                                                              value={true}
                                                            >
                                                              Si
                                                            </option>
                                                            <option
                                                              value={false}
                                                            >
                                                              No
                                                            </option>
                                                          </select>
                                                        </div>
                                                      </Col>
                                                      <Col>
                                                        <div className="form-group">
                                                          <label>OnClick</label>
                                                          <input
                                                            type="text"
                                                            name={`Menu.SubmenuColumna2.${index}.onClick`}
                                                            className="form-control"
                                                            onChange={
                                                              handleChange
                                                            }
                                                            onBlur={handleBlur}
                                                            value={
                                                              values.Menu
                                                                .SubmenuColumna2[
                                                                index
                                                              ].onClick
                                                            }
                                                          />
                                                          {errors &&
                                                            errors.Menu &&
                                                            errors.Menu
                                                              .SubmenuColumna2 &&
                                                            errors.Menu
                                                              .SubmenuColumna2[
                                                              index
                                                            ] &&
                                                            errors.Menu
                                                              .SubmenuColumna2[
                                                              index
                                                            ].onClick && (
                                                              <div className="text-danger">
                                                                {
                                                                  errors?.Menu
                                                                    ?.SubmenuColumna2[
                                                                    index
                                                                  ]?.onClick
                                                                }
                                                              </div>
                                                            )}
                                                        </div>
                                                      </Col>
                                                    </Row>
                                                    <Row>
                                                      <Col>
                                                        <div className="form-group">
                                                          <label>
                                                            ¿El enlace es
                                                            interno?
                                                          </label>
                                                          <select
                                                            className="form-control"
                                                            name={`Menu.SubmenuColumna2.${index}.enlaceInterno`}
                                                            value={
                                                              values.Menu
                                                                .SubmenuColumna2[
                                                                index
                                                              ].enlaceInterno
                                                            }
                                                            onChange={
                                                              handleChange
                                                            }
                                                          >
                                                            <option
                                                              value={true}
                                                            >
                                                              Si
                                                            </option>
                                                            <option
                                                              value={false}
                                                            >
                                                              No
                                                            </option>
                                                          </select>
                                                        </div>
                                                      </Col>
                                                    </Row>
                                                  </Card.Body>
                                                </Accordion.Collapse>
                                              </Card>
                                            </Accordion>
                                          </Col>
                                        </Row>
                                      );
                                    }
                                  )}
                                <button
                                  type="button"
                                  className="btn btn-secondary"
                                  onClick={() =>
                                    push({
                                      nombre: "nombreMenu",
                                      url: "#",
                                      esBoton: false,
                                      onClick: "",
                                      enlaceInterno: true,
                                    })
                                  }
                                >
                                  Añadir Item
                                </button>
                              </>
                            );
                          }}
                        </FieldArray>
                      </Col>
                    </Row>

                    <Row className="mt-5">
                      <Col className="col col-lg-2">
                        <Button
                          type="button"
                          onClick={() => handleSubmit()}
                          disabled={isLoading}
                          className="btn btn-success btn-icon"
                        >
                          <i className="fa fa-floppy-o " />
                          Guardar
                        </Button>
                      </Col>
                      <Col className="col col-lg-3">
                        <Button
                          type="button"
                          onClick={() => onEnviarAprobar(values)}
                          disabled={isLoading}
                          className="btn btn-info btn-icon"
                        >
                          <i className="fa fa-share " />
                          Enviar aprobar
                        </Button>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </form>
        )}
      </Formik>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.user.data,
    token: state.user.token,
    isLoading: state.loading,
    permissions: state.permissions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    isLoadingAction: (data) => {
      dispatch(loadingAction(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VerBorradorMenu);
