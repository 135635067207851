import React, { useMemo, useState } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import JoditEditor, { Jodit } from "jodit-react";
import { useFormik } from "formik";
import { Fetch, catchError } from "../../../utils/fetch";
import { useDispatch, useSelector } from "react-redux";
import { AlertInfo, AlertSuccess } from "../../alert";
import { loadingAction } from "../../../redux/actions";
import MediaUploader from "../../../utils/media-uploader";
import { useHistory } from "react-router-dom";
import { validateModel } from "./validationSchema";
import { BASE_URL, EstadoContenido } from "../../../utils/constants";
import { removeSpecial } from "../../../utils/tools";
import LayoutJodit from "../../customizer/layout-jodit";

const Template1Component = ({ dataResult }) => {
  const editor = React.useRef(null);
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.loading);
  const permissions = useSelector((state) => state.permissions);
  const [layoutJodit, setLayoutJodit] = useState("PC");
  const canPublish = permissions.paginas.aprobar.enabled;
  const history = useHistory();

  React.useEffect(() => {
    if (dataResult) {
      setValues(dataResult);
    }
  }, [dataResult]);

  const token = localStorage.getItem("jwt_token");
  //const [isLoading, setIsLoading] = React.useState(false);

  const config = useMemo(
    () => ({
      readonly: false,
      language: "es",
      disablePlugins: ["table"],
      askBeforePasteHTML: false,
      askBeforePasteFromWord: false,
      defaultActionOnPaste: "insert_clear_html",
      enableDragAndDropFileToEditor: true,
      uploader: {
        url: BASE_URL + "/upload",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("jwt_token"),
        },
        data: null,
        filesVariableName: function (i) {
          return "files";
        },
        withCredentials: false,
        pathVariableName: "path",
        format: "json",
        method: "POST",
        prepareData: function (formData) {
          formData.append("id", 1);
        },
        isSuccess: function (resp) {
          return !resp.error;
        },
        getMessage: function (resp) {
          return resp.msgs.join("\n");
        },
        process: function (resp) {
          return resp;
        },
        defaultHandlerSuccess: function (resp) {
          var imagenesPermitidas = ["image/jpeg", "image/png"];
          for (var i = 0; i < resp.length; i++) {
            if (imagenesPermitidas.indexOf(resp[i].mime) !== -1) {
              var img1 = new Image();
              img1.src = resp[i]["url"];
              img1.alt = "imagen";
              img1.className = "img-fluid";
              this.s.insertImage(img1);
            } else if (resp[i].mime === "application/pdf") {
              const enlace = document.createElement("a");
              enlace.setAttribute("href", resp[i].url);
              const contenido = document.createTextNode(resp[i].name);
              // add the text node to the newly created div
              enlace.appendChild(contenido);
              this.s.insertNode(enlace);
            } else {
              AlertInfo("Tipo de archivo no permitido");
            }
          }
        },
      },
    }),
    []
  );

  const submit = async (values) => {
    dispatch(loadingAction(true));

    values.published_at = null;
    values.estado = EstadoContenido.EnRevision;

    //values.template1.contenido=removeSpecial(values.template1.contenido);

    try {
      const { data } = await Fetch("paginas", "post", values);

      dispatch(loadingAction(false));

      AlertInfo(
        "Página creada con éxito.<br/>Recuerde que falta su confirmación para aprobar.<br/> Nota: Por favor, notificar al responsable de publicar"
      );
      history.push("/pages/lista-borradores");
    } catch (error) {
      catchError(error);
      dispatch(loadingAction(false));
    }
  };

  const {
    values,
    handleSubmit,
    handleChange,
    setFieldValue,
    setValues,
    validateForm,
    errors,
  } = useFormik({
    initialValues: {
      nombre: "",
      slug: "",
      template: "template1",
      template1: {
        titulo_imagen: "",
        titulo: "",
        imagen: "",
        contenido: "",
      },
      locale: "es",
    },
    enableReinitialize: true,
    validationSchema: validateModel,
    onSubmit: (values) => submit(values),
  });

  React.useEffect(() => {
    const validateFormImg = async () => {
      if (values.template1.imagen) {
        await validateForm();
      }
    };
    validateFormImg();
  }, [values.template1.imagen]);

  const handleBlurTitulo = async (event) => {
    const value = event.target.value;
    const name = event.target.name;

    const dataToApi = {
      contentTypeUID: "application::paginas.paginas",
      field: "slug",
      data: {
        nombre: value,
        slug: "",
      },
    };
    setFieldValue(name, value, false);
    try {
      const { data } = await Fetch(
        "content-manager/uid/generate",
        "post",
        dataToApi
      );
      if (data.data != "-1") {
        setFieldValue("slug", data.data, false);
      } else {
        setFieldValue("slug", "paginas-1", false);
      }
    } catch (error) {
      console.error("mostrando error", error);
    }
  };

  const handleRemoveImg = async () => {
    setFieldValue("template1.imagen", "", false);
    setTimeout(async () => {
      await validateForm();
    }, 1000);
  };

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit}>
        <Row>
          <Col>
            <h3>Nueva página</h3>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group>
              <label>Nombre de la página</label>
              <input
                type="text"
                name="nombre"
                onChange={handleChange}
                onBlur={(e) => handleBlurTitulo(e)}
                value={values.nombre}
                className="form-control "
              />
              <div className="text-danger">{errors?.nombre}</div>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group>
              <label>Url</label>
              <input
                type="text"
                name="slug"
                className="form-control"
                value={values.slug}
                readOnly
              />
            </Form.Group>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col>
            <h3>Título e imagen (opcional)</h3>
          </Col>
        </Row>
        {values.template1?.imagen && (
          <>
            <Row>
              <Col>
                <Form.Group>
                  <img
                    src={values?.template1?.imagen?.url}
                    alt="vista-previa"
                    className="img-fluid w-25"
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Button
                  type="button"
                  style={{ marginBottom: "10px" }}
                  onClick={handleRemoveImg}
                  className="btn btn-danger btn-icon mt-2"
                >
                  <i className="fa fa-trash-o " />
                  Eliminar
                </Button>
              </Col>
            </Row>
          </>
        )}
        <Row>
          <Col>
            <Form.Group>
              <label>Título imagen</label>
              <input
                type="text"
                name="template1.titulo_imagen"
                className="form-control"
                onChange={handleChange}
                value={values.template1.titulo_imagen}
              />
              <div className="text-danger">
                {errors?.template1?.titulo_imagen}
              </div>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group>
              <label>Imagen</label>
              <MediaUploader
                value={values.template1.imagen ? 100 : 0}
                key={values.template1.imagen}
                setFieldValue={setFieldValue}
                field={"template1.imagen"}
                tipoMedia="imagen"
                ancho={300}
                alto={370}
              />
              <div className="text-danger">{errors?.template1?.imagen}</div>
              <span className="text-muted">
                Se recomienda una imagen de 300px de ancho x 370px de alto. En
                formato JPG o PNG.
              </span>
            </Form.Group>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col>
            <h3>Información del contenido</h3>
          </Col>
        </Row>

        <Row>
          <Col>
            <Form.Group>
              <label>Título</label>
              <input
                type="text"
                name="template1.titulo"
                onChange={handleChange}
                value={values.template1.titulo}
                className="form-control "
              />
              <div className="text-danger">{errors?.template1?.titulo}</div>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col className={`${layoutJodit == "MOBILE" && "col-6"}`}>
            <Form.Group>
              <label>Contenido</label>
              <LayoutJodit
                setLayoutJodit={setLayoutJodit}
                layoutJodit={layoutJodit}
              />
              <JoditEditor
                ref={editor}
                config={config}
                value={values.template1.contenido}
                tabIndex={1} // tabIndex of textarea
                onBlur={(v) => setFieldValue(`template1.contenido`, v, false)}
              />
              <div className="text-danger">{errors?.template1?.contenido}</div>
            </Form.Group>
          </Col>
        </Row>
        <Button
          type="button"
          onClick={() => handleSubmit()}
          disabled={isLoading}
          className="btn btn-success btn-icon mt-2"
        >
          <i className="fa fa-floppy-o " />
          Guardar
        </Button>
      </form>
    </React.Fragment>
  );
};
export default Template1Component;
